import { Injectable } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { GoogleAnalyticsService } from "ngx-google-analytics";
import { environment } from "src/environments/environment";

@Injectable()
export class AnalyticsHelper {

    private static _instance: AnalyticsHelper;

    constructor(private gaService: GoogleAnalyticsService, private title: Title) {
        AnalyticsHelper._instance = this;
    }

    static get instance() {
        return this._instance;
    }

    logEvent(eventName: string, eventData: Object) {
        this.gaService.gtag('event',eventName,eventData);
        // this.gaService.event(eventName, undefined,undefined,undefined, undefined, eventData);
        console.log("Analytics - Event name: " + eventName + ", data: " + JSON.stringify(eventData));
    }

    pageView(title: string) {
        this.title.setTitle("FLRHUB | " + title);
        this.gaService.pageView(window.location.pathname, title);
        this.gaService.appView(title, "FLRHUB " + environment.production ? "Production" : "Staging");
        this.gaService.gtag('config', environment.firebase.measurementId, {
            'page_title' : title,
            'page_path': window.location.pathname
          });
        console.log("Analytics - Page view: " + title + ", location" + window.location.pathname);
    }

}