import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';
import { PaymentsHelper } from 'src/app/helpers/PaymentsHelper';
import { AlertViewComponent } from 'src/app/modals/alert-view/alert-view.component';
import { CreditCardComponent } from 'src/app/modals/credit-card/credit-card.component';

@Component({
  selector: 'app-account-cancelled',
  templateUrl: './account-cancelled.component.html',
  styleUrls: ['./account-cancelled.component.scss']
})
export class AccountCancelledComponent implements OnInit {

  loading = false;

  constructor(public dialog: MatDialog, private router: Router) {
    console.log('Account Cancelled component loaded');
   }

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView("Account Cancelled");
  }

  payment() {
    this.dialog.open(CreditCardComponent, {
      disableClose: false,
      maxWidth: '600px',
      maxHeight: '-webkit-fill-available',
      panelClass: ['profile-modal', 'no-padding', 'no-animation'],
      id: 'credit-card-modal',
    });
    DatabaseHelper.instance.dataCallbacks.dataCallback = async (cardDetails: any) => {
      if (cardDetails.card) {
        this.loading = true;
        if (cardDetails.id !== '') {
          this.user.card = cardDetails.card;
          await PaymentsHelper.instance.updateSimplifyCustomer(this.user, cardDetails.id);
          await this.user.save();
        }
        PaymentsHelper.instance.subscribeSimplifyCustomer(this.user).then(async val => {
          if (val.error) {
            this.handleError();
          } else {
            this.user.status = "Activated";
            await this.user.save();
            this.router.navigate([""]);
          }
        }).catch(err => {
          this.handleError();
        });
      }
    }
  }

  handleError() {
    this.loading = false;
    this.dialog.open(AlertViewComponent, {
      disableClose: false,
      maxWidth: "400px",
      panelClass: ['alert-modal', 'no-padding'],
      id: 'alert-modal',
      data: {
        title: "Could not process payment.",
        message: "We could not process your payment using the card you entered.",
        primaryButton: "OK",
      }
    });
  }

  get user() {
    return AuthHelper.instance.user;
  }

}
