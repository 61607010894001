import { Component, OnInit } from '@angular/core';

import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import * as Glider from 'glider-js';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { ViewEncapsulation } from '@angular/core';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
  encapsulation: ViewEncapsulation.None, // allow styles to work on dynamic elements
})
export class LandingComponent implements OnInit {
  videoStart = 0;

  constructor() {}

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView('The future of flooring');

    // add indicator for page that require to listen to viewport
    document.querySelector('html')?.classList.add('has-scroll-trigger');

    // Listen to page wrapper rather than window viewport because the body overflow is hidden
    ScrollTrigger.defaults({
      //scroller: ".page-wrapper",
      invalidateOnRefresh: true,
    });

    // load our function
    this.initMenuJump();
    this.initSectionRevealItems();
    this.initSectionRevealSection();
    this.initRollingLogo();
    this.initStickyPricing();
    this.initLatestNewsLoop();
    this.initVideoPopup();
    this.footerCopyrightYear();

    // ScrollTrigger.normalizeScroll(true)
    ScrollTrigger.refresh();
  } // end ngOnInit

  // dynamic copyright year
  footerCopyrightYear() {
    if (document.querySelector('#current-year') == null) {
      return;
    }
    const currentYear = new Date().getFullYear() as any;
    const currentSelector = document.querySelector(
      '#current-year'
    ) as HTMLElement;
    currentSelector.innerHTML = currentYear;
  }

  // jump to section
  initMenuJump() {
    const navLink = document.querySelectorAll('#masthead ul li a');
    navLink.forEach((element) => {
      element.addEventListener('click', function (e) {
        const target = element.getAttribute('href') as string;
        const targetElement = document.querySelector(target) as HTMLElement;
        if (target.includes('#') && targetElement != null) {
          e.preventDefault();
          gsap.to(window, { duration: 0.7, scrollTo: target });
        }
      });
    });
  }

  // gsap reveal item
  initSectionRevealItems() {
    document.querySelectorAll('.-anim-container').forEach((el) => {
      if (el.querySelector('.-anim') != null) {
        const batchTarget = el.querySelectorAll('.-anim');
        gsap.set(batchTarget, { opacity: 0, y: 60 });
        ScrollTrigger.batch(batchTarget, {
          onEnter: (elements) => {
            gsap.to(elements, {
              opacity: 1,
              y: 0,
              stagger: 0.08,
              duration: 1,
            });
          },
          once: true,
        });
      }

      if (el.querySelector('.-anim-right') != null) {
        const batchTargetRight = el.querySelectorAll('.-anim-right');
        gsap.set(batchTargetRight, { opacity: 0, x: -60 });
        ScrollTrigger.batch(batchTargetRight, {
          onEnter: (elements) => {
            gsap.to(elements, {
              opacity: 1,
              x: 0,
              stagger: 0.08,
              duration: 1,
            });
          },
          once: true,
        });
      }

      if (el.querySelector('.-anim-left') != null) {
        const batchTargetLeft = el.querySelectorAll('.-anim-left');
        gsap.set(batchTargetLeft, { opacity: 0, x: 60 });
        ScrollTrigger.batch(batchTargetLeft, {
          onEnter: (elements) => {
            gsap.to(elements, {
              opacity: 1,
              x: 0,
              stagger: 0.08,
              duration: 1,
            });
          },
          once: true,
        });
      }
    });
  }

  // gsap reveal section
  initSectionRevealSection() {
    if (document.querySelector('.reveal-blocks') == null) {
      return;
    }
    document.querySelectorAll('.reveal-blocks').forEach((etarget) => {
      const theTarget = etarget as HTMLElement;
      ScrollTrigger.create({
        trigger: theTarget,
        pin: true,
        start: () => 'bottom bottom',
        end: () => {
          return '+=' + theTarget.offsetHeight;
        },
        pinSpacing: false,
        anticipatePin: 1,
        //pinType: 'fixed'
      });
    });
  }

  // rolling logo
  initRollingLogo() {
    if (document.querySelector('#partner-logo .rolling-logo') == null) {
      return;
    }
    var rollingLogo = document.querySelector(
      '#partner-logo .rolling-logo'
    ) as HTMLElement;
    var rollingLogoInner = rollingLogo.innerHTML;
    var logoList = document.querySelector(
      '#partner-logo .rolling-logo ul'
    ) as HTMLElement;
    var logoWidth = logoList.offsetWidth;
    var windWidth = window.innerWidth;
    var minWidth = windWidth * 4; // need to have 4 times to ensure fill the space

    // speed adjustment
    // 980px ~ 30s - Pretty happy with this speed from initial design 30/980 = 0.0306
    const speedPower = 0.0306;
    const speed = (logoWidth * speedPower).toFixed(0);

    // if too few iterate clone
    if (logoWidth < minWidth) {
      // create loop to append the list
      for (let i = 1; i < 100; i++) {
        rollingLogo.insertAdjacentHTML('beforeend', rollingLogoInner);

        // stop if reach the min width
        var logoNewWidth = i * logoWidth;
        if (logoNewWidth > minWidth) {
          break;
        }
      }
    } else {
      // ensure we have 3 loop
      for (let i = 1; i < 4; i++) {
        rollingLogo.insertAdjacentHTML('beforeend', rollingLogoInner);
      }
    }

    // weird safari bug - change marquee animation using gsap
    document
      .querySelectorAll('.partner-logo .rolling-logo ul')
      .forEach((el) => {
        gsap.to(el, {
          duration: speed,
          ease: 'none',
          xPercent: -100, //move each box 500px to right
          repeat: -1,
        });
      });
  }

  // sticky pricing
  initStickyPricing() {
    if (document.querySelector('.heading.row-pricing') == null) {
      return;
    }

    const pricingHeadEl = document.querySelector(
      '.heading.row-pricing'
    ) as HTMLElement;
    const headerEl = document.querySelector('#masthead') as HTMLElement;
    const pricingHead = pricingHeadEl.innerHTML;
    const pricingClone = document.createElement('div');
    pricingClone.classList.add('sticky-pricing');
    pricingClone.innerHTML =
      '<div class="inner-sticky boxed"><div class="container"><div class="row-pricing">' +
      pricingHead +
      '</div></div></div>';
    document.body.appendChild(pricingClone);

    // sticky pricing
    ScrollTrigger.create({
      trigger: '.pricing .heading',
      start: () => 'bottom top+=' + (headerEl.offsetHeight + 100),
      end: () => {
        const pricing = document.querySelector(
          '#pricing .pricing-group-wrapper'
        ) as HTMLElement;
        const pricingHeight = pricing.offsetHeight;
        const pricingPadTop = parseFloat(
          window.getComputedStyle(pricing, null).getPropertyValue('padding-top')
        );
        const pricingPadBottom = parseFloat(
          window.getComputedStyle(pricing, null).getPropertyValue('padding-top')
        );
        const headerHeight = headerEl.offsetHeight;
        const endDynamic =
          pricingHeight -
          pricingPadTop -
          pricingPadBottom -
          pricingPadBottom -
          headerHeight;
        return '+=' + endDynamic;
      },
      onEnter: () => {
        pricingClone.classList.add('appear');
      },
      onEnterBack: () => {
        pricingClone.classList.add('appear');
      },
      onLeave: () => {
        pricingClone.classList.remove('appear');
      },
      onLeaveBack: () => {
        pricingClone.classList.remove('appear');
      },
      //markers: true
    });
  }

  // latest news scroll if any
  initLatestNewsLoop() {
    if (document.querySelector('#latest-news') == null) {
      return;
    }

    const newsCards = document.querySelectorAll('.latest-news .card');
    const newsCardsHolder = document.querySelector(
      '.latest-news .cards-holder'
    ) as HTMLElement;
    newsCardsHolder.classList.add('size-' + newsCards.length);
    if (newsCards.length > 3) {
      newsCardsHolder.classList.add('has-scroller');
    }

    // only applied the slider if more than 3
    if (newsCards.length > 3) {
      const cardWrapper = document.querySelector(
        '.latest-news .cards-holder'
      ) as HTMLElement;
      var glideOptions = {
        slidesToScroll: 1,
        slidesToShow: 3.5,
        arrows: {
          prev: '.glider-prev',
          next: '.glider-next',
        },
      };
      //gsap.matchMedia().add("(min-width: 1001px)", () => {
      var glideSlider = new Glider(cardWrapper, glideOptions);
      //})
    }

    // scroll spy
    const cursor = document.querySelector('#scrollspy') as HTMLElement;
    let mouseX = 0;
    let mouseY = 0;
    let cursorX = 0;
    let cursorY = 0;
    let speed = 0.5; // change to increase the ease
    function animate() {
      let distX = mouseX - cursorX;
      let distY = mouseY - cursorY;
      cursorX = cursorX + distX * speed;
      cursorY = cursorY + distY * speed;
      cursor.style.left = cursorX + 'px';
      cursor.style.top = cursorY + 'px';
      requestAnimationFrame(animate);
    }

    animate();
    window.addEventListener('mousemove', (event) => {
      mouseX = event.clientX;
      mouseY = event.clientY;
    });

    // scroll style
    document
      .querySelector('.latest-news .glider-next')
      ?.addEventListener('mouseenter', () => {
        document
          .querySelector('#scrollspy')
          ?.classList.add('active', 'active-next');
      });
    document
      .querySelector('.latest-news .glider-next')
      ?.addEventListener('mouseleave', () => {
        document
          .querySelector('#scrollspy')
          ?.classList.remove('active', 'active-next');
      });
    document
      .querySelector('.latest-news .glider-prev')
      ?.addEventListener('mouseenter', () => {
        document
          .querySelector('#scrollspy')
          ?.classList.add('active', 'active-prev');
      });
    document
      .querySelector('.latest-news .glider-prev')
      ?.addEventListener('mouseleave', () => {
        document
          .querySelector('#scrollspy')
          ?.classList.remove('active', 'active-prev');
      });
  }

  // video popup
  initVideoPopup() {
    function videoTemplate(url: string | null) {
      return (
        '      <video id="video-uid" width="1140" controls="no">' +
        '          <source src="' +
        url +
        '" type="video/mp4">' +
        '          <!--Browser does not support <video> tag -->' +
        '      </video>'
      );
    }

    function openVideo(aTarget: HTMLElement) {
      var videoURL = aTarget.getAttribute('href');
      var videoSource = videoTemplate(videoURL);
      var videoPlayer = document.querySelector('#video-player') as HTMLElement;
      var videoInner = videoPlayer.querySelector('.video-inner') as HTMLElement;

      videoInner.innerHTML = videoSource;
      videoPlayer.classList.add('playing');

      var videoEl = videoPlayer.querySelector('video') as HTMLVideoElement;
      videoEl.play();
    }

    const playVideo = document.querySelectorAll('.play-video');
    playVideo.forEach((el) => {
      var elElement = el as HTMLElement;
      el.addEventListener('click', (e) => {
        e.preventDefault();

        AnalyticsHelper.instance.logEvent('video_clicked', {});
        this.videoStart = Date.now();

        openVideo(elElement);
      });
    });

    const closeVideoTrigger = document.querySelectorAll('.close-vid');
    closeVideoTrigger.forEach((el) => {
      el.addEventListener('click', (e) => {
        e.preventDefault();

        const videoPlayerContainer = el.closest('#video-player') as HTMLElement;
        const videoPlayerElement = videoPlayerContainer.querySelector(
          'video'
        ) as HTMLVideoElement;
        videoPlayerElement.pause();

        const timePlayed = videoPlayerElement.currentTime,
          videoDuration = videoPlayerElement.duration;

        const videoProgress = `${Math.round(
            (timePlayed / videoDuration) * 100
          )}%`,
          videoTimeElapsed = `${timePlayed.toFixed(2)}s`;

        const interactionTimeElapsed = `${(
          (Date.now() - this.videoStart) /
          1000
        ).toFixed(2)}s`;

        AnalyticsHelper.instance.logEvent('video_exited', {
          videoTimeElapsed,
          videoProgress,
          interactionTimeElapsed,
        });

        videoPlayerContainer.classList.remove('playing');
      });
    });
  }

  trackTime() {
    return Date.now() - this.videoStart;
  }
}
