import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';
import { PaymentsHelper } from 'src/app/helpers/PaymentsHelper';
import { AlertViewComponent } from '../alert-view/alert-view.component';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  loading = false;
  active = 'Account details';

  errors: any = {};
  errorMessage: any = [];
  startValidation: boolean = false;

  name: string = '';
  cardNumber: string = '';
  expiry: string = '';
  cvc: string = '';

  changingPassword = false;
  passwordsUpdated = false;
  currentPassword = '';
  newPassword = '';
  newPasswordConfirm = '';

  changingCard = false;

  constructor(public dialog: MatDialog, public router: Router) { }

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView("Account Modal");
  }

  save() {
    this.loading = true;
    this.user.save().then(val => {
      this.loading = false;
      this.close();
    });
  }

  cancelSubscription() {
    this.dialog.open(AlertViewComponent, {
      disableClose: false,
      maxWidth: "400px",
      panelClass: ['alert-modal', 'no-padding'],
      id: 'alert-modal',
      data: {
        title: "Are you sure you want to cancel your subscription?",
        message: "To cancel your subscription, please contact accounts@flrhub.com.au",
        primaryButton: "Contact FLRHUB",
        secondaryButton: "Back",
      }
    });
    DatabaseHelper.instance.dataCallbacks.alertViewCallback = (button: string) => {
      if (button === "Contact FLRHUB") {
        window.open("mailto:accounts@flrhub.com.au?subject=Cancel%20FLRHUB%20account", "_blank");
        // this.user.status = "Cancelled";
        // this.user.save();
        // this.dialog.closeAll();
        // this.router.navigate(['account-cancelled']);
        // DatabaseHelper.instance.sendAccountDisabledEmail(this.user);
      }
    }
  }

  changeCard() {
    if (!this.changingCard) {
      this.changingCard = true;
    } else {
      this.startValidation = true;
      if (!this.validate()) {
        return;
      }
      this.loading = true;
      PaymentsHelper.instance.createCardToken(this.name, this.cardNumber, this.cvc, this.expiry).then(val => {
        console.log(val);
        if (val.error) {
          val.error.data.error.fieldErrors.forEach((e: any) => {
            if (e.field === "card.name") {
              this.errors.name = true;
            } else if (e.field === "card.number") {
              this.errors.cardNumber = true;
            } else if (e.field === "card.expMonth") {
              this.errors.expiry = true;
            } else if (e.field === "card.expYear") {
              this.errors.expiry = true;
            } else if (e.field === "card.cvc") {
              this.errors.cvc = true;
            }
          });
          this.loading = false;
        } else {
          this.user.card = val.card;
          PaymentsHelper.instance.updateSimplifyCustomer(this.user, val.id).then(val4 => {
            this.user.save();
            this.loading = false;
            this.changingCard = false;
          });
        }
      }).catch(err => {
        this.loading = false;
      });
    }
  }

  changePassword() {
    if (!this.changingPassword) {
      this.changingPassword = true;
    } else {
      this.startValidation = true;
      if (!this.validate()) {
        return;
      }
      this.loading = true;
      AuthHelper.instance.updatePassword(this.currentPassword, this.newPassword).then(val => {
        this.changingPassword = false;
        this.passwordsUpdated = true;
        this.newPassword = "";
        this.newPasswordConfirm = "";
        this.currentPassword = "";
      }).catch(err => {
        this.errorMessage = [];
        this.errorMessage.push(err);
      }).finally(() => this.loading = false);
    }
  }

  validate(): boolean {
    this.errorMessage = [];
    this.errors = {};
    if (!this.startValidation) return false;
    if (this.active === 'Account details') {
      if (!this.currentPassword) {
        this.errorMessage.push("Enter your current password");
        return false;
      }
      if (!this.newPassword) {
        this.errorMessage.push("Enter your new password");
        return false;
      }
      if (!this.newPasswordConfirm) {
        this.errorMessage.push("Confirm your new password");
        return false;
      }
      if (!/[a-z]/.test(this.newPassword)) {
        this.errorMessage.push("One lowercase character");
      }
      if (!/[A-Z]/.test(this.newPassword)) {
        this.errorMessage.push("One uppercase character");
      }
      if (!/\d/.test(this.newPassword)) {
        this.errorMessage.push("One number");
      }
      if (!/\W/.test(this.newPassword)) {
        this.errorMessage.push("One special character");
      }
      if (this.newPassword.length < 8) {
        this.errorMessage.push("8 characters minimum");
      }
      if (this.newPassword !== this.newPasswordConfirm) {
        this.errorMessage.push("Passwords do not match");
      }
      return this.errorMessage.length === 0;
    } else if (this.active === 'Billing details') {
      if (!this.name) {
        this.errors.name = true;
      }
      if (!this.cardNumber) {
        this.errors.cardNumber = true;
      }
      if (!this.expiry || this.expiry.length < 5) {
        this.errors.expiry = true;
      }
      if (!this.cvc) {
        this.errors.cvc = true;
      }
      return Object.keys(this.errors).length === 0;
    }
    return false;
  }

  close() {
    this.dialog.getDialogById('account-modal')?.addPanelClass("animate-out");
    setTimeout(() => {
      this.dialog.closeAll();
    }, 500);
  }

  get user() {
    return AuthHelper.instance.user;
  }

}
