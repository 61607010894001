<div class="page-container page-job-board">
    <app-menu active="My Hub"></app-menu>
    <div class="content-container">
        <app-header showBackButton="true" title="My Hub"></app-header>
        <app-my-hub-tabs class="hide-phone" active="My Job Posts"></app-my-hub-tabs>
        <div class="content">
            <div class="small-button hide-phone" (click)="back()">Back</div>
            <div class="job-name-row">
                <div class="job-name">{{job.jobName}}</div>
                <div class="secondary-button small-button"
                    (click)="seeFullJobPost()">See Full Job Post</div>
                <div class="spacer"></div>
                <div class="secondary-button small-button red-button" [ngClass]="{loading: deleting}"
                    (click)="deleteJobPost()">Delete Post</div>
            </div>
            <div class="closing-container">
                <div class="closing-item">
                    <div class="title">Closing Date</div>
                    <div class="description">{{job.closingDate ? utilityHelper.formatDate(job.closingDate) : '-'}}</div>
                </div>
                <div class="closing-item">
                    <div class="title">Closing Time</div>
                    <div class="description">{{job.closingTime ? utilityHelper.formatTime(job.closingTime) : '-'}}</div>
                </div>
            </div>
            <div class="winner-container" *ngIf="this.job.winnerQuoteNumber && !this.loadingJobQuotes">
                <div class="winner-title">{{this.job.winnerQuoteNumber.length == 1 ? 'Winner' : 'Winners'}}</div>
                <div class="job-card winner" *ngFor="let jobQuoteNumber of this.job.winnerQuoteNumber" (click)="openJobBid(jobQuoteWithQuoteNumber(jobQuoteNumber))">
                    <div class="inner-card">
                        <div class="card-head"><span class="heading">{{jobQuoteWithQuoteNumber(jobQuoteNumber).installerName ? jobQuoteWithQuoteNumber(jobQuoteNumber).installerName : 'Unknown'}}{{isPartialQuote(jobQuoteWithQuoteNumber(jobQuoteNumber)) ? ' (Partial Quote)' : ''}}</span><br><span
                                class="posted small">Quoted
                                {{utilityHelper.formatDate(jobQuoteWithQuoteNumber(jobQuoteNumber).quoteSubmittedDate)}}</span></div>

                        <div class="card-footer">
                            <div class="category" *ngIf="jobQuoteWithQuoteNumber(jobQuoteNumber).installerType">{{jobQuoteWithQuoteNumber(jobQuoteNumber).installerType ? jobQuoteWithQuoteNumber(jobQuoteNumber).installerType : 'Unknown'}}</div>
                            <div class="location"><img src="../../../assets/images/location.png" width="12" height="17"
                                    alt="Location" /> {{jobQuoteWithQuoteNumber(jobQuoteNumber).installerLocation ? jobQuoteWithQuoteNumber(jobQuoteNumber).installerLocation : 'Unknown'}}</div>
                        </div><!-- card-footer -->
                    </div>
                    <div class="quote-container">
                        <div class="sub-heading">Quoted amount</div>
                        <div class="amount">{{jobQuoteWithQuoteNumber(jobQuoteNumber).quotedAmount}}</div>
                    </div>
                </div><!-- job-card -->
            </div>
            <div class="quotes-container" *ngIf="job.postStatus !== 'Draft'">
                <div class="title">Quotes</div>
                <mat-spinner class="loading" *ngIf="loadingJobQuotes" diameter="35" strokeWidth="1"></mat-spinner>
                <div *ngIf="!loadingJobQuotes && jobQuotes.length === 0" class="empty-placeholder">
                    <div class="message">You haven't received any quotes yet</div>
                </div>
                <div class="job-card" *ngFor="let quote of jobQuotes" (click)="openJobBid(quote)">
                    <div class="inner-card">
                        <div class="card-head">
                            <span class="heading">{{quote.installerName ? quote.installerName : 'Unknown'}}{{isPartialQuote(quote) ? ' (Partial Quote)' : ''}}</span>
                            <br>
                            <span *ngIf="quote.status !== 'Accepted'" class="posted small">Quoted {{utilityHelper.formatDate(quote.quoteSubmittedDate)}}</span>
                        </div>

                        <div class="card-footer">
                            <div class="category" *ngIf="quote.installerType">{{quote.installerType ? quote.installerType : 'Unknown'}}</div>
                            <div *ngIf="quote.status !== 'Accepted'" class="location"><img src="../../../assets/images/location.png" width="12" height="17"
                                    alt="Location" /> {{quote.installerLocation ? quote.installerLocation : 'Unknown'}}</div>
                        </div><!-- card-footer -->
                    </div>
                    <div *ngIf="quote.status !== 'Accepted'" class="quote-container">
                        <div class="sub-heading">Quoted amount</div>
                        <div class="amount">{{quote.quotedAmount}}</div>
                    </div>
                    <div *ngIf="quote.status === 'Accepted'" class="quote-container">
                        <div *ngIf="quote.job.siteVisitInfo.length" class="sub-heading">Installer will be in contact to arrange a Site Visit</div>
                        <br *ngIf="quote.job.siteVisitInfo.length">
                        <div class="sub-heading">Quote Pending</div>
                    </div>
                </div><!-- job-card -->
            </div>
        </div>
    </div>
</div>