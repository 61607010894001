import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { UtilityHelper } from 'src/app/helpers/UtilityHelper';
import { EditProfileComponent } from '../edit-profile/edit-profile.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView("Profile Modal");
  }

  editProfile() {
    this.dialog.open(EditProfileComponent, {
      disableClose: true,
      height: this.isPhone ? '100vh' : 'calc(100vh - 60px)',
      width: this.isPhone ? '100vw' : 'calc(60vw)',
      maxWidth: '100vw',
      maxHeight: '-webkit-fill-available',
      position: { bottom: '0', left: this.isPhone ? '0' : '20vw' },
      enterAnimationDuration: '0',
      panelClass: ['profile-modal', 'no-padding'],
      id: 'edit-panel-modal',
    });
  }

  close() {
    this.dialog.getDialogById('panel-modal')?.addPanelClass("animate-out");
    setTimeout(() => {
      this.dialog.closeAll();
    }, 500);
  }

  get user() {
    return AuthHelper.instance.user;
  }

  get isPhone() {
    return UtilityHelper.isPhone();
  }

}
