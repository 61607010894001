import { Component, OnInit } from '@angular/core';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';

@Component({
  selector: 'app-account-created',
  templateUrl: './account-created.component.html',
  styleUrls: ['./account-created.component.scss']
})
export class AccountCreatedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView("Account Created");
  }

}
