import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { JobQuote } from 'src/app/classes/JobQuote';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';
import { UtilityHelper } from 'src/app/helpers/UtilityHelper';
import { AlertViewComponent } from 'src/app/modals/alert-view/alert-view.component';
import { CreateJobTypeComponent } from 'src/app/modals/create-job-type/create-job-type.component';
import { JobBidDetailsComponent } from 'src/app/modals/job-bid-details/job-bid-details.component';

@Component({
  selector: 'app-my-job-bids',
  templateUrl: './my-job-bids.component.html',
  styleUrls: ['./my-job-bids.component.scss']
})
export class MyJobBidsComponent implements OnInit, AfterViewInit {

  activeTab = "Pending Quotes";
  loading = true;
  perPage = 10;
  allLoaded = false;
  page = 0;
  jobQuotes: JobQuote[] = [];

  constructor(public router: Router, private authHelper: AuthHelper, public dialog: MatDialog) { }

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView("My Job Bids");
    this.redirectJobBoardPage();
  }

  ngAfterViewInit() {
    document.getElementById('scrollContent')?.addEventListener('scroll', (event) => {
      let element: HTMLElement = event.target as HTMLElement;
      if (element.scrollHeight - element.scrollTop - element.clientHeight <= 50) {
        console.log("We are deep down, time to load more.");
        if (!this.loading && !this.allLoaded) {
          this.page++;
          this.loadJobQuotes();
        }
      }
    });
  }

  reloadJobQuotes() {
    this.allLoaded = false;
    this.page = 0;
    this.jobQuotes = [];
    this.loadJobQuotes();
  }

  loadJobQuotes() {
    this.loading = true;
    let lastDocumentId = '';
    if (this.jobQuotes.length > 0) {
      lastDocumentId = this.jobQuotes[this.jobQuotes.length - 1].jobQuoteNumber;
    }
    DatabaseHelper.instance.getUserJobQuotes(this.user.uid, this.perPage, lastDocumentId).then(val => {
      if (val.jobQuotes && val.jobQuotes.length < this.perPage) this.allLoaded = true;
      this.jobQuotes = this.jobQuotes.concat(val.jobQuotes);
      this.loading = false;
    });
  }

  async redirectJobBoardPage() {
    if (await this.authHelper.getUserStatus()) {
      // redirect to job board page by user type
      const userType = this.user.type
      if (userType == 'Builder') {
        this.router.navigateByUrl('my-job-posts');
      } else {
        this.loadJobQuotes();
      }
    }
  }

  toggleSelectMobile() {
    document.querySelector('.main-tab-menu')?.classList.toggle('mobile-active')
  }
  
  removeSelectMobile() {
    document.querySelector('.main-tab-menu')?.classList.remove('mobile-active')
  }

  openJobQuote(job: JobQuote) {
    this.dialog.open(JobBidDetailsComponent, {
      height: this.isPhone ? '100vh' : 'calc(100vh - 30px)',
      width: this.isPhone ? '100vw' : 'calc(100vw - 60px)',
      maxWidth: '100vw',
      maxHeight: '-webkit-fill-available',
      position: { bottom: '0', left: this.isPhone ? '0' : '30px' },
      enterAnimationDuration: '0',
      panelClass: ['profile-modal', 'no-padding'],
      id: 'job-bid-details',
      data: {
        job: job
      }
    });
  }

  removeJobPost(job: JobQuote) {
    this.dialog.open(AlertViewComponent, {
      maxWidth: "400px",
      panelClass: ['alert-modal', 'no-padding'],
      id: 'alert-modal',
      data: {
        title: "Delete Job Quote?",
        message: "Are you sure you want to delete this quote?",
        primaryButton: "Yes, Delete",
        secondaryButton: "No",
      }
    });
    DatabaseHelper.instance.dataCallbacks.alertViewCallback = (button: string) => {
      if (button === 'Yes, Delete') {
        job.status = "Deleted";
        this.loading = true;
        this.jobQuotes = [];
        DatabaseHelper.instance.submitJobQuote(job, true).then(val => {
          this.loading = false;
          this.reloadJobQuotes();
        });
      }
    }
  }

  goTo(url: string) {
    this.router.navigateByUrl(url);
  }

  get quotesWon() {
    return this.jobQuotes.filter(jobQuote => jobQuote.won);
  }

  get pendingQuotes() {
    return this.jobQuotes.filter(jobQuote => jobQuote.status === 'Accepted');
  }

  get user() {
    return AuthHelper.instance.user;
  }
  get isPhone() {
    return UtilityHelper.isPhone();
  }

  get utilityHelper() {
    return UtilityHelper;
  }

  isPartialQuote(jobQuote : any)
  {
    var isPartialQuote = false;
    jobQuote.products.forEach((product : any) => {
      if(!product.price)
      {
        isPartialQuote = true;
      }

      product.subItems.forEach((subItem: any) => {
        if(!subItem.price)
        {
          isPartialQuote = true;
        }
      });
    });
    return isPartialQuote;
  }

}
