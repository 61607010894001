import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  email: string = '';
  password: string = '';
  showPassword: boolean = false;
  errorMessage = '';
  loading = false;
  bearerToken: string = '';

  constructor(
    private authHelper: AuthHelper,
    public router: Router,
  ) {}

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView('Login');
  }

  async login() {
    this.errorMessage = '';
    this.loading = true;

    try {
      await this.authHelper.loginUser(this.email, this.password);
      this.loading = false;
      this.router.navigate(['industry']);
    } catch (err) {
      if (err instanceof HttpErrorResponse) {
        this.errorMessage = this.authHelper.getError(err.status);
      }
    }

    this.loading = false;
  }
  //
  goTo(url: string) {
    this.router.navigateByUrl(url);
  }
}
