import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from '../safe-html.pipe';
import { SafeUrlPipe } from '../safe-url.pipe';
import { FormattedDatePipe } from '../formatted-date.pipe';
import { MapQuestionPipe } from '../map-question.pipe';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations:   [SafeHtmlPipe, SafeUrlPipe, FormattedDatePipe, MapQuestionPipe],
  exports:        [SafeHtmlPipe, SafeUrlPipe, FormattedDatePipe, MapQuestionPipe],
})
export class PipesModule { }
