<div class="page-container">
    <app-menu active="My Hub"></app-menu>
    <div class="content-container">
        <app-header title="My Hub"></app-header>
        <app-my-hub-tabs active="Home"></app-my-hub-tabs>
        <div class="content">

          <div class="widget-box-holder">
            <div class="widget-boxes">
              <div class="widget-box">
                <div class="inner">
                  <h2>Contact information</h2>
                  <div class="detail">
                    <p><b>Adelaide</b> - 4/59 Pennington terrace North Adelaide 5006, Australia PH: <a href="tel:0422319226" target="_blank">0422319226</a></p>
                    <p><b>Brisbane</b> - 12B/22 Baildon Street Kangaroo Point 4169, Australia, PH: <a href="tel:0472664835" target="_blank">0472664835</a></p>
                  </div>
                  <div class="boxes">
                    <div class="box">
                      <h3>ABN Number</h3>
                      68 649 251 543<br>
                      FLRHUB PTY LTD
                    </div>
                    <div class="box">
                      <h3>Email</h3>
                      <a href="mailto:info@flrhb.com.au" target="_blank">info@flrhb.com.au</a>
                    </div>
                  </div>
                </div>
              </div><!-- widget-box -->
              <div class="widget-box">
                <div class="inner">
                  <h2>Delivery Information</h2>
                  <div class="detail">
                    <p>Delivery Australia wide within 3-7 business days of order confirmation.</p>
                    <p>We are partnered with Mainfreight and GMK Transport for all deliveries.</p>
                    <p><a target=”_blank” href="/delivery" class="has-underline">Delivery Policy</a></p>
                  </div>
                </div>
              </div><!-- widget-box -->
            </div><!-- widget-boxes -->
          </div><!-- widget-box-holder -->

          <div class="widget-footer">
            <ul>
              <li><a target=”_blank” href="/terms">Terms & Conditions</a></li>
              <li><a target=”_blank” href="/privacy">Privacy Policy</a></li>
              <li><a target=”_blank” href="/returns">Returns Policy</a></li>
            </ul>
          </div>


        </div><!-- content -->
    </div>
</div>
