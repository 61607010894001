import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Job } from 'src/app/classes/Job';
import { JobQuote } from 'src/app/classes/JobQuote';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';
import { UtilityHelper } from 'src/app/helpers/UtilityHelper';
import { AlertViewComponent } from 'src/app/modals/alert-view/alert-view.component';
import { JobBidDetailsComponent } from 'src/app/modals/job-bid-details/job-bid-details.component';

@Component({
  selector: 'app-my-job-post-details',
  templateUrl: './my-job-post-details.component.html',
  styleUrls: ['./my-job-post-details.component.scss']
})
export class MyJobPostDetailsComponent implements OnInit {

  job: Job = new Job();
  jobQuotes: JobQuote[] = [];
  loadingJobQuotes = false;
  deleting = false;

  constructor(private router: Router, private dialog: MatDialog) {
    if (this.router.getCurrentNavigation() && this.router.getCurrentNavigation()?.extras.state) {
      this.job = this.router.getCurrentNavigation()?.extras.state!['job'];
      if (this.job.postStatus !== 'Draft') {
        this.loadJobQuotes();
      }
    } else {
      this.router.navigateByUrl("my-job-posts");
    }
  }

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView("My Job Post Details");
  }

  loadJobQuotes() {
    this.loadingJobQuotes = true;
    DatabaseHelper.instance.getQuotesForJob(this.job.jobPostNumber).then(val => {
      this.loadingJobQuotes = false;
      this.jobQuotes = val.jobQuotes;
    });
  }

  seeFullJobPost() {
    this.router.navigateByUrl("job-details", { state: { job: this.job } });
  }

  deleteJobPost() {
    this.dialog.open(AlertViewComponent, {
      maxWidth: "400px",
      panelClass: ['alert-modal', 'no-padding'],
      id: 'alert-modal',
      data: {
        title: "Delete Job Post?",
        message: "Are you sure you want to delete this job post?",
        primaryButton: "Yes, Delete",
        secondaryButton: "No",
      }
    });
    DatabaseHelper.instance.dataCallbacks.alertViewCallback = (button: string) => {
      if (button === 'Yes, Delete') {
        this.job.postStatus = "Deleted";
        this.deleting = true;
        DatabaseHelper.instance.submitJobPost(this.job, true, false).then(val => {
          this.router.navigateByUrl("my-job-posts");
        });
      }
    }
  }

  openJobBid(jobQuote: JobQuote) {
    if(jobQuote.status !== 'Accepted')
    {
      jobQuote.job = this.job;
      this.dialog.open(JobBidDetailsComponent, {
        height: this.isPhone ? '100vh' : 'calc(100vh - 30px)',
        width: this.isPhone ? '100vw' : 'calc(100vw - 60px)',
        maxWidth: '100vw',
        maxHeight: '-webkit-fill-available',
        position: { bottom: '0', left: this.isPhone ? '0' : '30px' },
        enterAnimationDuration: '0',
        panelClass: ['profile-modal', 'no-padding'],
        id: 'job-bid-details',
        data: {
          job: jobQuote,
          builderJob: this.job
        }
      });
      DatabaseHelper.instance.dataCallbacks.dataCallback = ((reload: any) => {
        if (reload.reloadJobPost) {
          this.loadingJobQuotes = true;
          this.jobQuotes = [];
          DatabaseHelper.instance.getUserJobPost(this.job.jobPostNumber).then(val => {
            this.job = val.jobPost;
            this.loadJobQuotes();
          });
        }
      });
    }
  }

  back() {
    window.history.back();
  }

  winnerQuote() {
    if (!this.job.winnerQuoteNumber) {
      return null;
    }
    return this.jobQuotes.find(e => e.jobQuoteNumber === this.job.winnerQuoteNumber);
  }

  get user() {
    return AuthHelper.instance.user;
  }

  get isPhone() {
    return UtilityHelper.isPhone();
  }

  get utilityHelper() {
    return UtilityHelper;
  }

  isPartialQuote(jobQuote : any)
  {
    var isPartialQuote = false;
    jobQuote.products.forEach((product : any) => {
      if(!product.price)
      {
        isPartialQuote = true;
      }

      product.subItems.forEach((subItem: any) => {
        if(!subItem.price)
        {
          isPartialQuote = true;
        }
      });
    });
    return isPartialQuote;
  }

  jobQuoteWithQuoteNumber(jobQuoteNumber: string)
  {
    var foundQuote = new JobQuote;
    this.jobQuotes.forEach(jobQuote => {
      if(jobQuote.jobQuoteNumber === jobQuoteNumber)
      {
        foundQuote = jobQuote;
      }
    });
    return foundQuote;
  }

}
