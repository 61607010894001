import { Pipe, PipeTransform } from '@angular/core';
import { EstimateQuestionsHelper } from 'src/app/helpers/EstimateQuestionsHelper';

@Pipe({
  name: 'mapQuestion'
})
export class MapQuestionPipe implements PipeTransform {

  transform(product: any): any {
    return this.getQuestion(product)
  }

  getQuestion(category: string): Array<any> {
    return EstimateQuestionsHelper.mapQuestionPerCategory(category)
  }

}
