import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Order } from 'src/app/classes/Order';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';
import { PaymentsHelper } from 'src/app/helpers/PaymentsHelper';

@Component({
  selector: 'app-credit-card',
  templateUrl: './credit-card.component.html',
  styleUrls: ['./credit-card.component.scss']
})
export class CreditCardComponent implements OnInit {

  errors: any = {};
  errorMessage = "";
  startValidation = false;

  name: string = '';
  cardNumber: string = '';
  expiry: string = '';
  cvc: string = '';

  loading = false;
  useNewCard = false;
  updateCard = false

  constructor(private dialog: MatDialog) { }

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView("Credit Card Modal");
  }

  validate() {
    if (!this.startValidation) return false;
    this.errors = {};
    if (!this.name) {
      this.errors.name = true;
    }
    if (!this.cardNumber) {
      this.errors.cardNumber = true;
    }
    if (!this.expiry || this.expiry.length < 5) {
      this.errors.expiry = true;
    }
    if (!this.cvc) {
      this.errors.cvc = true;
    }
    return Object.keys(this.errors).length === 0;
  }

  next() {
    this.startValidation = true;
    if (!this.useNewCard) {
      this.cardSaved({ id: '', card: {} }); // Use saved card
    } else if (this.validate()) {
      this.loading = true;
      PaymentsHelper.instance.createCardToken(this.name, this.cardNumber, this.cvc, this.expiry).then(val => {
        if (val.error) {
          val.error.data.error.fieldErrors.forEach((e: any) => {
            if (e.field === "card.name") {
              this.errors.name = true;
            } else if (e.field === "card.number") {
              this.errors.cardNumber = true;
            } else if (e.field === "card.expMonth") {
              this.errors.expiry = true;
            } else if (e.field === "card.expYear") {
              this.errors.expiry = true;
            } else if (e.field === "card.cvc") {
              this.errors.cvc = true;
            }
          });
          this.loading = false;
        } else {
          if (this.updateCard) {
            this.user.card = val.card;
            PaymentsHelper.instance.updateSimplifyCustomer(this.user, val.id).then(async val4 => {
              await this.user.save();
              this.cardSaved(val);
            });
          } else {
            this.cardSaved(val);
          }
        }
      });
    }
  }

  cardSaved(cardData: any) {
    console.log(cardData);
    DatabaseHelper.instance.dataCallbacks.dataCallback(cardData);
    this.dismiss();
  }

  dismiss() {
    this.dialog.getDialogById('credit-card-modal')?.close();
  }

  get user() {
    return AuthHelper.instance.user;
  }

}
