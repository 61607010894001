import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Order } from 'src/app/classes/Order';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';
import { PaymentsHelper } from 'src/app/helpers/PaymentsHelper';
import { UtilityHelper } from 'src/app/helpers/UtilityHelper';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  errors: any = {};
  errorMessage = "";
  startValidation = false;

  name: string = '';
  cardNumber: string = '';
  expiry: string = '';
  cvc: string = '';

  editingOrder = new Order();
  isEditingOrder = false;

  loading = false;
  useNewCard = false;
  updateCard = false

  constructor(private router: Router, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.isEditingOrder = false;
    if(this.data) {
      this.editingOrder = new Order();
      this.editingOrder.addData(data.order);
      this.isEditingOrder = true;
      console.log("Loading order data = " + JSON.stringify(this.order));
    } else if (this.router.getCurrentNavigation()?.extras && this.router.getCurrentNavigation()?.extras.state) {
      // this.order = this.router.getCurrentNavigation()?.extras.state!['order'];
    } else {
      this.router.navigate(["/cart"]);
    }
  }

  get order() {
    return this.isEditingOrder ? this.editingOrder : this.user.currentOrder;
  }

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView("Payment");
  }

  validate() {
    if (!this.startValidation) return false;
    this.errors = {};
    if (!this.order.paymentOption) {
      this.errors.paymentOption = true;
    } else if (this.order.paymentOption === 'Credit Card' && this.useNewCard) {
      if (!this.name) {
        this.errors.name = true;
      }
      if (!this.cardNumber) {
        this.errors.cardNumber = true;
      }
      if (!this.expiry || this.expiry.length < 5) {
        this.errors.expiry = true;
      }
      if (!this.cvc) {
        this.errors.cvc = true;
      }
    }
    return Object.keys(this.errors).length === 0;
  }

  keysForObject(object: Object) : string[]
  {
    return Object.keys(object);
  }

  next() {
    this.startValidation = true;
    if (this.validate()) {
      this.loading = true;
      if (this.order.paymentOption === 'Credit Card' && this.useNewCard) {
        // this.order.setupTotals();
        PaymentsHelper.instance.createCardToken(this.name, this.cardNumber, this.cvc, this.expiry).then(val => {
          console.log("Got results = "+JSON.stringify(val));
          if (val.error) {
            val.error.data.error.fieldErrors.forEach((e: any) => {
              if (e.field === "card.name") {
                this.errors.name = true;
              } else if (e.field === "card.number") {
                this.errors.cardNumber = true;
              } else if (e.field === "card.expMonth") {
                this.errors.expiry = true;
              } else if (e.field === "card.expYear") {
                this.errors.expiry = true;
              } else if (e.field === "card.cvc") {
                this.errors.cvc = true;
              }
              else
              {
                this.errors.name = true;
                this.errors.cardNumber = true;
                this.errors.expiry = true;
                this.errors.cvc = true;
              }
            });
            this.loading = false;
          } else {
            if (this.updateCard) {
              this.user.card = val.card;
              PaymentsHelper.instance.updateSimplifyCustomer(this.user, val.id).then(async val4 => {
                await this.user.save();
                this.goToReview();
              });
            } else {
              console.log("Token ID after token created = "+val.id);
              this.goToReview(val.id);
            }
          }
        });
      } else {
        this.goToReview();
      }
    }
  }

  goToReview(cardToken: string = '') {
    if (this.data) {
      DatabaseHelper.instance.dataCallbacks.dataCallback({
        page: 'review',
        order: this.order,
        cardToken: cardToken
      });
    }
    else {
      this.router.navigateByUrl("review", {
        state: {
          cardToken: cardToken
        }
      });
    }
  }

  selectPaymentOption(option: string) {
    AnalyticsHelper.instance.logEvent("payment_option_selected", { payment_option: option });
    this.order.paymentOption = option;
    this.validate();
  }

  get user() {
    return AuthHelper.instance.user;
  }

  back() {
    window.history.back();
  }

}
