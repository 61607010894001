import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';

@Component({
  selector: 'app-alert-view',
  templateUrl: './alert-view.component.html',
  styleUrls: ['./alert-view.component.scss']
})
export class AlertViewComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  buttonPressed(button: string) {
    this.close();
    DatabaseHelper.instance.dataCallbacks.alertViewCallback(button);
  }

  close() {
    this.dialog.getDialogById('alert-modal')?.close();
  }

}
