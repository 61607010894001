<div class="header">
    <div class="spacer"></div>
    <img class="close-icon" src="../../../assets/images/close_icon.png" (click)="dismiss()">
</div>

<div class="payment-option">
    <div class="payment-title-container">
        <div class="payment-option-title">Credit Card</div>
        <div class="spacer"></div>
        <img class="card-icon" src="../../../assets/images/visa_icon.png">
        <img class="card-icon" src="../../../assets/images/amex_icon.png">
        <img class="card-icon" src="../../../assets/images/mastercard_icon.png">
    </div>
    <div class="payment-details-container">
        <div class="card-selector" (click)="useNewCard = false">
            <div class="check-box" [ngClass]="{selected: !useNewCard}">
                <img src="../../../assets/images/check_icon.png">
            </div>
            <div class="card-text">Saved card - **** **** **** {{user.card.last4}}</div>
        </div>
        <div class="card-selector" (click)="useNewCard = true">
            <div class="check-box" [ngClass]="{selected: useNewCard}">
                <img src="../../../assets/images/check_icon.png">
            </div>
            <div class="card-text">Use a different card</div>
        </div>

        <div style="margin-top: 20px;" *ngIf="useNewCard">
            <div class="input-field" [ngClass]="{error: errors.name}">
                <label for="name">Name On Card</label>
                <input id="name" [(ngModel)]="name" (ngModelChange)="validate()" placeholder="Type here..." type="text"
                    autocomplete="name">
            </div>
            <div class="input-field" [ngClass]="{error: errors.cardNumber}">
                <label for="cardNumber">Card Number</label>
                <input id="cardNumber" [(ngModel)]="cardNumber" (ngModelChange)="validate()"
                    placeholder="•••• •••• •••• ••••" type="text" maxlength="19" cc-number>
            </div>
            <div class="input-field-row">
                <div class="input-field" [ngClass]="{error: errors.expiry}">
                    <label for="expiry">Expiration (MM/YY)</label>
                    <input id="expiry" [(ngModel)]="expiry" (ngModelChange)="validate()" placeholder="MM/YY" type="text"
                        maxlength="5" cc-expiry>
                </div>
                <div class="input-field" [ngClass]="{error: errors.cvc}">
                    <label for="cvc">CVC</label>
                    <input id="cvc" [(ngModel)]="cvc" (ngModelChange)="validate()" placeholder="Type here..."
                        type="text" numbersOnly="true">
                </div>
            </div>
            <div class="card-selector" (click)="updateCard = !updateCard">
                <div class="check-box" [ngClass]="{selected: updateCard}">
                    <img src="../../../assets/images/check_icon.png">
                </div>
                <div class="card-text">Update your saved card with this card</div>
            </div>
        </div>

        <div *ngIf="errorMessage" style="margin-bottom: 35px;" class="error" [innerHTML]="errorMessage"></div>
        <div class="pay-row">
            <img class="lock-icon" src="../../../assets/images/lock_icon.png">
            <div class="secure-text">
                <div class="secure-text-1">Secure checkout</div>
                <div class="secure-text-2">Pay safely with SSL technology.</div>
            </div>
            <div class="spacer"></div>
            <div class="primary-button" [ngClass]="{loading: loading}" (click)="next()">Submit</div>
        </div>
    </div>
</div>