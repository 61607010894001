import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { Job } from 'src/app/classes/Job';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';
import { ProductsComponent } from '../products/products.component';
import { ProductDetailsComponent } from '../product-details/product-details.component';
import { UtilityHelper } from 'src/app/helpers/UtilityHelper';
import { AlertViewComponent } from 'src/app/modals/alert-view/alert-view.component';
import { EstimationComponent } from '../estimation/estimation.component';
import { EstimationCart } from 'src/app/classes/EstimationCart';
import { EstimateJobItem } from 'src/app/classes/EstimateJobItem';
import { EstimationsComponent } from '../estimations/estimations.component';
import { EstimationDetailsComponent } from 'src/app/modals/estimation-details/estimation-details.component';

@Component({
  selector: 'app-job-board-post-no-visit',
  templateUrl: './job-board-post-no-visit.component.html',
  styleUrls: ['./job-board-post-no-visit.component.scss'],
})
export class JobBoardPostNoVisitComponent implements OnInit {
  activeTab = 1;
  loadingNextSteps = false;
  job: Job = new Job();
  uploadingSpecificationDrawings = false;
  uploadingOtherDocuments = false;
  errors: any = {};
  saving = false;
  updating = false;
  editingDraft = false;

  constructor(
    public router: Router,
    private authHelper: AuthHelper,
    public dialog: MatDialog,
  ) {
    if (
      this.router.getCurrentNavigation() &&
      this.router.getCurrentNavigation()?.extras.state &&
      !this.router.getCurrentNavigation()?.extras.state!['siteVisitRequired']
    ) {
      this.job = this.router.getCurrentNavigation()?.extras.state!['job'];
      this.updating = true;
      this.editingDraft = true;
    } else {
      this.job.userId = this.user.uid;
      if (
        this.router.getCurrentNavigation() &&
        this.router.getCurrentNavigation()?.extras.state &&
        this.router.getCurrentNavigation()?.extras.state!['siteVisitRequired']
      ) {
        this.job.siteVisitRequired = true;
      } else {
        this.job.siteVisitRequired = false;
      }
    }
    // if url is /job-board-post-no-visit?siteVisitRequired=true, then siteVisitRequired = true
    if (this.router.url.includes('siteVisitRequired=true')) {
      this.job.siteVisitRequired = true;
    }

    console.log('site visit required? = ' + this.job.siteVisitRequired);
  }

  isJobVisitRequired() {
    return this.job.siteVisitRequired;
  }

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView('Create a Job');
    this.loadFilters();
  }

  loadFilters() {
    if (this.jobMaterialTypes.length === 0) {
      DatabaseHelper.instance.getJobBoardFilters().then((val) => {
        DatabaseHelper.instance.jobBoardFilters['Material Type'].items =
          val.filters.materialTypes.map((e: string) => ({
            selected: false,
            name: e,
          }));
        DatabaseHelper.instance.jobBoardFilters['Location'].items =
          val.filters.location.map((e: string) => ({
            selected: false,
            name: e,
          }));
        DatabaseHelper.instance.jobBoardFilters['Job Size'].items =
          val.filters.jobSizes.map((e: string) => ({
            selected: false,
            name: e,
          }));
      });
    }
  }

  returnToJobBoard() {
    if (!this.job.jobName) {
      this.router.navigateByUrl('my-job-posts');
      return;
    }
    this.dialog.open(AlertViewComponent, {
      maxWidth: '400px',
      panelClass: ['alert-modal', 'no-padding'],
      id: 'alert-modal',
      data: {
        title: 'Exit or Save Draft',
        message: 'Do you want to save this job post as a draft?',
        primaryButton: 'Save Draft',
        secondaryButton: 'Exit',
      },
    });
    DatabaseHelper.instance.dataCallbacks.alertViewCallback = (
      button: string,
    ) => {
      if (button === 'Exit') {
        this.router.navigateByUrl('my-job-posts');
      } else if (button === 'Save Draft') {
        this.job.postStatus = 'Draft';
        this.saving = true;
        DatabaseHelper.instance
          .submitJobPost(this.job, this.updating, false)
          .then((val) => {
            this.saving = false;
            this.router.navigateByUrl('my-job-posts');
          })
          .catch(() => {
            this.saving = false;
            this.errorModal();
          });
      }
    };
  }

  errorModal() {
    this.dialog.open(AlertViewComponent, {
      maxWidth: '400px',
      panelClass: ['alert-modal', 'no-padding'],
      id: 'alert-modal',
      data: {
        title: 'Error',
        message:
          'An issue occurred while submitting the job post. Please try again.',
        primaryButton: 'OK',
      },
    });
  }

  useEstimationButtonPressed() {
    //User has clicked the estimate button so get them to pick an estimate to use.
    let detailsDialog: MatDialogRef<EstimationDetailsComponent, any>;
    this.dialog.open(EstimationsComponent, {
      disableClose: true,
      height: this.isPhone ? '100vh' : 'calc(100vh - 35px)',
      width: this.isPhone ? '100vw' : 'calc(100vw - 62px)',
      maxWidth: '100vw',
      maxHeight: '-webkit-fill-available',
      position: { bottom: '0', left: this.isPhone ? '0' : '32px' },
      enterAnimationDuration: '0',
      panelClass: ['choose-product-modal', 'no-padding'],
      id: 'estimations-modal',
      data: {
        choosingEstimation: true,
      },
    });
    DatabaseHelper.instance.dataCallbacks.dataCallback = (navEvent: any) => {
      if (navEvent.page == 'estimation-details') {
        detailsDialog = this.dialog.open(EstimationDetailsComponent, {
          disableClose: true,
          height: this.isPhone ? '100vh' : 'calc(100vh - 35px)',
          width: this.isPhone ? '100vw' : 'calc(100vw - 62px)',
          maxWidth: '100vw',
          maxHeight: '-webkit-fill-available',
          position: { bottom: '0', left: this.isPhone ? '0' : '32px' },
          panelClass: ['product-details-modal', 'no-padding'],
          id: 'product-details-modal',
          data: {
            productCollection: navEvent.productCollection,
            choosingEstimation: true,
          },
        });
      } else if (navEvent.page == 'dismissEstimationDetails') {
        detailsDialog.close();
      } else if (navEvent.page == 'dismissEstimations') {
        this.dialog.closeAll();
      } else if (navEvent.page == 'selectedEstimations') {
        console.log('Got the call!');
        var selectedEstimation = navEvent.estimation;
        this.loadDataFromEstimation(selectedEstimation);
        this.errors = {};
        //Close all dialogs
        this.dialog.closeAll();
      }
    };
  }

  loadDataFromEstimation(selectedEstimation: EstimationCart) {
    // DatabaseHelper.instance.getUserEstimations(this.user.uid, 0, '', false).then((estimations) => {
    //     console.log("Estimations = " + JSON.stringify(estimations));
    //     var selectedEstimation : EstimationCart = new EstimationCart();
    //     estimations.estimations.forEach((estimation : EstimationCart) => {
    //       if(estimation.code === "wROhMjn1iavaZ30drccVHXU7tD8Iwn5s")
    //       {
    //         selectedEstimation = estimation;
    //       }
    //     });
    //     //Not in here
    //   });

    if (selectedEstimation != null) {
      //We have found an estimation so we need to set the jobs job items to the same as the estimations job items.
      //For each estimation job item add to the jobs job items.
      selectedEstimation.jobItems.forEach((jobItem: EstimateJobItem) => {
        var mainQuantity = '';
        var subItems: any[] = [];
        jobItem.estimates.forEach((estimate) => {
          if (estimate.description === jobItem.code) {
            mainQuantity = parseFloat(estimate.quantity).toFixed(2);
          } else {
            subItems.push({
              name: estimate.description,
              quantity:
                parseFloat(estimate.quantity).toFixed(2) + '' + estimate.unit,
            });
          }
        });
        if (this.job.products == null) {
          this.job.products = [];
        }
        this.job.products.push({
          product: jobItem.product,
          quantity: mainQuantity,
          subItems: subItems,
        });
      });
    }
  }

  handleAddressChange(address: any) {
    var state = '';
    var suburb = '';
    address.address_components.forEach((component: any) => {
      component.types.forEach((type: any) => {
        if (type === 'administrative_area_level_1') {
          state = component.short_name;
        }
        if (type === 'locality') {
          suburb = component.short_name;
        }
      });
    });
    this.job.jobLocation = address.formatted_address;
    this.job.state = state;
    this.job.suburb = suburb;
    this.job.jobLocationLat = address.geometry.location.lat();
    this.job.jobLocationLng = address.geometry.location.lng();
  }

  openUrl(url: string) {
    window.open(url, '_blank');
  }

  deleteFile(index: number, type: 'Specification Drawing' | 'Other Document') {
    if (type === 'Specification Drawing') {
      DatabaseHelper.instance.deleteFile(
        this.job.specificationDrawings[index].url,
      );
      this.job.specificationDrawings.splice(index, 1);
    } else if (type === 'Other Document') {
      DatabaseHelper.instance.deleteFile(this.job.otherDocuments[index].url);
      this.job.otherDocuments.splice(index, 1);
    }
  }

  uploadFile(event: any, type: 'Specification Drawing' | 'Other Document') {
    if (event.target.files[0] === undefined) {
      return;
    }
    if (type === 'Specification Drawing') {
      this.uploadingSpecificationDrawings = true;
    } else if (type === 'Other Document') {
      this.uploadingOtherDocuments = true;
    }
    const promises: any[] = [];
    Array.from(event.target.files).forEach((file: any) => {
      promises.push(
        new Promise((res: any, rej: any) => {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (e) => {
            const name = file.name;
            DatabaseHelper.instance
              .uploadFile(
                'JobFiles/' +
                  this.user.uid +
                  '/' +
                  new Date().getTime() +
                  '_' +
                  name,
                file,
              )
              .then((val) => {
                if (type === 'Specification Drawing') {
                  this.job.specificationDrawings.push({ name: name, url: val });
                } else if (type === 'Other Document') {
                  this.job.otherDocuments.push({ name: name, url: val });
                }
                res(true);
              });
          };
        }),
      );
    });
    Promise.all(promises).then(() => {
      if (type === 'Specification Drawing') {
        this.uploadingSpecificationDrawings = false;
      } else if (type === 'Other Document') {
        this.uploadingOtherDocuments = false;
      }
      event.target.value = '';
    });
  }

  proceedToStep(step: number) {
    console.log('in active tab: ' + this.activeTab);
    console.log('proceed to step ' + step);
    if (this.activeTab === 1 && !this.validateStep1()) {
      return;
    }
    if (this.activeTab === 2 && !this.validateStep2()) {
      return;
    }
    if (this.activeTab === 3 && !this.validateStep3()) {
      return;
    }
    console.log('proceeding to step ' + step + ' after validation');
    this.activeTab = step;
  }

  submitJob() {
    this.loadingNextSteps = true;
    this.job.submittedDate = new Date().getTime().toString();
    this.job.closingDateTime = new Date(
      this.job.closingDate + 'T' + this.job.closingTime,
    ).getTime();
    this.job.postStatus = 'Active';
    DatabaseHelper.instance
      .submitJobPost(this.job, this.updating, this.editingDraft)
      .then((val) => {
        this.loadingNextSteps = false;
        this.activeTab = 5;
        DatabaseHelper.instance.notifyInstallersOnNewJobPost();
      })
      .catch(() => {
        this.loadingNextSteps = false;
        this.errorModal();
      });
  }

  openProductSelector() {
    let detailsDialog: MatDialogRef<ProductDetailsComponent, any>;
    this.dialog.open(ProductsComponent, {
      disableClose: true,
      height: this.isPhone ? '100vh' : 'calc(100vh - 35px)',
      width: this.isPhone ? '100vw' : 'calc(100vw - 62px)',
      maxWidth: '100vw',
      maxHeight: '-webkit-fill-available',
      position: { bottom: '0', left: this.isPhone ? '0' : '32px' },
      enterAnimationDuration: '0',
      panelClass: ['choose-product-modal', 'no-padding'],
      id: 'products-modal',
      data: {
        choosingProduct: true,
      },
    });
    DatabaseHelper.instance.dataCallbacks.dataCallback = (navEvent: any) => {
      if (navEvent.page == 'product-details') {
        detailsDialog = this.dialog.open(ProductDetailsComponent, {
          disableClose: true,
          height: this.isPhone ? '100vh' : 'calc(100vh - 35px)',
          width: this.isPhone ? '100vw' : 'calc(100vw - 62px)',
          maxWidth: '100vw',
          maxHeight: '-webkit-fill-available',
          position: { bottom: '0', left: this.isPhone ? '0' : '32px' },
          panelClass: ['product-details-modal', 'no-padding'],
          id: 'product-details-modal',
          data: {
            productCollection: navEvent.productCollection,
            choosingProduct: true,
          },
        });
      } else if (navEvent.page == 'dismissProductDetails') {
        detailsDialog.close();
      } else if (navEvent.page == 'dismissProducts') {
        this.dialog.closeAll();
      } else if (navEvent.page == 'selectingProduct') {
        this.job.products.push({
          product: navEvent.product,
          quantity: '',
          subItems: [],
        });
        this.errors = {};
        //Close all dialogs
        this.dialog.closeAll();
      }
    };
  }

  goTo(url: string) {
    this.router.navigateByUrl(url);
  }

  validateStep1() {
    console.log('hit validator step 1');
    // return true;
    this.errors = {};
    var fieldsToValidate = [
      'jobName',
      'jobDescription',
      'industry',
      'materialType',
      'closingDate',
      'closingTime',
      'jobLocation',
      'jobSize',
      'constructionPeriod',
      'workingDays',
      'specificationDrawings',
      'scopeOfWorks',
    ];
    if (this.isJobVisitRequired()) {
      fieldsToValidate = [
        'jobName',
        'jobDescription',
        'industry',
        'materialType',
        'closingDate',
        'closingTime',
        'jobLocation',
        'jobSize',
        'constructionPeriod',
        'workingDays',
        'scopeOfWorks',
      ];
    }
    fieldsToValidate.forEach((field) => {
      if (!(this.job as any)[field] || (this.job as any)[field].length === 0) {
        this.errors[field] = true;
      }
      if (
        field === 'scopeOfWorks' &&
        !(this.job as any)[field].some((item: string) => item.length > 0)
      ) {
        this.errors[field] = true;
      }
    });
    if (Object.keys(this.errors).length === 0) {
      this.job.scopeOfWorks = this.job.scopeOfWorks.filter((e) => e.length > 0);
      this.job.specialConditions = this.job.specialConditions.filter(
        (e) => e.length > 0,
      );
    }
    this.job.company = this.user.companyName;
    console.log('errors = ' + JSON.stringify(this.errors));
    return Object.keys(this.errors).length === 0;
  }

  validateStep2() {
    this.errors = {};
    const fieldsToValidate = ['products'];
    fieldsToValidate.forEach((field) => {
      if (!(this.job as any)[field] || (this.job as any)[field].length === 0) {
        this.errors[field] = true;
      }
    });
    return Object.keys(this.errors).length === 0;
  }

  validateStep3() {
    this.errors = {};
    for (let i = 0; i < this.job.products.length; i++) {
      let item = this.job.products[i];
      if (!item.quantity) {
        this.errors.products = i;
        break;
      }
    }
    if (Object.keys(this.errors).length === 0) {
      console.log(this.job.products);
      this.job.products.forEach((p) => {
        p.subItems = p.subItems.filter(
          (s) => s.name.length > 0 && s.quantity.length > 0,
        );
      });
    }
    return Object.keys(this.errors).length === 0;
  }

  get user() {
    return AuthHelper.instance.user;
  }

  get jobMaterialTypes() {
    return DatabaseHelper.instance.jobBoardFilters['Material Type'].items.map(
      (e) => e.name,
    );
  }

  get isPhone() {
    return UtilityHelper.isPhone();
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  jobLog() {
    console.log(this.job.products);
  }
}
