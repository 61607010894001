import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Order } from 'src/app/classes/Order';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';
import { UtilityHelper } from 'src/app/helpers/UtilityHelper';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit, AfterViewInit {

  orders: Order[] = [];
  loading = true;
  perPage = 20;
  allLoaded = false;
  page = 0;

  constructor(private router: Router) { }

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView("Orders");
    this.loadOrders();
  }

  ngAfterViewInit() {
    console.log("View loaded now! + "+document.getElementById('scrollContent'));
    document.getElementById('scrollContent')?.addEventListener('scroll', (event) => {
      console.log("Scroll now");
      let element: HTMLElement = event.target as HTMLElement;
      if (element.scrollHeight - element.scrollTop - element.clientHeight <= 50) {
        console.log("We are deep down, time to load more.");
        if (!this.loading && !this.allLoaded) {
          this.page++;
          this.loadOrders();
          console.log("Load MORE");
        }
      }
    });
  }

  loadOrders() {
    this.loading = true;
    let lastDocumentId = '';
    if (this.orders.length > 0) {
      // lastDocumentId = this.orders[this.orders.length - 1].orderNumber;
      lastDocumentId = this.orders[this.orders.length - 1].orderDate;
    }
    DatabaseHelper.instance.getUserOrders(this.user.uid, this.perPage, lastDocumentId).then(val => {
      console.log("Got more order = "+val.orders.length);
      if (val.orders.length < this.perPage) this.allLoaded = true;
      this.orders = this.orders.concat(val.orders);
      this.loading = false;
    });
  }

  viewOrder(order: Order) {
    this.router.navigateByUrl("order-details", { state: { order: order } });
  }


  get user() {
    return AuthHelper.instance.user;
  }

  get userLoading() {
    return AuthHelper.instance.userLoaded;
  }

  goTo(url: string) {
    this.router.navigateByUrl(url);
  }

}
