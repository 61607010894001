import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
} from '@angular/router';
import { AuthHelper } from './helpers/AuthHelper';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authHelper: AuthHelper,
  ) {}
  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Promise<boolean> {
    try {
      return await this.authHelper.isLoggedIn();
    } catch (err) {
      if (route.routeConfig?.path === '') {
        console.log('Redirecting to landing');
        this.router.navigateByUrl('/landing');
        return Promise.resolve(false);
      }
      this.router.navigateByUrl('/login');
      return await this.authHelper.isLoggedIn();
    }
  }
}
