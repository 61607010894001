export class ProductCollection {

    collectionName: string;
    coverImageUrl: string;
    fullCoverImageUrl: string;
    brand: string;
    category: string;
    subCategory: string;
    products: {
      variations: any; productCode: string, thumbnailImageUrl: string, fullThumbnailImageUrl: string, stockAvailable : string, stockLevel : string 
}[];
    retailPrice: number;
    maximumStockAvailable : number;

    constructor() {
        this.collectionName = "";
        this.coverImageUrl = "";
        this.fullCoverImageUrl = "";
        this.brand = "";
        this.category = "";
        this.products = [];
        this.retailPrice = 0;
        this.maximumStockAvailable = 0;
        this.subCategory = "";
    }

}