<div class="page-container page-estimation">
  <app-menu active="Estimation"></app-menu>
  <div class="content-container">
    <app-header title="Estimations"></app-header>
    <div class="inner-content" [ngClass]="{'no-estimates': loadingEstimates || estimations.length === 0}">
      <div class="estimate-row">
        <div class="details">
          <div class="inner">
            <h1 class="page-title"><img src="../../../assets/images/logo.png" width="135" height="41" class="logo"><span>Estimation services</span></h1>
            <div class="desc">
              <p><strong>Don’t have time to work out how much material you need?</strong> We've got you covered! Just upload your floor plan, answer a few questions and we’ll make sure everything is ready for your prospective installers to quote.</p>
            </div>
            <div class="drop-estimation">
              <div class="dropdown-box" [ngClass]="{loading: !user.email}">
                <div class="dropdown-selected">
                  <div class="context">
                    <div class="label">Industry</div>
                    <div class="selected"><span class="placeholder">Please select</span></div>
                  </div>
                  <div class="price">
                    <span class="symbol">$</span><span class="amount">00.00</span>
                  </div>
                  <span class="drop"><img src="../../../assets/images/down_arrow.png" width="12" alt="arrow"></span>
                </div>
                <div class="dropdown-items-holder">
                  <div class="dropdown-items">
                    <div class="item" data-price="95.00" data-type="Residential">
                      <div class="context">Residential (Per plan)</div>
                      <div class="price"><span class="symbol">$</span><span class="amount">95.00</span></div>
                    </div><!-- item -->
                    <div class="item" data-price="165.00" data-type="Commercial">
                      <div class="context">Commercial (Up to 3 plans)</div>
                      <div class="price"><span class="symbol">$</span><span class="amount">165.00</span></div>
                    </div><!-- item -->
                    <div class="item" data-price="165.00" data-type="Industrial">
                      <div class="context">Industrial (Up to 3 plans)</div>
                      <div class="price"><span class="symbol">$</span><span class="amount">165.00</span></div>
                    </div><!-- item -->
                  </div><!-- dropdown-item -->
                </div>
              </div>
              <div class="error message" style="display: none;">Please select the type.</div>
            </div><!-- drop-estimation -->

            <div class="how-works">
              <!-- <h3>Here's how it works</h3> -->
              <div class="steps">
                <div class="step">
                  <div class="number">1</div>
                  <div class="detail">Select materials, upload your floor plan<br class="hide-phone"> and complete checklist</div>
                </div>
                <div class="step">
                  <div class="number">2</div>
                  <div class="detail">Submit your request and have it processed within <strong>2-3 days</strong></div>
                </div>
                <div class="step">
                  <div class="number">3</div>
                  <div class="detail">Review your estimation and relevant documents,<br class="hide-phone"> sent straight to your inbox</div>
                </div>

              </div><!-- steps -->
            </div><!-- how-works -->

            <div class="cta"><a (click)="openEstimationSteps()" class="primary-button" [ngClass]="{loading: !user.email}">Get Started</a></div>

          </div><!-- inner -->

        </div><!-- details -->
        <div class="pending-item" [ngClass]="{'hide-phone': loadingEstimates || estimations.length === 0}">
          <div class="inner">
            <h3>Pending Estimations <span class="pending-estimation-mobile show-phone"><span id="pending-item">{{estimations.length}}</span> <img class="icon down show-phone" src="../../../assets/images/down_arrow.png" width="12"></span></h3>
            <div class="pending-list">
              <!-- <div id="scrollContent" class="content" *ngIf="!loadingEstimates && estimations.length > 0"> -->
                <div id="scrollContent" class="content">
                <div class="estimation-cell" (click)="viewEstimate(estimate)" *ngFor="let estimate of estimations">
                    <div class="estimation-details">
                        <h1>{{estimate.jobName}}</h1>
                        <p>{{estimate.estimationStatus == 'PENDING' ? 'Submitted' : 'Completed'}} {{estimate.estimationDate | formattedDate}}</p>
                    </div>
                </div>
              </div>
              <mat-spinner class="loading" *ngIf="loadingEstimates" diameter="35" strokeWidth="1"></mat-spinner>
              <div class="no-item" *ngIf="!loadingEstimates && estimations.length === 0">You haven’t requested any estimations yet</div>
            </div>
          </div>
        </div><!-- pending-item -->
      </div>

    </div><!-- inner-content -->
  </div>
</div>
