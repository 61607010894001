import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Order } from 'src/app/classes/Order';
import { Product } from 'src/app/classes/Product';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';
import { PaymentsHelper } from 'src/app/helpers/PaymentsHelper';
import { UtilityHelper } from 'src/app/helpers/UtilityHelper';

@Component({
  selector: 'app-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss']
})
export class ReviewComponent implements OnInit {

  editingOrder = new Order();
  isEditingOrder = false;

  loading = false;
  errorMessage = '';

  cardToken = '';

  public productClass = Product;

  constructor(private router: Router, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.isEditingOrder = false;
    if(this.data) {
      this.editingOrder = new Order();
      this.editingOrder.addData(data.order);
      this.isEditingOrder = true;
      console.log("Loading order data = " + JSON.stringify(this.order));
    }
    else if (this.router.getCurrentNavigation()?.extras && this.router.getCurrentNavigation()?.extras.state) {
      this.cardToken = this.router.getCurrentNavigation()?.extras.state!['cardToken'];
    } else {
      this.router.navigate(["/cart"]);
    }
  }

  get order() {
    return this.isEditingOrder ? this.editingOrder : this.user.currentOrder;
  }

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView("Review");
  }

  async next() {
    this.loading = true;
    this.errorMessage = '';
    this.order.userId = this.user.uid;
    this.order.orderDate = new Date().getTime().toString();
    this.order.paymentStatus = "";
    this.order.fulfilmentStatus = "AWAITING STOCK CHECK";
    this.order.setupTotals();

    if (this.order.paymentOption === 'Credit Card') {
      let val = await PaymentsHelper.instance.authorizePayment(this.order, this.user, this.cardToken);
      if (val.error || val.paymentStatus !== 'APPROVED') {
        this.loading = false;
        this.errorMessage = "We were unable to authorise your card payment. Please check your card details and try again."
        console.log("Got error = "+JSON.stringify(val));
        return;
      }
      this.order.cardAuthorization = val;
    }

    var suppliers: any = this.keysForObject(this.order.shippingPackages);
    this.order.stockChecks = [];
    //Check that each of the suppliers actually has products
    suppliers.forEach((supplier: any) => {
      if (this.order.shippingPackages[supplier].products.length === 0) {
        delete this.order.shippingPackages[supplier];
      }
    });
    suppliers = this.keysForObject(this.order.shippingPackages);

    //Remove any stock checks and delivery options that don't have any products attached to them
    this.order.stockChecks = this.order.stockChecks.filter((stockCheck: any) => {
      return suppliers.includes(stockCheck.supplier);
    });
    this.order.stockChecks = this.order.stockChecks.filter((stockCheck: any) => {
      return this.order.shippingPackages[stockCheck.supplier].products.length > 0;
    });
    suppliers = this.keysForObject(this.order.shippingPackages);
    
    suppliers.forEach((supplier: any) => {
      console.log("Send to supplier = " + supplier);
      var length = 32;
      var wishlist = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
      var password = Array.from(crypto.getRandomValues(new Uint32Array(length))).map((x) => wishlist[x % wishlist.length]).join('');
      this.order.stockChecks.push({ supplier: supplier, code: password, timestamp: "" + Date.now() });

      // Add a stock check object for FLRHUB for deliveries that require a quote.
      if (this.order.shippingPackages[supplier].deliveryOption.cost === "TBC") {
        var password2 = Array.from(crypto.getRandomValues(new Uint32Array(length))).map((x) => wishlist[x % wishlist.length]).join('');
        this.order.stockChecks.push({ supplier: "FLRHUB_"+supplier, code: password2, timestamp: "" + Date.now() });
      }
    });
    console.log("Stock checks = " + JSON.stringify(this.order.stockChecks));
    if (this.data) {
      DatabaseHelper.instance.submitUpdatedOrder(this.order).then(val => {
        if (val.success) {
          if (this.data) {
            //User is updating an existing order through dialogs
            DatabaseHelper.instance.dataCallbacks.dataCallback({ page: 'order-submitted', order: this.order });
          }
          else {
            this.user.currentOrder = new Order();
            this.user.save();
            this.router.navigateByUrl("order-submitted", { state: { order: this.order } });
            if(this.order.discountCode)
            {
              DatabaseHelper.instance.redeemCoupon(this.user, this.order.discountCode, val.orderNumber); // Marks the coupon as redeemed
            }
          }
        } else {
          this.loading = false;
          // TODO - Show alert
        }
      });
    }
    else {
      DatabaseHelper.instance.submitOrder(this.order).then(val => {
        if (val.success) {
          if (this.data) {
            //User is updating an existing order through dialogs
            DatabaseHelper.instance.dataCallbacks.dataCallback({ page: 'order-submitted', order: this.order });
          }
          else {
            this.user.currentOrder = new Order();
            this.user.save();
            this.router.navigateByUrl("order-submitted", { state: { order: this.order } });
            if(this.order.discountCode)
            {
              DatabaseHelper.instance.redeemCoupon(this.user, this.order.discountCode, val.orderNumber); // Marks the coupon as redeemed
            }
          }
        } else {
          this.loading = false;
          // TODO - Show alert
        }
      });
    }
  }

  unitTypeToMeasure(unitType : string)
  {
    return UtilityHelper.unitTypeToMeasure(unitType);
  }

  keysForObject(object: Object): string[] {
    return Object.keys(object);
  }

  get user() {
    return AuthHelper.instance.user;
  }

  back() {
    window.history.back();
  }

}
