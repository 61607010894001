import { Injectable } from "@angular/core";

@Injectable()
export class EstimateQuestionsHelper {

  static mapQuestionPerCategory(product: any){

    

    var category = product.productCategory
    var brand = product.brand
    var productCollection = product.productCollection

    // Vinyl Sheet/ Linoleum/ Marmoleum
    const questionSet1 = [
      {
        type: 'boolean_extra',
        question: 'Do you require spare material',
        desc: '',
        required: true,
        extra_field: true,
        extra_field_label: 'Please enter in a percentage of total quantity above',
        extra_field_required: true,
        extra_field_condition: 'show_if_yes',
        alt_title: 'Spare material required',
      },
      {
        type: 'radio',
        question: 'Lay Direction',
        desc: '',
        required: true,
        choices: [
          'S-N',
          'E-W',
        ],
        alt_title: 'Lay direction',
      },
      {
        type: 'radio',
        question: 'Grinding Required',
        desc: '',
        required: true,
        choices: [
          'Not Required',
          'New Slab',
          'Glue Removal'
        ],
        alt_title: 'Grinding required',
      },
      {
        type: 'boolean_extra_with_file_upload',
        question: 'Moisture Barrier',
        desc: '',
        required: true,
        alt_title: 'Moisture barrier',
        extra_field: true,
        extra_field_label: 'Please provide more information or upload marked up drawing',
        extra_field_required: true,
        extra_field_condition: 'show_if_yes',
        file_field_label: 'Upload marked up drawing'
      },
      {
        type: 'radio_extra',
        question: 'Coving Required',
        desc: '',
        required: true,
        choices: [
          'To joinery and walls',
          'To external walls only (under joinery)',
          'No coving required',
        ],
        extra_field: true,
        extra_field_label: 'Height',
        extra_field_required: true,
        extra_field_condition: ['To joinery and walls', 'To external walls only (under joinery)'],
        alt_title: 'Coving required',
      },
      {
        type: 'boolean_extra_with_file_upload',
        question: 'Waterproofing required',
        desc: '(Moisture Tanking)',
        required: true,
        alt_title: 'Waterproofing required',
        extra_field: true,
        extra_field_label: 'Please provide more information or upload marked up drawing',
        extra_field_required: true,
        extra_field_condition: 'show_if_yes',
        file_field_label: 'Upload marked up drawing'
      },
      {
        type: 'radio',
        question: 'Edging Products',
        desc: '',
        required: true,
        choices: [
          'Silicone to perimeter',
          '100mm vinyl skirting',
          '150mm vinyl skirting',
          'Not required'
        ],
        alt_title: 'Edging required',
      },
      {
        type: 'radio',
        question: 'Underlay Requirements',
        desc: '',
        required: true,
        choices: [
          'Hardboard (Timber Floors only)',
          'No'
        ],
        alt_title: 'Underlay required',
      },
      // {
      //   type: 'radio',
      //   question: 'Average depth Areas',
      //   desc: '',
      //   required: true,
      //   choices: [
      //     'Levelling',
      //     'Screed to Falls'
      //   ],
      //   alt_title: 'Average depth areas',
      // },
      {
        type: 'boolean_extra_with_file_upload',
        question: 'Levelling / Screed to Falls',
        desc: '',
        required: true,
        alt_title: 'Levelling / Screed to Falls',
        extra_field: true,
        extra_field_label: 'Please provide more information or upload marked up drawing',
        extra_field_required: true,
        extra_field_condition: 'show_if_yes',
        file_field_label: 'Upload marked up drawing'
      },
      {
        type: 'radio',
        question: 'Estimate to be completed wall to wall or cut into joinery',
        desc: '',
        required: true,
        choices: [
          'Wall to wall',
          'Cut into joinery'
        ],
        alt_title: 'Estimate to be completed wall to wall or cut into joinery',
      },
      {
        type: 'boolean',
        question: 'Stair Nosings Required',
        desc: '',
        required: true,
        alt_title: 'Stair Nosings required',
      },
      {
        type: 'boolean_extra_with_file_upload',
        question: 'Floor Removal Required',
        desc: '',
        required: true,
        alt_title: 'Floor Removal Required',
        extra_field: true,
        extra_field_label: 'Please upload markup if more than one floor type needs to be removed',
        extra_field_required: true,
        extra_field_condition: 'show_if_yes',
        file_field_label: 'Upload marked up drawing'
      },
      {
        type: 'boolean',
        question: 'Floor Protection Required',
        desc: '',
        required: true,
        alt_title: 'Floor protection required',
      },
      {
        type: 'textarea_with_file_upload',
        question: 'Please enter any additional information or a file',
        desc: '',
        required: false,
        alt_title: 'Additional information or a file',
        answer: ''
      },
    ]

    // Wall Vinyl
    const questionSet2 = [
      {
        type: 'boolean_extra',
        question: 'Do you require spare material',
        desc: '',
        required: true,
        extra_field: true,
        extra_field_label: 'Please enter in a percentage of total quantity above',
        extra_field_required: true,
        extra_field_condition: 'show_if_yes',
        alt_title: 'Spare material required',
      },
      {
        type: 'radio',
        question: 'Lay Direction',
        desc: '',
        required: true,
        choices: [
          'S-N',
          'E-W',
        ],
        alt_title: 'Lay direction',
      },
      {
        type: 'boolean_extra_with_file_upload',
        question: 'Waterproofing required',
        desc: '(Moisture Tanking)',
        required: true,
        alt_title: 'Waterproofing required',
        extra_field: true,
        extra_field_label: 'Please upload marked up drawing',
        extra_field_required: true,
        extra_field_condition: 'show_if_yes',
        file_field_label: 'Upload marked up drawing'
      },
      {
        type: 'radio',
        question: 'Edging products',
        desc: '',
        required: true,
        choices: [
          'Silicone to perimeter',
          'Not required'
        ],
        alt_title: 'Edging products',
      },
      {
        type: 'radio',
        question: 'Estimate to be completed wall to wall or cut into joinery',
        desc: '',
        required: true,
        choices: [
          'Wall to wall',
          'Cut into joinery'
        ],
        alt_title: 'Estimate to be completed wall to wall or cut into joinery',
      },
      {
        type: 'textarea_with_file_upload',
        question: 'Please enter any additional information or a file',
        desc: '',
        required: false,
        alt_title: 'Additional information or a file',
        answer: ''
      },

    ]

    // LVT/ Vinyl Tiles
    const questionSet3 = [
      {
        type: 'boolean_extra',
        question: 'Do you require spare material',
        desc: '',
        required: true,
        extra_field: true,
        extra_field_label: 'Please enter in a percentage of total quantity above',
        extra_field_required: true,
        extra_field_condition: 'show_if_yes',
        alt_title: 'Spare material required',
      },
      {
        type: 'radio',
        question: 'Lay Direction',
        desc: '',
        required: true,
        choices: [
          'S-N',
          'E-W',
        ],
        alt_title: 'Lay direction',
      },
      {
        type: 'radio',
        question: 'Lay Pattern',
        desc: '',
        required: true,
        choices: [
          'Herringbone',
          'Straight Lay',
          'Brick Pattern',
          'Corner to Corner',
          'Chevron',
        ],
        alt_title: 'Lay pattern',
      },
      {
        type: 'radio',
        question: 'Grinding Required',
        desc: '',
        required: true,
        choices: [
          'Not Required',
          'New Slab',
          'Glue Removal'
        ],
        alt_title: 'Grinding required',
      },
      {
        type: 'boolean_extra_with_file_upload',
        question: 'Moisture Barrier',
        desc: '',
        required: true,
        alt_title: 'Moisture barrier',
        extra_field: true,
        extra_field_label: 'Please provide more information or upload marked up drawing',
        extra_field_required: true,
        extra_field_condition: 'show_if_yes',
        file_field_label: 'Upload marked up drawing'
      },
      {
        type: 'radio',
        question: 'Edging Products',
        desc: '',
        required: true,
        choices: [
          'Silicone to perimeter',
          '100mm vinyl skirting',
          '150mm vinyl skirting',
          'Not required'
        ],
        alt_title: 'Edging products',
      },
      {
        type: 'radio',
        question: 'Underlay Requirements',
        desc: '',
        required: true,
        choices: [
          'Hardboard (Timber Floors only)',
          'No'
        ],
        alt_title: 'Underlay required',
      },
      {
        type: 'boolean_extra_with_file_upload',
        question: 'Levelling',
        desc: '',
        required: true,
        alt_title: 'Levelling',
        extra_field: true,
        extra_field_label: 'Please provide more information or upload marked up drawing',
        extra_field_required: true,
        extra_field_condition: 'show_if_yes',
        file_field_label: 'Upload marked up drawing'
      },
      {
        type: 'radio',
        question: 'Estimate to be completed wall to wall or cut into joinery',
        desc: '',
        required: true,
        choices: [
          'Cut into joinery',
          'Wall to wall'
        ],
        alt_title: 'Estimate to be completed wall to wall or cut into joinery',
      },
      {
        type: 'boolean',
        question: 'Stair Nosings Required',
        desc: '',
        required: true,
        alt_title: 'Stair Nosings required',
      },
      {
        type: 'boolean_extra_with_file_upload',
        question: 'Floor Removal Required',
        desc: '',
        required: true,
        alt_title: 'Floor Removal Required',
        extra_field: true,
        extra_field_label: 'Please upload markup if more than one floor type needs to be removed',
        extra_field_required: true,
        extra_field_condition: 'show_if_yes',
        file_field_label: 'Upload marked up drawing'
      },
      {
        type: 'boolean',
        question: 'Floor Protection Required',
        desc: '',
        required: true,
        alt_title: 'Floor protection required',
      },
      {
        type: 'textarea_with_file_upload',
        question: 'Please enter any additional information or a file',
        desc: '',
        required: false,
        alt_title: 'Additional information or a file',
        answer: ''
      },
    ]

    // Hybrid/ Laminate / Tarkett Click Lock Vinyl Tiles
    const questionSet4 = [
      {
        type: 'boolean_extra',
        question: 'Do you require spare material',
        desc: '',
        required: true,
        extra_field: true,
        extra_field_label: 'Please enter in a percentage of total quantity above',
        extra_field_required: true,
        extra_field_condition: 'show_if_yes',
        alt_title: 'Spare material required',
      },
      {
        type: 'radio',
        question: 'Lay Direction',
        desc: '',
        required: true,
        choices: [
          'S-N',
          'E-W',
          'Least Wastage'
        ],
        alt_title: 'Lay direction',
      },
      {
        type: 'radio',
        question: 'Edging Products',
        desc: '',
        required: true,
        choices: [
          'Scotia',
          'Beading',
          'Skirting'
        ],
        alt_title: 'Edging products',
      },
      {
        type: 'radio',
        question: 'Underlay Requirements',
        desc: '',
        required: true,
        choices: [
          'Acoustic Rubber',
          'Standard (None for Hybrid)'
        ],
        alt_title: 'Underlay requirements',
      },
      {
        type: 'boolean_extra_with_file_upload',
        question: 'Levelling',
        desc: '',
        required: true,
        alt_title: 'Levelling',
        extra_field: true,
        extra_field_label: 'Please provide more information or upload marked up drawing',
        extra_field_required: true,
        extra_field_condition: 'show_if_yes',
        file_field_label: 'Upload marked up drawing'
      },
      {
        type: 'boolean',
        question: 'Stair Nosings Required',
        desc: '',
        required: true,
        alt_title: 'Stair Nosings Required',
      },
      {
        type: 'boolean_extra_with_file_upload',
        question: 'Floor Removal Required',
        desc: '',
        required: true,
        alt_title: 'Floor Removal Required',
        extra_field: true,
        extra_field_label: 'Please upload markup if more than one floor type needs to be removed',
        extra_field_required: true,
        extra_field_condition: 'show_if_yes',
        file_field_label: 'Upload marked up drawing'
      },
      {
        type: 'boolean',
        question: 'Floor Protection Required',
        desc: '',
        required: true,
        alt_title: 'Floor Protection Required',
      },
      {
        type: 'textarea_with_file_upload',
        question: 'Please enter any additional information or a file',
        desc: '',
        required: false,
        alt_title: 'Additional information or a file',
        answer: ''
      },
    ]

    // Engineered Timber
    const questionSet5 = [
      {
        type: 'boolean_extra',
        question: 'Do you require spare material',
        desc: '',
        required: true,
        extra_field: true,
        extra_field_label: 'Please enter in a percentage of total quantity above',
        extra_field_required: true,
        extra_field_condition: 'show_if_yes',
        alt_title: 'Spare material required',
      },
      {
        type: 'radio',
        question: 'Lay Direction',
        desc: '',
        required: true,
        choices: [
          'S-N',
          'E-W',
        ],
        alt_title: 'Lay direction',
      },
      {
        type: 'radio',
        question: 'Lay Pattern',
        desc: '',
        required: true,
        choices: [
          'Herringbone',
          'Straight Lay',
          'Chevron',
          'Ashlar'
        ],
        alt_title: 'Lay pattern',
      },
      {
        type: 'radio',
        question: 'Installation',
        desc: 'Adds underlay or glue',
        required: true,
        choices: [
          'Floating',
          'Stick Down',
        ],
        check_on_change: true, // indicator this field as a condition for other fields
        alt_title: 'Adds underlay or glue',
      },
      {
        type: 'boolean_extra_with_file_upload',
        question: 'Moisture Barrier required',
        desc: '',
        required: true,
        condition:{
          target: 'Installation',
          value: 'Stick Down'
        },
        alt_title: 'Moisture barrier required',
        extra_field: true,
        extra_field_label: 'Please provide more information or upload marked up drawing',
        extra_field_required: true,
        extra_field_condition: 'show_if_yes',
        file_field_label: 'Upload marked up drawing'
      },
      {
        type: 'radio',
        question: 'Underlay Requirements', // Stick Down
        desc: '',
        required: true,
        choices: [
          'Upstairs only',
          'Whole floor'
        ],
        condition:{
          target: 'Installation',
          value: 'Stick Down'
        },
        alt_title: 'Underlay requirements',
      },
      {
        type: 'radio',
        question: 'Underlay Requirements', // Floating
        desc: '',
        required: true,
        choices: [
          'Acoustic Rubber',
          'Standard'
        ],
        condition:{
          target: 'Installation',
          value: 'Floating'
        },
        alt_title: 'Underlay requirements',
      },
      {
        type: 'radio',
        question: 'Grinding Required',
        desc: '',
        required: true,
        choices: [
          'Not Required',
          'New Slab',
          'Glue Removal'
        ],
        alt_title: 'Grinding required',
      },
      {
        type: 'radio',
        question: 'Edging Products',
        desc: '',
        required: true,
        choices: [
          'Scotia',
          'Beading',
          'Skirting',
          'Not required'
        ],
        alt_title: 'Edging products',
      },
      {
        type: 'boolean_extra_with_file_upload',
        question: 'Levelling',
        desc: '',
        required: true,
        alt_title: 'Levelling',
        extra_field: true,
        extra_field_label: 'Please provide more information or upload marked up drawing',
        extra_field_required: true,
        extra_field_condition: 'show_if_yes',
        file_field_label: 'Upload marked up drawing'
      },
      {
        type: 'radio',
        question: 'Estimate to be completed wall to wall or cut into joinery',
        desc: '',
        required: true,
        choices: [
          'Wall to wall',
          'Cut into joinery'
        ],
        alt_title: 'Estimate to be completed wall to wall or cut into joinery',
      },
      {
        type: 'boolean',
        question: 'Stair Nosings Required',
        desc: '',
        required: true,
        alt_title: 'Stair Nosings Required',
      },
      {
        type: 'boolean_extra_with_file_upload',
        question: 'Floor Removal Required',
        desc: '',
        required: true,
        alt_title: 'Floor Removal Required',
        extra_field: true,
        extra_field_label: 'Please upload markup if more than one floor type needs to be removed',
        extra_field_required: true,
        extra_field_condition: 'show_if_yes',
        file_field_label: 'Upload marked up drawing'
      },
      {
        type: 'boolean',
        question: 'Floor Protection Required',
        desc: '',
        required: true,
        alt_title: 'Floor Protection Required',
      },
      {
        type: 'textarea_with_file_upload',
        question: 'Please enter any additional information or a file',
        desc: '',
        required: false,
        alt_title: 'Additional information or a file',
        answer: ''
      },
    ]

    // Carpet Tiles
    const questionSet6 = [
      {
        type: 'boolean_extra',
        question: 'Do you require spare material',
        desc: '',
        required: true,
        extra_field: true,
        extra_field_label: 'Please enter in a percentage of total quantity above',
        extra_field_required: true,
        extra_field_condition: 'show_if_yes',
        alt_title: 'Spare material required',
      },
      {
        type: 'radio',
        question: 'Lay Direction',
        desc: '',
        required: true,
        choices: [
          'S-N',
          'E-W',
        ],
        alt_title: 'Lay direction',
      },
      {
        type: 'radio',
        question: 'Lay Pattern',
        desc: '',
        required: true,
        choices: [
          'Herringbone',
          'Straight Lay',
          'Brick Pattern',
          'Corner to Corner',
          'Chevron',
          'Ashlar'
        ],
        alt_title: 'Lay pattern',
      },
      {
        type: 'radio',
        question: 'Grinding Required',
        desc: '',
        required: true,
        choices: [
          'Not Required',
          'New Slab',
          'Glue Removal'
        ],
        alt_title: 'Grinding required',
      },
      {
        type: 'boolean_extra_with_file_upload',
        question: 'Moisture Barrier',
        desc: '',
        required: true,
        alt_title: 'Moisture barrier',
        extra_field: true,
        extra_field_label: 'Please provide more information or upload marked up drawing',
        extra_field_required: true,
        extra_field_condition: 'show_if_yes',
        file_field_label: 'Upload marked up drawing'
      },
      {
        type: 'radio',
        question: 'Edging Products',
        desc: '',
        required: true,
        choices: [
          '100mm vinyl skirting',
          '150mm vinyl skirting',
          'Not required'
        ],
        alt_title: 'Edging products',
      },
      {
        type: 'radio',
        question: 'Underlay Requirements',
        desc: '',
        required: true,
        choices: [
          'Hardboard (Timber Floors only)',
          'No'
        ],
        alt_title: 'Underlay requirements',
      },
      {
        type: 'boolean_extra_with_file_upload',
        question: 'Levelling',
        desc: '',
        required: true,
        alt_title: 'Levelling',
        extra_field: true,
        extra_field_label: 'Please provide more information or upload marked up drawing',
        extra_field_required: true,
        extra_field_condition: 'show_if_yes',
        file_field_label: 'Upload marked up drawing'
      },
      {
        type: 'radio',
        question: 'Estimate to be completed wall to wall or cut into joinery',
        desc: '',
        required: true,
        choices: [
          'Wall to wall',
          'Cut into joinery'
        ],
        alt_title: 'Estimate to be completed wall to wall or cut into joinery',
      },
      {
        type: 'boolean',
        question: 'Stair Nosings Required',
        desc: '',
        required: true,
        alt_title: 'Stair Nosings Required',
      },
      {
        type: 'boolean_extra_with_file_upload',
        question: 'Floor Removal Required',
        desc: '',
        required: true,
        alt_title: 'Floor Removal Required',
        extra_field: true,
        extra_field_label: 'Please upload markup if more than one floor type needs to be removed',
        extra_field_required: true,
        extra_field_condition: 'show_if_yes',
        file_field_label: 'Upload marked up drawing'
      },
      {
        type: 'boolean',
        question: 'Floor Protection Required',
        desc: '',
        required: true,
        alt_title: 'Floor Protection Required',
      },
      {
        type: 'textarea_with_file_upload',
        question: 'Please enter any additional information or a file',
        desc: '',
        required: false,
        alt_title: 'Additional information or a file',
        answer: ''
      },
    ]

    // Broadloom Carpet
    const questionSet7 = [
      {
        type: 'boolean_extra',
        question: 'Do you require spare material',
        desc: '',
        required: true,
        extra_field: true,
        extra_field_label: 'Please enter in a percentage of total quantity above',
        extra_field_required: true,
        extra_field_condition: 'show_if_yes',
        alt_title: 'Spare material required',
      },
      {
        type: 'radio',
        question: 'Lay Direction',
        desc: '',
        required: true,
        choices: [
          'S-N',
          'E-W',
        ],
        alt_title: 'Lay direction',
      },
      {
        type: 'radio',
        question: 'Grinding Required',
        desc: '',
        required: true,
        choices: [
          'Not Required',
          'New Slab',
          'Glue Removal'
        ],
        alt_title: 'Grinding required',
      },
      {
        type: 'boolean_extra_with_file_upload',
        question: 'Moisture Barrier',
        desc: '',
        required: true,
        alt_title: 'Moisture barrier',
        extra_field: true,
        extra_field_label: 'Please provide more information or upload marked up drawing',
        extra_field_required: true,
        extra_field_condition: 'show_if_yes',
        file_field_label: 'Upload marked up drawing'
      },
      {
        type: 'radio',
        question: 'Edging Products',
        desc: '',
        required: true,
        choices: [
          '100mm vinyl skirting',
          '150mm vinyl skirting',
          'Not required'
        ],
        alt_title: 'Edging products',
      },
      {
        type: 'boolean',
        question: 'Stick Down',
        desc: 'Adds Adhesive',
        required: true,
        alt_title: 'Stick down',
      },
      {
        type: 'boolean',
        question: 'Dual Bond',
        desc: 'Adds Underlay',
        required: true,
        alt_title: 'Dual bond',
      },
      {
        type: 'boolean',
        question: 'Conventional Install',
        desc: 'Adds Underlay',
        required: true,
        alt_title: 'Conventional install',
      },
      {
        type: 'radio',
        question: 'Underlay Requirements',
        desc: '',
        required: true,
        choices: [
          'Hardboard (Timber Floors only)',
          'No'
        ],
        alt_title: 'Underlay requirements',
      },
      {
        type: 'boolean_extra_with_file_upload',
        question: 'Levelling',
        desc: '',
        required: true,
        alt_title: 'Levelling',
        extra_field: true,
        extra_field_label: 'Please provide more information or upload marked up drawing',
        extra_field_required: true,
        extra_field_condition: 'show_if_yes',
      },
      {
        type: 'radio',
        question: 'Estimate to be completed wall to wall or cut into joinery',
        desc: '',
        required: true,
        choices: [
          'Wall to wall',
          'Cut into joinery'
        ],
        alt_title: 'Estimate to be completed wall to wall or cut into joinery',
      },
      {
        type: 'boolean',
        question: 'Stair Nosings Required',
        desc: '',
        required: true,
        alt_title: 'Stair Nosings Required',
      },
      {
        type: 'boolean_extra_with_file_upload',
        question: 'Floor Removal Required',
        desc: '',
        required: true,
        alt_title: 'Floor Removal Required',
        extra_field: true,
        extra_field_label: 'Please upload markup if more than one floor type needs to be removed',
        extra_field_required: true,
        extra_field_condition: 'show_if_yes',
        file_field_label: 'Upload marked up drawing'
      },
      {
        type: 'boolean',
        question: 'Floor Protection Required',
        desc: '',
        required: true,
        alt_title: 'Floor Protection Required',
      },
      {
        type: 'textarea_with_file_upload',
        question: 'Please enter any additional information or a file',
        desc: '',
        required: false,
        alt_title: 'Additional information or a file',
        answer: ''
      },
    ]

    //Epoxy Warehouse
    const questionSet8 = [
      {
        type: 'label',
        question: 'What you will receive',
        desc: 'Due to the nature of Epoxy products, the variability of site conditions and scope of works, instead of providing a fully quantified material list, FLRHUB will provide a estimate of areas and measurements as defined in the your project plans.',
        required: false,
        alt_title: ' ',
      },
      {
        type: 'boolean',
        question: 'Perimeter required for Edging Products',
        desc: '',
        required: true,
        alt_title: 'Perimeter required for Edging Products',
      },
      {
        type: 'boolean_extra_with_file_upload',
        question: 'Expansion Joints',
        desc: '',
        required: true,
        alt_title: 'Expansion Joints',
        extra_field: true,
        extra_field_label: 'Please provide more information or upload marked up drawing',
        extra_field_required: false,
        extra_field_condition: 'show_if_yes',
        file_field_label: 'Upload marked up drawings'
      },
      {
        type: 'textarea_with_file_upload',
        question: 'Please enter any additional information or a file',
        desc: '',
        required: false,
        alt_title: 'Additional information or a file',
        answer: ''
      },
    ]

    // Generic Question, just in case new category added
    const genericQuestion = [
      {
        type: 'textarea',
        question: 'Please enter any additional information',
        desc: '',
        required: true,
        alt_title: 'Additional information',
      }
    ]

    // Force map special case for Indoor Sports
    // productCollection = Multiflex M is Timber
    // any other product under Indoor Sports are Vinyl
    if( category == 'Indoor Sports' ){
      if( productCollection == 'Multiflex M'){
        category = 'Indoor Sports Timber'
      }else{
        category = 'Indoor Sports Vinyl'
      }
    }
    if( category == 'Luxury Vinyl Tiles' ){
      if( productCollection == 'iD Inspiration Ultimate'){
        category = 'Hybrid'
      }
    }
    if(brand == 'Epoxy Warehouse')
    {
      category = 'Epoxy Warehouse'
    }

    // Map Question per category
    const questionMapCategory = {
      // Vinyl Sheet/ Linoleum/ Marmoleum
      questionSet1 : ['Linoleum', 'Heterogeneous Vinyl', 'Homogeneous Vinyl', 'Safety Floors', 'Static Control', 'Indoor Sports Vinyl'],
      // Wall Vinyl
      questionSet2 : ['Wallcoverings'],
      /// LVT/ Vinyl Tiles
      questionSet3 : ['Luxury Vinyl Tiles'],
      // Hybrid/ Laminate / Tarkett Click Lock Vinyl Tiles - not in use at the moment
      questionSet4 : ['Hybrid'],
      // Engineered Timber (Currently Multiflex M only)
      questionSet5 : ['Indoor Sports Timber', 'Timber'],
      // Carpet Tiles
      questionSet6 : ['Modular Carpet'],
      // Broadloom Carpet
      questionSet7 : ['Carpet Rolls'],
      //Epoxy Warehouse
      questionSet8 : ['Epoxy Warehouse'],
    }


    let foundCategory = ''
    for( const [key, val] of Object.entries( questionMapCategory ) ){
      var value = val as any
      if( value.includes( category ) ){
        foundCategory = key
        break
      }
    }

    if( foundCategory == 'questionSet1' ){
      return questionSet1
    }
    else if( foundCategory == 'questionSet2' ){
      return questionSet2
    }
    else if( foundCategory == 'questionSet3' ){
      return questionSet3
    }
    else if( foundCategory == 'questionSet4' ){
      return questionSet4
    }
    else if( foundCategory == 'questionSet5' ){
      return questionSet5
    }
    else if( foundCategory == 'questionSet6' ){
      return questionSet6
    }
    else if( foundCategory == 'questionSet7' ){
      return questionSet7
    }
    else if( foundCategory == 'questionSet8' ){
      return questionSet8
    }
    else{
      return genericQuestion
    }



  }

}
