import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { EstimationCart } from 'src/app/classes/EstimationCart';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';
import { UtilityHelper } from 'src/app/helpers/UtilityHelper';
import { EstimationDetailsComponent } from 'src/app/modals/estimation-details/estimation-details.component';

@Component({
  selector: 'app-estimations',
  templateUrl: './estimations.component.html',
  styleUrls: ['./estimations.component.scss']
})
export class EstimationsComponent implements OnInit, AfterViewInit {

  estimations: EstimationCart[] = [];
  loading = true;
  perPage = 15;
  allLoaded = false;
  page = 0;
  filter : String = 'All';

  constructor(private router: Router, private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data : any) { }

  ngOnInit(): void {
    this.loadEstimations();
    AnalyticsHelper.instance.pageView("Estimations");
  }

  filterClicked(filter : String)
  {
    this.filter = filter;
    this.page = 0;
    this.estimations = [];
    this.loadEstimations();
  }

  viewEstimate(estimate : EstimationCart)
  {
    this.dialog.open(EstimationDetailsComponent, {
      height: this.isPhone ? '100vh' : 'calc(100vh - 30px)',
      width: this.isPhone ? '100vw' : 'calc(100vw - 60px)',
      maxWidth: '100vw',
      maxHeight: '-webkit-fill-available',
      position: { bottom: '0', left: this.isPhone ? '0' : '30px' },
      enterAnimationDuration: '0',
      panelClass: ['profile-modal', 'no-padding'],
      id: 'estimation-details',
      data: {
        estimation: estimate,
        choosingEstimation: this.isChoosingEstimation()
      }
    });
  }

  ngAfterViewInit() {
    document.getElementById('scrollContent')?.addEventListener('scroll', (event) => {
      let element: HTMLElement = event.target as HTMLElement;
      if (element.scrollHeight - element.scrollTop - element.clientHeight <= 50) {
        console.log("We are deep down, time to load more.");
        if (!this.loading && !this.allLoaded) {
          this.page++;
          this.loadEstimations();
        }
      }
    });
  }

  close()
  {
    DatabaseHelper.instance.dataCallbacks.dataCallback({ page: 'dismissEstimations' });
  }

  isChoosingEstimation()
  {
    if(this.data != null)
    {
      return this.data.choosingEstimation;
    }
    else
    {
      return false;
    }    
  }

  loadEstimations() {
    this.loading = true;
    let lastDocumentId = '';
    if(this.isChoosingEstimation())
    {
      //Load only completed estimations
      DatabaseHelper.instance.getUserCompletedEstimations(this.user.uid).then(val => {
        this.allLoaded = true;
        this.estimations = this.estimations.concat(val.estimations);
        console.log(JSON.stringify(val));
        this.loading = false;
      });
    }
    else
    {
      if (this.estimations.length > 0) {
        lastDocumentId = this.estimations[this.estimations.length - 1].estimationNumber;
      }
      DatabaseHelper.instance.getUserEstimations(this.user.uid, this.perPage, lastDocumentId, this.filter == "Pending" ? true : false).then(val => {
        if (val.estimations && val.estimations.length < this.perPage) this.allLoaded = true;
        this.estimations = this.estimations.concat(val.estimations);
        console.log(JSON.stringify(val));
        this.loading = false;
      });
    }
  }

  get pendingEstimationsLength() {
    return this.estimations.filter(e => e.estimationStatus === 'PENDING').length;
  }

  get user() {
    return AuthHelper.instance.user;
  }

  get userLoading() {
    return AuthHelper.instance.userLoaded;
  }

  goTo(url: string) {
    this.router.navigateByUrl(url);
  }

  get isPhone() {
    return UtilityHelper.isPhone();
  }

}
