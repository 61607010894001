<div class="page-container page-job-board">
  <app-menu active="My Hub"></app-menu>
  <div class="content-container">
    <app-header title="My Hub"></app-header>
    <app-my-hub-tabs active="My Job Posts"></app-my-hub-tabs>
    <div id="scrollContent" class="inner-content">
      <mat-spinner class="loading" *ngIf="loading" diameter="35" strokeWidth="1"></mat-spinner>
      <div *ngIf="!loading && jobPosts.length === 0" class="empty-placeholder">
        <div class="message">You haven't posted any jobs yet</div>
        <div class="secondary-button" (click)="openCreateJobDialog()">Post New Job</div>
      </div>
      <div *ngIf="!loading && jobPosts.length > 0" class="job-board-status-head">
        <div class="statuses">
          <div class="selected-mobile" (click)="toggleSelectMobile()">
            <ul>
              <li class="active" *ngIf="activeTab === 'Active Job Post'">
                <span class="text">Active Job Posts</span><span class="count">{{activeJobPosts.length}}</span>
              </li>
              <li class="active" *ngIf="activeTab === 'My Drafts'">
                <span class="text">My Drafts</span><span class="count">{{draftJobPosts.length}}</span>
              </li>
              <li class="active" *ngIf="activeTab === 'Closed Job Posts'">
                <span class="text">Closed Job Posts</span><span class="count">{{closedJobPosts.length}}</span>
              </li>
            </ul>
            <img src="../../../assets/images/down_arrow.png" width="12" alt="arrow" />
          </div><!-- selected-mobile -->
          <ul class="main-tab-menu">
            <li [ngClass]="{active: activeTab === 'Active Job Post'}"
              (click)="activeTab = 'Active Job Post'; removeSelectMobile()">
              <span class="text">Active Job Posts</span><span class="count">{{activeJobPosts.length}}</span>
            </li>
            <li [ngClass]="{active: activeTab === 'My Drafts'}" (click)="activeTab = 'My Drafts'; removeSelectMobile()">
              <span class="text">My Drafts</span><span class="count">{{draftJobPosts.length}}</span>
            </li>
            <li [ngClass]="{active: activeTab === 'Closed Job Posts'}"
              (click)="activeTab = 'Closed Job Posts'; removeSelectMobile()">
              <span class="text">Closed Job Posts</span><span class="count">{{closedJobPosts.length}}</span>
            </li>
          </ul>
        </div>
        <div class="post-new-job">
          <div class="secondary-button" (click)="openCreateJobDialog()">Post New Job</div>
        </div>
      </div><!-- job-board-status-head -->
      <div *ngIf="!loading && jobPosts.length > 0" class="job-board-tabs">

        <div class="job-board-tab" *ngIf="activeTab === 'Active Job Post'">
          <div *ngIf="activeJobPosts.length === 0" class="empty-placeholder">
            <div class="message">You don't have any active job posts</div>
          </div>
          <div class="job-card" *ngFor="let jobPost of activeJobPosts; let i = index" (click)="openJob(jobPost)">
            <div class="inner-card">
              <div class="card-head"><span class="color-orange heading">{{jobPost.jobName}}</span> <span
                  class="posted small">Posted {{utilityHelper.formatDate(jobPost.submittedDate)}}</span></div>

              <div class="card-boxes">
                <div class="box counter">
                  <div class="context small">Quotes submitted</div>
                  <div class="number color-orange">{{jobPost.quotesSubmitted}}</div>
                </div><!-- box -->
                <div class="box counter" *ngIf="isPhone" [style.visibility]="'hidden'">
                  <div class="context small">Job Accepted</div>
                  <div class="number color-orange">0</div>
                </div><!-- box -->
                <div class="box box-spacer">&nbsp;</div>
                <div class="box info">
                  <div class="context small">Closing Date</div>
                  <div class="date">{{utilityHelper.formatDate(jobPost.closingDate)}}</div>
                </div><!-- box -->
                <div class="box info">
                  <div class="context small">Closing Time</div>
                  <div class="date">{{utilityHelper.formatTime(jobPost.closingTime)}}</div>
                </div><!-- box -->
              </div><!-- card boxes -->

              <div class="card-footer">
                <div class="category" *ngFor="let item of jobPost.materialType">{{item}}</div>
                <div class="location"><img src="../../../assets/images/location.png" width="12" height="17"
                    alt="Location" /> {{jobPost.suburb}}, {{jobPost.state}}</div>
              </div><!-- card-footer -->

              <div class="card-action" (click)="$event.stopPropagation();">
                <span class="dots"><span></span></span>
                <ul class="submenu">
                  <!-- <li>Edit</li> -->
                  <li (click)="removeJobPost(jobPost); $event.stopPropagation();">Delete</li>
                </ul>
              </div>

            </div>
          </div><!-- job-card -->
        </div><!-- job-board-tab -->

        <div class="job-board-tab" *ngIf="activeTab === 'My Drafts'">
          <div *ngIf="draftJobPosts.length === 0" class="empty-placeholder">
            <div class="message">You don't have any draft job posts</div>
          </div>
          <div class="job-card" *ngFor="let jobPost of draftJobPosts; let i = index" (click)="openJob(jobPost)">
            <div class="inner-card">
              <div class="card-head"><span class="color-orange heading">{{jobPost.jobName}}</span></div>

              <div class="card-boxes">
                <div class="box counter">
                  <div class="context small">Quotes submitted</div>
                  <div class="number color-orange">-</div>
                </div><!-- box -->
                <div class="box counter" *ngIf="isPhone" [style.visibility]="'hidden'">
                  <div class="context small">Job Accepted</div>
                  <div class="number color-orange">-</div>
                </div><!-- box -->
                <div class="box box-spacer">&nbsp;</div>
                <div class="box info">
                  <div class="context small">Closing Date</div>
                  <div class="date">{{jobPost.closingDate ? utilityHelper.formatDate(jobPost.closingDate) : '-'}}</div>
                </div><!-- box -->
                <div class="box info">
                  <div class="context small">Closing Time</div>
                  <div class="date">{{jobPost.closingTime ? utilityHelper.formatTime(jobPost.closingTime) : '-'}}</div>
                </div><!-- box -->
              </div><!-- card boxes -->

              <div class="card-footer">
                <div class="category" *ngFor="let item of jobPost.materialType">{{item}}</div>
                <div class="location"><img src="../../../assets/images/location.png" width="12" height="17"
                    alt="Location" /> {{jobPost.jobLocation ? (jobPost.suburb + ', ' + jobPost.state) : '-'}}
                </div>
              </div><!-- card-footer -->

              <div class="card-action" (click)="$event.stopPropagation();">
                <span class="dots"><span></span></span>
                <ul class="submenu">
                  <li (click)="editJobPost(jobPost); $event.stopPropagation();">Edit</li>
                  <li (click)="removeJobPost(jobPost); $event.stopPropagation();">Delete</li>
                </ul>
              </div>

            </div>
          </div><!-- job-card -->
        </div><!-- job-board-tab -->

        <div class="job-board-tab" *ngIf="activeTab === 'Closed Job Posts'">
          <div *ngIf="closedJobPosts.length === 0" class="empty-placeholder">
            <div class="message">You don't have any closed job posts</div>
          </div>
          <div class="job-card" *ngFor="let jobPost of closedJobPosts; let i = index" (click)="openJob(jobPost)">
            <div class="inner-card">
              <div class="card-head"><span class="color-orange heading">{{jobPost.jobName}}</span> <span
                  class="posted small">Posted {{utilityHelper.formatDate(jobPost.submittedDate)}}</span></div>

              <div class="card-boxes">
                <div class="box counter">
                  <div class="context small">Quotes submitted</div>
                  <div class="number color-orange">{{jobPost.quotesSubmitted}}</div>
                </div><!-- box -->
                <div class="box counter" *ngIf="isPhone" [style.visibility]="'hidden'">
                  <div class="context small">Job Accepted</div>
                  <div class="number color-orange">0</div>
                </div><!-- box -->
                <div class="box box-spacer">&nbsp;</div>
                <div class="box info">
                  <div class="context small">Closing Date</div>
                  <div class="date">{{utilityHelper.formatDate(jobPost.closingDate)}}</div>
                </div><!-- box -->
                <div class="box info">
                  <div class="context small">Closing Time</div>
                  <div class="date">{{utilityHelper.formatTime(jobPost.closingTime)}}</div>
                </div><!-- box -->
              </div><!-- card boxes -->

              <div class="card-footer">
                <div class="category" *ngFor="let item of jobPost.materialType">{{item}}</div>
                <div class="location"><img src="../../../assets/images/location.png" width="12" height="17"
                    alt="Location" /> {{jobPost.suburb}}, {{jobPost.state}}</div>
              </div><!-- card-footer -->

              <div class="card-action" (click)="$event.stopPropagation();">
                <span class="dots"><span></span></span>
                <ul class="submenu">
                  <li (click)="removeJobPost(jobPost); $event.stopPropagation();">Delete</li>
                </ul>
              </div>

            </div>
          </div><!-- job-card -->
        </div><!-- job-board-tab -->

      </div><!-- job-board-tabs -->
    </div>
  </div>
</div>