<div class="header">
    <div class="header-title">Edit Profile</div>
    <div class="spacer"></div>
    <div class="secondary-button" (click)="cancel()">
        Cancel
    </div>
    <div class="primary-button" (click)="save()" [ngClass]="{loading: loading}">
        Save
    </div>
</div>

<div class="container">
  <div class="row-flex about-image">
    <div class="field field-image">
      <div classcompanyLogoUrl="input-field">
        <div class="upload-image {{ user.companyLogoUrl ? 'has-image' : 'no-image' }}">
            <div class="preview-image-section">
                <div class="image-title">Company Logo</div>
                <img class="preview-image"
                    [src]="user.companyLogoUrl ? user.companyLogoUrl :  '../../../assets/images/placeholder_image.png'">
            </div>
            <div class="input-section">
                <label for="uploadImage">
                    <input id="uploadImage" class="file-input" type="file" accept="image/*" (change)="uploadImage($event)">
                    <div class="secondary-button" [ngClass]="{loading: imageUploading}"><img class="icon"
                            style="transform: scaleY(-1);"
                            src="../../../assets/images/download_icon.png">{{user.companyLogoUrl ?
                        'Change':'Upload'}} image</div>
                    <div class="input-description" *ngIf="imageSizeError" [ngClass]="{'size-error': imageSizeError}"><span>The image should be at least 300px in width and height</span></div>
                </label>
            </div>
            <div *ngIf="user.companyLogoUrl" class="image-preview-has-image" (click)="openFileUpload($event)">
              <div class="image" style="background-image:url({{user.companyLogoUrl}})"></div>
              <div class="edit">Change Image</div>
            </div>
        </div>
      </div>
    </div>
    <div class="field field-about">
      <div class="input-field" [ngClass]="{error: errors.about}">
        <label for="about">About</label>
        <textarea id="about" [(ngModel)]="user.about" (ngModelChange)="validate()"
            placeholder="Type here..." ></textarea>
      </div>
    </div>
  </div><!-- about-image -->

  <div class="fields personal-fields">
    <div class="row-flex">
      <div class="field">
        <div class="input-field">
            <label for="firstName" class="field-title">First Name</label>
            <input type="text" id="firstName" [(ngModel)]="user.firstName" (ngModelChange)="validate()" placeholder="Type here...">
          </div>
      </div>
      <div class="field">
        <div class="input-field">
          <label for="lastName" class="field-title">Last Name</label>
          <input type="text" id="lastName" [(ngModel)]="user.lastName" (ngModelChange)="validate()" placeholder="Type here...">
        </div>
      </div>
      <div class="field">
        <div class="input-field">
          <label for="mobileNumber" class="field-title">Mobile Number</label>
          <input type="text" id="mobileNumber" [(ngModel)]="user.mobileNumber" (ngModelChange)="validate()" placeholder="Type here...">
        </div>
      </div>
      <div class="field">
        <div class="input-field">
          <label for="driversLicenceNumber" class="field-title">Drivers Licence Number</label>
          <input type="text" id="driversLicenceNumber" [(ngModel)]="user.driversLicenceNumber" (ngModelChange)="validate()" placeholder="Type here...">
        </div>
      </div>
      <div class="field full">
        <div class="input-field">
          <label for="homeAddress" class="field-title">Address</label>
          <input type="text" ngx-google-places-autocomplete id="homeAddress" [(ngModel)]="user.homeAddress" (ngModelChange)="validate()" placeholder="Type here...">
        </div>
      </div>

    </div>
  </div>

  <!-- Company Details -->
  <div class="fields company-fields">
    <div class="row-flex">
      <div class="field">
        <div class="input-field">
            <label for="companyName" class="field-title">Company Name</label>
            <input type="text" id="companyName" [(ngModel)]="user.companyName" (ngModelChange)="validate()" placeholder="Type here...">
          </div>
      </div>
      <div class="field">
        <div class="input-field">
          <label for="abn" class="field-title">ABN</label>
          <input type="text" id="abn" [(ngModel)]="user.abn" (ngModelChange)="validate()" placeholder="Type here...">
        </div>
      </div>
      <div class="field">
        <div class="input-field">
          <label for="companyPhone" class="field-title">Company Phone Number</label>
          <input type="tel" id="companyPhone" [(ngModel)]="user.companyPhone" (ngModelChange)="validate()" placeholder="Type here...">
        </div>
      </div>
      <div class="field">
        <div class="input-field">
          <label for="companyEmail" class="field-title">Company Email</label>
          <input type="email" id="companyEmail" [(ngModel)]="user.companyEmail" (ngModelChange)="validate()" placeholder="Type here...">
        </div>
      </div>
      <div class="field full">
        <div class="input-field">
          <label for="companyAddress" class="field-title">Company Address</label>
          <input type="text" ngx-google-places-autocomplete id="companyAddress" [(ngModel)]="user.companyAddress" (ngModelChange)="validate()" placeholder="Type here...">
        </div>
      </div>
      <div class="field">
        <div class="input-field">
          <label for="companyBusinessType" class="field-title">Business Type</label>
          <select id="companyBusinessType" [(ngModel)]="user.companyBusinessType" name="companyBusinessType">
              <option value="" disabled selected hidden>Please select...</option>
              <option value="Company">Company</option>
              <option value="Trust">Trust</option>
              <option value="Other">Other</option>
          </select>
          <!-- <input type="text" id="companyBusinessType" [(ngModel)]="user.companyBusinessType" (ngModelChange)="validate()" placeholder="Type here..."> -->
        </div>
      </div>
      <div class="field">
        <div class="input-field">
          <label for="companyJobTitle" class="field-title">Job Title</label>
          <select id="companyJobTitle" [(ngModel)]="user.companyJobTitle" name="companyJobTitle">
              <option value="" disabled selected hidden>Please select...</option>
              <option value="Owner">Owner</option>
              <option value="Director">Director</option>
              <option value="Manager">Manager</option>
              <option value="Employee">Employee</option>
          </select>
          <!-- <input type="text" id="companyJobTitle" [(ngModel)]="user.companyJobTitle" (ngModelChange)="validate()" placeholder="Type here..."> -->
        </div>
      </div>
      <div class="field">
        <div class="input-field">
          <label for="companyOrgSize" class="field-title">Organisation Size</label>
          <select id="companyOrgSize" [(ngModel)]="user.companyOrgSize" name="companyOrgSize">
              <option value="" disabled selected hidden>Please select...</option>
              <option value="1-10 Employees">1-10 Employees</option>
              <option value="11-25 Employees">11-25 Employees</option>
              <option value="25+ Employees">25+ Employees</option>
          </select>
          <!-- <input type="text" id="companyOrgSize" [(ngModel)]="user.companyOrgSize" (ngModelChange)="validate()" placeholder="Type here..."> -->
        </div>
      </div>
      <div class="field">
        <div class="input-field">
          <label for="companyAnnualTurnover" class="field-title">Annual Turnover</label>
          <select id="companyAnnualTurnover" [(ngModel)]="user.companyAnnualTurnover" name="companyAnnualTurnover">
              <option value="" disabled selected hidden>Please select...</option>
              <option value="0-5 Millions">0-5 Millions</option>
              <option value="5-10 Millions">5-10 Millions</option>
              <option value="10-30 Millions">10-30 Millions</option>
              <option value="30 Millions +">30 Millions +</option>
          </select>
          <!-- <input type="text" id="companyAnnualTurnover" [(ngModel)]="user.companyAnnualTurnover" (ngModelChange)="validate()" placeholder="Type here..."> -->
        </div>
      </div>
      <div class="field full">
        <div class="input-field">
          <label for="companyBankrupcy" class="field-title">Have you, your company or any company you've been part of declared bankruptcy in the past?</label>
          <div class="radio-choice">
            <div class="radio-item">
              <input id="companyBankrupcyYes" type="radio" [(ngModel)]="user.companyBankrupcy" (ngModelChange)="validate()" value="Yes" name="companyBankrupcy">
              <label for="companyBankrupcyYes" class="radio-label">Yes</label>
            </div>
            <div class="radio-item">
              <input id="companyBankrupcyNo" type="radio" [(ngModel)]="user.companyBankrupcy" (ngModelChange)="validate()" value="No" name="companyBankrupcy">
              <label for="companyBankrupcyNo" class="radio-label">No</label>
            </div>
          </div>
        </div>
      </div>


      <!-- builder -->
      <div class="field" *ngIf="user.type == 'Builder'">
        <div class="input-field">
          <label for="companyBuildingType" class="field-title">Types of buildings </label>
          <input type="text" id="companyBuildingType" [(ngModel)]="user.companyBuildingType" (ngModelChange)="validate()" placeholder="Type here...">
        </div>
      </div>
      <div class="field" *ngIf="user.type == 'Builder'">
        <div class="input-field">
          <label for="companyBuildPerYear" class="field-title">How many builds per year</label>
          <input type="tel" numbersOnly="true" id="companyBuildPerYear" [(ngModel)]="user.companyBuildPerYear" (ngModelChange)="validate()" placeholder="Type here...">
        </div>
      </div>
      <div class="field" *ngIf="user.type == 'Builder'">
        <div class="input-field">
          <label for="companyBuildNationally" class="field-title">Build Nationally</label>
          <div class="radio-choice">
            <div class="radio-item">
              <input id="companyBuildNationallyYes" type="radio" [(ngModel)]="user.companyBuildNationally" (ngModelChange)="validate()" value="Yes" name="companyBuildNationally">
              <label for="companyBuildNationallyYes" class="radio-label">Yes</label>
            </div>
            <div class="radio-item">
              <input id="companyBuildNationallyNo" type="radio" [(ngModel)]="user.companyBuildNationally" (ngModelChange)="validate()" value="No" name="companyBuildNationally">
              <label for="companyBuildNationallyNo" class="radio-label">No</label>
            </div>
          </div>
          <!-- <input type="text" id="companyBuildNationally" [(ngModel)]="user.companyBuildNationally" (ngModelChange)="validate()" placeholder="Type here..."> -->
        </div>
      </div>
      <div class="field" *ngIf="user.type == 'Builder'">
        <div class="input-field">
          <label for="companydCurrentSupplier" class="field-title">Current supplier and flooring contractors used</label>
          <input type="text" id="companydCurrentSupplier" [(ngModel)]="user.companydCurrentSupplier" (ngModelChange)="validate()" placeholder="Type here...">
        </div>
      </div>



      <!-- installer -->
      <div class="field" *ngIf="user.type == 'Installer'">
        <div class="input-field">
          <label for="companyCovering" class="field-title">Coverings installation</label>
          <select id="companyCovering" [(ngModel)]="user.companyCovering" name="companyCovering">
              <option value="" disabled selected hidden>Please select...</option>
              <option value="Commercial">Commercial</option>
              <option value="Residential">Residential</option>
              <option value="Industrial">Industrial</option>
          </select>
          <!-- <input type="text" id="companyCovering" [(ngModel)]="user.companyCovering" (ngModelChange)="validate()" placeholder="Type here..."> -->
        </div>
      </div>
      <div class="field" *ngIf="user.type == 'Installer'">
        <div class="input-field">
          <label for="companyArea" class="field-title">Areas covered</label>
          <input type="text" id="companyArea" [(ngModel)]="user.companyArea" (ngModelChange)="validate()" placeholder="Type here...">
        </div>
      </div>
      <div class="field" *ngIf="user.type == 'Installer'">
        <div class="input-field">
          <label for="companyTeamSize" class="field-title">Size of team</label>
          <input type="tel" numbersOnly="true" id="companyTeamSize" [(ngModel)]="user.companyTeamSize" (ngModelChange)="validate()" placeholder="Type here...">
        </div>
      </div>
      <div class="field" *ngIf="user.type == 'Installer'">
        <div class="input-field">
          <label for="companyCurrentInstall" class="field-title">Who do you install for currently</label>
          <input type="text" id="companyCurrentInstall" [(ngModel)]="user.companyCurrentInstall" (ngModelChange)="validate()" placeholder="Type here...">
        </div>
      </div>

      <div class="field">
        <div class="input-field">
          <label for="companyExperience" class="field-title">Years of experience </label>
          <input type="tel" numbersOnly="true" id="companyExperience" [(ngModel)]="user.companyExperience" (ngModelChange)="validate()" placeholder="Type here...">
        </div>
      </div>


    </div>
  </div>


<!--

    <div class="input-field" [ngClass]="{error: errors.companyName}">
        <label for="companyName">Company Name</label>
        <input id="companyName" [(ngModel)]="user.companyName" (ngModelChange)="validate()" placeholder="Type here..."
            type="text">
    </div>
    <div class="input-field-row">
        <div class="input-field" [ngClass]="{error: errors.firstName}">
            <label for="firstName">First Name</label>
            <input id="firstName" [(ngModel)]="user.firstName" (ngModelChange)="validate()" placeholder="Type here..."
                type="text">
        </div>
        <div class="input-field" [ngClass]="{error: errors.lastName}">
            <label for="lastName">Last Name</label>
            <input id="lastName" [(ngModel)]="user.lastName" (ngModelChange)="validate()" placeholder="Type here..."
                type="text">
        </div>
    </div>
    <div class="input-field-row">
        <div class="input-field" [ngClass]="{error: errors.companyName}">
            <label for="companyName">Company Name</label>
            <input id="companyName" [(ngModel)]="user.companyName" (ngModelChange)="validate()"
                placeholder="Type here..." type="text">
        </div>
        <div class="input-field" [ngClass]="{error: errors.abn}">
            <label for="abn">Company ABN</label>
            <input id="abn" [(ngModel)]="user.abn" (ngModelChange)="validate()" placeholder="Type here..."
                type="text" ABN="true">
        </div>
    </div>
    <div class="input-field-row">
        <div class="input-field" [ngClass]="{error: errors.companyEmail}">
            <label for="companyEmail">Company Email</label>
            <input id="companyEmail" [(ngModel)]="user.companyEmail" (ngModelChange)="validate()"
                placeholder="Type here..." type="text">
        </div>
    </div>
    <div class="input-field-row">
        <div class="input-field" [ngClass]="{error: errors.businessLicenceNumber}">
            <label for="businessLicenceNumber">Business Licence Number</label>
            <input id="businessLicenceNumber" [(ngModel)]="user.businessLicenceNumber" (ngModelChange)="validate()"
                placeholder="Type here..." type="text">
        </div>
        <div class="input-field" [ngClass]="{error: errors.companyPhone}">
            <label for="companyPhone">Company Phone Number</label>
            <input id="companyPhone" [(ngModel)]="user.companyPhone" (ngModelChange)="validate()"
                placeholder="Type here..." type="tel" numbersOnly="true" maxlength="10">
        </div>
    </div>
    <div class="input-field" [ngClass]="{error: errors.companyAddress}">
        <label for="companyAddress">Company Address</label>
        <input id="companyAddress" [(ngModel)]="user.companyAddress" (ngModelChange)="validate()"
            placeholder="Type here..." type="text">
    </div> -->

</div>
