import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { AuthHelper } from 'src/app/helpers/AuthHelper';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss'],
})
export class SplashComponent implements OnInit {
  constructor(
    public router: Router,
    private authHelper: AuthHelper,
  ) {}

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView('Splash');
    this.checkUser();
  }

  async checkUser() {
    if (await this.authHelper.isLoggedIn()) {
      console.log('Redirecting to industry');
      this.router.navigateByUrl('/industry');
    } else {
      this.router.navigateByUrl('/landing');
    }
  }
}
