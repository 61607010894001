<div class="header">
    <div class="header-title">Filters</div>
    <div class="spacer"></div>
    <div class="secondary-button small-button" *ngIf="filterIsSelected()" (click)="clearAllFilter()"><img class="icon"
        src="../../../assets/images/close_icon.png"> Clear all filters</div>
    <img class="close-icon" src="../../../assets/images/close_icon.png" (click)="close()">
</div>
<div class="content">
    <div class="filters-container hide-scrollbar">
        <div class="filter-option" [ngClass]="{expanded: filters[filter].expanded}"
            *ngFor="let filter of keysForObject(filters)"
            (click)="filters[filter].expanded = !filters[filter].expanded">
            <div class="filter-label">{{filter}}</div>
            <img class="arrow-icon" src="../../../assets/images/down_arrow.png">
            <!-- <div class="options-container" [style]="'height: '+filters[filter].items.length * 30+'px;'">
                <div class="options" *ngFor="let item of filters[filter].items"
                    (click)="filterSelected(filter, item); $event.stopPropagation();">
                    <div class="check-box" [ngClass]="{selected: item.selected}">
                        <img src="../../../assets/images/check_icon.png">
                    </div>
                    <div class="item-name">{{item.name}}</div>
                </div>
            </div> -->

            <div class="options-container" [style]="'height: '+getSubItemsHeight(filters[filter].items)+'px;'">
                <div class="options" *ngFor="let item of filters[filter].items" [style]="'height: '+getFilterHeight(item)+'px;'">

                <!-- <div class="options" *ngFor="let item of filters[filter].items" [ngClass]="{'expanded-sub-option': item.inputField && item.selected}"> -->
                    <div class="flex" (click)="filterSelected(filter, item); $event.stopPropagation();">
                        <div class="check-box" [ngClass]="{selected: item.selected}">
                            <img src="../../../assets/images/check_icon.png">
                        </div>
                        <div class="item-name" [innerHTML]="item.name"></div>
                    </div>
                    <div class="subbrand-container" *ngIf="item.selected">
                        <div class="flex indent" *ngFor="let subBrand of item.subCategories ? item.subCategories : []" (click)="subbrandFilterSelected(filter, item, subBrand); $event.stopPropagation();">
                            <div class="check-box" [ngClass]="{selected: subBrand.selected}">
                                <img src="../../../assets/images/check_icon.png">
                            </div>
                            <div class="item-name small" [innerHTML]="subBrand.name"></div>
                        </div>
                    </div>
                    <div *ngIf="item.inputField && item.selected" class="sub-flex" (click)="$event.stopPropagation();">
                        <input class="quantity-field" type="number" id="filterData" [(ngModel)]="item.inputField.dataEntered" (ngModelChange)="stockRequiredUpdated(filter, item)" placeholder={{item.inputField.placeholder}}>
                        <div class="item-name">m�</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>