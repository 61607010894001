<div class="header">
    <div class="header-title">Search</div>
    <div class="spacer"></div>
    <div class="secondary-button small-button" *ngIf="searchTerm" (click)="clearSearch()"><img class="icon"
            src="../../../assets/images/close_icon.png"> Clear search</div>
    <img class="close-icon" src="../../../assets/images/close_icon.png" (click)="close()">
</div>
<div class="content">
    <div class="search-container">
        <div class="input-field search-field">
            <img class="icon" src="../../../assets/images/search_icon.png">
            <input [(ngModel)]="searchTerm" (keydown.enter)="searchProducts()" placeholder="Search" type="text"
                autocomplete="off">
        </div>
    </div>
</div>