<div class="page-wrapper">
  <header id="masthead">
    <div class="inner boxed center-logo">
      <div class="site-logo">
        <a href="/"
          ><img
            src="/assets/images/logo.png"
            width="165"
            height="50"
            alt="Flrhub Logo"
        /></a>
      </div>
      <!--
          Dont need on terms and privacy aye?
        <nav class="primary">
            <ul class="ul-reset">
                <li><a href="/#features">Features</a></li>
                <li><a href="/#pricing">Pricing</a></li>
            </ul>
        </nav>
        <nav class="secondary">
            <ul class="ul-reset">
                <li><a href="/login/">Log In</a></li>
                <li><a href="/#pricing" class="primary-button">Get Started</a></li>
            </ul>
        </nav>
        -->
    </div>
  </header>
  <!-- #masthead -->

  <div class="page-content">
    <section id="entry-content" class="entry-content boxed">
      <div class="container">
        <div class="heading">
          <h1>Privacy Policy</h1>
          <div class="context hierarchy-list">
            <ol>
              <li>
                Introduction
                <ol>
                  <li>
                    FLRHUB Pty Ltd (“FLRHUB”) has a commitment to privacy in
                    accordance with the Privacy Act 1988 (Cth) (“Privacy Act”).
                    We are bound by the Privacy Act and the Australian Privacy
                    Principles contained in the Privacy Act. We only collect
                    personal information as required or authorised by law. In
                    particular, the Privacy Act authorises us to collect
                    personal information in various ways and in certain
                    circumstances. This Privacy Policy is provided to make you
                    aware of how we collect, use and manage personal
                    information. It also provides you with information about how
                    you can access your personal information.
                  </li>
                  <li>
                    <strong>personal information</strong> means information or
                    an opinion about an identified individual, or an individual
                    who is reasonably identifiable:
                    <div class="alpha-list">
                      <div class="list">
                        whether the information or opinion is true or not, and
                      </div>
                      <div class="list">
                        whether the information or opinion is recorded in a
                        material form or not.
                      </div>
                    </div>
                  </li>
                  <li>
                    In making this Privacy Policy, we do so on the basis that
                    this policy does not replace or limit the requirements of
                    the Privacy Act.
                  </li>
                </ol>
              </li>
              <li>
                Collection of Personal Information &amp; Purpose of Collection
                <ol>
                  <li>
                    FLRHUB collects personal information which is reasonably
                    necessary to enable us to carry out the various functions
                    and activities of our organisation, efficiently conduct
                    normal business/commercial operations, and otherwise
                    professionally and effectively connect/interact with the
                    individuals with whom we have dealings.
                  </li>
                  <li>
                    The type of personal information we collect includes, but
                    may not be limited to:
                    <div class="alpha-list">
                      <div class="list">
                        identification information about an individual, in
                        particular, full name, address (including delivery
                        address), contact details (email and telephone/facsimile
                        numbers), date of birth, signature, occupation and
                        driver licence number;
                      </div>
                      <div class="list">
                        the individual&#8217;s bank and associated contact
                        details;
                      </div>
                      <div class="list">
                        trade referees and associated contact details;
                      </div>
                      <div class="list">
                        information that may assist us to confirm your financial
                        position or credit history when you are considering a
                        finance or credit arrangement, including your credit
                        card details, bank details, purchase history or
                        transaction details and loan and credit information
                        where finance arrangements apply;
                      </div>
                      <div class="list">
                        information that may be provided to us by a credit
                        reporting body and information relating to payment of
                        our accounts;
                      </div>
                      <div class="list">
                        details about your purchases and orders from us and your
                        product and services preferences;
                      </div>
                      <div class="list">
                        if you are a supplier or service provider, in connection
                        with the supply of products and/or services by your
                        business to us; and
                      </div>
                      <div class="list">
                        cookies/digital identifiers providing details/statistics
                        in relation to website and page visits, your domain
                        name, notes made of telephone contact (including
                        recorded/saved or transcribed voicemail/texts) and the
                        content of letters received, copies of letters sent, and
                        emails (both electronically stored and in printed form).
                      </div>
                    </div>
                  </li>

                  <li>
                    We will only use your personal information for the purpose
                    of:
                    <div class="alpha-list">
                      <div class="list">
                        conducting our business, including the sourcing and
                        supply products and/or the provision of services in the
                        ordinary course of our business;
                      </div>
                      <div class="list">
                        facilitating and processing your orders and payments,
                        and delivering products and services;
                      </div>
                      <div class="list">managing product claims;</div>
                      <div class="list">
                        to charge you for products and services we provide to
                        you and to prepare invoices and sales records for such
                        purchases;
                      </div>
                      <div class="list">
                        providing and marketing products and services to you;
                      </div>
                      <div class="list">
                        assessing the credit worthiness of an individual
                        applying for credit (Trade Account) or an individual
                        offering to guarantee a Trade Account sought by a
                        corporate applicant or another person;
                      </div>
                      <div class="list">
                        assessing the capacity of an individual to pay or
                        continue to pay for products and/or services delivered
                        on credit terms;
                      </div>
                      <div class="list">
                        assisting in the management of Trade Accounts, including
                        the collection of overdue amounts;
                      </div>
                      <div class="list">
                        monitoring and keeping under review an
                        individual&#8217;s suitability for the provision of
                        ongoing credit, taking into account payment history and
                        other risk management criteria;
                      </div>
                      <div class="list">
                        providing benefits/rewards to suppliers, customers and
                        others who become members of our business and loyalty
                        programs from time to time (if any);
                      </div>
                      <div class="list">
                        maintaining and expanding our organisation&#8217;s
                        database of customers, suppliers and other
                        business/commercial associates for the purposes of
                        distributing industry information, issuing
                        advertising/marketing materials, conducting product
                        promotions and otherwise enhancing the way we do
                        business;
                      </div>
                      <div class="list">
                        to derive insights about you in order to better
                        understand your preferences and interests, personalise
                        your experience, enhance the products and services you
                        receive, and to tell you about products and services
                        that may be of interest to you. We may also use trusted
                        service providers to undertake the process of creating
                        these consumer insights;
                      </div>
                      <div class="list">
                        to assist us in providing and improving our services to
                        you, and to analyse trends in sales and better
                        understand your needs or to develop, improve and market
                        products and services to you;
                      </div>
                      <div class="list">
                        for purposes directly related to any of the above;
                      </div>
                      <div class="list">
                        where you have consented to us using your personal
                        information for other purposes or its use for another
                        purpose is required or permitted by law (such as to
                        comply with obligations under the Privacy Act in
                        relation to mandatory data breach notification).
                      </div>
                    </div>
                  </li>
                  <li>
                    We may process your personal information for any other
                    specific purpose indicated at the time of collection of
                    personal information.
                  </li>
                  <li>
                    Without collecting personal information we may be unable to
                    provide products and services to you, and may be unable to
                    achieve or fully achieve the collection purposes. If you do
                    not wish us to use your personal information in any of the
                    above ways, please contact us (see details under section
                    entitled “Accessing Your Personal Information”).
                  </li>
                </ol>
              </li>
              <li>
                Method of Collection of Personal Information
                <ol>
                  <li>
                    FLRHUB obtains personal information by lawful and fair means
                    primarily from the individual directly, but also from: trade
                    referees as provided by the individual (including as to
                    default information and payment information); the
                    individual&#8217;s bank, as disclosed by the individual;
                    other credit providers, with the individual’s consent;
                    credit reporting bodies authorised to disclose personal
                    information, with the individual&#8217;s consent (including
                    disclosure of credit information, default information and
                    payment information); and from other trusted unrelated third
                    parties.
                  </li>
                </ol>
              </li>
              <li>
                Accuracy and Completeness of Personal Information
                <ol>
                  <li>
                    While we will endeavour to ensure that the personal
                    information collected from you is up to date and complete,
                    we will assume that any personal information provided by you
                    is free from errors and omissions, is not misleading or
                    deceptive and complies with all relevant laws.
                  </li>
                  <li>
                    We rely on the personal information provided by you. We will
                    not check or verify the accuracy of any personal information
                    we obtain from you or other persons.
                  </li>
                  <li>
                    You should provide us with details of any changes to your
                    personal information as soon as reasonably practicable
                    following such change.
                  </li>
                </ol>
              </li>
              <li>
                Disclosure of Your Personal Information
                <ol>
                  <li>
                    We do not permit the personal information we hold to be
                    disclosed to third parties unless:
                    <div class="alpha-list">
                      <div class="list">
                        we consider it necessary to be disclosed in order that
                        the products and services we provide to you can be
                        properly supplied and carried out;
                      </div>
                      <div class="list">
                        such disclosure is necessary to facilitate payment by
                        you for Goods and/or to facilitate the provision of
                        credit to you as required;
                      </div>
                      <div class="list">
                        you have consented (expressly or impliedly) or requested
                        your personal information to be provided to a third
                        party; or
                      </div>
                      <div class="list">
                        we are required or permitted by law to provide
                        information to a third party.
                      </div>
                    </div>
                  </li>
                  <li>
                    Further, it may be necessary from time to time for FLRHUB to
                    disclose personal information to or exchange such
                    information with various third parties, including: other
                    credit providers; a credit reporting body; guarantors of
                    corporate applicants/other persons seeking a Trade Account;
                    major suppliers and clients (such as building/construction
                    companies and associated suppliers) and suppliers of related
                    products/services with whom we have developed working
                    relationships; and third parties who may assist us with a
                    variety of functions including with research, mail and
                    delivery, security, insurance, professional advisory
                    (including legal, accounting and auditing advice), banking,
                    payment processing, facilitating credit arrangements, credit
                    reporting, fraud checks, data storage, information
                    processing, order tracking, marketing, product reviews,
                    online competitions, responding to customer queries and
                    complaints or technology services.
                  </li>
                  <li>
                    FLRHUB is unlikely to disclose personal information to any
                    overseas recipient. However, should FLRHUB intend to, for
                    any reason, in fact disclose personal information to an
                    overseas recipient, we will:
                    <div class="alpha-list">
                      <div class="list">
                        take prior reasonable steps to ensure the overseas
                        recipient does not breach the Australian privacy laws;
                        or
                      </div>
                      <div class="list">
                        first be reasonably satisfied that the overseas
                        recipient is subject to substantially similar laws with
                        respect to the protection of personal information; or
                      </div>
                      <div class="list">
                        first obtain the individual&#8217;s consent (after
                        informing him or her that such consent will have the
                        effect of relieving us from any responsibility should
                        the overseas recipient breach Australian privacy laws);
                        or
                      </div>
                      <div class="list">
                        in any event, be likewise relieved from any such
                        responsibility should the disclosure to an overseas
                        recipient be required or authorised by Australian law.
                      </div>
                    </div>
                  </li>
                </ol>
              </li>
              <li>
                Disclosure to Credit Reporting Bodies
                <ol>
                  <li>
                    We may disclose certain information about you to credit
                    reporting bodies (<strong>CRBs</strong>), for example if you
                    fail to meet payment obligations. The CRB may include such
                    information in reports provided to credit providers to
                    assist them in assessing your credit worthiness. We may
                    disclose information to the following CRBs:<br />
                    <strong>Equifax</strong><br />
                    Phone: 13 8332<br />
                    Web:
                    <a
                      class="none"
                      href="https://www.equifax.com.au"
                      target="_blank"
                      rel="noopener"
                      >www.equifax.com.au</a
                    ><br /><br />
                    <strong>Illion</strong><br />
                    Phone: 13 23 33<br />
                    Web:
                    <a
                      class="none"
                      href="https://www.illion.com.au"
                      target="_blank"
                      rel="noopener"
                      >www.illion.com.au</a
                    ><br /><br />
                    <strong>Experian</strong><br />
                    Phone: 1300 783 684<br />
                    Web:
                    <a
                      class="none"
                      href="https://www.experian.com.au"
                      target="_blank"
                      rel="noopener"
                      >www.experian.com.au</a
                    ><br /><br />
                  </li>
                  <li>
                    You have the right to request the above CRBs not to:
                    <div class="alpha-list">
                      <div class="list">
                        use credit reporting information for the purposes of pre
                        screening of direct marketing by a credit provider; or
                      </div>
                      <div class="list">
                        use or disclose credit reporting information if you
                        believe on reasonable grounds that you have been, or are
                        likely to be, a victim of fraud.
                      </div>
                    </div>
                  </li>
                  <li>
                    If you would like more information about how the above CRBs
                    manage credit related information you can contact them
                    directly.
                  </li>
                </ol>
              </li>
              <li>
                Protecting Your Personal Information
                <ol>
                  <li>
                    We are committed to ensuring the security of your personal
                    information. We store information in either hard copy or
                    electronic form. We protect your personal information from
                    misuse, loss, unauthorised access, modification and
                    disclosure and will take reasonable steps to ensure that
                    personal information collected, used and disclosed is:
                    <div class="alpha-list">
                      <div class="list">
                        securely stored on our computer network and otherwise at
                        one or more of our various business premises within
                        Australia; and
                      </div>
                      <div class="list">
                        is destroyed or de-identified when no longer needed by
                        our organisation or required to be retained by
                        Australian law.
                      </div>
                    </div>
                  </li>
                  <li>
                    Some of the security measures we have in place to protect
                    personal information include: a secure server for our
                    computer network, including installation and maintenance of
                    firewalls; use of log-in passwords/tools and virus
                    protection programs; and restriction of access to relevant
                    personnel only.
                  </li>
                  <li>
                    No data transmission over the internet can be guaranteed to
                    be secure. As a result, we cannot ensure or warrant the
                    security of any personal information transmitted by you to
                    us over the internet.
                  </li>
                </ol>
              </li>
              <li>
                Cookies
                <ol>
                  <li>
                    A cookie is a data file that a website transfers to your
                    hard drive. This enables the website to track the pages you
                    have visited. A cookie only contains information you supply.
                    It cannot read data on your hard drive. Our website uses
                    cookies. You can set your browser to refuse cookies,
                    however, this may mean you are unable to take full advantage
                    of our website and/or platform. We may also collect data
                    relating to the number and frequency of clicks on links and
                    pages of our website and/or platform.
                  </li>
                </ol>
              </li>
              <li>
                Accessing Your Personal Information
                <ol>
                  <li>
                    If you wish to:
                    <div class="alpha-list">
                      <div class="list">
                        access your personal information held by us, or
                      </div>
                      <div class="list">
                        seek correction of such information, or complain about
                        possible breaches of this policy, the Privacy Act or the
                        Australian Privacy Principles, you should direct your
                        enquiry to our Privacy Officer as follows:<br />
                        Address: PO BOX 555, The Gap, Brisbane 4061 QLD<br />
                        E mail:
                        <a href="mailto:privacy@flrhub.com.au"
                          >privacy@flrhub.com.au</a
                        >
                      </div>
                    </div>
                  </li>
                  <li>
                    Our Privacy Officer will endeavour to deal with your inquiry
                    or complaint as soon as is reasonably practicable. You can
                    also contact the Office of the Australian Information
                    Commissioner by visiting
                    <a
                      href="https://www.oaic.gov.au/privacy/privacy-complaints"
                      target="_blank"
                      >www.oaic.gov.au/privacy/privacy-complaints/</a
                    >.
                  </li>
                </ol>
              </li>
              <li>
                Variation of Privacy Policy
                <ol>
                  <li>
                    We may vary the terms of this Privacy Policy from time to
                    time, and may let you know of significant changes to this
                    policy by posting a notification on our website and/or
                    platform.
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    <!-- .entry content -->

    <section id="footer" class="section-footer color-white reveal-blocks">
      <div class="container">
        <h2 class="h1">Any questions?</h2>
        <h3>
          Please email us at
          <a href="mailto:team@flrhub.com.au" target="_blank"
            >team@flrhub.com.au</a
          >
        </h3>
      </div>
      <div class="copyright-lines">
        <div class="inner">
          <div class="copy">
            &copy;2001-<span id="current-year">2022</span> All Rights Reserved.
            FLRHUB®
          </div>
          <nav class="links">
            <ul class="ul-reset">
              <li><a href="/privacy">Privacy</a></li>
              <li><a href="/terms">Terms</a></li>
            </ul>
          </nav>
          <div class="credit">
            Built by <a href="https://kwpx.au" target="_blank"><em>kwpx</em></a>
          </div>
        </div>
      </div>
    </section>
  </div>
  <!-- .page-content -->
</div>
<!-- .page-wrapper -->
