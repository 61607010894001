<div class="alert-container">
    <div class="title">{{data.title}}</div>
    <div class="message" [innerHTML]="data.message"></div>
    <div class="button-group">
        <div class="secondary-button button" *ngIf="data.secondaryButton" (click)="buttonPressed(data.secondaryButton)">
            {{data.secondaryButton}}
        </div>
        <div class="primary-button button" (click)="buttonPressed(data.primaryButton)">
            {{data.primaryButton}}
        </div>
    </div>
</div>