import { Product } from "./Product";

import { EstimateJobItem } from "./EstimateJobItem";

export class EstimationCart {

    jobName: string;
    jobItems: EstimateJobItem[];
    jobDocument: string;
    jobDocuments: { name: string, url: string }[];
    jobType: string;
    jobPrice: number;

    estimationNumber: string;
    userId: string;
    estimationStatus: string;
    estimationDate: string;
    cardPaymentData: any;

    code: string;
    timestamp: string;

    costingSheetUrl: string;
    projectFiles: { name: string, url: string }[];

    constructor() {
        this.estimationNumber = "";
        this.userId = "";
        this.estimationDate = "";
        this.estimationStatus = "";
        this.cardPaymentData = {};
        this.jobName = "";
        this.jobItems = [];
        this.jobDocument = "";
        this.jobDocuments = [];
        this.jobType = "";
        this.jobPrice = 0;
        this.estimationStatus = 'PENDING';
        this.estimationDate = '0';
        this.code = "";
        this.timestamp = "";
        this.costingSheetUrl = "";
        this.projectFiles = [];
    }

}
