import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Order } from 'src/app/classes/Order';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  orders: Order[] = [];
  loading = true;
  perPage = 10;
  allLoaded = false;
  page = 0;

  constructor(private router: Router) { }

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView("Home");
  }

  goTo(url: string) {
    this.router.navigateByUrl(url);
  } 

}
