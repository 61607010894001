<div class="header">
    <div class="header-title">My Account</div>
    <div class="spacer"></div>
    <img class="close-icon" src="../../../assets/images/close_icon.png" (click)="close()">
    <div class="tabs">
        <div class="items" [ngClass]="{active: active === 'Account details'}" (click)="active = 'Account details'">
            Account details</div>
        <div class="items" [ngClass]="{active: active === 'Billing details'}" (click)="active = 'Billing details'">
            Billing details</div>
    </div>
</div>

<div class="container">
    <div *ngIf="active === 'Billing details'">
        <div class="section">
            <div class="section-title">Payment Method</div>
            <div class="payment-method-name">{{user.card.name}}</div>
            <div class="payment-method-number">**** **** **** {{user.card.last4}}</div>
            <div *ngIf="changingCard">
                <div class="card-payment-heading">
                    <div class="card-title">Change Credit Card</div>
                    <div class="payment-icons">
                        <img src="../../../assets/images/visa_icon.png">
                        <img src="../../../assets/images/amex_icon.png">
                        <img src="../../../assets/images/mastercard_icon.png">
                    </div>
                </div>
                <div class="input-field" [ngClass]="{error: errors.name}">
                    <label for="name">Name On Card</label>
                    <input id="name" [(ngModel)]="name" (ngModelChange)="validate()" placeholder="Type here..."
                        type="text" autocomplete="name">
                </div>
                <div class="input-field" [ngClass]="{error: errors.cardNumber}">
                    <label for="cardNumber">Card Number</label>
                    <input id="cardNumber" [(ngModel)]="cardNumber" type="text" (ngModelChange)="validate()"
                        placeholder="•••• •••• •••• ••••" maxlength="19" cc-number>
                </div>
                <div class="input-field-row">
                    <div class="input-field" [ngClass]="{error: errors.expiry}">
                        <label for="expiry">Expiration (MM/YY)</label>
                        <input id="expiry" [(ngModel)]="expiry" (ngModelChange)="validate()" placeholder="MM/YY"
                            type="text" maxlength="5" cc-expiry>
                    </div>
                    <div class="input-field" [ngClass]="{error: errors.cvc}">
                        <label for="cvc">CVC</label>
                        <input id="cvc" [(ngModel)]="cvc" (ngModelChange)="validate()" placeholder="Type here..."
                            type="text" numbersOnly="true">
                    </div>
                </div>
                <div *ngIf="errorMessage" class="error" [innerHTML]="errorMessage"></div>
            </div>
            <div class="secondary-button section-button" [ngClass]="{loading: loading}" (click)="changeCard()">
                Change card
            </div>
        </div>
    </div>
    <div *ngIf="active === 'Account details'" class="section-container">
        <div class="section">
            <div class="section-title">Passwords</div>
            <div *ngIf="changingPassword" style="margin-top: 20px;" class="input-field password-field">
                <label for="currentPassword">Current Password</label>
                <input class="fake-email-field" placeholder="Type here..." type="email" autocomplete="email">
                <input id="currentPassword" [(ngModel)]="currentPassword" (ngModelChange)="validate()"
                    placeholder="Type here..." type="password" autocomplete="current-password">
            </div>
            <div *ngIf="changingPassword" class="input-field password-field">
                <label for="newPassword">New Password</label>
                <input id="newPassword" [(ngModel)]="newPassword" (ngModelChange)="validate()"
                    placeholder="Type here..." type="password" autocomplete="new-password">
            </div>
            <div *ngIf="changingPassword" class="input-field password-field">
                <label for="newPasswordConfirm">Confirm Password</label>
                <input id="newPasswordConfirm" [(ngModel)]="newPasswordConfirm" (ngModelChange)="validate()"
                    placeholder="Type here..." type="password" autocomplete="new-password">
            </div>
            <div *ngIf="errorMessage.length > 0" style="margin-bottom: 35px;">
                <div class="error" *ngFor="let error of errorMessage"><img class="error-icon"
                        src="../../../assets/images/cross_icon.png">{{error}}</div>
            </div>
            <p *ngIf="passwordsUpdated">Password changed successfuly.</p>
            <div class="secondary-button section-button" [ngClass]="{loading: loading}" (click)="changePassword()">
                Change password
            </div>
        </div>
        <div class="spacer"></div>
        <div class="cancel-subscription" (click)="cancelSubscription()">Cancel subscription <img class="right-arrow"
                src="../../../assets/images/down_arrow.png"></div>
        <div class="widget-footer">
            <ul>
                <li><a target=”_blank” href="/terms">Terms & Conditions</a></li>
                <li><a target=”_blank” href="/privacy">Privacy Policy</a></li>
                <li><a target=”_blank” href="/returns">Returns Policy</a></li>
            </ul>
        </div>
    </div>
</div>