import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';

@Component({
  selector: 'app-edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {

  imageSizeError = false;
  imageUploading = false;
  loading = false;
  errors: any = {};

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView("Edit Profile Modal");
  }

  openFileUpload(el: any){
    el.target.closest('.upload-image').querySelector('#uploadImage').click()
  }

  uploadImage(event: any) {

    // if undefined, bail
    if( event.target.files[0] === undefined ){
      return
    }

    this.imageUploading = true;
    this.imageSizeError = false;

    var reader = new FileReader();

    //Read the contents of Image File.
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (e) => {

      //Initiate the JavaScript Image object.
      var image = new Image();

      //Set the Base64 string return from FileReader as source.
      image.src = e.target!.result!.toString();

      //Validate the File Height and Width.
      image.onload = () => {
        var height = image.height;
        var width = image.width;
        if (height < 300 || width < 300) {
          this.imageSizeError = true;
          this.imageUploading = false;
          return false;
        }
        const name = event.target.files[0].name;
        const lastDot = name.lastIndexOf('.');
        const ext = name.substring(lastDot + 1);

        const fileName = this.user.firstName + new Date().getTime() + "." + ext;

        DatabaseHelper.instance.uploadFile("CompanyLogos/" + fileName, event.target.files[0]).then(val => {
          this.user.companyLogoUrl = val;
          this.imageUploading = false;
        });
        return true;
      };
    };
  }

  validate() {

  }

  save() {
    this.loading = true;
    this.user.save().then(val => {
      this.loading = false;
      this.cancel();
    });
  }

  cancel() {
    this.dialog.getDialogById('edit-panel-modal')?.addPanelClass("animate-out");
    setTimeout(() => {
      this.dialog.getDialogById('edit-panel-modal')?.close();
    }, 500);
  }

  get user() {
    return AuthHelper.instance.user;
  }

}
