import { Component, OnInit } from '@angular/core';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';

@Component({
  selector: 'app-account-disabled',
  templateUrl: './account-disabled.component.html',
  styleUrls: ['./account-disabled.component.scss']
})
export class AccountDisabledComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView("Account Disabled");
  }

}
