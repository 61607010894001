<div class="page-container page-job-board">
  <app-menu active="My Hub"></app-menu>
  <div class="content-container">
    <app-header title="My Hub"></app-header>
    <app-my-hub-tabs active="My Job Bids"></app-my-hub-tabs>
    <div id="scrollContent" class="inner-content">
      <mat-spinner class="loading" *ngIf="loading" diameter="35" strokeWidth="1"></mat-spinner>
      <div *ngIf="!loading && jobQuotes.length === 0" class="empty-placeholder">
        <div class="message">You haven't quoted on any jobs yet</div>
        <div class="secondary-button" (click)="goTo('/job-board')">Browse Jobs</div>
      </div>
      <div *ngIf="!loading && jobQuotes.length > 0" class="job-board-status-head">
        <div class="statuses">
          <div class="selected-mobile" (click)="toggleSelectMobile()">
            <ul>
              <li class="active" *ngIf="activeTab === 'Pending Quotes'">
                <span class="text">Pending Quotes</span>
              </li>
              <li class="active" *ngIf="activeTab === 'Submitted Job Quotes'">
                <span class="text">Submitted Job Quotes</span>
              </li>
              <li class="active" *ngIf="activeTab === 'Quotes Won'">
                <span class="text">Quotes Won</span>
              </li>
            </ul>
            <img src="../../../assets/images/down_arrow.png" width="12" alt="arrow" />
          </div><!-- selected-mobile -->
          <ul class="main-tab-menu">
            <li [ngClass]="{active: activeTab === 'Pending Quotes'}"
              (click)="activeTab = 'Pending Quotes'; removeSelectMobile()">
              <span class="text">Pending Quotes</span>
            </li>
            <li [ngClass]="{active: activeTab === 'Submitted Job Quotes'}"
              (click)="activeTab = 'Submitted Job Quotes'; removeSelectMobile()">
              <span class="text">Submitted Job Quotes</span>
            </li>
            <li [ngClass]="{active: activeTab === 'Quotes Won'}"
              (click)="activeTab = 'Quotes Won'; removeSelectMobile()">
              <span class="text">Quotes Won</span>
            </li>
          </ul>
        </div>
      </div><!-- job-board-status-head -->
      <div class="job-board-tabs">
        <div class="job-board-tab" *ngIf="activeTab === 'Pending Quotes'">
          <div class="job-card" *ngFor="let jobQuote of pendingQuotes; let i = index" (click)="openJobQuote(jobQuote)">
            <div class="inner-card">
              <div class="card-head"><span class="color-orange heading">{{jobQuote.job.jobName}}{{isPartialQuote(jobQuote) ? ' (Partial Quote)' : ''}}</span><br><span
                  class="company-name">{{jobQuote.job.company}}</span><span class="posted small">Posted
                  {{utilityHelper.formatDate(jobQuote.job.submittedDate)}}</span></div>

              <div class="card-description">
                {{jobQuote.job.jobDescription}}
              </div><!-- card boxes -->

              <div class="card-footer">
                <div class="category" *ngFor="let item of jobQuote.job.materialType">{{item}}</div>
                <div class="location"><img src="../../../assets/images/location.png" width="12" height="17"
                    alt="Location" /> {{jobQuote.job.suburb}}, {{jobQuote.job.state}}</div>
              </div><!-- card-footer -->

              <div *ngIf="!jobQuote.won"  class="card-action" (click)="$event.stopPropagation()">
                <span class="dots"><span></span></span>
                <ul class="submenu">
                  <!-- <li>Edit</li> -->
                  <li (click)="removeJobPost(jobQuote); $event.stopPropagation();">Delete</li>
                </ul>
              </div>

              <div class="bottom-right">
                <button
                  class="primary-button regular-font"
                  (click)="openJobQuote(jobQuote)"
                >
                Quote Job
                </button>
              </div>

            </div>
          </div><!-- job-card -->
        </div><!-- job-board-tab -->

        <div class="job-board-tab" *ngIf="activeTab === 'Submitted Job Quotes'">
          <div class="job-card" *ngFor="let jobQuote of jobQuotes; let i = index" (click)="openJobQuote(jobQuote)">
            <div class="inner-card">
              <div class="card-head"><span class="color-orange heading">{{jobQuote.job.jobName}}{{isPartialQuote(jobQuote) ? ' (Partial Quote)' : ''}}</span><br><span
                  class="company-name">{{jobQuote.job.company}}</span><span class="posted small">Posted
                  {{utilityHelper.formatDate(jobQuote.job.submittedDate)}}</span></div>

              <div class="card-description">
                {{jobQuote.job.jobDescription}}
              </div><!-- card boxes -->

              <div class="card-footer">
                <div class="category" *ngFor="let item of jobQuote.job.materialType">{{item}}</div>
                <div class="location"><img src="../../../assets/images/location.png" width="12" height="17"
                    alt="Location" /> {{jobQuote.job.suburb}}, {{jobQuote.job.state}}</div>
              </div><!-- card-footer -->

              <div *ngIf="!jobQuote.won"  class="card-action" (click)="$event.stopPropagation()">
                <span class="dots"><span></span></span>
                <ul class="submenu">
                  <!-- <li>Edit</li> -->
                  <li (click)="removeJobPost(jobQuote); $event.stopPropagation();">Delete</li>
                </ul>
              </div>

            </div>
          </div><!-- job-card -->
        </div><!-- job-board-tab -->

        <div class="job-board-tab" *ngIf="activeTab === 'Quotes Won'">
          <div *ngIf="quotesWon.length === 0" class="empty-placeholder">
            <div class="message">You haven't won any bids yet.</div>
          </div>
          <div class="job-card" *ngFor="let jobQuote of quotesWon; let i = index" (click)="openJobQuote(jobQuote)">
            <div class="inner-card">
              <div class="card-head"><span class="color-orange heading">{{jobQuote.job.jobName}}{{isPartialQuote(jobQuote) ? ' (Partial Quote)' : ''}}</span><br><span
                  class="company-name">{{jobQuote.job.company}}</span><span class="posted small">Posted
                  {{utilityHelper.formatDate(jobQuote.job.submittedDate)}}</span></div>

              <div class="card-description">
                {{jobQuote.job.jobDescription}}
              </div><!-- card boxes -->

              <div class="card-footer">
                <div class="category" *ngFor="let item of jobQuote.job.materialType">{{item}}</div>
                <div class="location"><img src="../../../assets/images/location.png" width="12" height="17"
                    alt="Location" /> {{jobQuote.job.suburb}}, {{jobQuote.job.state}}</div>
              </div><!-- card-footer -->

              <div class="card-action" (click)="$event.stopPropagation()">
                <span class="dots"><span></span></span>
                <ul class="submenu">
                  <!-- <li>Edit</li> -->
                  <li (click)="removeJobPost(jobQuote); $event.stopPropagation();">Delete</li>
                </ul>
              </div>

            </div>
          </div><!-- job-card -->
        </div><!-- job-board-tab -->

      </div><!-- job-board-tabs -->
    </div>
  </div>
</div>