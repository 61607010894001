import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/classes/User';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { UtilityHelper } from 'src/app/helpers/UtilityHelper';
import { HubspotService } from "../../helpers/HubspotService";
import { CookieService } from "ngx-cookie-service"
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {

  showPassword: boolean = false;
  errorMessage: string[] = [];
  startValidation: boolean = false;
  loading: boolean = false;

  constructor(
      private authHelper: AuthHelper,
      public router: Router,
      private hubspot: HubspotService,
      private cookieService: CookieService
  ) { }

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView("Sign Up");
    this.checkUserLoggedIn()
    this.getDefaultUserType()
  }

  getDefaultUserType(){
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const type = urlParams. get('type')
    if( type == 'Builder' || type == 'Installer' ){
      this.user.type = type
    }
  }

  async checkUserLoggedIn() {
    if (await this.authHelper.isLoggedIn()) {
      this.router.navigateByUrl('/');
    }
  }

  validate(): boolean {
    this.errorMessage = [];
    if (!this.startValidation) return false;
    if (!this.user.type) {
      this.errorMessage.push("Select Builder or Installer");
    }
    if (!UtilityHelper.isEmailValid(this.user.email)) {
      this.errorMessage.push("Valid email address");
    }
    if (!/[a-z]/.test(this.password)) {
      this.errorMessage.push("One lowercase character");
    }
    if (!/[A-Z]/.test(this.password)) {
      this.errorMessage.push("One uppercase character");
    }
    if (!/\d/.test(this.password)) {
      this.errorMessage.push("One number");
    }
    if (!/\W/.test(this.password)) {
      this.errorMessage.push("One special character");
    }
    if (this.password.length < 8) {
      this.errorMessage.push("8 characters minimum");
    }
    return this.errorMessage.length === 0;
  }

  async signup() {
    this.startValidation = true;
    if (this.validate()) {
      this.loading = true;
      let hubspotCookie = this.cookieService.get('hubspotutk');
      this.user.currentHutk = hubspotCookie;

      AuthHelper.instance.registerUser(this.user.email, this.password, this.user).then(userResponse => {
        console.log('User Response:', userResponse);
        //Maps Flrhub user types to HubSpot form input values
        /*let userTypeMap:{
          [key: string]: any
        } = {
          "Installer": "Installation Contractors",
          "Builder": "Builders & Building Contractors"
        }

        this.hubspot.submitHubspotForm(
            '7b06d4cc-fa23-41b6-a515-a70e61d6bbd1',
            {
              "hutk": hubspotCookie, // include this parameter and set it to the hubspotutk cookie value to enable cookie tracking on your submission
              "pageUri": "/sign-up/",
              "pageName": "Sign Up Page"
            },
            [
              {
                "objectTypeId": "0-1",
                "name": "member_type",
                "value": userTypeMap[this.user.type]
              },
              {
                "objectTypeId": "0-1",
                "name": "email",
                "value": this.user.email
              }
        ]).subscribe({
            next: (response) => {
                console.log('Sent HS Form Submission with hutk' + hubspotCookie)
              },
            error: (e) => {
              console.error(e)
              throw(e);
            },
            complete: () => {}
          })*/
        this.router.navigateByUrl('sign-up-details');
      }).catch((error) => {
        let errorMsg=error instanceof HttpErrorResponse? error.error.error:error.message;
        this.loading = false;
        this.errorMessage.push(errorMsg);//Something went wrong while creating your account, please try again shortly.')
      });
    }
  }

  goTo(url: string) {
    this.router.navigateByUrl(url);
  }

  get user() {
    return AuthHelper.instance.user;
  }

  get password() {
    return AuthHelper.instance.password;
  }

  set password(password: string) {
    AuthHelper.instance.password = password;
  }

}
