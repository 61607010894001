import { Product } from "./Product";

export class EstimateJobItem {

    product: Product;
    code: string;
    questions: any[];
    estimates: { description: string, quantity: string, unit: string }[];

    constructor() {
        this.product = new Product();
        this.code = '';
        this.questions = [];
        this.estimates = [];
    }

}
