import { Component, OnInit } from '@angular/core';

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

@Component({
  selector: 'app-terms',
  templateUrl: './estimation-terms.component.html',
  styleUrls: ['./estimation-terms.component.scss']
})
export class EstimationTermsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {

    AnalyticsHelper.instance.pageView("Estimation Terms");

    // add indicator for page that require to listen to viewport
    document.querySelector('html')?.classList.add('has-scroll-trigger')

    // Listen to page wrapper rather than window viewport because the body overflow is hidden
    ScrollTrigger.defaults({
      //scroller: ".page-wrapper",
      invalidateOnRefresh: true,
    })

    // load our function
    this.initSectionRevealSection()
    this.footerCopyrightYear()

    // ScrollTrigger.normalizeScroll(true)
    ScrollTrigger.refresh()


  }

  // dynamic copyright year
  footerCopyrightYear(){
    if( document.querySelector('#current-year') == null ){
      return
    }
    const currentYear = new Date().getFullYear() as any
    const currentSelector = document.querySelector('#current-year') as HTMLElement
    currentSelector.innerHTML = currentYear
  }

  // gsap reveal section
  initSectionRevealSection(){
    if( document.querySelector('.reveal-blocks') == null ){
      return
    }
    document.querySelectorAll('.reveal-blocks').forEach( etarget =>{
      const theTarget = etarget as HTMLElement
        ScrollTrigger.create({
            trigger: theTarget,
            pin: true,
            start: () => 'bottom bottom',
            end: () => {
                return '+=' + theTarget.offsetHeight
            },
            pinSpacing: false,
            anticipatePin: 1,
        })
    })
  }

}
