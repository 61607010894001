import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
    selector: '[cc-expiry]'
})
export class CreditCardExpiryDirective {

    // Allow numbers and slashes.
    private regex: RegExp = new RegExp(/^[0-9\s\/]*$/g);

    // Allow key codes for special events. Reflect :
    // Backspace, tab, end, home
    private specialKeys: Array<string> = ['Backspace', 'Tab', '/'];

    @HostListener('keydown', ['$event'])
    @HostListener('input', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        const input = event.target as HTMLInputElement;

        let trimmed = input.value.replace(/\s+/g, '').replace(/\//g, '');
        if (trimmed.length > 4) {
            trimmed = trimmed.substring(0, 4);
        }

        let month = '';
        let year = '';

        for (let i = 0; i < trimmed.length; i++) {
            let number = trimmed.substring(i, i + 1);
            if (month.length < 2) {
                if (number > '1' && month.length == 0) {
                    month += "0" + number;
                } else {
                    if (month == '1' && number > '2') {
                        month = '01';
                        year = number;
                    } else {
                        month += number;
                    }
                }
            } else {
                year += number;
            }
        }

        let value = month;
        if (month.length == 2 && event.key !== 'Backspace') {
            value += "/";
        }
        if (month !== '0' && month.length == 1 && event.key == '/') {
            value = '0' + month + '/';
        }
        value += year;

        if (!isNaN(Number(value.replace(/\s+/g, '').replace(/\//g, '')))) {
            input.value = value;
        }

        // Allow Backspace, tab, end, and home keys
        if (this.specialKeys.indexOf(event.key) !== -1) {
            let current: string = input.value;
            setTimeout(() => {
                input.value = input.value.replace('v', '');
            }, 200);
            return;
        }

        // Do not use event.keycode this is deprecated.
        // See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
        let current: string = input.value;
        // We need this because the current value on the DOM element
        // is not yet updated with the value from this event
        let next: string = current.concat(event.key);
        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
        }

    }
}