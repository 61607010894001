import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { MatDialog } from '@angular/material/dialog';
import { CreateJobTypeComponent } from 'src/app/modals/create-job-type/create-job-type.component';
import { UtilityHelper } from 'src/app/helpers/UtilityHelper';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';

@Component({
  selector: 'app-job-board-builder',
  templateUrl: './job-board-builder.component.html',
  styleUrls: ['./job-board-builder.component.scss']
})
export class JobBoardBuilderComponent implements OnInit {

  activeTab = "Active Job Post";

  constructor(public router: Router, private authHelper: AuthHelper, public dialog: MatDialog) { }

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView("Job Board Builder");
    this.redirectJobBoardPage();
  }

  async redirectJobBoardPage() {
      if( await this.authHelper.getUserStatus() ){
        // redirect to job board page by user type
        const userType = this.user.type
        if( userType == 'Installer' ){
          this.router.navigateByUrl('job-board-installer');
        }
      }
  }

  toggleSelectMobile(){
    document.querySelector('.main-tab-menu')?.classList.toggle('mobile-active')
  }
  removeSelectMobile(){
    document.querySelector('.main-tab-menu')?.classList.remove('mobile-active')
  }
  openCreateJobDialog(){
    this.dialog.open(CreateJobTypeComponent, {
      disableClose: true,
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      maxHeight: '-webkit-fill-available',
      position: { bottom: '0', left: this.isPhone ? '0' : '20vw' },
      enterAnimationDuration: '0ms',
      panelClass: ['profile-modal', 'no-padding'],
      id: 'create-job'
    });
  }

  get user() {
    return AuthHelper.instance.user;
  }
  get isPhone() {
    return UtilityHelper.isPhone();
  }

  get loading() {
    return !AuthHelper.instance.userLoaded;
  }

}
