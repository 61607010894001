import { Component, OnInit } from '@angular/core';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';

@Component({
  selector: 'app-order-submitted',
  templateUrl: './order-submitted.component.html',
  styleUrls: ['./order-submitted.component.scss']
})
export class OrderSubmittedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView("Order Submitted");
  }

}
