// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    apiKey: 'AIzaSyB_RzIinvUtx6n2JmrGuBEsUWNMK7D7t8o',
    authDomain: 'flrhub-staging.firebaseapp.com',
    projectId: 'flrhub-staging',
    storageBucket: 'flrhub-staging.appspot.com',
    messagingSenderId: '99640333974',
    appId: '1:99640333974:web:3d6039293efa61ef3fd4b7',
    measurementId: 'G-7KPGXEG266',
  },
  production: false,
  baseAPIUrl: process.env['NG_APP_API_URL'] || 'http://flrhub.test/api',
  simplifyPublicKey:
    process.env['NG_APP_SIMPLIFY_PUBLIC_KEY'] ||
    'sbpb_ZWI0M2UwNWMtMmE1My00MWVlLTgxN2QtMDRlODFjNjZhN2M3',
  gtmId: process.env['NG_APP_GTM_ID'] || 'GTM-P7ZF4NK',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
