<div class="page-container">
    <app-menu active="Marketplace" *ngIf="!isClientView"></app-menu>
    <div class="content-container">
        <app-header title="Marketplace"></app-header>
        <div class="inner-container">
            <div class="search-container">
                <img class="search-icon show-phone" (click)="searchModal()" src="../../../assets/images/search_icon.png">
                <div class="input-field search-field hide-phone">
                    <img class="icon" src="../../../assets/images/search_icon.png">
                    <input [(ngModel)]="searchTerm" (keyup.enter)="searchProducts()"
                        placeholder="Search products by name, type or brand..." type="text" autocomplete="off">
                </div>
                <div class="secondary-button" [ngClass]="{'small-button': isPhone}" gaEvent="browse_all_industry" gaCategory="click_event" (click)="browseAll()">Browse all</div>
            </div>
            <div class="industry-container">
                <div class="industry" gaEvent="commercial_industry" gaCategory="click_event" (click)="selectIndustry('Commercial')">
                    <img class="industry-image" src="../../../assets/images/marketplace-commercial-portrait.jpg" width="766" height="1020">
                    <div class="button">
                        <div>Commercial</div>
                        <img src="../../../assets/images/right_button_arrow.png">
                    </div>
                </div>
                <div class="industry" gaEvent="residential_industry" gaCategory="click_event" (click)="selectIndustry('Residential')">
                    <img class="industry-image" src="../../../assets/images/marketplace-residential-portrait.jpg" width="766" height="1020">
                    <div class="button">
                        <div>Residential</div>
                        <img src="../../../assets/images/right_button_arrow.png">
                    </div>
                </div>
                <div class="industry" gaEvent="industrial_industry" gaCategory="click_event" (click)="selectIndustry('Industrial')">
                    <img class="industry-image" src="../../../assets/images/marketplace-industrial-portrait.jpg" width="766" height="1020">
                    <div class="button">
                        <div>Industrial</div>
                        <img src="../../../assets/images/right_button_arrow.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
