import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/http.service';
//import { products } from './products';

@Injectable({
  providedIn: 'root',
})
export class ProductService {
  constructor(private httpService: HttpService) {}

  getProducts(searchTerm?: string, page?: number, sort?: any, filters?: any) {
    const api = page ? `/products?page=${page}` : '/products';
    //if (!searchTerm) {
    //  return this.httpService.get(api, { response: 'json' });
    //}
    return this.httpService.get(api, {
      response: 'json',
      params: {
        search: searchTerm ?? '',
        sort: JSON.stringify(sort) ?? '',
        filters: JSON.stringify(filters) ?? [],
      },
    });
  }

  getProduct(productCode: string) {
    return this.httpService.get(`/product/${productCode}`, {
      response: 'json',
    });
  }

  getFilters() {
    return this.httpService.post('/getFilters', { response: 'json' });
  }
}
