<div class="page-container">
    <div class="content-container">
        <app-header [title]="'Choose a product'" class="titleOnly"></app-header>
        <div class="inner-container">
            <div class="filters-container hide-scrollbar hide-phone" [ngClass]="{hideFilters: hideFilters}">
                <div class="filter-option" [ngClass]="{expanded: filters[filter].expanded}"
                    *ngFor="let filter of keysForObject(filters)"
                    (click)="filters[filter].expanded = !filters[filter].expanded">
                    <div class="filter-label">{{filter}}</div>
                    <img class="arrow-icon" src="../../../assets/images/down_arrow.png">
                    <!-- <div class="options-container" [style]="'height: fit-content'"> -->
                    <div class="options-container" [style]="'height: '+getSubItems(filters[filter].items) * 40+'px;'">
                        <div class="options" *ngFor="let item of filters[filter].items"
                        [ngClass]="{'expanded-sub-option': item.inputField && item.selected}">
                            <div class="flex" (click)="filterSelected(filter, item); $event.stopPropagation();">
                                <div class="check-box" [ngClass]="{selected: item.selected}">
                                    <img src="../../../assets/images/check_icon.png">
                                </div>
                                <div class="item-name" [innerHTML]="item.name"></div>
                            </div>
                            <div *ngIf="item.inputField && item.selected" class="sub-flex" (click)="$event.stopPropagation();">
                                <input class="quantity-field" type="number" id="filterData" [(ngModel)]="item.inputField.dataEntered" (ngModelChange)="stockRequiredUpdated(filter, item)" placeholder={{item.inputField.placeholder}}>
                                <div class="item-name">m�</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="secondary-button small-button clear-filters-button" *ngIf="filterIsSelected()"
                    (click)="clearAllFilter()"><img class="icon" src="../../../assets/images/close_icon.png">Clear all
                    filters</div>
            </div>
            <div id="scrollContent" class="content">
                <div class="search-container">
                    <img class="search-icon show-phone" (click)="searchModal()" src="../../../assets/images/search_icon.png">
                    <div class="secondary-button small-button show-phone filter-button" (click)="filtersModal()"><img class="icon"
                        src="../../../assets/images/filter_icon.png"> Filters</div>
                    <div class="filter-toggle hide-phone" [ngClass]="{filtersShowing: !hideFilters}"
                        (click)="hideFilters = !hideFilters"><img
                            src="../../../assets/images/filter_icon.png">{{hideFilters ? 'Show' : 'Hide'}} Filters</div>
                    <!-- <div class="sort-selector">Sort<img src="../../../assets/images/down_arrow.png"></div> -->
                    <div class="input-field search-field hide-phone">
                        <img class="icon" src="../../../assets/images/search_icon.png">
                        <input [(ngModel)]="searchTerm" (ngModelChange)="searchProducts()"
                            placeholder="Search products by name, type or brand..." type="text">
                    </div>
                </div>
                <div class="products-container">
                    <div class="product-item" (click)="viewProduct(collection)" *ngFor="let collection of localStockFilterApplied ? getProductsFilteredWithStockAndSearchTerm() : productCollections">
                        <!-- <div class="favourite-icon"><img src="../../../assets/images/favourite_icon.png"></div> -->
                        <img class="product-image" [src]="collection.coverImageUrl">
                        <div class="product-options">
                            <img class="product-option" *ngFor="let product of getFilteredProducts(collection).slice(0, (isPhone ? 4 : 8))"
                                [src]="product.thumbnailImageUrl">
                            <div class="extra-options" *ngIf="getFilteredProducts(collection).length > (isPhone ? 4 : 8)">
                                +{{getFilteredProducts(collection).length - (isPhone ? 4 : 8)}}</div>
                        </div>
                        <div class="product-name">{{collection.collectionName}}</div>
                        <p class="category" [innerHTML]="collection.brand + (collection.subBrand ? ' - '+collection.subBrand : '') +' | '+ (collection.productCategory) | safeHtml"></p>
                        <div class="product-price" *ngIf="collection.retailPrice !== 0">{{getPrice(collection)}}/m<sup>2</sup></div>
                    </div>
                </div>
                <div *ngIf="!loading && (localStockFilterApplied ? getProductsFilteredWithStockAndSearchTerm() : productCollections).length === 0" class="loading">No products found</div>
                <mat-spinner class="loading" *ngIf="loading" diameter="35" strokeWidth="3"></mat-spinner>
            </div>
        </div>
    </div>
</div>