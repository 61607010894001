<div class="post-job-container post-a-job">
  <div class="sidebar">
    <div class="logo">
      <img
        src="../../../assets/images/logo.png"
        width="135"
        height="41"
        class="logo"
      />
    </div>
    <div class="menus">
      <h2>Create Job</h2>
      <div
        class="menu-item"
        [ngClass]="{ active: activeTab === 1 }"
        (click)="activeTab = 1"
      >
        <div class="number">1</div>
        <div class="step-context">
          <div class="step">Job Details</div>
          <div class="checkbox" [ngClass]="{ completed: activeTab > 1 }">
            <span></span>
          </div>
        </div>
      </div>
      <div
        class="menu-item"
        [ngClass]="{ active: activeTab === 2 }"
        (click)="activeTab = 2"
      >
        <div class="number">2</div>
        <div class="step-context">
          <div class="step">Post Job</div>
          <div class="checkbox" [ngClass]="{ completed: activeTab > 2 }">
            <span></span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="context">
    <div class="inner" [ngClass]="{ inner_thanks: activeTab === 3 }">
      <div class="form-head" *ngIf="activeTab < 3">
        <div class="logo show-phone">
          <img
            src="../../../assets/images/logo.png"
            width="120"
            height="36"
            alt="logo"
          />
        </div>
        <div class="close-holder">
          <button class="close secondary-button" (click)="returnToJobBoard()">
            Exit{{ this.job.jobName ? " & Save Draft" : "" }}
          </button>
        </div>
      </div>
      <div class="menus-mobile show-phone" *ngIf="activeTab < 3">
        <div
          class="menu-item"
          [ngClass]="{ active: activeTab === 1 }"
          (click)="activeTab = 1"
        >
          <div class="number" [ngClass]="{ completed: activeTab > 1 }">1</div>
        </div>
        <div
          class="menu-item"
          [ngClass]="{ active: activeTab === 2 }"
          (click)="activeTab = 2"
        >
          <div class="number" [ngClass]="{ completed: activeTab > 2 }">2</div>
        </div>
      </div>
      <div class="form-container">
        <form onsubmit="return false">
          <div id="step-1" class="post-steps" *ngIf="activeTab === 1">
            <div class="job-name">
              <input
                type="text"
                name="job_name"
                placeholder="Job Name"
                [(ngModel)]="job.jobName"
                (ngModelChange)="errors.jobName = false"
                [ngClass]="{ error: errors.jobName }"
              />
            </div>
            <div class="fields-group">
              <div class="form-fields">
                <div class="field full">
                  <div
                    class="input-field"
                    [ngClass]="{ error: errors.jobDescription }"
                  >
                    <label for="job_summary" class="field-title"
                      >Job Description</label
                    >
                    <input
                      type="text"
                      id="job_summary"
                      name="job_summary"
                      placeholder="A short summary of what the job is..."
                      [(ngModel)]="job.jobDescription"
                      (ngModelChange)="errors.jobDescription = false"
                    />
                  </div>
                </div>
                <!-- field -->
                <div class="field">
                  <div
                    class="input-field"
                    [ngClass]="{ error: errors.industry }"
                  >
                    <label for="job_industry" class="field-title"
                      >Industry</label
                    >
                    <select
                      type="text"
                      id="job_industry"
                      name="job_industry"
                      [(ngModel)]="job.industry"
                      (ngModelChange)="errors.industry = false"
                    >
                      <option value="" disabled selected>Please select</option>
                      <option value="Commercial">Commercial</option>
                      <option value="Residential">Residential</option>
                      <option value="Industrial">Industrial</option>
                    </select>
                  </div>
                </div>
                <!-- field -->
                <div class="field">
                  <div
                    class="input-field"
                    [ngClass]="{ error: errors.materialType }"
                  >
                    <label for="job_material_type" class="field-title"
                      >Material type
                      <span class="inline-desc"
                        >(Multiple selections allowed)</span
                      ></label
                    >
                    <mat-form-field
                      class="custom-mat-selector"
                      appearance="standard"
                    >
                      <mat-select
                        [(value)]="job.materialType"
                        (valueChange)="errors.materialType = false"
                        multiple
                        disableRipple
                        placeholder="Please select"
                      >
                        <mat-option
                          *ngFor="let materialType of jobMaterialTypes"
                          [value]="materialType"
                          >{{ materialType }}</mat-option
                        >
                      </mat-select>
                      <div class="selected-options hide-scrollbar">
                        <div *ngFor="let item of job.materialType">
                          {{ item }}
                        </div>
                      </div>
                    </mat-form-field>
                  </div>
                </div>
                <!-- field -->
                <div class="field">
                  <div
                    class="input-field"
                    [ngClass]="{ error: errors.closingDate }"
                  >
                    <label for="job_closing_date" class="field-title"
                      >Closing Date
                      <span class="inline-desc">(for Job Post)</span></label
                    >
                    <input
                      type="date"
                      id="job_closing_date"
                      name="job_closing_date"
                      placeholder="Type here..."
                      [(ngModel)]="job.closingDate"
                      (ngModelChange)="errors.closingDate = false"
                    />
                  </div>
                </div>
                <!-- field -->
                <div class="field">
                  <div
                    class="input-field"
                    [ngClass]="{ error: errors.closingTime }"
                  >
                    <label for="job_closing_time" class="field-title"
                      >Closing Time
                      <span class="inline-desc">(for Job Post)</span></label
                    >
                    <input
                      type="time"
                      id="job_closing_time"
                      name="job_closing_time"
                      placeholder="Type here..."
                      [(ngModel)]="job.closingTime"
                      (ngModelChange)="errors.closingTime = false"
                    />
                  </div>
                </div>
                <!-- field -->
                <div class="field">
                  <div
                    class="input-field"
                    [ngClass]="{ error: errors.jobLocation }"
                  >
                    <label for="job_location" class="field-title"
                      >Job Location</label
                    >
                    <input
                      type="text"
                      ngx-google-places-autocomplete
                      (onAddressChange)="handleAddressChange($event)"
                      id="job_location"
                      name="job_location"
                      placeholder="Type here..."
                      [(ngModel)]="job.jobLocation"
                      (ngModelChange)="errors.jobLocation = false"
                    />
                  </div>
                </div>
                <!-- field -->
                <div class="field">
                  <div
                    class="input-field"
                    [ngClass]="{ error: errors.jobSize }"
                  >
                    <label for="job_size" class="field-title">Job Size</label>
                    <select
                      type="text"
                      id="job_size"
                      name="job_size"
                      [(ngModel)]="job.jobSize"
                      (ngModelChange)="errors.jobSize = false"
                    >
                      <option value="" disabled selected>Please select</option>
                      <option value="1-100m²">1-100m²</option>
                      <option value="101-1000m²">101-1000m²/option></option>
                      <option value=">1000m²">>1000m²</option>
                    </select>
                  </div>
                </div>
                <!-- field -->
                <div class="field">
                  <div
                    class="input-field"
                    [ngClass]="{ error: errors.constructionPeriod }"
                  >
                    <label for="job_construction_period" class="field-title"
                      >Construction Period</label
                    >
                    <input
                      type="text"
                      id="job_construction_period"
                      name="job_construction_period"
                      placeholder="Type here..."
                      [(ngModel)]="job.constructionPeriod"
                      (ngModelChange)="errors.constructionPeriod = false"
                    />
                  </div>
                </div>
                <!-- field -->
                <div class="field">
                  <div
                    class="input-field"
                    [ngClass]="{ error: errors.workingDays }"
                  >
                    <label for="job_working_days" class="field-title"
                      >Working Days</label
                    >
                    <input
                      type="text"
                      id="job_working_days"
                      name="job_working_days"
                      placeholder="Type here..."
                      [(ngModel)]="job.workingDays"
                      (ngModelChange)="errors.workingDays = false"
                    />
                  </div>
                </div>
                <!-- field -->
              </div>
              <!-- form-fields -->
            </div>
            <!-- fields-group -->
            <div class="fields-group">
              <h2>
                Other Documents <span class="inline-desc">(Optional)</span>
              </h2>
              <div class="form-fields">
                <div class="field full">
                  <div class="uploaded-docs">
                    <div
                      class="doc-item"
                      *ngFor="let file of job.otherDocuments; let i = index"
                      (click)="openUrl(file.url)"
                    >
                      <img
                        class="download-icon"
                        src="../../../assets/images/download_icon.png"
                        width="8"
                        height="10"
                        alt="Download"
                      />
                      <div class="name">{{ file.name }}</div>
                      <div
                        class="remove"
                        (click)="deleteFile(i); $event.stopPropagation()"
                      >
                        <img
                          src="../../../assets/images/remove-icon.png"
                          width="19"
                          height="19"
                          alt="Remove"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- uploaded-docs -->
                  <label class="upload-file-label">
                    <input
                      class="file-input"
                      type="file"
                      multiple
                      (change)="
                        uploadFile($event); errors.otherDocuments = false
                      "
                    />
                    <button
                      class="secondary-button upload-button-icon"
                      [ngClass]="{
                        loading: uploadingOtherDocuments,
                        error: errors.otherDocuments
                      }"
                    >
                      Upload document
                    </button>
                  </label>
                </div>
                <!-- field -->
              </div>
              <!-- form-fields -->
            </div>
            <!-- fields-group -->

            <div class="fields-separator"></div>

            <div class="fields-group">
              <h2>Scope of works</h2>
              <div class="form-fields field-counter">
                <div class="field full">
                  <div class="scope-items-container">
                    <div
                      class="scope-item counter-item"
                      *ngFor="
                        let item of job.scopeOfWorks;
                        let i = index;
                        trackBy: trackByFn
                      "
                    >
                      <div
                        class="input-field"
                        [ngClass]="{ error: errors.scopeOfWorks }"
                      >
                        <input
                          type="text"
                          [(ngModel)]="job.scopeOfWorks[i]"
                          (ngModelChange)="errors.scopeOfWorks = false"
                          placeholder="Type here..."
                          [ngModelOptions]="{ standalone: true }"
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    class="secondary-button add-row"
                    (click)="job.scopeOfWorks.push('')"
                  >
                    Add another row
                  </button>
                </div>
                <!-- field -->
              </div>
              <!-- form-fields -->
            </div>
            <!-- fields-group -->

            <div class="fields-separator"></div>

            <div class="fields-group">
              <h2>Special Conditions</h2>
              <div class="form-fields field-counter">
                <div class="field full">
                  <div class="scope-items-container">
                    <div
                      class="scope-item counter-item"
                      [ngClass]="{ error: errors.specialConditions }"
                      *ngFor="
                        let item of job.specialConditions;
                        let i = index;
                        trackBy: trackByFn
                      "
                    >
                      <div
                        class="input-field"
                        [ngClass]="{ error: errors.specialConditions }"
                      >
                        <input
                          type="text"
                          [(ngModel)]="job.specialConditions[i]"
                          (ngModelChange)="errors.specialConditions = false"
                          [ngModelOptions]="{ standalone: true }"
                          placeholder="Type here..."
                        />
                      </div>
                    </div>
                  </div>
                  <button
                    class="secondary-button add-row"
                    (click)="job.specialConditions.push('')"
                  >
                    Add another row
                  </button>
                </div>
                <!-- field -->
              </div>
              <!-- form-fields -->
            </div>
            <!-- fields-group -->

            <div class="fields-separator"></div>

            <div class="fields-group">
              <div class="form-fields">
                <div class="field full">
                  <div class="question-item">
                    <p class="question">
                      When can the installer visit the work site?
                    </p>
                    <p class="description">
                      (Please detail if only for specific time)
                    </p>
                    <div class="question-field type-boolean">
                      <label for="job_site_visit_type_contact">
                        <input
                          type="radio"
                          name="job_site_visit_type_contact"
                          id="job_site_visit_type_contact"
                          value="Contact"
                          [(ngModel)]="job.siteVisitType"
                        />
                        <div class="label">Contact me to arrange time</div>
                      </label>
                      <label for="job_site_visit_type_custom">
                        <input
                          type="radio"
                          name="job_site_visit_type_custom"
                          id="job_site_visit_type_custom"
                          value="Custom"
                          [(ngModel)]="job.siteVisitType"
                        />
                        <div class="label">Only certain allocated times</div>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="field full">
                  <div
                    class="input-field"
                    [ngClass]="{ error: errors.siteVisitInfo }"
                  >
                    <label for="job_site_visit_info" class="visually-hidden"
                      >Site visit details</label
                    >
                    <input
                      type="text"
                      name="job_site_visit_info"
                      placeholder="Type here..."
                      [(ngModel)]="job.siteVisitInfo"
                      (ngModelChange)="errors.siteVisitInfo = false"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="fields-separator"></div>

            <div *ngIf="false" class="fields-group">
              <div class="form-fields">
                <div class="field full">
                  <div class="question-item">
                    <div class="question">What is the job urgency?</div>
                    <div class="question-field type-radio has-checkmark">
                      <label>
                        <input
                          type="radio"
                          name="job_urgent"
                          value="The job needs to be started within 14 days"
                        />
                        <div class="label">
                          The job needs to be started within 14 days
                        </div>
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="job_urgent"
                          value="The job needs to be started within 30 days"
                        />
                        <div class="label">
                          The job needs to be started within 30 days
                        </div>
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="job_urgent"
                          value="No, it's not urgent"
                        />
                        <div class="label">Standard - The job will be scheduled in at a later date</div>
                      </label>
                    </div>
                    <!-- type-radio -->
                  </div>
                </div>
                <!-- field -->
              </div>
              <!-- form-fields -->
            </div>
            <!-- fields-group -->

            <div *ngIf="false" class="fields-group">
              <div class="form-fields">
                <div class="field full">
                  <div class="question-item">
                    <div class="question">
                      Restrict this post's visibility to only select Installers?
                    </div>
                    <div class="question-field type-radio has-checkmark">
                      <label>
                        <input
                          type="radio"
                          name="job_installer_only"
                          value="Yes"
                        />
                        <div class="label">Yes</div>
                      </label>
                      <label>
                        <input
                          type="radio"
                          name="job_installer_only"
                          value="No"
                        />
                        <div class="label">
                          No, I want this to be open for all installers
                        </div>
                      </label>
                    </div>
                    <!-- type-radio -->
                  </div>
                </div>
                <!-- field -->
              </div>
              <!-- form-fields -->
            </div>
            <!-- fields-group -->

            <div class="step-footer">
              <button class="primary-button" (click)="proceedToStep(2)">
                Next
              </button>
            </div>
          </div>
          <!-- step-1 -->

          <div id="step-2" class="post-steps" *ngIf="activeTab === 2">
            <h2>Review your job post</h2>
            <div class="job-summary">
              <div class="wrapper">
                <div class="head">
                  <h2 class="job-heading-title">{{ job.jobName }}</h2>
                  <div class="excerpt">
                    <div class="name">{{ job.company }}</div>
                  </div>
                  <div class="summary">
                    {{ job.jobDescription }}
                  </div>
                  <div class="job-detail boxes">
                    <div class="box">
                      <div class="title">Job Location</div>
                      <div class="value">{{ job.suburb }}, {{ job.state }}</div>
                    </div>
                    <div class="box">
                      <div class="title">Job Size</div>
                      <div class="value">{{ job.jobSize }}</div>
                    </div>
                    <div class="box">
                      <div class="title">Material Type</div>
                      <div class="value">{{ job.materialType.join(", ") }}</div>
                    </div>
                  </div>
                  <div class="job-time boxes">
                    <div class="box">
                      <div class="title">Construction Period</div>
                      <div class="value">{{ job.constructionPeriod }}</div>
                    </div>
                    <div class="box">
                      <div class="title">Working Days</div>
                      <div class="value">{{ job.workingDays }}</div>
                    </div>
                  </div>
                </div>
                <div class="line-separator"></div>
                <div class="other-docs">
                  <h3>Documents</h3>
                  <div class="uploaded-docs">
                    <div
                      class="doc-item"
                      *ngFor="let file of job.otherDocuments; let i = index"
                      (click)="openUrl(file.url)"
                    >
                      <img
                        class="download-icon"
                        src="../../../assets/images/download_icon.png"
                        width="8"
                        height="10"
                        alt="Download"
                      />
                      <div class="name">{{ file.name }}</div>
                    </div>
                  </div>
                </div>
                <div class="line-separator"></div>
                <div class="special-conditions">
                  <h3>Special Conditions</h3>
                  <div class="list-items">
                    <div
                      class="item"
                      *ngFor="let item of job.specialConditions"
                    >
                      {{ item }}
                    </div>
                  </div>
                </div>
                <div class="line-separator"></div>
                <div class="special-conditions">
                  <h3>Scope of Works</h3>
                  <div class="list-items">
                    <div class="item" *ngFor="let item of job.scopeOfWorks">
                      {{ item }}
                    </div>
                  </div>
                </div>
                <div class="line-separator"></div>
                <div class="site-visit-info">
                  <h3>Site Visit Information</h3>
                  <p>
                    {{
                      job.siteVisitType === "Contact"
                        ? "Contact builder to arrange a time"
                        : "Only certain times allowed"
                    }}
                  </p>
                  <p *ngIf="job.siteVisitInfo.length">
                    {{ job.siteVisitInfo }}
                  </p>
                  <p *ngIf="job.siteContactName.length">
                    {{ "Site contact name: "+job.siteContactName }}
                  </p>
                  <p *ngIf="job.siteContactNumber.length">
                    {{ "Site contact number: "+job.siteContactNumber }}
                  </p>
                </div>
              </div>
            </div>
            <!-- job-summary -->

            <div class="step-footer">
              <div class="next-steps">
                <button
                  class="primary-button"
                  [ngClass]="{ loading: loadingNextSteps }"
                  (click)="submitJob()"
                >
                  Post Job
                </button>
              </div>
            </div>
          </div>
          <!-- step-2 -->

          <div id="step-3" class="post-steps thank-you" *ngIf="activeTab === 3">
            <div class="thank-you-container">
              <div class="icon">
                <img
                  src="../../../assets/images/order_submitted_icon.png"
                  width="100"
                  height="185"
                  alt="Thank you"
                />
              </div>
              <h2>
                Your job was successfully posted!<br />Now it’s time to wait for
                those quotes to roll in...
              </h2>
              <div class="primary-button" (click)="goTo('/my-job-posts')">
                Done
              </div>
              <div class="note">
                You can keep track of job bids in
                <span (click)="goTo('/my-job-posts')">My Hub</span>
              </div>
            </div>
          </div>
          <!-- step-3 -->
        </form>
      </div>
    </div>
    <!-- inner -->
  </div>
</div>
