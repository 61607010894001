import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { UtilityHelper } from 'src/app/helpers/UtilityHelper';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { EstimationStepsComponent } from 'src/app/modals/estimation-steps/estimation-steps.component';
import { EstimationCart } from 'src/app/classes/EstimationCart';
import { EstimateJobItem } from 'src/app/classes/EstimateJobItem';
import { EstimationDetailsComponent } from 'src/app/modals/estimation-details/estimation-details.component';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';

@Component({
  selector: 'app-estimation',
  templateUrl: './estimation.component.html',
  styleUrls: ['./estimation.component.scss']
})
export class EstimationComponent implements OnInit {

  estimateInit = false;
  loadingEstimates = true;
  estimations: EstimationCart[] = [];

  constructor(private router: Router, public dialog: MatDialog) { }

  ngOnInit(): void {
    this.mobileToggle()
    this.loadEstimations();
    AnalyticsHelper.instance.pageView("Estimation");
  }

  ngAfterContentChecked(): void {
    // re init run once
    if( this.userLoaded && ! this.estimateInit ){
      this.fancyDropdown()
      this.initPreviousEstimation()
      this.estimateInit = true
    }
  }

  fancyDropdown(){
    document.querySelectorAll('.dropdown-box').forEach( dropDown =>{
      const selected = dropDown.querySelector('.dropdown-selected') as HTMLElement
      const selectedText = selected.querySelector('.context .selected') as HTMLElement
      const selectedPriceHTML = selected.querySelector('.price .amount') as HTMLElement

      // toggle open hide
      dropDown.addEventListener('click', ()=>{
        if( dropDown.classList.contains('opened') ){
          dropDown.classList.remove('opened')
        }else{
          dropDown.classList.add('opened')
        }
      })

      // selected
      dropDown.querySelectorAll('.dropdown-items .item').forEach( item => {
        item.addEventListener('click', ()=>{

          // remove selected
          item.parentElement?.querySelectorAll('.item').forEach(sib => {
            sib.classList.remove('selected')
          })
          item.classList.add('selected')

          // change selected text and price
          const selectedItemText = item.querySelector('.context') as HTMLElement
          if (UtilityHelper.isPhone() && selectedText.innerHTML.includes("Industrial")) {
            selectedText.innerHTML = selectedItemText.innerHTML.split("(")[0] + "<br>(" + selectedItemText.innerHTML.split("(")[1];
          } else {
            selectedText.innerHTML = selectedItemText.innerHTML;
          }

          const selectedItemPrice = item.querySelector('.price .amount') as HTMLElement
          selectedPriceHTML.innerHTML = selectedItemPrice.innerText

          selected.setAttribute('data-type', item.getAttribute('data-type') as string )
          selected.setAttribute('data-price', selectedItemPrice.innerText as string )

        })
      })

      // close if click outside element
      document.addEventListener('click', (e) => {
        // Check if the filter list parent element exist
        if (e.target instanceof Element) {
          const isClosest = e.target.closest('.dropdown-box')
          if (!isClosest && dropDown.classList.contains('opened')) {
            dropDown.classList.remove('opened');
          }
        }
      });

      // estimation related start here


    })
  }

  mobileToggle(){
    const pendingList = document.querySelector('.pending-item > .inner > .pending-list') as HTMLElement
    const triggerPendingToggle = document.querySelector('.pending-item > .inner > h3')
    triggerPendingToggle?.addEventListener('click', ()=>{

      // only need for mobile
      if( window.innerWidth > 1000 ){
        return
      }

      if(! pendingList.classList.contains('active')) {
      /** Show the  pendingList. */
         pendingList.classList.add('active')
           pendingList.style.height = "auto"

      /** Get the computed height of the  pendingList. */
        var height =  pendingList.clientHeight + "px"

      /** Set the height of the content as 0px, */
          /** so we can trigger the slide down animation. */
           pendingList.style.height = "0px"

      /** Do this after the 0px has applied. */
          /** It's like a delay or something. MAGIC! */
          setTimeout(() => {
               pendingList.style.height = height
          }, 0)

      /** Slide up. */
      } else {
        /** Set the height as 0px to trigger the slide up animation. */
         pendingList.style.height = "0px"

          /** Remove the `active` class when the animation ends. */
         pendingList.addEventListener('transitionend', () => {
             pendingList.classList.remove('active')
          }, {once: true})
      }


    })
  }

  initEstimationCart(){

    // get selected type of job
    const selected = document.querySelector('.dropdown-selected') as HTMLElement
    const selectedText = selected.getAttribute('data-type') as string
    const selectedPrice = selected.getAttribute('data-price') as string

    const estimationCartItem = new EstimationCart()
    estimationCartItem.jobName = ''
    estimationCartItem.jobType = selectedText
    estimationCartItem.jobPrice = parseFloat( selectedPrice )
    estimationCartItem.jobDocument = ''
    estimationCartItem.jobItems = []
    this.user.estimationCart = estimationCartItem
    this.user.save()
    AnalyticsHelper.instance.logEvent("estimation_started", { plan:  estimationCartItem.jobType });
  }

  initPreviousEstimation(){
    if( this.hasEstimationCart() ){

      const estCart = this.user.estimationCart

      // if price has been selected
      if( estCart.jobType != '' ){
        const jobTypeEl = document.querySelector('.dropdown-items .item[data-type="'+ estCart.jobType +'"]') as HTMLElement
        if( jobTypeEl != null ){
          jobTypeEl.click()
          document.querySelector('.dropdown-box')?.classList.remove('opened')
        }
      }

    }
  }

  openEstimationSteps(){

    // simple validation
    const dropError = document.querySelector('.drop-estimation .error') as HTMLElement
    dropError.style.display="none"
    const selected = document.querySelector('.dropdown-selected') as HTMLElement
    const selectedText = selected.getAttribute('data-type') as string
    const selectedPrice = selected.getAttribute('data-price') as string
    if( selectedText == null || selectedPrice == null || selectedText == '' || selectedPrice == ''){
      dropError.style.display="block"
      return
    }

    // check if array has been set
    if( ! this.user.estimationCart.jobType ){
      this.initEstimationCart()

    // change after init
    }else{
      // only save if has different value
      if( this.user.estimationCart.jobType != selectedText){
        this.user.estimationCart.jobType = selectedText
        this.user.estimationCart.jobPrice = parseFloat( selectedPrice )
        this.user.save()
      }
      AnalyticsHelper.instance.logEvent("continue_estimation", { plan:  this.user.estimationCart.jobType });
    }

    // open dialog
    this.dialog.open(EstimationStepsComponent, {
      disableClose: true,
      height: '100vh',
      width: '100vw',
      maxWidth: '100vw',
      maxHeight: '-webkit-fill-available',
      position: { bottom: '0', left: this.isPhone ? '0' : '20vw' },
      enterAnimationDuration: '0ms',
      panelClass: ['profile-modal', 'no-padding'],
      id: 'estimation-steps'
    });
  }

  hasEstimationCart(){
    return this.user.estimationCart.jobType ? true : false
  }

  loadEstimations() {
    this.loadingEstimates = true;
    DatabaseHelper.instance.getUserEstimations(this.user.uid, 5, '', true).then(val => {
      this.estimations = val.estimations;
      this.loadingEstimates = false;
    });
  }

  viewEstimate(estimate : EstimationCart)
  {
    this.dialog.open(EstimationDetailsComponent, {
      height: this.isPhone ? '100vh' : 'calc(100vh - 30px)',
      width: this.isPhone ? '100vw' : 'calc(100vw - 60px)',
      maxWidth: '100vw',
      maxHeight: '-webkit-fill-available',
      position: { bottom: '0', left: this.isPhone ? '0' : '30px' },
      enterAnimationDuration: '0',
      panelClass: ['profile-modal', 'no-padding'],
      id: 'estimation-details',
      data: {
        estimation: estimate,
      }
    });
  }

  get isPhone() {
    return UtilityHelper.isPhone();
  }

  get loading() {
    return !AuthHelper.instance.userLoaded;
  }

  get userLoaded(){
    return AuthHelper.instance.userLoaded;
  }

  get user() {
    return AuthHelper.instance.user;
  }

}
