import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Order } from "../classes/Order";
import { User } from "../classes/User";
import { DatabaseHelper } from "./DatabaseHelper";

declare var SimplifyCommerce: any;

@Injectable()
export class PaymentsHelper {

    private static _instance: PaymentsHelper;

    constructor() {
        PaymentsHelper._instance = this;
    }

    static get instance() {
        return this._instance;
    }

    createSimplifyCustomer(user: User) {
        return DatabaseHelper.instance.performWebRequest("createSimplifyCustomer", "POST", { user: user });
    }

    updateSimplifyCustomer(user: User, cardToken: string) {
        return DatabaseHelper.instance.performWebRequest("updateSimplifyCustomer", "POST", { user: user, cardToken: cardToken });
    }

    createCardToken(name: string, cardNumber: string, cvc: string, expiry: string): Promise<any> {
        return DatabaseHelper.instance.performWebRequest("createCardToken", "POST", { 
                        name: name,
                        cardNumber: cardNumber.replace(/ /g, ''),
                        cvc: cvc,
                        expiry: expiry
                    });
        // return new Promise((res: any, rej: any) => {
        //     console.log("Card Creation #1 = "+expiry);
        //     console.log("token = "+environment.simplifyPublicKey);
        //     var testtoken = environment.simplifyPublicKey;
        //     console.log("token here! = "+testtoken);
        //     SimplifyCommerce.generateToken({
        //         key: environment.simplifyPublicKey,
        //         card: {
        //             name: name,
        //             number: cardNumber.replace(/ /g, ''),
        //             cvc: cvc,
        //             expMonth: expiry.split('/')[0],
        //             expYear: expiry.split('/')[1],
        //         },
        //     }, (val: any) => {
        //         console.log("Card Create #1.1 = "+JSON.stringify(val));
        //         res(val);
        //     });
        // });
    }

    // createCardTokenWithAmount(name: string, cardNumber: string, cvc: string, expiry: string, order : Order): Promise<any> {
    //     return new Promise((res: any, rej: any) => {
    //         console.log("Card Creation #1 = "+expiry);
    //         const total = Number.parseFloat(order.orderTotalWithGst.replace("$", "").replace(new RegExp(",", 'g'), "")) * 100;
    //         console.log("Card Creation #1.1 = "+total);
    //         SimplifyCommerce.generateToken({
    //             key: environment.simplifyPublicKey,
    //             card: {
    //                 name: name,
    //                 number: cardNumber.replace(/ /g, ''),
    //                 cvc: cvc,
    //                 expMonth: expiry.split('/')[0],
    //                 expYear: expiry.split('/')[1],
    //             },
    //         }, (val: any) => {
    //             console.log("Card Create #1.2 = "+JSON.stringify(val));
    //             res(val);
    //         });
    //     });
    // }

    authorizePayment(order: Order, user: User, cardToken: string) {
        console.log("Card token = "+cardToken);
        return DatabaseHelper.instance.performWebRequest("authorizePayment", "POST", { user: user, order: order, cardToken: cardToken });
    }

    captureAuthorization(order: Order, user: User) {
        return DatabaseHelper.instance.performWebRequest("captureAuthorization", "POST", { user: user, order: order });
    }

    reverseAuthorizedPayment(authorizationId: string) {
        return DatabaseHelper.instance.performWebRequest("reverseAuthorizedPayment", "POST", { authorizationId: authorizationId });
    }

    subscribeSimplifyCustomer(user: User) {
        return DatabaseHelper.instance.performWebRequest('subscribeSimplifyCustomer', 'POST', { user: user.toJson() });
    }

    chargeCard(reference: string, amount: number, description: string, token: string, simplifyCustomerId: string) {
        return DatabaseHelper.instance.performWebRequest('chargeCard', 'POST', { reference: reference, amount: amount, description: description, token: token, simplifyCustomerId: simplifyCustomerId });
    }

    chargeCardWithoutToken(reference: string, amount: number, description: string, token: string, simplifyCustomerId: string, name: string, cardNumber: string, cvc: string, expiry: string) {
        return DatabaseHelper.instance.performWebRequest('chargeCardWithoutToken', 'POST', { 
            reference: reference, 
            amount: amount, 
            description: description, 
            token: token, 
            simplifyCustomerId: simplifyCustomerId, 
            card: {
                name: name,
                number: cardNumber.replace(/ /g, ''),
                cvc: cvc,
                expMonth: expiry.split('/')[0],
                expYear: expiry.split('/')[1],
                }
        });
    }

}