import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class HubspotService {

    private apiKey:string = 'pat-na1-53fa81c4-70f8-4623-ab9e-07903cc12dde'

    private apiUrl:string = 'https://api.hubapi.com';
    private formsApiUrl:string = 'https://api.hsforms.com';

    constructor(private http: HttpClient) {
        this.http = http;
    }

    /**
     * @param formId
     * @param context
     * @param fields
     *
     * See https://legacydocs.hubspot.com/docs/methods/forms/submit_form
     */
    public submitHubspotForm(
        formId:string,
        context: {hutk:string, pageUri:string, pageName:string},
        fields:object
    ):Observable<object>{

        let url = this.formsApiUrl + '/submissions/v3/integration/submit/23509883/' + formId;
        let dataToSend = {
            "submittedAt": Date.now(),
            "fields": fields,
            "context": context,
        }

        return this.http.post(url, dataToSend, {});
    }
}
