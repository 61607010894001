<div class="page-container phone-padding-top">
    <div class="show-phone top-logo-container">
        <img class="logo" src="../../../assets/images/logo.png">    
    </div>
    <img class="logo hide-phone" src="../../../assets/images/logo.png">
    <div class="inner-container">
        <div class="small-button" (click)="back()">Back</div>
        <h1 class="heading">Forgot Password</h1>
        <p class="sub-heading" *ngIf="!success">Enter your email address</p>
        <div class="input-field" *ngIf="!success">
            <label for="email">Email Address</label>
            <input id="email" [(ngModel)]="email" placeholder="Type here..." type="email">
        </div>
        <p *ngIf="success">
            Instructions for resetting password sent to {{email}}.
        </p>
        <div *ngIf="errorMessage" style="margin-bottom: 35px;" class="error">{{errorMessage}}</div>
        <div class="primary-button" [ngClass]="{loading: loading}" (click)="login()">{{success ? 'Back to login' : 'Send email'}}</div>
    </div>
</div>

<img class="background-logo" src="../../../assets/images/bg_logo.png">