import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Job } from 'src/app/classes/Job';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';
import { UtilityHelper } from 'src/app/helpers/UtilityHelper';
import { JobBidDetailsComponent } from 'src/app/modals/job-bid-details/job-bid-details.component';
import { CartItem } from 'src/app/classes/CartItem';
import { AlertViewComponent } from 'src/app/modals/alert-view/alert-view.component';
import { JobQuote } from 'src/app/classes/JobQuote';

@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss'],
})
export class JobDetailsComponent implements OnInit {
  job: Job = new Job();
  loadingQuoteJob = false;
  addingToCart = false;
  jobQuote : JobQuote = new JobQuote();

  constructor(private router: Router, private dialog: MatDialog) {
    if (
      this.router.getCurrentNavigation() &&
      this.router.getCurrentNavigation()?.extras.state
    ) {
      this.job = this.router.getCurrentNavigation()?.extras.state!['job'];
    } else {
      this.router.navigateByUrl('/job-board');
    }
  }

  ngOnInit(): void {
    this.loadQuoteForJob();
  }

  openUrl(url: string) {
    window.open(url, '_blank');
  }

  loadQuoteForJob()
  {
    this.loadingQuoteJob = true;
    this.jobQuote.status = '';
    this.jobQuote.job = this.job;
    this.loadingQuoteJob = false;

    // this.loadingQuoteJob = true;
    // DatabaseHelper.instance
    //   .getUserJobQuoteForJobPost(this.job.jobPostNumber, this.user.uid)
    //   .then((val) => {
    //     this.loadingQuoteJob = false;
    //     if(Object.keys(val).includes('empty'))
    //     {
    //       this.jobQuote.status = '';
    //       this.jobQuote.job = this.job;
    //     }
    //     else
    //     {
    //       this.jobQuote = val.jobQuote;
    //       console.log("JOB QUOTE: ", this.jobQuote);
    //     }

    //   });
  }

  async quoteJob() {
        if(this.jobQuote.status == '')
        {
          //Get the quote details and submit it with a status of "Accepted"
          this.jobQuote.status = 'Accepted';
          this.jobQuote.userId = this.user.uid;
          this.jobQuote.installerName = this.user.companyName;
          this.jobQuote.installerLocation = this.user.companyAddress.split(", ")[this.user.companyAddress.split(", ").length - 2];

          //Save the quote to the database with status Accepted
          this.loadingQuoteJob = true;
          await DatabaseHelper.instance.submitJobQuote(this.jobQuote);
          this.loadingQuoteJob = false;
          //Display a popup notifying the user they have accepted the job and asking them if they want to quote now or do it later with          
          var siteVisitMessage = "";
          if(this.job.siteVisitInfo.length)
          {
            siteVisitMessage = '<br>'+"This job requires a site delivery.";
          }
          if(this.job.siteVisitType === "Contact")
          {
            siteVisitMessage = '<br>'+"Contact builder to arrange a time.";
          }
          else
          {
            siteVisitMessage = '<br>'+"Only certain times allowed.";
          }
          if(this.job.siteVisitInfo.length)
          {
            siteVisitMessage = '<br><br>Site Visit Info:'+siteVisitMessage + '<br>' + this.job.siteVisitInfo+"<br>Contact builder to confirm site visit details.";
          }
          if(this.job.siteContactName.length)
          {
            siteVisitMessage = '<br><br>'+siteVisitMessage + '<br>Site contact name:' + this.job.siteContactName;
          }
          if(this.job.siteContactNumber.length)
          {
            siteVisitMessage = '<br><br>'+siteVisitMessage + '<br>Site contact number:' + this.job.siteContactNumber;
          }
          this.dialog.open(AlertViewComponent, {
            maxWidth: "400px",
            panelClass: ['alert-modal', 'no-padding'],
            id: 'alert-modal',
            data: {
              title: this.job.siteVisitInfo.length ? "Site Visit Required" : "Job accepted",
              message: this.job.company+" has been alerted of your interest to quote on "+this.job.jobName+"."+siteVisitMessage,
              primaryButton: "Quote Now",
              secondaryButton: "I'll do it later",
            }
          });
          DatabaseHelper.instance.dataCallbacks.alertViewCallback = (button: string) => {
            if (button === 'Quote Now') {
              this.showQuoteView();
            } 
            else if (button === 'I\'ll do it later') {
              this.dialog.closeAll();
              this.dialog.open(AlertViewComponent, {
                maxWidth: "400px",
                panelClass: ['alert-modal', 'no-padding'],
                id: 'alert-modal-my-hub',
                data: {
                  title: "This job has been added to My Hub",
                  message: "You have until "+UtilityHelper.formatDate(this.job.closingDate)+" to submit your quote for this job before it's closing date",
                  primaryButton: "Okay",
                }
              });
            }
            else if(button === 'Okay')
            {
              this.dialog.closeAll();
            }
          }
          //If not notify the user the job has been added to my hub

        }
        else
        {          
          this.showQuoteView();
        }
  }

  showQuoteView()
  {
    let data: any = this.job;
    // if (this.user.type !== 'Admin') {
      // data = this.jobQuote;
    // }
    this.dialog.open(JobBidDetailsComponent, {
      height: this.isPhone ? '100vh' : 'calc(100vh - 30px)',
      width: this.isPhone ? '100vw' : 'calc(100vw - 60px)',
      maxWidth: '100vw',
      maxHeight: '-webkit-fill-available',
      position: { bottom: '0', left: this.isPhone ? '0' : '30px' },
      enterAnimationDuration: '0',
      panelClass: ['profile-modal', 'no-padding'],
      id: 'job-bid-details',
      data: {
        job: data,
      },
    }); 
  }

  back() {
    window.history.back();
  }

  
  addToCart() {
    // console.log(this.user.cart);

    if (this.user.cart.length > 0) {

      this.dialog.open(AlertViewComponent, {
        maxWidth: "400px",
        panelClass: ['alert-modal', 'no-padding'],
        id: 'alert-modal',
        data: {
          title: "Clear Cart",
          message: "This will clear all items in your cart. Do you want to continue?",
          primaryButton: "Continue",
          secondaryButton: "Back",
        }
      });
    
      DatabaseHelper.instance.dataCallbacks.alertViewCallback = (button: string) => {
        if (button === "Continue") {
          this.user.cart.length = 0;
          this.addAllItemsToCart()
        }
      }
    } else {
      this.addAllItemsToCart();
    }

  }

  addAllItemsToCart() {
    this.addingToCart = true;
    this.job.products.forEach(materialItem => {
      const product = materialItem.product;
      let item = new CartItem();

      item.product = product;
      item.quantity = Number.parseFloat(materialItem.quantity);
      CartItem.calculateSupplierTotal(item);
      CartItem.calculateSupplierTotalWithGst(item);
      this.user.cart.push(item);
      this.user.save().then(val => {
        this.addingToCart = false;
        this.router.navigateByUrl("/cart");
      });
    })

  }

  get user() {
    return AuthHelper.instance.user;
  }

  get isPhone() {
    return UtilityHelper.isPhone();
  }

  get utilityHelper() {
    return UtilityHelper;
  }
}
