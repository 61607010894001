import { AnalyticsHelper } from '../helpers/AnalyticsHelper';
import { DatabaseHelper } from '../helpers/DatabaseHelper';
import { UtilityHelper } from '../helpers/UtilityHelper';
import { CartItem } from './CartItem';

export class Order {
  id: string;
  userId: string;
  orderDate: string;
  orderNumber: string;
  cart: CartItem[];
  discountCode: string;
  discountCodeAmount: string;
  bulkDiscount: string;
  useFHCredit: boolean;
  shippingName: string;
  shippingPhone: string;
  shippingAddress: string;
  shippingNotes: string;
  deliveryOption: { option: string; cost: string };
  paymentOption: string;
  cardAuthorization: any;
  paymentStatus: string;
  fulfilmentStatus: string;
  orderSubtotal: string;
  shippingTotal: string;
  orderTotal: string;
  deliveryDate: string;
  discountData: {};

  deliverySiteOpeningTime: string;
  deliverySiteClosingTime: string;
  deliveryDropOffLocation: string;
  deliveryBuildingSite: boolean;
  deliveryCallBeforeDelivery: boolean;
  deliverySiteSpecificNotes: string;
  stockChecks: { supplier: string; code: string; timestamp: string }[];

  shippingStreetNumber: string;
  shippingStreet: string;
  shippingCountry: string;
  shippingState: string;
  shippingSuburb: string;
  shippingPostcode: string;
  shippingLat: string;
  shippingLng: string;

  orderGst: string;
  orderTotalWithGst: string;
  orderDiscount: string;
  supplierSubtotalTotal: string;
  supplierGst: string;
  supplierTotalWithGst: string;
  shippingPackages: any;

  constructor() {
    this.id = '';
    this.userId = '';
    this.orderDate = '';
    this.orderNumber = '';
    this.cart = [];
    this.discountCode = '';
    this.useFHCredit = false;
    this.shippingName = '';
    this.shippingPhone = '';
    this.shippingAddress = '';
    this.shippingNotes = '';
    this.deliveryOption = { option: '', cost: '' };
    this.paymentOption = '';
    this.cardAuthorization = {};
    this.paymentStatus = '';
    this.fulfilmentStatus = '';
    this.orderSubtotal = '';
    this.shippingTotal = '';
    this.orderTotal = '';
    this.orderGst = '';
    this.orderTotalWithGst = '';
    this.orderDiscount = '';
    this.deliveryDate = '';
    this.deliverySiteOpeningTime = '';
    this.deliverySiteClosingTime = '';
    this.deliveryDropOffLocation = '';
    this.deliveryBuildingSite = false;
    this.deliveryCallBeforeDelivery = false;
    this.deliverySiteSpecificNotes = '';
    this.stockChecks = [];
    this.shippingStreetNumber = '';
    this.shippingStreet = '';
    this.shippingCountry = '';
    this.shippingState = '';
    this.shippingSuburb = '';
    this.shippingPostcode = '';
    this.shippingLat = '';
    this.shippingLng = '';
    this.supplierSubtotalTotal = '';
    this.supplierGst = '';
    this.supplierTotalWithGst = '';
    this.shippingPackages = {};
    this.discountCodeAmount = '';
    this.bulkDiscount = '';
    this.discountData = {};
  }

  addData(data: any) {
    if (!data) return;
    this.id = data.id;
    this.userId = data.userId;
    this.orderDate = data.orderDate;
    this.orderNumber = data.orderNumber;
    this.cart = data.cart;
    this.discountCode = data.discountCode;
    this.useFHCredit = data.useFHCredit;
    this.shippingName = data.shippingName;
    this.shippingPhone = data.shippingPhone;
    this.shippingAddress = data.shippingAddress;
    this.shippingNotes = data.shippingNotes;
    this.deliveryOption = data.deliveryOption;
    this.paymentOption = data.paymentOption;
    this.cardAuthorization = data.cardAuthorization;
    this.paymentStatus = data.paymentStatus;
    this.fulfilmentStatus = data.fulfilmentStatus;
    this.orderSubtotal = data.orderSubtotal;
    this.shippingTotal = data.shippingTotal;
    this.orderTotal = data.orderTotal;
    this.orderGst = data.orderGst;
    this.orderTotalWithGst = data.orderTotalWithGst;
    this.orderDiscount = data.orderDiscount;
    this.deliveryDate = data.deliveryDate;
    this.deliverySiteOpeningTime = data.deliverySiteOpeningTime;
    this.deliverySiteClosingTime = data.deliverySiteClosingTime;
    this.deliveryDropOffLocation = data.deliveryDropOffLocation;
    this.deliveryBuildingSite = data.deliveryBuildingSite;
    this.deliveryCallBeforeDelivery = data.deliveryCallBeforeDelivery;
    this.deliverySiteSpecificNotes = data.deliverySiteSpecificNotes;
    this.stockChecks = data.stockChecks;
    this.shippingStreetNumber = data.shippingStreetNumber;
    this.shippingStreet = data.shippingStreet;
    this.shippingCountry = data.shippingCountry;
    this.shippingState = data.shippingState;
    this.shippingSuburb = data.shippingSuburb;
    this.shippingPostcode = data.shippingPostcode;
    this.shippingLat = data.shippingLat;
    this.shippingLng = data.shippingLng;
    this.supplierSubtotalTotal = data.supplierSubtotalTotal;
    this.supplierGst = data.supplierGst;
    this.supplierTotalWithGst = data.supplierTotalWithGst;
    this.shippingPackages = data.shippingPackages;
    this.discountCodeAmount = data.discountCodeAmount;
    this.bulkDiscount = data.bulkDiscount;
  }

  setupTotals() {
    this.orderSubtotal = this.getSubTotal();
    this.shippingTotal = this.getShippingTotal();
    this.orderTotal = this.getTotal();
    this.orderGst = this.getGst();
    this.orderTotalWithGst = this.getTotalWithGst();
    this.orderDiscount = this.getTotalDiscount();
    this.bulkDiscount = this.getBulkDiscount();

    this.supplierSubtotalTotal = this.getSupplierSubtotalTotal();
    this.supplierGst = this.getSupplierGst();
    this.supplierTotalWithGst = this.getSupplierTotalWithGst();
  }

  // ORDER TOTAL FUNCTIONS IN ORDER OF WHICH THEY ARE APPLIED

  /**
   * @returns Total retail price for all products and line items
   */
  getSubTotal() {
    let total = 0;
    this.cart.forEach((item) => {
      total += UtilityHelper.parsePrice(item.total);
      // item.lineItems.forEach((lineItem) => {
      //   total += UtilityHelper.parsePrice(lineItem.total);
      // });
    });
    return UtilityHelper.formatPrice(total);
  }

  /**
   * @returns Checks if the user is qualified for a discount returns empty string if no discount otherwise returns formatted discount amount
   */
  getBulkDiscount() {
    let subtotal = UtilityHelper.parsePrice(this.getSubTotal());
    let discount = 0;
    if (DatabaseHelper.instance.settings.priceBreakpoints) {
      if (subtotal >= 10000) {
        discount =
          (subtotal *
            parseFloat(
              DatabaseHelper.instance.settings.priceBreakpoints['10000'],
            )) /
          100;
      }
      if (subtotal >= 30001) {
        discount =
          (subtotal *
            parseFloat(
              DatabaseHelper.instance.settings.priceBreakpoints['30001'],
            )) /
          100;
      }
      if (subtotal >= 50001) {
        discount =
          (subtotal *
            parseFloat(
              DatabaseHelper.instance.settings.priceBreakpoints['50001'],
            )) /
          100;
      }
    }
    return discount > 0 ? UtilityHelper.formatPrice(discount) : '';
  }

  /**
   * Called in cart.component.ts when discount code is entered.
   * Calculates discount after deducting bulk order discount.
   * @param data
   */
  applyDiscountCode(data: any) {
    this.discountCode = data.couponCode;
    this.discountData = data;
    AnalyticsHelper.instance.logEvent('discount_code_applied', {
      discount_code: this.discountCode,
    });
    if (data.percentOff) {
      this.discountCodeAmount = UtilityHelper.formatPrice(
        (data.percentOff / 100) *
          (UtilityHelper.parsePrice(this.getSubTotal()) -
            UtilityHelper.parsePrice(this.getBulkDiscount())),
      );
    } else if (data.amountOff) {
      this.discountCodeAmount = UtilityHelper.formatPrice(data.amountOff / 100);
    }
  }

  /**
   * @returns Total shipping price as a number
   */
  getTotalDeliveryPrice() {
    var deliveryPrice = 0.0;
    if (this.fulfilmentStatus === 'Checkout' || !this.fulfilmentStatus)
      return 0;
    Object.keys(this.shippingPackages).forEach((key) => {
      deliveryPrice =
        deliveryPrice +
        UtilityHelper.parsePrice(
          this.shippingPackages[key].deliveryOption.cost,
        );
    });
    return deliveryPrice;
  }

  /**
   * @returns Total shipping price formatted
   */
  getShippingTotal() {
    return UtilityHelper.formatPrice(this.getTotalDeliveryPrice());
  }

  /**
   * @returns Discount based on payment option as a number
   */
  getTotalDiscountValue() {
    if (
      this.paymentOption === 'Credit Card Old' ||
      this.paymentOption === 'Bank Transfer Old'
    ) {
      return (
        (UtilityHelper.parsePrice(this.getSubTotal()) +
          this.getTotalDeliveryPrice() -
          UtilityHelper.parsePrice(this.getBulkDiscount()) -
          UtilityHelper.parsePrice(this.discountCodeAmount)) *
        0.05
      );
    } else {
      return 0;
    }
  }

  /**
   * @returns Discount based on payment option - formatted
   */
  getTotalDiscount() {
    var paymentDiscountAmount = 0;
    if (
      this.paymentOption === 'Credit Card Old' ||
      this.paymentOption === 'Bank Transfer Old'
    ) {
      paymentDiscountAmount = 0.05;
    }
    return UtilityHelper.formatPrice(
      (UtilityHelper.parsePrice(this.getSubTotal()) +
        this.getTotalDeliveryPrice() -
        UtilityHelper.parsePrice(this.getBulkDiscount()) -
        UtilityHelper.parsePrice(this.discountCodeAmount)) *
        paymentDiscountAmount,
    );
  }

  /**
   * @returns Total without GST
   */
  getTotal() {
    return UtilityHelper.formatPrice(
      UtilityHelper.parsePrice(this.getSubTotal()) +
        this.getTotalDeliveryPrice() -
        this.getTotalDiscountValue() -
        UtilityHelper.parsePrice(this.discountCodeAmount) -
        UtilityHelper.parsePrice(this.getBulkDiscount()),
    );
  }

  /**
   * @returns GST based on total
   */
  getGst() {
    let total = UtilityHelper.parsePrice(this.getTotal());
    total = total * 0.1;
    return UtilityHelper.formatPrice(total);
  }

  /**
   * @returns Surcharge amount based on payment option
   */
  getSurchargeAmount() {
    let surcharge = 0;
    if (this.paymentOption === 'Credit Card') {
      surcharge = 0.0185;
    }

    let total =
      UtilityHelper.parsePrice(this.getTotal()) +
      UtilityHelper.parsePrice(this.getGst());

    total = total * surcharge;
    return UtilityHelper.formatPrice(total);
  }

  /**
   * @returns Final total incl. GST
   */
  getTotalWithGst() {
    let total =
      UtilityHelper.parsePrice(this.getTotal()) +
      UtilityHelper.parsePrice(this.getGst()) +
      UtilityHelper.parsePrice(this.getSurchargeAmount());
    if (total === 0) {
      total = 0.01;
    }
    return UtilityHelper.formatPrice(total);
  }

  // Supplier Totals

  getSupplierSubtotalTotal() {
    let total = 0;
    this.cart.forEach((item) => {
      total += UtilityHelper.parsePrice(item.supplierTotal);
      item.lineItems.forEach((lineItem) => {
        total += UtilityHelper.parsePrice(lineItem.supplierTotal);
      });
      if (item.product.supplierCutFeeQuantity) {
        total += UtilityHelper.parsePrice(item.product.supplierCutFee + '');
      }
      if (item.product.supplierRollFeeQuantity) {
        total += UtilityHelper.parsePrice(item.product.supplierRollFee + '');
      }
    });
    return UtilityHelper.formatPrice(total);
  }

  getSupplierGst() {
    let total = UtilityHelper.parsePrice(this.supplierSubtotalTotal);
    total = total * 0.1;
    return UtilityHelper.formatPrice(total);
  }

  getSupplierTotalWithGst() {
    let total =
      UtilityHelper.parsePrice(this.supplierSubtotalTotal) +
      UtilityHelper.parsePrice(this.supplierGst);
    return UtilityHelper.formatPrice(total);
  }

  getOrderDeliveryQuoteComplete() {
    let allDeliveryQuotesCompleted = true;
    this.stockChecks.forEach((stockCheck: any) => {
      if (!stockCheck.complete && stockCheck.supplier.includes('FLRHUB')) {
        allDeliveryQuotesCompleted = false;
      }
    });
    return allDeliveryQuotesCompleted;
  }

  clearTimeSensitiveData() {
    this.discountCode = '';
    this.discountCodeAmount = '';
    this.setupTotals();
  }
}
