import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { EmailAuthProvider } from 'firebase/auth';
import { first } from 'rxjs/operators';
import { Order } from '../classes/Order';
import { User as AppUser, User } from '../classes/User';
import { DatabaseHelper } from './DatabaseHelper';
import {
  HttpClient,
  HttpParams,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { HttpService } from 'src/app/http.service';

@Injectable()
export class AuthHelper {
  firebaseUser?: firebase.default.User;
  user: AppUser = new User('');
  userLoaded = false;
  justRegistered = false;
  editingOrder: any = null;
  bearerToken: string | null = '';
  private static _instance: AuthHelper;

  password: string = ''; // Used during sign up flow.

  constructor(
    public afAuth: AngularFireAuth,
    private http: HttpClient,
    private httpService: HttpService,
    private databaseHelper: DatabaseHelper,
  ) {
    this.bearerToken = this.getTokenFromStorage();

    AuthHelper._instance = this;
    this.isLoggedIn().then((res) => {
      console.log('user is logged in');
      console.log(res);

      console.log('Getting user details');
      this.getUserDetails();
      this.userLoaded = true;
    });
  }

  static get instance() {
    return this._instance;
  }

  public getEditingOrder() {
    return this.editingOrder;
  }

  public async getUserDetails() {
    try {
      const user = this.httpService.post('/logged-in', {
        responseType: 'json',
        observe: 'response',
      });

      console.log('User details');
      user.subscribe((data) => {
        console.log(data);
        if (data instanceof HttpResponse) {
          console.log(
            'User details in getUserDetails() function of AuthHelper.ts',
          );
          console.log(data.body);
          this.user = new AppUser(data.body.uid);
          this.user.fetch().catch((err) => {
            console.log('Error fetching user details');
            console.log(err);
          });
        }
      });
    } catch (err) {
      // Do something
      console.error(err);
    }
  }
  public async isLoggedIn(): Promise<boolean> {
    return new Promise((res, rej) => {
      return this.httpService
        .post('/logged-in', {
          responseType: 'json',
        })
        .toPromise()
        .then((response) => {
          console.log('Logged in');
          console.log(response);
          this.getUserDetails().then(() => res(true));
        })
        .catch((error) => rej(false));
    });
  }

  public getUserStatus(): Promise<any> {
    return new Promise(async (res, err) => {
      if (this.userLoaded) {
        console.log('User loaded');
        res(this.user.status);
      } else if (await this.isLoggedIn()) {
        console.log('User exists');
        this.user.fetch().then(() => {
          this.userLoaded = true;
          res(this.user.status);
        });
        //await this.afAuth.user.subscribe(async (u) => {
        //  if (u) {
        //    console.log('User is logged in: ' + u.uid);
        //    this.firebaseUser = u;
        //    this.user = new AppUser(this.firebaseUser.uid);
        //    await this.user.fetch().then(() => {
        //      this.userLoaded = true;
        //      res(this.user.status);
        //    });
        //  } else {
        //    console.log('Data 1');
        //    res('');
        //  }
        //});
      } else {
        console.log('Data 1');
        res('');
      }
    });
  }

  public loginUser(email: string, pass: string): Promise<any> {
    return new Promise((res, err) => {
      const params = new HttpParams().set('email', email).set('password', pass);

      return this.httpService
        .post('/login', {
          responseType: 'json',
          params,
          observe: 'response',
        })
        .toPromise()
        .then((response) => {
          if (response instanceof HttpResponse) {
            console.log('access token');
            console.log(response.body.access_token);
            this.bearerToken = response.body.access_token;
            this.setTokenInStorage(response.body.access_token);
            res(response);
          }
        })
        .catch((error) => err(error));
    });
  }

  public async registerUser(
    email: string,
    pass: string,
    user: AppUser,
    userComplete: boolean = false,
  ): Promise<AppUser> {

    this.justRegistered = true;
    //this.firebaseUser = (
    //  await this.afAuth.createUserWithEmailAndPassword(email, pass)
    //).user!;
    const params = new HttpParams().set('email', email).set('password', pass).set('type', user.type);
    this.user = user;
    try {
      const response = await this.httpService
        .post('/register', {
          responseType: 'json',
          params,
          observe: 'response',
        })
        .toPromise();
  
      if (response instanceof HttpResponse) {
        this.user.uid = response.body.uid;
        console.log(response.body);
        //console.log('uid:' + this.user.uid);
        await this.user.save();
        this.userLoaded = true;
        console.log('User created');
      }
    }catch (error) {
      if (error instanceof HttpErrorResponse)
      {
      console.error('Error during registration:', error.error);
      throw error; // Re-throw the error to allow the calling function to handle it
      }
    }
      return this.user;

    //this.user.status = userComplete ? 'Pending' : 'Incomplete';

  }

  public async updateUser(
    user: AppUser,
  ): Promise<AppUser> {

    this.justRegistered = true;
    const params = new HttpParams().set('uid', user.uid).set('firstName', user.firstName).set('lastName', user.lastName).set('mobileNumber', user.mobileNumber).set('driversLicenceNumber', user.driversLicenceNumber).set('homeAddress', user.homeAddress);
    this.user = user;
    try {
      const response = await this.httpService
        .post('/update-user', {
          responseType: 'json',
          params,
          observe: 'response',
        })
        .toPromise();
  
      if (response instanceof HttpResponse) {

        this.userLoaded = true;
        console.log('User Updated');
      }
    }catch (error) {
      if (error instanceof HttpErrorResponse)
      {
      throw error; // Re-throw the error to allow the calling function to handle it
      }
    }
      return this.user;
  }
  
  public async updateUserCompany(
    user: AppUser,
  ): Promise<AppUser> {

    this.justRegistered = true;
    const params = new HttpParams().set('uid', user.uid).set('companyName', user.companyName).set('insuranceUrl', user.insuranceUrl).set('licenceUrl', user.licenceUrl).set('companyAddress', user.companyAddress).set('abn', user.abn).set('companyBankrupcy', user.companyBankrupcy);
    this.user = user;
    try {
      const response = await this.httpService
        .post('/update-user-company', {
          responseType: 'json',
          params,
          observe: 'response',
        })
        .toPromise();
  
      if (response instanceof HttpResponse) {

        this.userLoaded = true;
        console.log('User Updated');
      }
    }catch (error) {
      if (error instanceof HttpErrorResponse)
      {
      throw error; // Re-throw the error to allow the calling function to handle it
      }
    }
      return this.user;
  }

  public async deleteUser() {
    return this.user!.delete();
  }

  public async changeEmail(newEmail: string) {
    return this.firebaseUser!.updateEmail(newEmail);
  }

  public sendEmailVerification() {
    return this.firebaseUser!.sendEmailVerification();
  }

  public sendPasswordResetEmail(email: string) {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  public resetPassword(code: string, newPass: string) {
    return this.afAuth.confirmPasswordReset(code, newPass);
  }

  public verifyPasswordCode(code: string) {
    return this.afAuth.verifyPasswordResetCode(code);
  }

  public verifyEmailAddress(code: string) {
    return this.afAuth.applyActionCode(code);
  }

  public updatePassword(currentPassword: string, newPassword: string) {
    return new Promise((res, rej) => {
      this.firebaseUser!.reauthenticateWithCredential(
        EmailAuthProvider.credential(this.user!.email, currentPassword),
      )
        .then((val) => {
          this.firebaseUser!.updatePassword(newPassword)
            .then(() => {
              console.log('Password updated');
              res({ success: true });
            })
            .catch((err) => {
              rej(this.getErrorForCode(err.code, true));
            });
        })
        .catch((err) => {
          rej(this.getErrorForCode(err.code, true));
        });
    });
  }

  public async logout() {
    try {
      await this.httpService.post('/logout', {}).toPromise();
      localStorage.removeItem('token');
    } catch (err) {
      console.error(err);
    }
  }

  public getError(code: any) {
    const codes: any = {
      401: 'The email or password is incorrect.',
      404: 'The email or password is incorrect.',
      422: 'An internal error has occurred. Please try again.',
      500: 'An internal error has occurred. Please try again later.',
    };
    return codes[code] ?? 'An error has occurred. Please try again.';
  }

  public getTokenFromStorage() {
    return localStorage.getItem('token');
  }

  public setTokenInStorage(token: string) {
    localStorage.setItem('token', token);
  }

  public getErrorForCode(code: string, passwordOnly = false) {
    console.log(code);
    const codes: any = {
      'auth/wrong-password':
        'The ' +
        (passwordOnly ? 'current' : 'email or') +
        ' password is incorrect.',
      'auth/email-already-exists':
        'The provided email is already in use by an existing user. Each user must have a unique email.',
      'auth/invalid-password':
        'The provided value for the password user property is invalid. It must be a string with at least six characters.',
      'auth/credential-already-in-use':
        'This credential is already associated with a different user account.',
      'auth/requires-recent-login':
        'This operation is sensitive and requires recent authentication. Log in again before retrying this request.',
      'auth/email-already-in-use':
        'The email address is already in use by another account.',
      'auth/internal-error':
        'An internal error has occurred. Please try again.',
      'auth/invalid-verification-code':
        'The SMS verification code used to create the phone auth credential is invalid. Please resend the verification code sms and be sure to use the verification code provided by the user.',
      'auth/invalid-email': 'The email address is invalid.',
      'auth/invalid-phone-number':
        'The format of the phone number provided is incorrect. Please enter the phone number in a format that can be parsed into E.164 format. E.164 phone numbers are written in the format [+][country code][subscriber number including area code].',
      'auth/timeout': 'The operation has timed out.',
      'auth/too-many-requests':
        'We have blocked all requests from this device due to unusual activity. Try again later.',
      'auth/user-disabled':
        'The user account has been disabled by an administrator.',
      'auth/weak-password': 'The password must be 6 characters long or more.',
      'auth/user-not-found': 'The email or password is incorrect.',
    };
    return codes[code];
  }
}
