<div class="page-container page-job-board">
  <app-menu active="Job Board"></app-menu>
  <div class="content-container">
    <app-header showBackButton="true" title="Job Board"></app-header>
    <div class="content">
      <div class="left-content">
        <button class="small-button hide-phone" (click)="back()">Back</button>
        <div class="job-name-row">
          <h2 class="job-name">{{ job.jobName }}</h2>
        </div>
        <p class="job-date">
          Posted {{ utilityHelper.formatDate(job.submittedDate) }}
        </p>
        <div class="job-description">{{ job.jobDescription }}</div>
        <div class="item-container">
          <div class="item">
            <h3 class="title">Job Location</h3>
            <!-- <div class="description">{{job.suburb}}, {{job.state}}</div> -->
            <p class="description">{{ job.jobLocation }}</p>
          </div>
          <div class="item">
            <h3 class="title">Job Size</h3>
            <p class="description">{{ job.jobSize }}</p>
          </div>
          <div class="item">
            <h3 class="title">Material Type</h3>
            <p class="description">{{ job.materialType.join(", ") }}</p>
          </div>
        </div>
        <div class="item-container">
          <div class="item">
            <h3 class="title">Construction Period</h3>
            <p class="description">{{ job.constructionPeriod }}</p>
          </div>
          <div class="item">
            <h3 class="title">Working Days</h3>
            <p class="description">{{ job.workingDays }}</p>
          </div>
        </div>
        <div *ngIf="!job.siteVisitRequired">
          <div class="separator"></div>
          <h3 class="section-title">Materials Supplied</h3>
          <div class="materials-container hide-scrollbar">
            <div
              class="cart-item"
              *ngFor="let jobItems of job.products; let i = index"
            >
              <img
                class="cart-item-image"
                [src]="jobItems.product.thumbnailImageUrl"
              />
              <div class="cart-item-container">
                <div class="item-name">
                  {{ jobItems.product.productCollection }}
                </div>
                <p
                  class="category"
                  [innerHTML]="
                    jobItems.product.brand +
                      (jobItems.product.subBrand
                        ? ' - ' + jobItems.product.subBrand
                        : '') +
                      ' | ' +
                      jobItems.product.productCategory | safeHtml
                  "
                ></p>
                <p class="design">
                  {{ jobItems.product.productDesign
                  }}{{
                    jobItems.product.variations &&
                    jobItems.product.variations["Size"]
                      ? " - " + jobItems.product.variations["Size"].option
                      : ""
                  }}
                </p>
                <div class="total">{{ jobItems.quantity }}m�</div>
              </div>
            </div>
          </div>
          <div
            class="add-to-cart-container"
            *ngIf="user.type === 'Builder' || user.type === 'Admin'"
          >
            <button
              class="secondary-button dark-button regular-font add-to-cart-button"
              [ngClass]="{ loading: addingToCart }"
              (click)="addToCart()"
            >
              Add Products To Cart
            </button>
          </div>
        </div>
        <div class="separator"></div>
        <div *ngIf="!job.siteVisitRequired" c>
          <h3 class="section-title">Specification Drawings</h3>
          <div lass="files-container">
            <div
              class="secondary-button small-button"
              *ngFor="let item of job.specificationDrawings"
              (click)="openUrl(item.url)"
            >
              <img
                class="icon"
                src="../../../assets/images/download_icon.png"
              />{{ item.name }}
            </div>
          </div>
        </div>
        <h3 class="section-title">Other Documents</h3>
        <div class="files-container">
          <div
            class="secondary-button small-button"
            *ngFor="let item of job.otherDocuments"
            (click)="openUrl(item.url)"
          >
            <img
              class="icon"
              src="../../../assets/images/download_icon.png"
            />{{ item.name }}
          </div>
        </div>
        <div class="separator"></div>
        <h3 class="section-title">Scope of Works</h3>
        <ol class="numbered-list">
          <li
            value="{{ i + 1 }}"
            class="numbered-list-item"
            *ngFor="let item of job.scopeOfWorks; let i = index"
          >
            <!-- <div class="number">{{ i + 1 }}</div> -->
            <p class="description">{{ item }}</p>
          </li>
        </ol>
        <div class="separator"></div>
        <h3 class="section-title">Special Conditions</h3>
        <ol class="numbered-list">
          <li
            value="{{ i + 1 }}"
            class="numbered-list-item"
            *ngFor="let item of job.specialConditions; let i = index"
          >
            <!-- <div class="number">{{ i + 1 }}</div> -->
            <p class="description">{{ item }}</p>
          </li>
        </ol>
        <div *ngIf="job.siteVisitRequired">
          <div class="separator"></div>
          <h3 class="section-title">Site Visit Information</h3>
          <p class="description">
            {{
              job.siteVisitType === "Contact"
                ? "Contact builder to arrange a time"
                : "Only certain times allowed"
            }}
          </p>
          <p *ngIf="job.siteVisitInfo.length" class="description">
            {{ job.siteVisitInfo }}
          </p>
          <p *ngIf="job.siteContactName.length">
            {{ "Site contact name: "+job.siteContactName }}
          </p>
          <p *ngIf="job.siteContactNumber.length">
            {{ "Site contact number: "+job.siteContactNumber }}
          </p>
        </div>
      </div>
      <div class="right-content">
        <button
          class="primary-button regular-font"
          [ngClass]="{ loading: loadingQuoteJob }"
          (click)="quoteJob()"
        >
          {{jobQuote.status === '' ? 'Quote Job' : 'Quote Job'}}
        </button>
        <div class="item-container">
          <div class="item">
            <h3 class="title">Closing Date</h3>
            <p class="description">
              {{ utilityHelper.formatDate(job.closingDate) }}
            </p>
          </div>
          <div class="item">
            <h3 class="title">Closing Time</h3>
            <p class="description">
              {{ utilityHelper.formatTime(job.closingTime) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
