<div class="page-container page-job-board">
  <app-menu active="Job Board"></app-menu>
  <div class="content-container">
    <app-header title="Job Board"></app-header>
    <div class="inner-content">
      <div class="job-board-status-head">
        <div class="statuses">
          <div class="selected-mobile" (click)="toggleSelectMobile()">
            <ul>
              <li class="active" *ngIf="activeTab === 'Active Job Post'">
                <span class="text">Active Job Posts</span><span class="count">3</span>
              </li>
              <li class="active" *ngIf="activeTab === 'My Drafts'">
                <span class="text">My Drafts</span><span class="count">1</span>
              </li>
              <li class="active" *ngIf="activeTab === 'Closed Job Posts'">
                <span class="text">Closed Job Posts</span><span class="count empty"></span>
              </li>
            </ul>
            <img src="../../../assets/images/down_arrow.png" width="12" alt="arrow" />
          </div><!-- selected-mobile -->
          <ul class="main-tab-menu">
            <li [ngClass]="{active: activeTab === 'Active Job Post'}" (click)="activeTab = 'Active Job Post'; removeSelectMobile()">
              <span class="text">Active Job Posts</span><span class="count">3</span>
            </li>
            <li [ngClass]="{active: activeTab === 'My Drafts'}" (click)="activeTab = 'My Drafts'; removeSelectMobile()">
              <span class="text">My Drafts</span><span class="count">1</span>
            </li>
            <li [ngClass]="{active: activeTab === 'Closed Job Posts'}" (click)="activeTab = 'Closed Job Posts'; removeSelectMobile()">
              <span class="text">Closed Job Posts</span><span class="count empty"></span>
            </li>
          </ul>
        </div>
        <div class="post-new-job">
          <div class="secondary-button" (click)="openCreateJobDialog()">Post New Job</div>
        </div>
      </div><!-- job-board-status-head -->
      <div class="job-board-tabs">

        <div class="job-board-tab" *ngIf="activeTab === 'Active Job Post'">
          <div class="job-card" *ngFor="let item of [].constructor(5); let i = index">
            <div class="inner-card">
              <div class="card-head"><span class="color-orange heading">Brighton Office</span> <span class="posted small">Posted yesterday</span></div>

              <div class="card-boxes">
                <div class="box counter">
                  <div class="context small">Quotes submitted</div>
                  <div class="number color-orange">3</div>
                </div><!-- box -->
                <div class="box counter">
                  <div class="context small">Job Accepted</div>
                  <div class="number color-orange">7</div>
                </div><!-- box -->
                <div class="box box-spacer">&nbsp;</div>
                <div class="box info">
                  <div class="context small">Closing Date</div>
                  <div class="date">20 May 2023</div>
                </div><!-- box -->
                <div class="box info">
                  <div class="context small">Closing Time</div>
                  <div class="date">3:00 PM</div>
                </div><!-- box -->
              </div><!-- card boxes -->

              <div class="card-footer">
                <div class="category">Vinyl Flooring</div>
                <div class="location"><img src="../../../assets/images/location.png" width="12" height="17" alt="Location"/> Brighton, SA</div>
              </div><!-- card-footer -->

              <div class="card-action">
                <span class="dots"><span></span></span>
                <ul class="submenu">
                  <!-- <li>Edit</li> -->
                  <li>Remove</li>
                </ul>
              </div>

            </div>
          </div><!-- job-card -->
        </div><!-- job-board-tab -->

        <div class="job-board-tab" *ngIf="activeTab === 'My Drafts'">
          <div class="job-card" *ngFor="let item of [].constructor(2); let i = index">
            <div class="inner-card">
              <div class="card-head"><span class="color-orange heading">Draft Brighton Office</span> <span class="posted small">Posted yesterday</span></div>

              <div class="card-boxes">
                <div class="box counter">
                  <div class="context small">Quotes submitted</div>
                  <div class="number color-orange">-</div>
                </div><!-- box -->
                <div class="box counter">
                  <div class="context small">Job Accepted</div>
                  <div class="number color-orange">-</div>
                </div><!-- box -->
                <div class="box box-spacer">&nbsp;</div>
                <div class="box info">
                  <div class="context small">Closing Date</div>
                  <div class="date">20 May 2023</div>
                </div><!-- box -->
                <div class="box info">
                  <div class="context small">Closing Time</div>
                  <div class="date">3:00 PM</div>
                </div><!-- box -->
              </div><!-- card boxes -->

              <div class="card-footer">
                <div class="category">Vinyl Flooring</div>
                <div class="location"><img src="../../../assets/images/location.png" width="12" height="17" alt="Location"/> Brighton, SA</div>
              </div><!-- card-footer -->

              <div class="card-action">
                <span class="dots"><span></span></span>
                <ul class="submenu">
                  <li>Edit</li>
                  <li>Remove</li>
                </ul>
              </div>

            </div>
          </div><!-- job-card -->
        </div><!-- job-board-tab -->

        <div class="job-board-tab" *ngIf="activeTab === 'Closed Job Posts'">
          <div class="job-card" *ngFor="let item of [].constructor(3); let i = index">
            <div class="inner-card">
              <div class="card-head"><span class="color-orange heading">Closed Office Name</span> <span class="posted small">Posted yesterday</span></div>

              <div class="card-boxes">
                <div class="box counter">
                  <div class="context small">Quotes submitted</div>
                  <div class="number color-orange">-</div>
                </div><!-- box -->
                <div class="box counter">
                  <div class="context small">Job Accepted</div>
                  <div class="number color-orange">-</div>
                </div><!-- box -->
                <div class="box box-spacer">&nbsp;</div>
                <div class="box info">
                  <div class="context small">Closing Date</div>
                  <div class="date">20 May 2023</div>
                </div><!-- box -->
                <div class="box info">
                  <div class="context small">Closing Time</div>
                  <div class="date">3:00 PM</div>
                </div><!-- box -->
              </div><!-- card boxes -->

              <div class="card-footer">
                <div class="category">Vinyl Flooring</div>
                <div class="location"><img src="../../../assets/images/location.png" width="12" height="17" alt="Location"/> Brighton, SA</div>
              </div><!-- card-footer -->

              <div class="card-action">
                <span class="dots"><span></span></span>
                <ul class="submenu">
                  <li>Edit</li>
                  <li>Remove</li>
                </ul>
              </div>

            </div>
          </div><!-- job-card -->
        </div><!-- job-board-tab -->

      </div><!-- job-board-tabs -->
    </div>
  </div>
</div>
