import { UtilityHelper } from '../helpers/UtilityHelper';
import { Product } from './Product';

export class CartItem {
  product: Product;

  lineItems: CartItem[];

  id: string;
  quantity: number;
  total: string;
  supplierTotal: string;
  stockAvaliablity: string;
  dispatchDate: string;
  deliveryDate: string;
  supplierTotalWithGst: string;
  batches: any[];

  constructor() {
    this.id = '';
    this.product = new Product();
    this.quantity = 0;
    this.total = '';
    this.supplierTotal = '';
    this.stockAvaliablity = '';
    this.dispatchDate = '';
    this.supplierTotalWithGst = '';
    this.lineItems = [];
    this.batches = [];
    this.deliveryDate = '';
  }

  static calculateTotal(cartItem: CartItem) {
    console.log('CALCULATING TOTAL');
    if (cartItem.product.unitType === 'LM') {
      cartItem.total = UtilityHelper.formatPrice(
        cartItem.quantity *
          UtilityHelper.parsePrice(
            Product.calculateRetailCost(cartItem.product, cartItem.quantity),
          ) +
          this.calculateLmCutPrice(cartItem.product),
      );
    } else if (cartItem.product.productCollection === 'Weld Rod') {
      cartItem.total = UtilityHelper.formatPrice(
        cartItem.quantity *
          UtilityHelper.parsePrice(cartItem.product.retailPrice),
      );
    } else if (
      cartItem.product.unitType === 'Roll' &&
      cartItem.quantity &&
      cartItem.quantity <
        (+cartItem.product.width * +cartItem.product.length) / 10000
    ) {
      console.log('CALCULATING TOTAL FOR ROLL');
      cartItem.total = UtilityHelper.formatPrice(
        4 * cartItem.quantity +
          cartItem.quantity *
            UtilityHelper.parsePrice(
              Product.calculateRetailCost(cartItem.product, cartItem.quantity),
            ),
      );
      console.log('CALCULATING TOTAL got = ' + cartItem.total);
    } else {
      cartItem.total = UtilityHelper.formatPrice(
        cartItem.quantity *
          UtilityHelper.parsePrice(
            Product.calculateRetailCost(cartItem.product, cartItem.quantity),
          ),
      );
    }
  }

  static calculateLmCutPrice(product: Product) {
    var totalCutPrice = 0;
    var supplierCutFee = 0;
    var supplierRollFee = 0;
    var supplierCutFeeQuantity = 0;
    var supplierRollFeeQuantity = 0;
    product.cuts.forEach((cut) => {
      if (cut < 30 && cut > 0) {
        totalCutPrice = totalCutPrice + cut * 11 + 16.5;
        supplierCutFee = supplierCutFee + cut * 9;
        supplierRollFee = supplierRollFee + 13.5;
        supplierCutFeeQuantity = supplierCutFeeQuantity + cut;
        supplierRollFeeQuantity = supplierRollFeeQuantity + 1;
      }
    });
    product.cutPrice = totalCutPrice;
    product.supplierCutFee = supplierCutFee.toFixed(2);
    product.supplierRollFee = supplierRollFee.toFixed(2);
    product.supplierCutFeeQuantity = supplierCutFeeQuantity;
    product.supplierRollFeeQuantity = supplierRollFeeQuantity;
    return totalCutPrice;
  }

  static calculateSupplierTotal(cartItem: CartItem) {
    cartItem.supplierTotal = UtilityHelper.formatPrice(
      UtilityHelper.parsePrice(
        Product.calculateSupplierCost(cartItem.product, cartItem.quantity),
      ) * cartItem.quantity,
    );
    console.log(
      'SUPPLIER TOTAL PRICE #1 = ' + cartItem.product.supplierUnitPrice,
    );
    console.log('SUPPLIER TOTAL PRICE #2 = ' + cartItem.quantity);
    console.log(
      'SUPPLIER TOTAL PRICE #3 = ' +
        UtilityHelper.formatPrice(
          UtilityHelper.parsePrice(
            Product.calculateSupplierCost(cartItem.product, cartItem.quantity),
          ) * cartItem.quantity,
        ),
    );
    console.log('SUPPLIER TOTAL PRICE #4 = ' + cartItem.supplierTotal);
  }

  static calculateSupplierTotalWithGst(cartItem: CartItem) {
    cartItem.supplierTotalWithGst = UtilityHelper.formatPrice(
      UtilityHelper.parsePrice(cartItem.supplierTotal) * 1.1,
    );
  }
}
