import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';
import { ProductService } from 'src/app/services/product.service';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss'],
})
export class FiltersComponent implements OnInit {
  isJobBoard = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog,
    private productService: ProductService,
  ) {
    if (data && data.isJobBoard) {
      this.isJobBoard = data.isJobBoard;
    }
  }

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView('Filters Modal');
  }

  filterIsSelected() {
    for (let key in this.filters) {
      for (let item in this.filters[key].items)
        if (this.filters[key].items[item].selected) {
          return true;
        }
    }
    return false;
  }

  getSubItems(items: any) {
    var count = items.length;
    items.forEach((item: any) => {
      if (item.inputField && item.selected) {
        count = count + 2;
      }
    });
    return count;
  }

  clearAllFilter() {
    for (let key in this.filters) {
      for (let item in this.filters[key].items)
        this.filters[key].items[item].selected = false;
    }
    this.close();
  }

  getFilterHeight(item: any) {
    if (item.inputField && item.selected) {
      return 90;
    } else if (item.subCategories && item.selected) {
      return Object.keys(item.subCategories).length * 32 + 40;
    } else {
      return 40;
    }
  }

  getSubItemsHeight(items: any) {
    var count = items.length * 40;
    if (items) {
      items.forEach((item: any) => {
        if (item.inputField && item.selected) {
          count = count + 2 * 40;
        }
        if (item.selected && item.subCategories) {
          item.subCategories.forEach((subbrand: any) => {
            count = count + 1 * 32;
          });
        }
      });
    }
    return count;
  }

  filterSelected(filter: string, item: any) {
    const newFilterState = !item.selected;
    AnalyticsHelper.instance.logEvent('filter_clicked', {
      selected: newFilterState,
      filter: filter,
    });
    this.processFilter(filter);
    item.selected = newFilterState;
  }

  subbrandFilterSelected(filter: string, item: any, subbrand: any) {
    const newFilterState = !subbrand.selected;
    AnalyticsHelper.instance.logEvent('subbrand_filter_clicked', {
      selected: newFilterState,
      filter: filter,
      subbrand: subbrand.name,
    });
    var index = item.subCategories.findIndex(
      (foundSubbrand: any) => foundSubbrand.name === subbrand.name,
    );
    item.subCategories[index].selected = newFilterState;
  }

  stockRequiredUpdated(filter: string, item: any) {
    const newFilterState = true;
    AnalyticsHelper.instance.logEvent('filter_input_updated', {
      inputData: item.inputField.inputData,
      filter: filter,
    });
    item.selected = newFilterState;
  }

  processFilter(filter: string) {
    if (this.filters[filter].singleSelectionOnly) {
      this.clearAllFiltersForGroup(filter);
    }
  }

  clearAllFiltersForGroup(group: string) {
    for (let item in this.filters[group].items)
      this.filters[group].items[item].selected = false;
  }

  keysForObject(object: any) {
    return Object.keys(object);
  }

  close() {
    DatabaseHelper.instance.dataCallbacks.dataCallback2(this.filters);
    this.dialog.getDialogById('filters-modal')?.addPanelClass('animate-out');
    setTimeout(() => {
      this.dialog.getDialogById('filters-modal')?.close();
    }, 500);
  }

  get filters() {
    //  return await this.productService.getFilters().toPromise();
    return DatabaseHelper.instance.marketPlaceFilters;
    // return this.isJobBoard ? DatabaseHelper.instance.jobBoardFilters : DatabaseHelper.instance.marketPlaceFilters;
  }
}
