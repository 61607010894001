import { Component, OnInit } from '@angular/core';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';

@Component({
  selector: 'app-account-pending',
  templateUrl: './account-pending.component.html',
  styleUrls: ['./account-pending.component.scss']
})
export class AccountPendingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView("Account Pending");
  }

}
