import { AfterViewInit, Component, Inject, NgZone, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ProductCollection } from 'src/app/classes/ProductCollection';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';
import { UtilityHelper } from 'src/app/helpers/UtilityHelper';
import { FiltersComponent } from 'src/app/modals/filters/filters.component';
import { SearchComponent } from 'src/app/modals/search/search.component';
import { Product } from '../../classes/Product';

import { EstimationStepsProductComponent } from '../estimation-steps-product/estimation-steps-product.component';

@Component({
  selector: 'app-estimation-steps-products',
  templateUrl: './estimation-steps-products.component.html',
  styleUrls: ['./estimation-steps-products.component.scss']
})
export class EstimationStepsProductsComponent implements OnInit, AfterViewInit {
  
    hideFilters = false;
  
    searchTerm: string = '';
    productCollections: ProductCollection[] | any = [];
    allProductCollections: ProductCollection[] | any = [];
    loading = true;
    allLoaded = false;
    perPage = 12;
    page = 0;
    timeout: any;
    pageHasScrolled: boolean = false;
  
    pageloaded = false;
  
    localStockFilterApplied = false;
  
    constructor(private router: Router, private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data : any, private zone: NgZone) {
      if (this.router.getCurrentNavigation()?.extras && this.router.getCurrentNavigation()?.extras.state) {
        if (this.router.getCurrentNavigation()?.extras.state!['searchTerm']) {
          this.searchTerm = this.router.getCurrentNavigation()?.extras.state!['searchTerm'];
        }
      }
    }
  
    ngOnInit(): void {
      AnalyticsHelper.instance.pageView("Estimation Select Product Modal");
      this.loadProducts();
      this.loadFilters();
    }

  
    viewProduct(collection: ProductCollection) {
      /* if(this.isReplacingProduct())
      {
        DatabaseHelper.instance.dataCallbacks.dataCallback({page: 'product-details',  productCollection: collection });
      }
      else
      {
        this.router.navigateByUrl('/product-details', { state: { productCollection: collection } });
      } */
      this.dialog.open(EstimationStepsProductComponent, {
        height: this.isPhone ? '100vh' : 'calc(100vh - 30px)',
        width: this.isPhone ? '100vw' : 'calc(100vw - 60px)',
        maxWidth: '100vw',
        maxHeight: '-webkit-fill-available',
        position: { bottom: '0', left: this.isPhone ? '0' : '30px' },
        enterAnimationDuration: '0',
        panelClass: ['profile-modal', 'no-padding', 'no-animation'],
        id: 'estimation-steps-view-product',
        data: {
          productCollection: collection
        }
      });
  
      DatabaseHelper.instance.dataCallbacks.dataCallback = ((val: any) => {
        if (val.page === "dismissProducts") {
          this.back();
          this.dialog.getDialogById("estimation-steps-view-product")?.close();
        }
      });
  
    }
  
    back(){
      this.dialog.getDialogById('estimation-steps-product')?.close();
    }

    
  
    isReplacingProduct() {
      if (this.data && !this.data.choosingProduct) {
        return true;
      }
      else {
        return false;
      }
    }
  
    isSelectingProduct() {
      if (this.data && this.data.choosingProduct) {
        return true;
      }
      else {
        return false;
      }
    }

  
    ngAfterViewInit() {
      document.getElementById('scrollContent')?.addEventListener('scroll', (event) => {
        console.log("Scroll!");
        let element: HTMLElement = event.target as HTMLElement;
        if (element.scrollHeight - element.scrollTop - element.clientHeight <= 50) {
          console.log("We are deep down, time to load more. " + this.allLoaded);
          if (!this.loading && !this.allLoaded) {
            this.page++;
            this.loadProducts();
          }
        }
      });
    }
  
    searchModal() {
      DatabaseHelper.instance.dataCallbacks.dataCallback2 = (search: string) => {
        this.searchTerm = search;
        this.searchProducts();
      }
      this.dialog.open(SearchComponent, {
        disableClose: true,
        height: '100vh',
        width: '100vh',
        maxWidth: '100vw',
        maxHeight: '-webkit-fill-available',
        position: { bottom: '0', left: '0' },
        enterAnimationDuration: '0ms',
        panelClass: ['profile-modal', 'grey-bg'],
        id: 'search-modal',
        autoFocus: false,
        data: { searchTerm: this.searchTerm }
      });
    }
  
    searchProducts() {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        clearTimeout(this.timeout);
        if (!this.loading) {
          AnalyticsHelper.instance.logEvent("search_product", { search_term: this.searchTerm });
          this.productCollections = null;
          this.productCollections = [];
          this.allLoaded = false;
          this.loadProducts();
        }
      }, 300);
    }
  
    filterProductCollections()
    {
      const filtersToSend: any = {};
      Object.keys(this.filters).forEach(key => {
        filtersToSend[key] = this.filters[key].items.filter(el => el.selected).map(el => el.name);
      });
  
      let maximumStock = 0;
      if(this.filters['Stock'].items.length > 0)
      {
        this.filters['Stock'].items.forEach(filter => {
          if(filter.selected && filter.inputField)
          {
            maximumStock = +filter.inputField.dataEntered;
          }
        });
      }
      this.productCollections = [];
      this.allProductCollections.forEach((productCollection : any) => {
        if (
          ((filtersToSend["Brands"].length > 0 && filtersToSend["Brands"].includes(productCollection.brand)) || filtersToSend["Brands"].length === 0) &&
            ((filtersToSend["Product Type"].length > 0 && filtersToSend["Product Type"].includes(productCollection.category)) || filtersToSend["Product Type"].length === 0) &&
            ((filtersToSend["Colour"].length > 0 && filtersToSend["Colour"].some((e: string) => productCollection.colourOptions && productCollection.colourOptions.includes(e))) || filtersToSend["Colour"].length === 0) &&
            ((filtersToSend["Industries"].length > 0 && filtersToSend["Industries"].some((e: string) => productCollection.industryOptions && productCollection.industryOptions.includes(e))) || filtersToSend["Industries"].length === 0) &&
            // ((filtersToSend["Clearance"].length > 0 && filtersToSend["Clearance"].some((e: string) => productCollection.clearanceOptions && productCollection.clearanceOptions.includes(e))) || filtersToSend["Clearance"].length === 0) &&
            ((filtersToSend["Shop by price"].length > 0 && filtersToSend["Shop by price"].some((e: string) => productCollection.priceRangeOptions && productCollection.priceRangeOptions.includes(e))) || filtersToSend["Shop by price"].length === 0) &&
            ((filtersToSend["Stock"].length > 0 && filtersToSend["Stock"].some((e: string) => productCollection.stockOptions && productCollection.stockOptions.includes(e))) ||
             filtersToSend["Stock"].length === 0 ||
            ((filtersToSend["Stock"].length > 0 && filtersToSend["Stock"].includes('In stock') && productCollection.maximumStockAvailable > maximumStock - 1) ||
            ((filtersToSend["Stock"].length > 0 && filtersToSend["Stock"].includes('Out of stock') && productCollection.minimumStockAvailable <= 0)))))
                  {
                    if(this.searchTerm)
                    {
                      if(JSON.stringify(productCollection).toLowerCase().includes(this.searchTerm.toLowerCase()))
                      {
                        this.productCollections.push(productCollection);
                      }
                    }
                    else
                    {
                      this.productCollections.push(productCollection);
                    }
                  }
      });
    }
  
    async loadProducts(reload = false) {
      if(this.allProductCollections.length <= 0)
      {
        console.log("Loading products now");
        this.loading = true;
        await DatabaseHelper.instance.listProductCollections().then(val => {
          console.log("RESULT NOW!");
            this.allProductCollections = val.products;
            this.allLoaded = true;
            this.loading = false;
            this.checkForLargeViews();
        }).catch(err =>{
          console.log("AN error = "+JSON.stringify(err));
        });
      }
  
      this.filterProductCollections();
  
      // this.localStockFilterApplied = false;
      // if (reload) {
      //   this.pageHasScrolled = false;
      //   this.allLoaded = false;
      //   this.productCollections = null;
      //   this.productCollections = [];
      // }
      // let lastDocumentId = '';
      // let lastStock = 0;
      // // if (this.productCollections.length > 0) {
      // //   lastDocumentId = this.productCollections[this.productCollections.length - 1].collectionName;
      // //   lastStock = this.productCollections[this.productCollections.length - 1].maximumStockAvailable;
      // // }
      // const filtersToSend: any = {};
      // Object.keys(this.filters).forEach(key => {
      //   filtersToSend[key] = this.filters[key].items.filter(el => el.selected).map(el => el.name);
      //     // filtersToSend[key] = this.filters[key].items.filter(el => el.selected).map(el => el.inputField?.dataEntered ? el.name+':'+el.inputField?.dataEntered : el.name);
      // });
      // console.log("Filters = "+JSON.stringify(filtersToSend));
  
      // let maximumStock = 0;
      // // if(this.filters['Stock'].items.length > 0)
      // // {
      // //   this.filters['Stock'].items.forEach(filter => {
      // //     if(filter.selected && filter.inputField)
      // //     {
      // //       maximumStock = +filter.inputField.dataEntered;
      // //     }
      // //   });
      // // }
  
      // if (!this.searchTerm || this.searchTerm === '') {
      //   DatabaseHelper.instance.listProductCollections(this.perPage, lastDocumentId, filtersToSend, lastStock, maximumStock).then(val => {
      //     console.log("All loaded now! NOT Got products length = "+val.products.length+" vs "+this.perPage);
      //     // val.products.forEach((loopProduct: any) => {
      //     //   if(this.checkProductCollectionExists(loopProduct))
      //     //   {
      //     //     console.log("Product already exists in the array so we're done loading");
      //     //     this.allLoaded = true;
      //     //   } 
      //     //   else
      //     //   {
      //     //     this.productCollections.push(loopProduct);
      //     //   }
      //     // });)
      //     // this.zone.run(() => {
      //       this.allProductCollections = val.products;
      //       this.productCollections = val.products;
      //       this.allLoaded = true;
      //       // if (val.products.length < this.perPage) {
      //       //   this.allLoaded = true;
      //       // }
      //       this.loading = false;
      //       this.checkForLargeViews();
      //     // });
      //   });
      // } else {
      //   if ((filtersToSend["Stock"].includes("In stock") || filtersToSend["Stock"].includes("Out of stock"))) {
      //     this.localStockFilterApplied = true;
      //   }
      //   DatabaseHelper.instance.searchProductCollections(this.perPage, lastDocumentId, UtilityHelper.capitalizeFirstLetter(this.searchTerm), filtersToSend).then(val => {
      //     // val.products.forEach((loopProduct: any) => {
      //     //   if(this.checkProductCollectionExists(loopProduct))
      //     //   {
      //     //     console.log("Product already exists in the array so we're done loading");
      //     //     this.allLoaded = true;
      //     //   }  
      //     //   else
      //     //   {
      //     //     this.productCollections.push(loopProduct);
      //     //   }
      //     // });
      //     this.productCollections = this.productCollections.concat(val.products);
      //     console.log(this.productCollections);
      //     if (val.products.length < this.perPage) {
      //       this.allLoaded = true;
      //     }
      //     this.loading = false;
      //     this.checkForLargeViews();
      //   });
      // }
    }
  
    checkProductCollectionExists(product: any):boolean {
      return this.productCollections.some((p : any) => p.collectionName === product.collectionName);
    }
  
    checkForLargeViews() {
      setTimeout(() => {
        var x: any = document.getElementById('scrollContent')?.clientHeight;
        var y: any = document.getElementById('scrollContent')?.scrollHeight;
        console.log("document has scroll: ", y > x)
        if (y <= x && !this.pageHasScrolled && this.productCollections.length > 0 && !this.allLoaded || (this.localStockFilterApplied && !this.allLoaded)) {
          //Load more products as the user can't scroll yet
          this.page++;
          this.loadProducts();
        }
        else {
          this.pageHasScrolled = true;
        }
      }, this.localStockFilterApplied && !this.allLoaded ? 50 : 1000) // speed up the check when localStockFilterApplied
    }
  
    filtersModal() {
      DatabaseHelper.instance.dataCallbacks.dataCallback2 = (filters: any) => {
        DatabaseHelper.instance.marketPlaceFilters = filters;
        this.loadProducts(true);
      }
      this.dialog.open(FiltersComponent, {
        disableClose: true,
        height: '100vh',
        width: '100vh',
        maxWidth: '100vw',
        maxHeight: '-webkit-fill-available',
        position: { bottom: '0', left: '0' },
        enterAnimationDuration: '0',
        panelClass: ['profile-modal', 'grey-bg', 'no-scroll'],
        id: 'filters-modal',
      });
    }
  
    getFilteredProducts(productCollection : ProductCollection)
    {
      var products: any[] = [];
      var selectedStockFilter = '';
      var selectedStockFilterValue = '1';
      this.filters['Stock'].items.forEach(filter => {
        if(filter.selected)
        {
          selectedStockFilter = filter.name;
          if(filter.inputField?.dataEntered)
          {
            selectedStockFilterValue = filter.inputField?.dataEntered;
          }
        }
      });
      if(selectedStockFilter !== '')
      {
      // console.log("Filter selected = "+selectedStockFilter + ' ' + selectedStockFilterValue);
      productCollection.products.forEach(product => {
        if(selectedStockFilter === 'Made to order')
        {
          if(product.stockLevel === 'Enquiry Only')
          {
            console.log('Push made to order');
            products.push(product);
          }
        }
        else if(selectedStockFilter === 'In stock')
        {
          // console.log(product.productCode+" Stock = "+product.stockAvailable);
          // console.log("Filter value = "+selectedStockFilterValue);
          if(+product.stockAvailable >= +selectedStockFilterValue && product.stockLevel !== 'Enquiry Only')
          {
            // console.log('In stock = '+ product.stockLevel + product.productCode);
            products.push(product);
          }
        }
        else if(selectedStockFilter === 'Out of stock' && product.stockLevel !== 'Enquiry Only')
        {
          if(+product.stockAvailable >= 0)
          {
            console.log('Out of stock');
            products.push(product);
          }
        }
      });
      productCollection.products = products;
      return productCollection.products;
      }
      else
      {
        return productCollection.products;
      }
    }
  
    loadFilters() {
      if (this.filters['Colour'].items.length === 0) {
        DatabaseHelper.instance.getFilters().then(val => {
          this.filters['Brands'].items = Object.keys(val.filters.brandOptions).map((e: string) => ({ selected: false, name: e, subbrands : val.filters.brandOptions[e], count: 0}));
          this.filters['Colour'].items = val.filters.colourOptions.map((e: string) => ({ selected: false, name: e }));
          this.filters['Product Type'].items = val.filters.productTypes.map((e: string) => ({ selected: false, name: e }));
        });
      }
    }
  
    getSubItems(items : any)
    {
      var count = items.length;
      items.forEach((item:any) => {
        if(item.inputField && item.selected)
        {
          count = count + 2;
        }
      });
      return count;
    }
  
    filterIsSelected() {
      for (let key in this.filters) {
        for (let item in this.filters[key].items)
          if (this.filters[key].items[item].selected) {
            return true;
          }
      }
      return false;
    }
  
    clearAllFilter() {
      for (let key in this.filters) {
        for (let item in this.filters[key].items)
          this.filters[key].items[item].selected = false;
      }
      this.loadProducts(true);
    }
  
    filterSelected(filter: string, item: any) {
      const newFilterState = !item.selected;
      AnalyticsHelper.instance.logEvent("filter_clicked", { selected: newFilterState, filter: filter });
      this.processFilter(filter);
      item.selected = newFilterState;
      this.loadProducts(true);
    }
  
    stockRequiredUpdated(filter: string, item: any) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        clearTimeout(this.timeout);
        if (!this.loading) {
          const newFilterState = true;
          AnalyticsHelper.instance.logEvent("filter_input_updated", { inputData: item.inputField.inputData, filter: filter, });
          item.selected = newFilterState;
          this.loadProducts(true);
        }
      }, 500);
    }
  
    processFilter(filter: string) {
      if (this.filters[filter].singleSelectionOnly) {
        this.clearAllFiltersForGroup(filter);
      }
    }
  
    clearAllFiltersForGroup(group: string) {
      for (let item in this.filters[group].items)
        this.filters[group].items[item].selected = false;
    }
  
    keysForObject(object: any) {
      return Object.keys(object);
    }
  
    getPrice(collection: ProductCollection) {
      return UtilityHelper.formatPrice(collection.retailPrice);
    }
  
    getProductsFilteredWithStockAndSearchTerm() {
      let selectedStockFilter = "";
      let selectedStockFilterValue = "1";
      this.filters['Stock'].items.forEach(filter => {
        if(filter.selected)
        {
          selectedStockFilter = filter.name;
          if(filter.inputField?.dataEntered)
          {
            selectedStockFilterValue = filter.inputField?.dataEntered;
          }
        }
      });
      return this.productCollections.filter((collection: ProductCollection) => {
       //   if ((collection.collectionName.toUpperCase().startsWith(this.searchTerm.toUpperCase()) || collection.collectionName.toUpperCase().includes(this.searchTerm.toUpperCase()))) {
          if (selectedStockFilter === "In stock") {
            return collection.maximumStockAvailable >= +selectedStockFilterValue;
          } else if (selectedStockFilter === "Out of stock") {
            return collection.maximumStockAvailable === 0;
          }
        // }
        return false;
      });
    }
  
    get isPhone() {
      return UtilityHelper.isPhone();
    }
  
    get filters() {
      return DatabaseHelper.instance.marketPlaceFilters;
    }
  
  }
  