import { UtilityHelper } from "../helpers/UtilityHelper";

export class Product {

    productCategory: string;
    subProductCategory: string;
    brand: string;
    chemicalResistance: string;
    productCode: string;
    productCollection: string;
    color: string;
    colorType: string;
    colorTone: string;
    productDesign: string;
    eanCode: string;
    format: string;
    formatType: string;
    installationMethod: string;
    layingDirection: string;
    unitType: string;
    patternType: string;
    productTypeIso: string;
    length: string;
    thickness: string;
    weight: string;
    width: string;
    coverImage: string;
    thumbnailImage: string;
    coverImageUrl: string;
    thumbnailImageUrl: string;
    fullCoverImageUrl: string;
    fullThumbnailImageUrl: string;
    files: { fileName: string, fileUrl: string, fileType: string }[];
    price: string;
    retailPrice: string;
    priceRange: string;
    industry: string[]
    clearance: string[];
    active: string;
    productDescription: string;
    stockLevel: string;
    maximumPurchasable: string;
    supplierUnitPrice: string;
    surfacePerBox: string;
    stockAvailable : string;
    stockBatches : [];
    boxPerPallet: string;
    variations: any;
    brandHierarchy: string;
    subBrand: any;
    shippingClass: any;
    hubspotProductId: any;
    lastHubspotSync: any;
    installableProduct: string;
    extraImages: any;
    margin: any;
    priceBreakpoints: [];
    cuts: any[];
  cutPrice: number;
    supplierCutFee: any;
    supplierCutFeeQuantity: any;
    supplierRollFee: any;
    supplierRollFeeQuantity: any;

    constructor() {
        this.productCategory = "";
        this.subProductCategory = "";
        this.brand = "";
        this.chemicalResistance = "";
        this.productCode = "";
        this.productCollection = "";
        this.color = "";
        this.colorType = "";
        this.colorTone = "";
        this.productDesign = "";
        this.eanCode = "";
        this.format = "";
        this.formatType = "";
        this.installationMethod = "";
        this.layingDirection = "";
        this.unitType = "";
        this.patternType = "";
        this.productTypeIso = "";
        this.length = "";
        this.thickness = "";
        this.weight = "";
        this.width = "";
        this.coverImage = "";
        this.thumbnailImage = "";
        this.coverImageUrl = "";
        this.thumbnailImageUrl = "";
        this.fullCoverImageUrl = "";
        this.fullThumbnailImageUrl = "";
        this.files = [];
        this.price = "";
        this.retailPrice = "";
        this.priceRange = "";
        this.industry = [];
        this.clearance = [];
        this.active = "";
        this.productDescription = "";
        this.stockLevel = "";
        this.maximumPurchasable = "";
        this.supplierUnitPrice = "";
        this.surfacePerBox = "";
        this.stockAvailable = "";
        this.stockBatches = [];
        this.boxPerPallet = "";
        this.variations = {};
        this.brandHierarchy = "";
        this.subBrand = "";
        this.shippingClass = "";
        this.hubspotProductId = "";
        this.lastHubspotSync = "";
        this.installableProduct = "";
        this.extraImages = [];
        this.margin = "0.28";
        this.priceBreakpoints = [];
        this.cuts = [];
        this.cutPrice = 0;
        this.supplierCutFee = "";
        this.supplierRollFee = "";
        this.supplierCutFeeQuantity = "";
        this.supplierRollFeeQuantity = "";
    }

    static calculateRetailCost(product : Product, quantity : number) : string
    {
        console.log("CALCULATING REAL COST");
        return UtilityHelper.formatPrice(this.calculateProductPriceWithQuantity(product, quantity ? quantity : 0));
    }

    static calculateSupplierCost(product : Product, quantity : number = 0) : string
    {
        return UtilityHelper.formatPrice(this.calculateSupplierPriceWithQuantity(product, quantity ? quantity : 0));
    }

    //Returns the supplier cost of a product if a set quantity is ordered
    static calculateSupplierPriceWithQuantity(product : Product, quantity : number) : number
    {
        var finalCost = product.supplierUnitPrice;
        if(product.priceBreakpoints && product.priceBreakpoints.length > 0)
        {
            
            product.priceBreakpoints.forEach((breakpoint : any) => {
                var cost = breakpoint.split("@")[0];
                var units = breakpoint.split("@")[1];
                if(quantity >= units)
                {
                    finalCost = cost;
                }
            });
        }
        console.log("CALCULATING REAL COST "+finalCost);
        return UtilityHelper.parsePrice(finalCost+"");
    }

    //Returns the retail cost of a product if a set quantity is ordered including margin
    static calculateProductPriceWithQuantity(product : Product, quantity : number) : number
    {
        var finalCost = product.supplierUnitPrice;
        if(product.priceBreakpoints && product.priceBreakpoints.length > 0)
        {
            
            product.priceBreakpoints.forEach((breakpoint : any) => {
                var cost = breakpoint.split("@")[0];
                var units = breakpoint.split("@")[1];
                if(quantity >= units)
                {
                    finalCost = cost;
                }
            });
        }
        return UtilityHelper.parsePrice((+finalCost * (product.margin ? +product.margin : 1.28))+"");
    }

}
