<div class="header">
    <div class="small-button show-phone" (click)="editProfile()">Edit Profile</div>
    <div class="spacer"></div>
    <img class="close-icon" src="../../../assets/images/close_icon.png" (click)="close()">
</div>

<div class="content">
    <div class="left-col">
        <div class="row">
            <img *ngIf="user.companyLogoUrl" class="company-logo" [src]="user.companyLogoUrl">
            <div class="small-button hide-phone" (click)="editProfile()">Edit Profile</div>
        </div>
        <div *ngIf="user.companyName" class="company-name">{{user.companyName}}</div>
        <div *ngIf="user.companyAddress" class="company-address">{{user.companyAddress}}</div>
        <div class="separator"></div>
        <div class="about-title">About</div>
        <div *ngIf="user.about" class="about">{{user.about}}</div>
        <div *ngIf="!user.about" class="complete-profile" (click)="editProfile()">Click here to complete your profile</div>
    </div>
    <div class="right-col">
      <!-- Personal Details -->
      <div class="fields personal-fields">
        <div class="row-flex">
          <div class="field">
              <div class="field-title">First Name</div>
              <div class="field-description">{{ user.firstName ? user.firstName : '-' }}</div>
          </div>
          <div class="field">
            <div class="field-title">Last Name</div>
            <div class="field-description">{{ user.lastName ? user.lastName : '-' }}</div>
          </div>
          <div class="field">
            <div class="field-title">Mobile Number</div>
            <div class="field-description">{{ user.mobileNumber ? user.mobileNumber : '-' }}</div>
          </div>
          <div class="field">
            <div class="field-title">Drivers Licence Number</div>
            <div class="field-description">{{ user.driversLicenceNumber ? user.driversLicenceNumber : '-' }}</div>
          </div>
          <div class="field full">
            <div class="field-title">Address</div>
            <div class="field-description">{{ user.homeAddress ? user.homeAddress : '-' }}</div>
          </div>

        </div>
      </div>

      <!-- Company Details -->
      <div class="fields company-fields">
        <div class="row-flex">
          <div class="field">
              <div class="field-title">Company Name</div>
              <div class="field-description">{{ user.companyName ? user.companyName : '-' }}</div>
          </div>
          <div class="field">
            <div class="field-title">ABN</div>
            <div class="field-description">{{ user.abn ? user.abn : '-' }}</div>
          </div>
          <div class="field">
            <div class="field-title">Company Phone Number</div>
            <div class="field-description">{{ user.companyPhone ? user.companyPhone : '-' }}</div>
          </div>
          <div class="field">
            <div class="field-title">Company Email</div>
            <div class="field-description">{{ user.companyEmail ? user.companyEmail : '-' }}</div>
          </div>
          <div class="field full">
            <div class="field-title">Company Address</div>
            <div class="field-description">{{ user.companyAddress ? user.companyAddress : '-' }}</div>
          </div>
          <div class="field">
            <div class="field-title">Business Type</div>
            <div class="field-description">{{ user.companyBusinessType ? user.companyBusinessType :  '-' }}</div>
          </div>
          <div class="field">
            <div class="field-title">Job Title</div>
            <div class="field-description">{{ user.companyJobTitle ? user.companyJobTitle :  '-' }}</div>
          </div>
          <div class="field">
            <div class="field-title">Organisation Size</div>
            <div class="field-description">{{ user.companyOrgSize ? user.companyOrgSize :  '-' }}</div>
          </div>
          <div class="field">
            <div class="field-title">Annual Turnover</div>
            <div class="field-description">{{ user.companyAnnualTurnover ? user.companyAnnualTurnover :  '-' }}</div>
          </div>
          <div class="field full">
            <div class="field-title">Have you, your company or any company you've been part of declared bankruptcy in the past?</div>
            <div class="field-description">{{ user.companyBankrupcy ? user.companyBankrupcy :  '-' }}</div>
          </div>


          <!-- builder -->
          <div class="field" *ngIf="user.type == 'Builder'">
            <div class="field-title">Types of buildings </div>
            <div class="field-description">{{ user.companyBuildingType ? user.companyBuildingType :  '-' }}</div>
          </div>
          <div class="field" *ngIf="user.type == 'Builder'">
            <div class="field-title">How many builds per year</div>
            <div class="field-description">{{ user.companyBuildPerYear ? user.companyBuildPerYear :  '-' }}</div>
          </div>
          <div class="field" *ngIf="user.type == 'Builder'">
            <div class="field-title">Build Nationally</div>
            <div class="field-description">{{ user.companyBuildNationally ? user.companyBuildNationally :  '-' }}</div>
          </div>
          <div class="field" *ngIf="user.type == 'Builder'">
            <div class="field-title">Current supplier and flooring contractors used</div>
            <div class="field-description">{{ user.companydCurrentSupplier ? user.companydCurrentSupplier :  '-' }}</div>
          </div>



          <!-- installer -->
          <div class="field" *ngIf="user.type == 'Installer'">
            <div class="field-title">Coverings installation</div>
            <div class="field-description">{{ user.companyCovering ? user.companyCovering :  '-' }}</div>
          </div>
          <div class="field" *ngIf="user.type == 'Installer'">
            <div class="field-title">Areas covered</div>
            <div class="field-description">{{ user.companyArea ? user.companyArea :  '-' }}</div>
          </div>
          <div class="field" *ngIf="user.type == 'Installer'">
            <div class="field-title">Size of team</div>
            <div class="field-description">{{ user.companyTeamSize ? user.companyTeamSize :  '-' }}</div>
          </div>
          <div class="field" *ngIf="user.type == 'Installer'">
            <div class="field-title">Who do you install for currently</div>
            <div class="field-description">{{ user.companyCurrentInstall ? user.companyCurrentInstall :  '-' }}</div>
          </div>

          <div class="field">
            <div class="field-title">Years of experience </div>
            <div class="field-description">{{ user.companyExperience ? user.companyExperience :  '-' }}</div>
          </div>

        </div>
      </div>

    </div>
</div>
