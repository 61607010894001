import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  private url = '';

  //with Cors headers
  private: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  });

  constructor(private http: HttpClient) {
    this.url = environment.baseAPIUrl;
  }

  urlBuilder(route: string) {
    return this.url + route;
  }
  // append headers to options
  request(method: string, route: string, options?: any) {
    return this.http.request(method, this.urlBuilder(route), {
      headers: this.private,
      ...options,
    });
  }

  post(route: string, options?: any) {
    return this.http.request('POST', this.urlBuilder(route), {
      headers: this.private,
      ...options,
    });
  }

  get(route: string, options?: any) {
    return this.http.request('GET', this.urlBuilder(route), {
      headers: this.private,
      ...options,
    });
  }

  put(route: string, options?: any) {
    return this.http.request('PUT', this.urlBuilder(route), {
      headers: this.private,
      ...options,
    });
  }

  delete(route: string, options?: any) {
    return this.http.request('DELETE', this.urlBuilder(route), {
      headers: this.private,
      ...options,
    });
  }
}
