import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { AuthHelper } from 'src/app/helpers/AuthHelper';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  email: string = '';
  showPassword: boolean = false;
  loading: boolean = false;
  errorMessage = '';
  success = false;

  constructor(private authHelper: AuthHelper, public router: Router) { }

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView("Forgot Password");
  }

  async login() {
    if (this.success) {
      this.router.navigateByUrl('/login');
    } else {
      if (!this.email) {
        this.errorMessage = 'Please enter your email address';
      } else {
        this.errorMessage = '';
        this.loading = true;
        this.authHelper.sendPasswordResetEmail(this.email).then(val => {
        }).catch(err => {
        }).finally(() => {
          this.success = true;
          this.loading = false;
        });
      }
    }
  }

  goTo(url: string) {
    this.router.navigateByUrl(url);
  }

  back() {
    window.history.back();
  }

}
