import { DatabaseHelper } from '../helpers/DatabaseHelper';
import { PaymentsHelper } from '../helpers/PaymentsHelper';
import { CartItem } from './CartItem';
import { EstimationCart } from './EstimationCart';
import { Order } from './Order';

export class User {
  uid: string;
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  driversLicenceNumber: string;
  homeAddress: string;

  type: string;
  companyName: string;
  abn: string;
  companyAddress: string;
  insuranceNumber: string;
  companyLogoUrl: string;
  companyPhone: string;
  businessLicenceNumber: string;
  currentOrder: Order;
  website: string;
  about: string;
  status: string;

  licenceUrl: string;
  insuranceUrl: string;

  simplifyCustomerId: string;
  discountCode: string;
  discountCodeId: string;
  card: any;

  companyEmail: string;
  companyBusinessType: string;
  companyJobTitle: string;
  companyOrgSize: string;
  companyAnnualTurnover: string;
  companyBankrupcy: string;
  companyExperience: string;

  companyBuildingType: string;
  companyBuildPerYear: string;
  companyBuildNationally: string;
  companydCurrentSupplier: string;

  companyCovering: string;
  companyArea: string;
  companyTeamSize: string;
  companyCurrentInstall: string;

  estimationCart: EstimationCart;

  currentHutk: string;
  hubspotContactId: string;
  hubspotCompanyId: string;

  constructor(private firebaseId: string) {
    this.uid = firebaseId;
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.type = '';
    this.companyName = '';
    this.abn = '';
    this.companyAddress = '';
    this.insuranceNumber = '';
    this.companyLogoUrl = '';
    this.companyPhone = '';
    this.businessLicenceNumber = '';
    this.currentOrder = new Order();
    this.website = '';
    this.about = '';
    this.status = '';
    this.mobileNumber = '';
    this.driversLicenceNumber = '';
    this.homeAddress = '';
    this.licenceUrl = '';
    this.insuranceUrl = '';
    this.discountCode = '';
    this.discountCodeId = '';
    this.simplifyCustomerId = '';
    this.card = {};
    this.companyEmail = '';
    this.companyBusinessType = '';
    this.companyJobTitle = '';
    this.companyOrgSize = '';
    this.companyAnnualTurnover = '';
    this.companyBankrupcy = '';
    this.companyExperience = '';

    this.companyBuildingType = '';
    this.companyBuildPerYear = '';
    this.companyBuildNationally = '';
    this.companydCurrentSupplier = '';

    this.companyCovering = '';
    this.companyArea = '';
    this.companyTeamSize = '';
    this.companyCurrentInstall = '';

    this.estimationCart = new EstimationCart();

    this.currentHutk = '';
    this.hubspotContactId = '';
    this.hubspotCompanyId = '';
  }

  initWithDictionary(user: any) {
    this.uid = user.uid;
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.email = user.email;
    this.type = user.type;
    this.companyName = user.companyName;
    this.abn = user.abn;
    this.companyAddress = user.companyAddress;
    this.insuranceNumber = user.insuranceNumber;
    this.companyLogoUrl = user.companyLogoUrl;
    this.companyPhone = user.companyPhone;
    this.businessLicenceNumber = user.businessLicenceNumber;
    this.currentOrder.addData(user.currentOrder);
    this.currentOrder.clearTimeSensitiveData();
    this.website = user.website;
    this.about = user.about;
    this.status = user.status ? user.status : '';
    this.mobileNumber = user.mobileNumber ? user.mobileNumber : '';
    this.driversLicenceNumber = user.driversLicenceNumber
      ? user.driversLicenceNumber
      : '';
    this.homeAddress = user.homeAddress ? user.homeAddress : '';
    this.licenceUrl = user.licenceUrl ? user.licenceUrl : '';
    this.insuranceUrl = user.insuranceUrl ? user.insuranceUrl : '';
    this.discountCode = user.discountCode ? user.discountCode : '';
    this.discountCodeId = user.discountCodeId ? user.discountCodeId : '';

    this.companyEmail = user.companyEmail;
    this.companyBusinessType = user.companyBusinessType;
    this.companyJobTitle = user.companyJobTitle;
    this.companyOrgSize = user.companyOrgSize;
    this.companyAnnualTurnover = user.companyAnnualTurnover;
    this.companyBankrupcy = user.companyBankrupcy;
    this.companyExperience = user.companyExperience;

    this.companyBuildingType = user.companyBuildingType;
    this.companyBuildPerYear = user.companyBuildPerYear;
    this.companyBuildNationally = user.companyBuildNationally;
    this.companydCurrentSupplier = user.companydCurrentSupplier;

    this.companyCovering = user.companyCovering;
    this.companyArea = user.companyArea;
    this.companyTeamSize = user.companyTeamSize;
    this.companyCurrentInstall = user.companyCurrentInstall;

    this.estimationCart = user.estimationCart ? user.estimationCart : {};

    this.currentHutk = user.currentHutk ?? '';
    this.hubspotContactId = user.hubspotContactId ?? '';
    this.hubspotCompanyId = user.hubspotCompanyId ?? '';

    if (!user.simplifyCustomerId) {
      console.log('Creating simplify customer...');
      PaymentsHelper.instance.createSimplifyCustomer(this).then((val) => {
        this.simplifyCustomerId = val.id;
      });
    } else {
      this.simplifyCustomerId = user.simplifyCustomerId;
    }
    this.card = user.card ? user.card : {};
  }

  fetch(): Promise<boolean> {
    console.log('Fetching user...');
    return new Promise(async (res, err) => {
      console.log('Fetching user using DatabaseHelper...');
      DatabaseHelper.instance
        .getUser(this.uid)
        .then(async (response) => {
          let val = response.user;
          if (val.uid) {
            this.initWithDictionary(val);
            res(true);
          }
        })
        .catch((error) => {
          err(error);
        });
    });
  }

  save(): Promise<void> {
    console.log('Saving user...');
    return DatabaseHelper.instance.updateUser(this, false);
  }

  delete(): Promise<void> {
    return DatabaseHelper.instance.deleteUser(this.uid);
  }

  toJson() {
    return {
      uid: this.uid,
      firstName: this.firstName,
      lastName: this.lastName,
      email: this.email,
      type: this.type,
      companyName: this.companyName,
      abn: this.abn,
      companyAddress: this.companyAddress,
      insuranceNumber: this.insuranceNumber,
      companyLogoUrl: this.companyLogoUrl,
      companyPhone: this.companyPhone,
      businessLicenceNumber: this.businessLicenceNumber,
      currentOrder: this.currentOrder,
      website: this.website,
      about: this.about,
      status: this.status,
      mobileNumber: this.mobileNumber,
      driversLicenceNumber: this.driversLicenceNumber,
      homeAddress: this.homeAddress,
      licenceUrl: this.licenceUrl,
      insuranceUrl: this.insuranceUrl,
      simplifyCustomerId: this.simplifyCustomerId,
      card: this.card,
      discountCode: this.discountCode,
      discountCodeId: this.discountCodeId,

      companyEmail: this.companyEmail,
      companyBusinessType: this.companyBusinessType,
      companyJobTitle: this.companyJobTitle,
      companyOrgSize: this.companyOrgSize,
      companyAnnualTurnover: this.companyAnnualTurnover,
      companyBankrupcy: this.companyBankrupcy,
      companyExperience: this.companyExperience,

      companyBuildingType: this.companyBuildingType,
      companyBuildPerYear: this.companyBuildPerYear,
      companyBuildNationally: this.companyBuildNationally,
      companydCurrentSupplier: this.companydCurrentSupplier,

      companyCovering: this.companyCovering,
      companyArea: this.companyArea,
      companyTeamSize: this.companyTeamSize,
      companyCurrentInstall: this.companyCurrentInstall,

      estimationCart: this.estimationCart,

      currentHutk: this.currentHutk,
      hubspotContactId: this.hubspotContactId,
      hubspotCompanyId: this.hubspotCompanyId,
    };
  }

  get cart() {
    return this.currentOrder.cart;
  }
}
