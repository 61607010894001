import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  searchTerm:string = '';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.searchTerm = this.data.searchTerm;
    AnalyticsHelper.instance.pageView("Search Modal");
  }

  clearSearch() {
    this.searchTerm = "";
    DatabaseHelper.instance.dataCallbacks.dataCallback2(this.searchTerm);
    this.close();
  }

  searchProducts() {
    AnalyticsHelper.instance.logEvent("search_product", { search_term: this.searchTerm });
    DatabaseHelper.instance.dataCallbacks.dataCallback2(this.searchTerm);
    this.close();
  }

  close() {
    this.dialog.getDialogById('search-modal')?.addPanelClass("animate-out");
    setTimeout(() => {
      this.dialog.getDialogById('search-modal')?.close();
    }, 500);
  }

}
