import { Pipe, PipeTransform } from '@angular/core';
import { UtilityHelper } from '../helpers/UtilityHelper';

/**
 * Generated class for the SafeHtmlPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'formattedDate',
})
export class FormattedDatePipe implements PipeTransform {

  constructor() { }

  transform(date: string) {
    return UtilityHelper.formatDate(date);
  }

}