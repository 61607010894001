import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
    selector: '[cc-number]'
})
export class CreditCardDirective {

    // Allow numbers and spaces
    private regex: RegExp = new RegExp(/^[0-9\s]*$/g);

    // Allow key codes for special events. Reflect :
    // Backspace, tab, end, home
    private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home', 'Ctrl', 'v'];

    @HostListener('keydown', ['$event'])
    @HostListener('input', ['$event'])
    onKeyDown(event: KeyboardEvent) {
        const input = event.target as HTMLInputElement;

        // Allow Backspace, tab, end, and home keys
        if (this.specialKeys.indexOf(event.key) !== -1) {
            let current: string = input.value;
            setTimeout(() => {
                input.value = input.value.replace('v', '');
            }, 200);
            return;
        }

        // Do not use event.keycode this is deprecated.
        // See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode
        let current: string = input.value;
        // We need this because the current value on the DOM element
        // is not yet updated with the value from this event
        let next: string = current.concat(event.key);
        if (next && !String(next).match(this.regex)) {
            event.preventDefault();
        }

        let trimmed = input.value.replace(/ /g, '');
        if (trimmed.length > 16) {
            trimmed = trimmed.substring(0, 16);
        }

        let numbers = [];
        for (let i = 0; i < trimmed.length; i += 4) {
            numbers.push(trimmed.substring(i, i + 4));
        }

        input.value = numbers.join(' ');

    }
}