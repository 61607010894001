import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Order } from 'src/app/classes/Order';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';

@Component({
  selector: 'app-order-confirmed',
  templateUrl: './order-confirmed.component.html',
  styleUrls: ['./order-confirmed.component.scss']
})
export class OrderConfirmedComponent implements OnInit {

  order = new Order();

  constructor(private router: Router) {
    if (this.router.getCurrentNavigation()?.extras && this.router.getCurrentNavigation()?.extras.state) {
      this.order = this.router.getCurrentNavigation()?.extras.state!['order'];
    } else {
      
    }
  }

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView("Order Confirmed");
  }

}
