import { Job } from "./Job";
import { Product } from "./Product";

export class JobQuote {

    jobQuoteNumber: string;
    job: Job;
    installerName: string;
    installerType: string;
    installerLocation: string;
    quoteSubmittedDate: string;
    quoteAcceptedDate: string;
    quotedAmount: string;
    userId: string;
    products: { product: Product, quantity: string, price: string, subItems: { name: string, quantity: string, price: string, installerAdded: boolean }[] }[];
    status: string;
    won: boolean;


    constructor() {
        this.jobQuoteNumber = "";
        this.job = new Job();
        this.installerName = "";
        this.installerType = "";
        this.quoteSubmittedDate = "";
        this.quoteAcceptedDate = "";
        this.installerLocation = "";
        this.quotedAmount = "";
        this.userId = "";
        this.products = [];
        this.status = "Active";
        this.won = false;
    }
}