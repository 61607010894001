import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { AuthHelper } from 'src/app/helpers/AuthHelper';

@Component({
  selector: 'app-job-board-installer',
  templateUrl: './job-board-installer.component.html',
  styleUrls: ['./job-board-installer.component.scss']
})
export class JobBoardInstallerComponent implements OnInit {

  constructor(public router: Router, private authHelper: AuthHelper) { }

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView("Job Board Installer");
    this.redirectJobBoardPage();
  }

  async redirectJobBoardPage() {
      if( await this.authHelper.getUserStatus() ){
        // redirect to job board page by user type
        const userType = this.user.type
        if( userType == 'Builder' ){
          this.router.navigateByUrl('job-board-builder');
        }
      }
  }

  get user() {
    return AuthHelper.instance.user;
  }

}
