import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import dist from 'gsap/dist';
import { Options } from 'ngx-google-places-autocomplete/objects/options/options';
import { max } from 'rxjs';
import { CartItem } from 'src/app/classes/CartItem';
import { Order } from 'src/app/classes/Order';
import { Product } from 'src/app/classes/Product';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';
import { UtilityHelper } from 'src/app/helpers/UtilityHelper';

@Component({
  selector: 'app-shipping',
  templateUrl: './shipping.component.html',
  styleUrls: ['./shipping.component.scss'],
})
export class ShippingComponent implements OnInit {
  errors: any = {};
  startValidation = false;
  currentDate = new Date().toISOString().split('T')[0];

  public productClass = Product;

  options: Options = new Options({
    // bounds: undefined, fields: ["address_component"], strictBounds: false,
    // types: ['geocode','route'],
    componentRestrictions: { country: 'au' },
  });

  tarkettHandlingFee = 68;
  editingOrder = new Order();
  isEditingOrder = false;

  tempShippingPackages: any = {};

  constructor(
    private router: Router,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.waitForUserToLoad();
    this.isEditingOrder = false;
    if (this.data) {
      this.editingOrder = new Order();
      this.editingOrder.addData(data.order);
      this.isEditingOrder = true;
      // this.order = data.order;
      // console.log("Loading order data = "+JSON.stringify(this.order));
      var addressArray = this.order.shippingAddress.split(',');
      var postcodePart = addressArray[1].trim();
      // console.log("Length = "+postcodePart.length);
      var postcodeIndex = postcodePart.length - 4;
      // console.log("Length end = "+postcodeIndex);
      var postcode = postcodePart.slice(postcodeIndex, postcodeIndex + 4);
      // console.log(postcodePart+" = Post = "+postcode);
      var stateArray = addressArray[1]
        .trim()
        .replace(postcode, '')
        .trim()
        .split(' ');
      var state = stateArray[stateArray.length - 1].slice(0, 3).trim();
      var suburb = addressArray[1]
        .trim()
        .replace(postcode, '')
        .replace(state, '')
        .trim();

      // console.log('Address state:'+state + "suburb:"+suburb+"postcode:"+postcode);
      this.splitShippingPackages();
      this.order.deliveryOption = { option: '', cost: '' };
      this.clearDeliveryOptions();
      var lat = '0';
      var lon = '0';
      if (this.order.shippingAddress) {
        // Address is already set when replacing a product
        lat = this.order.shippingLat;
        lon = this.order.shippingLng;
      }
      this.calculateDeliveryInformation(state, suburb, postcode, lat, lon);
    }
  }

  get order() {
    return this.isEditingOrder ? this.editingOrder : this.user.currentOrder;
  }

  async ngOnInit() {
    AnalyticsHelper.instance.pageView('Shipping');
    if (!this.isEditingOrder) {
      this.waitForUserToLoad();
    }
  }

  async waitForUserToLoad() {
    try{
    await AuthHelper.instance.isLoggedIn();
      this.splitShippingPackages();
    }catch(e){
      console.log('Error fetching user');
    }

  }

  splitShippingPackages() {
    this.tempShippingPackages = JSON.parse(
      JSON.stringify(this.order.shippingPackages),
    );
    // console.log("Splitting shipping packages");
    this.order.shippingPackages = {};
    this.order.cart.forEach((cartItem) => {
      if (this.order.shippingPackages.hasOwnProperty(cartItem.product.brand)) {
        //Brand already exists
        // console.log('Brand 1 = '+cartItem.product.brand);
        this.order.shippingPackages[cartItem.product.brand].cartItems.push(
          cartItem,
        );
        this.order.shippingPackages[cartItem.product.brand].deliveryOption = {
          option: '',
          cost: '',
        };
      } else {
        //Brand doesn't exist yet
        // console.log('Brand 2 = '+cartItem.product.brand);
        this.order.shippingPackages[cartItem.product.brand] = {
          cartItems: [cartItem],
          deliveryOptions: [],
          deliveryOption: { option: '', cost: '' },
        };
      }
    });
    if (this.order.shippingAddress) {
      this.calculateDeliveryInformation(
        this.order.shippingState,
        this.order.shippingSuburb,
        this.order.shippingPostcode,
        this.order.shippingLat,
        this.order.shippingLng,
      );
    }
  }

  checkTempShippingPackages() {
    this.keysForObject(this.order.shippingPackages).forEach((key) => {
      if (this.tempShippingPackages.hasOwnProperty(key)) {
        let shippingOption = this.order.shippingPackages[
          key
        ].deliveryOptions.find(
          (option: any) =>
            option.title ===
            this.tempShippingPackages[key].deliveryOption.option,
        );
        if (shippingOption) {
          this.selectDeliveryOption(
            shippingOption.title,
            shippingOption.price,
            key,
            shippingOption.dispatchRegion,
            shippingOption.deliveryRegion,
            shippingOption.address,
            shippingOption.packingAndOrderCost
              ? shippingOption.packingAndOrderCost
              : null,
          );
          if (shippingOption.title.toLowerCase().includes('site delivery')) {
            this.order.shippingPackages[key].siteContact =
              this.tempShippingPackages[key].siteContact;
            this.order.shippingPackages[key].siteContactNumber =
              this.tempShippingPackages[key].siteContactNumber;
            this.order.shippingPackages[key].businessName =
              this.tempShippingPackages[key].businessName;
            this.order.shippingPackages[key].customerPoRef =
              this.tempShippingPackages[key].customerPoRef;
            this.order.shippingPackages[key].deliverySiteOpeningTime =
              this.tempShippingPackages[key].deliverySiteOpeningTime;
            this.order.shippingPackages[key].deliverySiteClosingTime =
              this.tempShippingPackages[key].deliverySiteClosingTime;
            this.order.shippingPackages[key].deliveryDropOffLocation =
              this.tempShippingPackages[key].deliveryDropOffLocation;
            this.order.shippingPackages[key].deliveryBuildingSite =
              this.tempShippingPackages[key].deliveryBuildingSite ?? '';
            this.order.shippingPackages[key].deliveryCallBeforeDelivery =
              this.tempShippingPackages[key].deliveryCallBeforeDelivery ?? '';
            this.order.shippingPackages[key].deliverySiteSpecificNotes =
              this.tempShippingPackages[key].deliverySiteSpecificNotes;
          }
          delete this.tempShippingPackages[key];
        }
      }
    });
  }

  handleAddressChange(address: any) {
    // console.log('Address change');
    // console.log('Address change = '+JSON.stringify(address));
    this.clearDeliveryOptions();
    // this.userAddress = address.formatted_address
    // this.userLatitude = address.geometry.location.lat()
    // this.userLongitude = address.geometry.location.lng()
    console.log('Address = ' + JSON.stringify(address));
    var streetNumber = '';
    var street = '';
    var country = '';
    var state = '';
    var suburb = '';
    var postcode = '';
    var lat = address.geometry.location.lat();
    var lon = address.geometry.location.lng();

    this.order.shippingAddress = address.formatted_address;
    address.address_components.forEach((component: any) => {
      component.types.forEach((type: any) => {
        if (type === 'street_number') {
          streetNumber = component.short_name;
        }
        if (type === 'route') {
          street = component.long_name;
        }
        if (type === 'country') {
          country = component.long_name;
        }
        if (type === 'administrative_area_level_1') {
          state = component.short_name;
        }
        if (type === 'locality') {
          suburb = component.short_name;
        }
        if (type === 'postal_code') {
          postcode = component.short_name;
        }
      });
    });
    this.order.shippingStreetNumber = streetNumber;
    this.order.shippingStreet = street;
    this.order.shippingCountry = country;
    this.order.shippingState = state;
    this.order.shippingSuburb = suburb;
    this.order.shippingPostcode = postcode;
    this.order.shippingLat = lat;
    this.order.shippingLng = lon;
    // console.log('state = '+state);
    // console.log('suburb = '+suburb);
    this.calculateDeliveryInformation(state, suburb, postcode, lat, lon);
  }

  clearDeliveryOptions() {
    // console.log("Clear delivery options now!");
    Object.keys(this.order.shippingPackages).forEach((key: any) => {
      this.order.shippingPackages[key].deliveryOptions = [];
    });
  }

  calculateDeliveryInformation(
    state: string,
    suburb: string,
    postcode: string,
    lat: string,
    lon: string,
  ) {
    console.log('in calculateDeliveryInformation');
    // console.log('state');
    if (this.order.shippingPackages.hasOwnProperty('Interface')) {
      // console.log('Has an Interface Package');

      // Custom flow for cart that containes less than 2 rolls of weld rod.
      if (this.containsLessThan2WeldRodsOnly) {
        this.order.shippingPackages['Interface'].deliveryOptions.push({
          title: 'Site Delivery',
          description: 'Delivery right to your site, conditions apply',
          price: '$25.00',
          dispatchRegion: 'Sydney',
          deliveryRegion: this.order.shippingSuburb,
        });
        this.checkTempShippingPackages();
        return;
      }

      // Interface - Anywhere
      // 1. First move from Interface to Sydney MAINFREIGHT DEPOT will be covered by Interface
      if (state === 'NSW') {
        // 2. Pick Up available for Sydney Customers for MAINFREIGHT - no charge unless storage becomes applicable (storage only becomes applicable if not picked up within a week and will be dealt with manually for now)
        //Add MAINFREIGHT Major City Depot pickup option
        // this.order.shippingPackages['Interface'].deliveryOptions.push({title:'Pick up from supplier (Primary NSW Depot)', description:'You can pick your order up from Mainfreights primary depot in NSW',price:'Free'})
        // this.checkTempShippingPackages();
      } else {
        // 3. Second Move From Sydney to Next MAINFREIGHT CBD DEPOT is covered by Interface (including NT and Tasmania)
        // 4. Pick Up is Available from MAINFREIGHT- no charge unless storage becomes applicable (client receives a phone call FLRHUB get an email from MAINFREIGHT to let them know it has arrived) - Correspondence from FLRHUB notifying users of pickup
        //Add MAINFREIGHT Major City Depot pickup option
        // this.order.shippingPackages['Interface'].deliveryOptions.push({title:'Pick up from supplier (Primary '+state+' Depot)', description:'You can pick your order up from Mainfreights primary depot in '+state,price:'Free'})
        // this.checkTempShippingPackages();
      }

      //get cost will handle the following delivery options
      this.getInterfaceDepotCost(lat, lon, state);
      this.getInterfaceCost(postcode, suburb, state);
      // 5. On forwarding by MAINFREIGHT to regional address (FLRHUB account) as per price sheet
      //Setup on forwarding to regional depot for pickup

      // 6. Site Delivery by TAILGATE MAINFREIGHT (FLRHUB account) as per price sheet
      //Add Site delivery option
    }
    if (this.order.shippingPackages.hasOwnProperty('Tarkett')) {
      // console.log('Has a Tarkett Package');

      // Custom flow for cart that containes less than 2 rolls of weld rod.
      if (this.containsLessThan2WeldRodsOnly) {
        this.order.shippingPackages['Tarkett'].deliveryOptions.push({
          title: 'Site Delivery',
          description: 'Delivery right to your site, conditions apply',
          price: '$25.00',
          dispatchRegion: 'Sydney',
          deliveryRegion: 'Sydney',
        });
        this.checkTempShippingPackages();
        return;
      }
      // Tarkett - Anywhere
      // 1. Stored IN GMK SYDNEY warehouse
      if (state === 'NSW') {
        // 2. Pick up available from GMK SYDNEY- free of charge to Sydney customers  (Notification sent from FLRHUB to client and GMK phone call)
        //Add GMK Major City Depot pickup option
        // this.order.shippingPackages['Tarkett'].deliveryOptions.push({title:'Pick up from supplier', description:'You can pick your order up from GMKs primary depot at 82 Rodeo Road, Gregory Hills 2557 NSW',price:'$118.00', dispatchRegion:'Sydney',deliveryRegion:'Sydney', address: "82 Rodeo Road, Gregory Hills 2557 NSW"})
      } else {
        //1. First interstate move from GMK to GMK covered by Tarkett (Only available to Adelaide, Newcastle, Brisbane, Perth, Melbourne)(Tasmania to be worked out if they can palletise vinyl for delivery with Mainfreight)
        //2. Pick Up Available from GMK Depot (Notification sent from FLRHUB to client and GMK phone call) Free of charge
        //Add GMK Major City Depot pickup option
        // console.log('Packages = '+JSON.stringify(this.order.shippingPackages));
        // this.order.shippingPackages['Tarkett'].deliveryOptions.push({title:'Pick up from supplier (Primary '+state+' Depot)', description:'You can pick your order up from GMKs primary depot in '+state,price:'$118.00'})
        // this.checkTempShippingPackages();
        // console.log('Packages 2 = '+JSON.stringify(this.order.shippingPackages));
      }

      //get cost will handle the following delivery options
      // this.getTarkettCost(postcode,suburb);
      this.getTarkettDepotCost(lat, lon);
      //We are currently forcing a site delivery quote required instead of calculating the cost for site delivery.
      this.order.shippingPackages['Tarkett'].deliveryOptions.push({
        title: 'Site Delivery (Quote Required)',
        description: 'A quote is required for shipping to your location.',
        price: 'TBC',
        dispatchRegion: 'Sydney',
        deliveryRegion: this.order.shippingSuburb,
      });
      this.checkTempShippingPackages();
      // 5. On forwarding by MAINFREIGHT to regional address (FLRHUB account) as per price sheet
      //Setup on forwarding to regional depot for pickup

      // 6. Site Delivery by TAILGATE MAINFREIGHT (FLRHUB account) as per price sheet
      //Add Site delivery option
    }
    if (this.order.shippingPackages.hasOwnProperty('Pentarch Forestry')) {
      // console.log('Has an Pentarch Package');

      // Pentarch Forestry - Anywhere
      // 1. First move from Pentarch Forestry to Sydney MAINFREIGHT DEPOT will be covered by Pentarch Forestry
      if (state === 'NSW') {
        // 2. Pick Up available for Sydney Customers for MAINFREIGHT - no charge unless storage becomes applicable (storage only becomes applicable if not picked up within a week and will be dealt with manually for now)
        //Add MAINFREIGHT Major City Depot pickup option
        // this.order.shippingPackages['Pentarch Forestry'].deliveryOptions.push({title:'Pick up from supplier (Primary NSW Depot)', description:'You can pick your order up from Mainfreights primary depot in NSW',price:'Free'})
      } else {
        // 3. Second Move From Sydney to Next MAINFREIGHT CBD DEPOT is covered by Pentarch Forestry (including NT and Tasmania)
        // 4. Pick Up is Available from MAINFREIGHT- no charge unless storage becomes applicable (client receives a phone call FLRHUB get an email from MAINFREIGHT to let them know it has arrived) - Correspondence from FLRHUB notifying users of pickup
        //Add MAINFREIGHT Major City Depot pickup option
        // this.order.shippingPackages['Pentarch Forestry'].deliveryOptions.push({title:'Pick up from supplier (Primary '+state+' Depot)', description:'You can pick your order up from Mainfreights primary depot in '+state,price:'Free'})
      }

      //get cost will handle the following delivery options
      this.getPentarchCost(postcode, suburb, state);
      // this.getPentarchDepotPickup(state);
      this.getPentarchDepotCost(lat, lon, state);
      // 5. On forwarding by MAINFREIGHT to regional address (FLRHUB account) as per price sheet
      //Setup on forwarding to regional depot for pickup

      // 6. Site Delivery by TAILGATE MAINFREIGHT (FLRHUB account) as per price sheet
      //Add Site delivery option
    }
    if (this.order.shippingPackages.hasOwnProperty('Epoxy Warehouse')) {
      this.getEpoxyWarehouseCost(postcode, suburb, state);
    } else {
      // console.log('Other suppliers = '+JSON.stringify(this.order.shippingPackages));
    }
    if (this.order.shippingPackages.hasOwnProperty('GH Commercial')) {
      //Depot Pick-up
      this.getGodfreyHirstDepotCost(lat, lon);
      //Site delivery
      this.order.shippingPackages['GH Commercial'].deliveryOptions.push({
        title: 'Site Delivery (Quote Required)',
        description: 'A quote is required for shipping to your location.',
        price: 'TBC',
        dispatchRegion: 'Sydney',
        deliveryRegion: this.order.shippingSuburb,
      });
      //
    }

    // Tarkett Rolls - NSW       *Pallet Package means rolls are put on pallets and strapped for on forwarding*
    // 1. Stored IN GMK SYDNEY warehouse
    // 2. Pick up available from GMK SYDNEY- free of charge to Sydney customers  (Notification sent from FLRHUB to client and GMK phone call)
    // 3. Pallet Package by GMK as per pricing to be provided.  (COST TO FLRHUB $130)
    // 4. Regional Delivery NSW pick up and deliver by MAINFREIGHT or Site Delivery CBD

    // Tarkett Rolls- Rest of Australia
    // 1. First interstate move from GMK to GMK covered by Tarkett (Only available to Adelaide, Newcastle, Brisbane, Perth, Melbourne)(Tasmania to be worked out if they can palletise vinyl for delivery with Mainfreight)
    // 2. Pick Up Available from GMK Depot (Notification sent from FLRHUB to client and GMK phone call) Free of charge
    // 3. Pallet Package by GMK as per pricing to be provided.  (COST TO FLRHUB $130)
    // 4. Regional Delivery pick up and deliver by MAINFREIGHT or Site Delivery CBD (NT to be serviced out of Adelaide)

    // Tarkett Pallets NSW
    // 1. Stored IN GMK SYDNEY warehouse
    // 2. Pick up available from GMK SYDNEY- free of charge to Sydney customers  (Notification sent from FLRHUB to client and GMK phone call)
    // 3. Regional Delivery NSW pick up and deliver by MAINFREIGHT or Site Delivery CBD

    // Tarkett Pallets - Rest of Australia
    // 1. First interstate move from GMK to GMK covered by Tarkett (Only available to Adelaide, Newcastle, Brisbane, Perth, Melbourne, Tasmania)
    // 2. Pick Up Available from GMK Depot (Notification sent from FLRHUB to client and GMK phone call) Free of charge
    // 3. Regional Delivery pick up and deliver by MAINFREIGHT or Site Delivery CBD (NT to be serviced out of Adelaide)
  }

  getInterfacePickupCost() {
    //3566
  }

  calculateVolumWeight(cartItem: CartItem) {
    // var length = +cartItem.product.length/100;
    // var width = +cartItem.product.width/100;
    var thickness = cartItem.product.thickness
      ? +cartItem.product.thickness / 1000
      : 0;
    var volumeWeight = thickness * cartItem.quantity * 333;
    return volumeWeight;
  }

  getEpoxyWarehouseCost(postcode: string, suburb: string, state: string) {
    //Check where we are Shipping to, Sydney Metro, Australia Wide, NSW
    var deliveryRegion = 'Australia-wide';
    if (state == 'NSW') {
      deliveryRegion = 'NSW';
    }
    console.log('Postcode = ' + postcode);
    if (
      (+postcode >= 1000 && +postcode <= 1920) ||
      (+postcode >= 2000 && +postcode <= 2239) ||
      (+postcode >= 2555 && +postcode <= 2574) ||
      (+postcode >= 2740 && +postcode <= 2786)
    ) {
      deliveryRegion = 'Sydney Metro';
    }
    console.log('Delivery region = ' + deliveryRegion);
    //Fetch the correct rates data
    this.http
      .get('assets/files/epoxyRates.csv', { responseType: 'text' })
      .subscribe((data) => {
        let csvToRowArray = data.split('\n');
        //Format CSV into an array
        var ratesArray = new Array();
        for (let index = 1; index < csvToRowArray.length - 1; index++) {
          let row = csvToRowArray[index].split(',');
          if (row[0] == deliveryRegion) {
            ratesArray.push({
              shippingName: row[0],
              shippingClass: row[1],
              minItem: +row[2],
              maxItem: +row[3],
              fee: +row[5],
              cost: +row[6],
            });
          }
        }
        var totalDeliveryCost = 0;
        var deliveryCalculated = true;
        //For each cart item calculate the delivery costs and update totalDeliveryCost
        this.order.shippingPackages['Epoxy Warehouse'].cartItems.forEach(
          (cartItem: CartItem) => {
            var itemDeliveryCost = 0;
            var costFound = false;
            ratesArray.forEach((deliveryRate: any) => {
              if (
                !cartItem.product.shippingClass ||
                cartItem.product.shippingClass === ''
              ) {
                //Shipping can't be calculated as shippingClass isn't included on the product so don't continue with any of the other checks
              } else if (cartItem.product.shippingClass == 'free-shipping') {
                //Shipping is free for this product
                costFound = true;
              } else if (
                cartItem.product.shippingClass === deliveryRate.shippingClass
              ) {
                //We have found the same rate type.
                if (deliveryRate.minItem || deliveryRate.maxItem) {
                  //If it's got a min or max item then make sure the item falls in the correct bracket
                  if (
                    cartItem.quantity > deliveryRate.minItem &&
                    cartItem.quantity < deliveryRate.maxItem
                  ) {
                    //This is the correct delivery rate as the quantityies match
                    //This is a quantity based cost so calculate it based on (fee+(quantity*cost))
                    itemDeliveryCost =
                      deliveryRate.fee + deliveryRate.cost * cartItem.quantity;
                    costFound = true;
                  } else {
                    //This isn't the correct delivery rate so keep looking
                  }
                } else {
                  //This isn't a quantity based cost so calculate it based on (fee+(quantity*cost))
                  itemDeliveryCost =
                    deliveryRate.fee + deliveryRate.cost * cartItem.quantity;
                  costFound = true;
                }
              }
            });
            if (costFound) {
              //We found a cost for this product so add it to the total
              console.log('itemDeliveryCost = ' + itemDeliveryCost);
              totalDeliveryCost = totalDeliveryCost + itemDeliveryCost;
            } else {
              //We didn't find a cost for this product so we have to set the whole delivery cost to TBC
              deliveryCalculated = false;
            }
          },
        );
        if (!deliveryCalculated) {
          //One of the products didn't have a delivery cost so we have to set the whole delivery to TBC
          this.order.shippingPackages['Epoxy Warehouse'].deliveryOptions.push({
            title: 'Site Delivery (Quote Required)',
            description: 'A quote is required for shipping to your location.',
            price: 'TBC',
            dispatchRegion: 'Sydney',
            deliveryRegion: this.order.shippingSuburb,
          });
        } else {
          //Don't add Fuel excise
          //Add the FLRHUB Costs
          var siteDeliveryPercentage = 0.0;
          this.order.shippingPackages['Epoxy Warehouse'].deliveryOptions.push({
            title: 'Site Delivery',
            description: 'Delivery right to your site, conditions apply',
            price:
              '$' +
              (totalDeliveryCost * (siteDeliveryPercentage + 1)).toFixed(2),
            dispatchRegion: 'Sydney',
            deliveryRegion: this.order.shippingSuburb,
          });
        }
      });
  }

  getPentarchCost(postcode: string, suburb: string, state: string) {
    var orderWeight: number = 0;
    var volumeWeight: number = 0;
    // console.log("Before pentarch");
    this.order.shippingPackages['Pentarch Forestry'].cartItems.forEach(
      (cartItem: CartItem) => {
        // console.log("cart item = "+JSON.stringify(cartItem));
        var totalWeight =
          parseFloat(cartItem.product.weight ? cartItem.product.weight : '0') *
          cartItem.quantity;
        var totalVolumeWeight = this.calculateVolumWeight(cartItem);
        // console.log("cart item weight = "+cartItem.product.weight);
        // console.log("cart item quantity = "+cartItem.quantity);
        //TODO change to boxes not SQ/M
        orderWeight = orderWeight + totalWeight;
        volumeWeight = volumeWeight + totalVolumeWeight;
      },
    );
    console.log('Item Weight pentarch = ' + orderWeight);
    console.log('Volume Weight pentarch = ' + volumeWeight);
    orderWeight = Math.max(orderWeight, volumeWeight);
    console.log('Usable Weight pentarch = ' + orderWeight);

    var postcodesObject: any = {};
    var suburbGroupsObject: any = {};
    //22.4 box
    //10 boxes
    //224kg
    //Adelaide 5000
    //ADELAIDE, $15.00, $75.00, $0.5250,
    //(224 * 0.525)+15
    //((117.6 +15 + 50)*1.28*1.17)
    //((117.6 + 50)*1.17*1.28)
    //((finalPrice+deliveryExtraPrice)*(siteDeliveryPercentage+1)*(fuelDeliveryPercentage+1))
    //((finalPrice+deliveryExtraPrice)*(siteDeliveryPercentage+1)*(fuelDeliveryPercentage+1))
    //$273
    var dispatchRegion = 'Sydney';
    var suburbGroupFile = 'assets/files/suburbGroups.csv';
    if (state === 'SA' || state === 'WA' || state === 'NT') {
      dispatchRegion = 'Adelaide';
      // console.log('Suburb file =  = '+'SA');
      suburbGroupFile = 'assets/files/suburbGroupsPentarchSA.csv';
    } else if (state === 'VIC' || state === 'TAS') {
      dispatchRegion = 'Melbourne';
      suburbGroupFile = 'assets/files/suburbGroupsPentarchVIC.csv';
      // console.log('Suburb file =  = '+'VIC');
    } else if (state === 'NSW') {
      dispatchRegion = 'Sydney';
      suburbGroupFile = 'assets/files/suburbGroupsPentarchNSW.csv';
      // console.log('Suburb file =  = '+'NSW');
    } else if (state === 'QLD') {
      dispatchRegion = 'Brisbane';
      suburbGroupFile = 'assets/files/suburbGroupsPentarchQLD.csv';
      // console.log('Suburb file =  = '+'QLD');
    }

    this.http
      .get('assets/files/postcodeGroups.csv', { responseType: 'text' })
      .subscribe(
        (data) => {
          let csvToRowArray = data.split('\n');
          // console.log('csv = '+data);
          for (let index = 1; index < csvToRowArray.length - 1; index++) {
            let row = csvToRowArray[index].split(',');
            postcodesObject[row[2].toUpperCase() + '-' + row[0].toUpperCase()] =
              {
                Suburb: row[0],
                Postcode: row[2],
                State: row[1],
                SuburbGroup: row[3].replace(/(\r\n|\n|\r)/gm, ''),
              };
          }
          // console.log(JSON.stringify(postcodesObject));
          var key = postcode.toUpperCase() + '-' + suburb.toUpperCase();
          var postcodeObject = postcodesObject[key];
          // console.log('get code = '+JSON.stringify(postcodesObject[key]));
          this.http.get(suburbGroupFile, { responseType: 'text' }).subscribe(
            async (data) => {
              let csvToRowArray = data.split('\n');
              // console.log('csv = '+data);
              for (let index = 1; index < csvToRowArray.length - 1; index++) {
                let row = csvToRowArray[index].split(',');
                suburbGroupsObject[row[0].toUpperCase()] = {
                  Location: row[0],
                  Base: row[1],
                  Minimum: row[2],
                  twoHundredAndFiftyKg: row[3],
                  fiveHundredKg: row[4],
                  oneThousandKg: row[5],
                  threeThousandKg: row[6],
                  twelveThousandKg: row[7],
                  greaterThanTwelveThousandKg: row[8],
                };
              }
              // console.log(JSON.stringify(suburbGroupsObject));
              if (
                postcodeObject != null &&
                postcodeObject.SuburbGroup.toUpperCase() !== 'QUOTE REQUIRED'
              ) {
                var pricingObject =
                  suburbGroupsObject[postcodeObject.SuburbGroup.toUpperCase()];
                var pickupPricingObject =
                  suburbGroupsObject['Sydney'.toUpperCase()];
                // console.log("Found price = "+JSON.stringify(pricingObject));
                // console.log("Found weight = "+orderWeight);
                var finalPrice: number = 0;
                var pickupFinalPrice: number = 0;
                if (orderWeight <= 250) {
                  finalPrice =
                    orderWeight *
                    parseFloat(
                      pricingObject.twoHundredAndFiftyKg.replace('$', ''),
                    );
                  if (postcodeObject.State === 'NSW') {
                    pickupFinalPrice =
                      orderWeight *
                      parseFloat(
                        pickupPricingObject.twoHundredAndFiftyKg.replace(
                          '$',
                          '',
                        ),
                      );
                  }
                } else if (orderWeight <= 500) {
                  finalPrice =
                    orderWeight *
                    parseFloat(pricingObject.fiveHundredKg.replace('$', ''));
                  if (postcodeObject.State === 'NSW') {
                    pickupFinalPrice =
                      orderWeight *
                      parseFloat(
                        pickupPricingObject.fiveHundredKg.replace('$', ''),
                      );
                  }
                } else if (orderWeight <= 1000) {
                  finalPrice =
                    orderWeight *
                    parseFloat(pricingObject.oneThousandKg.replace('$', ''));
                  if (postcodeObject.State === 'NSW') {
                    pickupFinalPrice =
                      orderWeight *
                      parseFloat(
                        pickupPricingObject.oneThousandKg.replace('$', ''),
                      );
                  }
                } else if (orderWeight <= 3000) {
                  finalPrice =
                    orderWeight *
                    parseFloat(pricingObject.threeThousandKg.replace('$', ''));
                  if (postcodeObject.State === 'NSW') {
                    pickupFinalPrice =
                      orderWeight *
                      parseFloat(
                        pickupPricingObject.threeThousandKg.replace('$', ''),
                      );
                  }
                } else if (orderWeight <= 12000) {
                  finalPrice =
                    orderWeight *
                    parseFloat(pricingObject.twelveThousandKg.replace('$', ''));
                  if (postcodeObject.State === 'NSW') {
                    pickupFinalPrice =
                      orderWeight *
                      parseFloat(
                        pickupPricingObject.twelveThousandKg.replace('$', ''),
                      );
                  }
                } else {
                  finalPrice =
                    orderWeight *
                    parseFloat(
                      pricingObject.greaterThanTwelveThousandKg.replace(
                        '$',
                        '',
                      ),
                    );
                  if (postcodeObject.State === 'NSW') {
                    pickupFinalPrice =
                      orderWeight *
                      parseFloat(
                        pickupPricingObject.greaterThanTwelveThousandKg.replace(
                          '$',
                          '',
                        ),
                      );
                  }
                }
                finalPrice =
                  finalPrice + parseFloat(pricingObject.Base.replace('$', ''));
                finalPrice = Math.max(
                  finalPrice,
                  parseFloat(pricingObject.Minimum.replace('$', '')),
                );
                var siteDeliveryPercentage = 0.28;
                var fuelDeliveryPercentage = 0.1745;
                await DatabaseHelper.instance
                  .getSettings()
                  .then((response) => {
                    fuelDeliveryPercentage = +response.gmkFuelExcise;
                  })
                  .catch((error) => {});
                var deliveryExtraPrice = 50;
                //If a role
                var palletFee = 0;
                Object.keys(this.order.shippingPackages).forEach((key: any) => {
                  this.order.shippingPackages[key].cartItems.forEach(
                    (cartItem: CartItem) => {
                      if (cartItem.product.formatType == 'Roll') {
                        palletFee = 200;
                        return;
                      }
                    },
                  );
                });

                if (pickupFinalPrice != 0) {
                  pickupFinalPrice =
                    pickupFinalPrice +
                    parseFloat(pickupPricingObject.Base.replace('$', ''));
                  pickupFinalPrice = Math.max(
                    pickupFinalPrice,
                    parseFloat(pickupPricingObject.Minimum.replace('$', '')),
                  );
                  var siteDeliveryPercentage = 0.28;
                  var fuelDeliveryPercentage = 0.1745;
                  await DatabaseHelper.instance
                    .getSettings()
                    .then((response) => {
                      fuelDeliveryPercentage = +response.gmkFuelExcise;
                    })
                    .catch((error) => {});
                  var deliveryExtraPrice = 50;
                  //If a role
                  var palletFee = 0;
                  Object.keys(this.order.shippingPackages).forEach(
                    (key: any) => {
                      this.order.shippingPackages[key].cartItems.forEach(
                        (cartItem: CartItem) => {
                          if (cartItem.product.formatType == 'Roll') {
                            palletFee = 200;
                            return;
                          }
                        },
                      );
                    },
                  );
                  this.order.shippingPackages[
                    'Pentarch Forestry'
                  ].deliveryOptions.push({
                    title: 'Pick up from supplier (Primary NSW Depot)',
                    description:
                      'You can pick your order up from Mainfreights primary depot in NSW',
                    price:
                      '$' +
                      (
                        finalPrice *
                        (siteDeliveryPercentage + 1) *
                        (fuelDeliveryPercentage + 1)
                      ).toFixed(2),
                    dispatchRegion: 'Sydney',
                    deliveryRegion: 'Sydney',
                    address: 'Maingreights primary depot in NSW',
                  });
                }
                // console.log("Final price for to site shipping = "+finalPrice);
                // console.log('Packages 3 = '+JSON.stringify(this.order.shippingPackages));
                // this.order.shippingPackages['Pentarch Forestry'].deliveryOptions.push({title:'Regional Depot Delivery ('+this.capitalizeFirstLetter(postcodeObject.SuburbGroup.toLowerCase()) +' Depot)', description:'You can pick your order up from Mainfreights regional depot in '+this.capitalizeFirstLetter(postcodeObject.SuburbGroup),price:'$'+(finalPrice*(siteDeliveryPercentage+1)*(fuelDeliveryPercentage+1)).toFixed(2)});
                // console.log('Final price = '+finalPrice);
                // console.log('deliveryExtraPrice price = '+deliveryExtraPrice);
                // console.log('siteDeliveryPercentage price = '+siteDeliveryPercentage);
                // console.log('fuelDeliveryPercentage price = '+fuelDeliveryPercentage);
                this.order.shippingPackages[
                  'Pentarch Forestry'
                ].deliveryOptions.push({
                  title: 'Site Delivery',
                  description: 'Delivery right to your site, conditions apply',
                  price:
                    '$' +
                    (
                      (finalPrice + deliveryExtraPrice) *
                      (siteDeliveryPercentage + 1) *
                      (fuelDeliveryPercentage + 1)
                    ).toFixed(2),
                  dispatchRegion: dispatchRegion,
                  deliveryRegion: postcodeObject.SuburbGroup,
                });
                // console.log('Packages 4 = '+JSON.stringify(this.order.shippingPackages));
              } else {
                //Quote is required for shipping
                // this.order.shippingPackages['Pentarch Forestry'].deliveryOptions = [];
                this.order.shippingPackages[
                  'Pentarch Forestry'
                ].deliveryOptions.push({
                  title: 'Site Delivery (Quote Required)',
                  description:
                    'A quote is required for shipping to your location.',
                  price: 'TBC',
                  dispatchRegion: dispatchRegion,
                  deliveryRegion: this.order.shippingSuburb,
                });
              }
              this.checkTempShippingPackages();
            },
            (error) => {
              console.error(error);
            },
          );
        },
        (error) => {
          console.error(error);
        },
      );
  }

  getPentarchDepotCost(lat: string, lon: string, state: string) {
    //Mainfreight
    //Get all depots
    var depots = [
      {
        name: 'Adelaide',
        address: '76/82 Gallipoli Drive',
        lat: -34.85449,
        lon: 138.57589,
        postcode: 5010,
        suburb: 'Regency Park',
        state: 'SA',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Epping (MEL)',
        address: '107 Gateway Blvd',
        lat: -37.63892,
        lon: 144.99746,
        postcode: 3076,
        suburb: 'Epping',
        state: 'VIC',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Clayton (MEL)',
        address: '1657 Centre Rd',
        lat: -37.92848,
        lon: 145.11833,
        postcode: 3168,
        suburb: 'Clayton',
        state: 'VIC',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Geelong',
        address: '7-13 Industrial Place',
        lat: -38.1842,
        lon: 144.37118,
        postcode: 3219,
        suburb: 'Breakwater',
        state: 'VIC',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Ballarat',
        address: '35 Grandlee Drive',
        lat: -37.53478,
        lon: 143.811,
        postcode: 3355,
        suburb: 'Wendouree',
        state: 'VIC',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Bendigo',
        address: '8a Wellsford Drive',
        lat: -36.7469596862793,
        lon: 144.3204803466797,
        postcode: 3550,
        suburb: 'East Bendigo',
        state: 'VIC',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Traralgon',
        address: '363 Princes Hwy',
        lat: -38.18953323364258,
        lon: 146.56883239746094,
        postcode: 3844,
        suburb: 'Traralgon East',
        state: 'VIC',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Prestons (SYD)',
        address: '50 Yarrawa Street',
        lat: -33.934146881103516,
        lon: 150.8646697998047,
        postcode: 2170,
        suburb: 'Prestons',
        state: 'NSW',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Erskine Park (SYD)',
        address: '34-42 Tyrone Pl',
        lat: -33.81517028808594,
        lon: 150.79119873046875,
        postcode: 2759,
        suburb: 'Erskine Park',
        state: 'NSW',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Newcastle',
        address: '20 Balbu Close',
        lat: -32.80803680419922,
        lon: 151.630859375,
        postcode: 2322,
        suburb: 'Beresfield',
        state: 'NSW',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Orange',
        address: '2-4 Ash Street',
        lat: -33.309104919433594,
        lon: 149.10394287109375,
        postcode: 2800,
        suburb: 'Orange',
        state: 'NSW',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Dubbo',
        address: '13 Asset Way',
        lat: -32.263614654541016,
        lon: 148.6512908935547,
        postcode: 2830,
        suburb: 'Dubbo',
        state: 'NSW',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Tamworth',
        address: '7-11 Hume Street',
        lat: -31.094263076782227,
        lon: 150.89410400390625,
        postcode: 2340,
        suburb: 'Tamworth',
        state: 'NSW',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Wollongong',
        address: '245 Shellharbour Road',
        lat: -34.479156494140625,
        lon: 150.8871307373047,
        postcode: 2505,
        suburb: 'Port Kembla',
        state: 'NSW',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Canberra',
        address: '30 Sawmill Circuit',
        lat: -35.39910888671875,
        lon: 149.1604461669922,
        postcode: 2620,
        suburb: 'Hume',
        state: 'ACT',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Bunbury',
        address: '34 Shanahan Road',
        lat: -33.36439514160156,
        lon: 115.67411804199219,
        postcode: 6230,
        suburb: 'Davenort',
        state: 'WA',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Perth',
        address: '1179 Abernethy Road',
        lat: -31.9175938,
        lon: 116.0015087,
        postcode: 6055,
        suburb: 'Hazelmere',
        state: 'WA',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Brisbane',
        address: '20 Distribution Street',
        lat: -27.647188186645508,
        lon: 153.00794982910156,
        postcode: 4110,
        suburb: 'Larapinta',
        state: 'QLD',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Gold Coast',
        address: '16 Precision Drive',
        lat: -27.96464729309082,
        lon: 153.36163330078125,
        postcode: 4214,
        suburb: 'Molendinar',
        state: 'QLD',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Mackay',
        address: '8 Merchant Street',
        lat: -21.19135856628418,
        lon: 149.15499877929688,
        postcode: 4740,
        suburb: 'Paget',
        state: 'QLD',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Narangba',
        address: '146 Potassium Street',
        lat: -27.196754455566406,
        lon: 152.99758911132812,
        postcode: 4504,
        suburb: 'Narangba',
        state: 'QLD',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Toowoomba',
        address: '41 Carrington Road',
        lat: -27.547136306762695,
        lon: 151.89544677734375,
        postcode: 4350,
        suburb: 'Torrington',
        state: 'QLD',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Townsville',
        address: '696 Ingham Road',
        lat: -19.2620518,
        lon: 146.7490804,
        postcode: 4814,
        suburb: 'Mt Louisa',
        state: 'QLD',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Devonport',
        address: 'Bass highway',
        lat: -41.20167922973633,
        lon: 146.40126037597656,
        postcode: 7307,
        suburb: 'Latrobe',
        state: 'TAS',
        distance: Number.MAX_VALUE,
      },
    ];

    //If lat and Lon are both 0 then just show all depots to pick from.
    if (lat == '0' && lon == '0') {
      return;
    }
    depots.forEach((depot) => {
      // console.log("Checking = "+depot.lat+","+depot.lon+","+lat+","+lon);
      var distance = UtilityHelper.getDistanceFromLatLonInKm(
        depot.lat,
        depot.lon,
        lat,
        lon,
      );
      // console.log("Distance = "+distance);
      depot.distance = distance;
    });
    depots.sort((a, b) => a.distance - b.distance);
    depots.forEach((depot) => {
      // console.log("Checking = "+depot.name+","+depot.distance+"km");
    });

    for (let index = 0; index < 3; index++) {
      let closestDepot = depots[index];
      let suburb = closestDepot.suburb + '';
      let postcode = closestDepot.postcode + '';
      let key = postcode.toUpperCase() + '-' + suburb.toUpperCase();
      //Get the depot that is closest to the site
      // console.log("Checking Depot = Suburb = "+suburb+", Postcode = "+postcode);
      // console.log("KEY TEST = "+key);
      var orderWeight: number = 0;
      var volumeWeight: number = 0;
      // console.log("Before pentarch");
      this.order.shippingPackages['Pentarch Forestry'].cartItems.forEach(
        (cartItem: CartItem) => {
          // console.log("cart item = "+JSON.stringify(cartItem));
          var totalWeight =
            parseFloat(
              cartItem.product.weight ? cartItem.product.weight : '0',
            ) * cartItem.quantity;
          var totalVolumeWeight = this.calculateVolumWeight(cartItem);
          // console.log("cart item weight = "+cartItem.product.weight);
          // console.log("cart item quantity = "+cartItem.quantity);
          //TODO change to boxes not SQ/M
          orderWeight = orderWeight + totalWeight;
          volumeWeight = volumeWeight + totalVolumeWeight;
        },
      );
      console.log('Item Weight pentarch = ' + orderWeight);
      console.log('Volume Weight pentarch = ' + volumeWeight);
      orderWeight = Math.max(orderWeight, volumeWeight);
      console.log('Usable Weight pentarch = ' + orderWeight);

      var postcodesObject: any = {};
      var suburbGroupsObject: any = {};

      this.http
        .get('assets/files/postcodeGroups.csv', { responseType: 'text' })
        .subscribe(
          (data) => {
            let csvToRowArray = data.split('\n');
            // console.log('csv = '+data);
            for (
              let rowIndex = 1;
              rowIndex < csvToRowArray.length - 1;
              rowIndex++
            ) {
              let row = csvToRowArray[rowIndex].split(',');
              postcodesObject[
                row[2].toUpperCase() + '-' + row[0].toUpperCase()
              ] = {
                Suburb: row[0],
                Postcode: row[2],
                State: row[1],
                SuburbGroup: row[3].replace(/(\r\n|\n|\r)/gm, ''),
              };
            }
            // console.log(JSON.stringify(postcodesObject));
            // console.log("KEY TEST = "+key);
            var postcodeObject = postcodesObject[key];
            // console.log('get code test = '+JSON.stringify(postcodesObject[key]));
            //Default to EX Sydney
            var dispatchRegion = 'Sydney';
            var suburbGroupFile = 'assets/files/suburbGroups.csv';
            if (state === 'SA' || state === 'WA' || state === 'NT') {
              dispatchRegion = 'Adelaide';
              // console.log('Suburb file =  = '+'SA');
              suburbGroupFile = 'assets/files/suburbGroupsPentarchSA.csv';
            } else if (state === 'VIC' || state === 'TAS') {
              dispatchRegion = 'Melbourne';
              suburbGroupFile = 'assets/files/suburbGroupsPentarchVIC.csv';
              // console.log('Suburb file =  = '+'VIC');
            } else if (state === 'NSW') {
              dispatchRegion = 'Sydney';
              suburbGroupFile = 'assets/files/suburbGroupsPentarchNSW.csv';
              // console.log('Suburb file =  = '+'NSW');
            } else if (state === 'QLD') {
              dispatchRegion = 'Brisbane';
              suburbGroupFile = 'assets/files/suburbGroupsPentarchQLD.csv';
              // console.log('Suburb file =  = '+'QLD');
            } else {
              console.log('State was = ' + state);
            }
            console.log('State was = ' + state);
            console.log('Got dispatch region = ' + dispatchRegion);
            console.log('#####################');

            //Add a free depot pickup during the first depot iteration
            if (index === 0) {
              console.log('Checking state etc = ' + state);
              if (dispatchRegion === 'Adelaide') {
                console.log('##################### in Adelaide');
                this.order.shippingPackages[
                  'Pentarch Forestry'
                ].deliveryOptions.push({
                  title:
                    'Warehouse Pickup (Pentarch ' +
                    dispatchRegion +
                    ' Warehouse)',
                  description:
                    'You can pick your order up from Pentarchs warehouse at 176-178 Magill Road, Norwood, SA, 5067',
                  price: 'Free',
                  dispatchRegion: 'Adelaide',
                  deliveryRegion: 'Adelaide',
                  address: '176-178 Magill Road, Norwood, SA, 5067',
                });
                console.log(
                  '##################### = ' +
                    JSON.stringify(
                      this.order.shippingPackages['Pentarch Forestry'],
                    ),
                );
              } else if (dispatchRegion === 'Melbourne') {
                console.log('##################### in Melb');
                this.order.shippingPackages[
                  'Pentarch Forestry'
                ].deliveryOptions.push({
                  title:
                    'Warehouse Pickup (Pentarch ' +
                    dispatchRegion +
                    ' Warehouse)',
                  description:
                    'You can pick your order up from Pentarchs warehouse at 98 Indian Road, Keysborough, VIC 3173',
                  price: 'Free',
                  dispatchRegion: 'Melbourne',
                  deliveryRegion: 'Melbourne',
                  address: '98 Indian Road, Keysborough, VIC 3173',
                });
              } else if (dispatchRegion === 'Sydney') {
                console.log('##################### in Sydney');
                this.order.shippingPackages[
                  'Pentarch Forestry'
                ].deliveryOptions.push({
                  title:
                    'Warehouse Pickup (Pentarch ' +
                    dispatchRegion +
                    ' Warehouse)',
                  description:
                    'You can pick your order up from Pentarchs warehouse at Unit D1, Regents Park estate, 391 Park Rd, Regents Park, NSW 2143',
                  price: 'Free',
                  dispatchRegion: 'Sydney',
                  deliveryRegion: 'Sydney',
                  address:
                    'Unit D1, Regents Park estate, 391 Park Rd, Regents Park, NSW 2143',
                });
              } else if (dispatchRegion === 'Brisbane') {
                console.log('##################### in Brisbane');
                this.order.shippingPackages[
                  'Pentarch Forestry'
                ].deliveryOptions.push({
                  title:
                    'Warehouse Pickup (Pentarch ' +
                    dispatchRegion +
                    ' Warehouse)',
                  description:
                    'You can pick your order up from Pentarchs warehouse at 838 Nudgee Rd, Northgate, QLD 4013',
                  price: 'Free',
                  dispatchRegion: 'Brisbane',
                  deliveryRegion: 'Brisbane',
                  address: '838 Nudgee Rd, Northgate, QLD 4013',
                });
              } else {
                console.log('##################### in something else?');
              }
            }

            // console.log('Suburb file =  = '+suburbGroupFile);
            this.http.get(suburbGroupFile, { responseType: 'text' }).subscribe(
              async (data) => {
                let csvToRowArray = data.split('\n');
                // console.log('csv = '+data);
                for (let index = 1; index < csvToRowArray.length - 1; index++) {
                  let row = csvToRowArray[index].split(',');
                  suburbGroupsObject[row[0].toUpperCase()] = {
                    Location: row[0],
                    Base: row[1],
                    Minimum: row[2],
                    twoHundredAndFiftyKg: row[3],
                    fiveHundredKg: row[4],
                    oneThousandKg: row[5],
                    threeThousandKg: row[6],
                    twelveThousandKg: row[7],
                    greaterThanTwelveThousandKg: row[8],
                  };
                }
                // console.log(JSON.stringify(suburbGroupsObject));
                if (
                  postcodeObject != null &&
                  postcodeObject.SuburbGroup.toUpperCase() !== 'QUOTE REQUIRED'
                ) {
                  var pricingObject =
                    suburbGroupsObject[
                      postcodeObject.SuburbGroup.toUpperCase()
                    ];
                  var pickupPricingObject =
                    suburbGroupsObject['Sydney'.toUpperCase()];
                  // console.log("Found price = "+JSON.stringify(pricingObject));
                  // console.log("Found weight = "+orderWeight);
                  var finalPrice: number = 0;
                  if (orderWeight <= 250) {
                    finalPrice =
                      orderWeight *
                      parseFloat(
                        pricingObject.twoHundredAndFiftyKg.replace('$', ''),
                      );
                  } else if (orderWeight <= 500) {
                    finalPrice =
                      orderWeight *
                      parseFloat(pricingObject.fiveHundredKg.replace('$', ''));
                  } else if (orderWeight <= 1000) {
                    finalPrice =
                      orderWeight *
                      parseFloat(pricingObject.oneThousandKg.replace('$', ''));
                  } else if (orderWeight <= 3000) {
                    finalPrice =
                      orderWeight *
                      parseFloat(
                        pricingObject.threeThousandKg.replace('$', ''),
                      );
                  } else if (orderWeight <= 12000) {
                    finalPrice =
                      orderWeight *
                      parseFloat(
                        pricingObject.twelveThousandKg.replace('$', ''),
                      );
                  } else {
                    finalPrice =
                      orderWeight *
                      parseFloat(
                        pricingObject.greaterThanTwelveThousandKg.replace(
                          '$',
                          '',
                        ),
                      );
                  }
                  finalPrice =
                    finalPrice +
                    parseFloat(pricingObject.Base.replace('$', ''));
                  finalPrice = Math.max(
                    finalPrice,
                    parseFloat(pricingObject.Minimum.replace('$', '')),
                  );
                  var siteDeliveryPercentage = 0.28;
                  var fuelDeliveryPercentage = 0.1745;
                  await DatabaseHelper.instance
                    .getSettings()
                    .then((response) => {
                      fuelDeliveryPercentage = +response.gmkFuelExcise;
                    })
                    .catch((error) => {});
                  var deliveryExtraPrice = 50;
                  //If a role
                  var palletFee = 0;
                  Object.keys(this.order.shippingPackages).forEach(
                    (key: any) => {
                      this.order.shippingPackages[key].cartItems.forEach(
                        (cartItem: CartItem) => {
                          if (cartItem.product.formatType == 'Roll') {
                            palletFee = 200;
                            return;
                          }
                        },
                      );
                    },
                  );
                  // console.log("Final price for to site shipping t = "+finalPrice);
                  // console.log("CHECKING DEPOT NUMBER = "+index);
                  // console.log('Packages 3 = '+JSON.stringify(this.order.shippingPackages));
                  this.order.shippingPackages[
                    'Pentarch Forestry'
                  ].deliveryOptions.splice(index, 0, {
                    title:
                      'Depot Pickup (' +
                      this.capitalizeFirstLetter(closestDepot.name) +
                      ' Depot)',
                    description:
                      'You can pick your order up from Mainfreights depot at ' +
                      closestDepot.address +
                      ', ' +
                      closestDepot.suburb +
                      ' ' +
                      closestDepot.postcode +
                      ' ' +
                      closestDepot.state +
                      ', ' +
                      closestDepot.distance.toFixed(1) +
                      'kms from the site address.',
                    price:
                      '$' +
                      (
                        finalPrice *
                        (siteDeliveryPercentage + 1) *
                        (fuelDeliveryPercentage + 1)
                      ).toFixed(2),
                    dispatchRegion: dispatchRegion,
                    deliveryRegion: postcodeObject.SuburbGroup,
                    address:
                      closestDepot.address +
                      ', ' +
                      closestDepot.suburb +
                      ' ' +
                      closestDepot.postcode +
                      ' ' +
                      closestDepot.state,
                  });
                  // this.order.shippingPackages['Pentarch Forestry'].deliveryOptions.splice(index, 0, {title:'Depot Pickup ('+this.capitalizeFirstLetter(postcodeObject.SuburbGroup.toLowerCase()) +' Depot)', description:'You can pick your order up from Mainfreights depot at '+closestDepot.address+', '+closestDepot.suburb+' '+closestDepot.postcode+' '+closestDepot.state+", "+closestDepot.distance.toFixed(1)+'kms from the site address.',price:'$'+(finalPrice*(siteDeliveryPercentage+1)*(fuelDeliveryPercentage+1)).toFixed(2), dispatchRegion:dispatchRegion,deliveryRegion:postcodeObject.SuburbGroup, address: closestDepot.address+', '+closestDepot.suburb+' '+closestDepot.postcode+' '+closestDepot.state});
                  // this.order.shippingPackages['Pentarch Forestry'].deliveryOptions.push({title:'Depot Pickup ('+this.capitalizeFirstLetter(postcodeObject.SuburbGroup.toLowerCase()) +' Depot)', description:'You can pick your order up from Mainfreights depot in '+this.capitalizeFirstLetter(postcodeObject.SuburbGroup),price:'$'+(finalPrice*(siteDeliveryPercentage+1)*(fuelDeliveryPercentage+1)).toFixed(2)});
                  // console.log('Packages 4 = '+JSON.stringify(this.order.shippingPackages));
                }
                this.checkTempShippingPackages();
              },
              (error) => {
                console.error(error);
              },
            );
          },
          (error) => {
            console.error(error);
          },
        );
    }
  }

  getInterfaceDepotCost(lat: string, lon: string, state: string) {
    //Mainfreight
    //Get all depots
    var depots = [
      {
        name: 'Adelaide',
        address: '76/82 Gallipoli Drive',
        lat: -34.85449,
        lon: 138.57589,
        postcode: 5010,
        suburb: 'Regency Park',
        state: 'SA',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Epping (MEL)',
        address: '107 Gateway Blvd',
        lat: -37.63892,
        lon: 144.99746,
        postcode: 3076,
        suburb: 'Epping',
        state: 'VIC',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Clayton (MEL)',
        address: '1657 Centre Rd',
        lat: -37.92848,
        lon: 145.11833,
        postcode: 3168,
        suburb: 'Clayton',
        state: 'VIC',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Geelong',
        address: '7-13 Industrial Place',
        lat: -38.1842,
        lon: 144.37118,
        postcode: 3219,
        suburb: 'Breakwater',
        state: 'VIC',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Ballarat',
        address: '35 Grandlee Drive',
        lat: -37.53478,
        lon: 143.811,
        postcode: 3355,
        suburb: 'Wendouree',
        state: 'VIC',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Bendigo',
        address: '8a Wellsford Drive',
        lat: -36.7469596862793,
        lon: 144.3204803466797,
        postcode: 3550,
        suburb: 'East Bendigo',
        state: 'VIC',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Traralgon',
        address: '363 Princes Hwy',
        lat: -38.18953323364258,
        lon: 146.56883239746094,
        postcode: 3844,
        suburb: 'Traralgon East',
        state: 'VIC',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Prestons (SYD)',
        address: '50 Yarrawa Street',
        lat: -33.934146881103516,
        lon: 150.8646697998047,
        postcode: 2170,
        suburb: 'Prestons',
        state: 'NSW',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Erskine Park (SYD)',
        address: '34-42 Tyrone Pl',
        lat: -33.81517028808594,
        lon: 150.79119873046875,
        postcode: 2759,
        suburb: 'Erskine Park',
        state: 'NSW',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Newcastle',
        address: '20 Balbu Close',
        lat: -32.80803680419922,
        lon: 151.630859375,
        postcode: 2322,
        suburb: 'Beresfield',
        state: 'NSW',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Orange',
        address: '2-4 Ash Street',
        lat: -33.309104919433594,
        lon: 149.10394287109375,
        postcode: 2800,
        suburb: 'Orange',
        state: 'NSW',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Dubbo',
        address: '13 Asset Way',
        lat: -32.263614654541016,
        lon: 148.6512908935547,
        postcode: 2830,
        suburb: 'Dubbo',
        state: 'NSW',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Tamworth',
        address: '7-11 Hume Street',
        lat: -31.094263076782227,
        lon: 150.89410400390625,
        postcode: 2340,
        suburb: 'Tamworth',
        state: 'NSW',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Wollongong',
        address: '245 Shellharbour Road',
        lat: -34.479156494140625,
        lon: 150.8871307373047,
        postcode: 2505,
        suburb: 'Port Kembla',
        state: 'NSW',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Canberra',
        address: '30 Sawmill Circuit',
        lat: -35.39910888671875,
        lon: 149.1604461669922,
        postcode: 2620,
        suburb: 'Hume',
        state: 'ACT',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Bunbury',
        address: '34 Shanahan Road',
        lat: -33.36439514160156,
        lon: 115.67411804199219,
        postcode: 6230,
        suburb: 'Davenort',
        state: 'WA',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Perth',
        address: '1179 Abernethy Road',
        lat: -31.9175938,
        lon: 116.0015087,
        postcode: 6055,
        suburb: 'Hazelmere',
        state: 'WA',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Brisbane',
        address: '20 Distribution Street',
        lat: -27.647188186645508,
        lon: 153.00794982910156,
        postcode: 4110,
        suburb: 'Larapinta',
        state: 'QLD',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Gold Coast',
        address: '16 Precision Drive',
        lat: -27.96464729309082,
        lon: 153.36163330078125,
        postcode: 4214,
        suburb: 'Molendinar',
        state: 'QLD',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Mackay',
        address: '8 Merchant Street',
        lat: -21.19135856628418,
        lon: 149.15499877929688,
        postcode: 4740,
        suburb: 'Paget',
        state: 'QLD',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Narangba',
        address: '146 Potassium Street',
        lat: -27.196754455566406,
        lon: 152.99758911132812,
        postcode: 4504,
        suburb: 'Narangba',
        state: 'QLD',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Toowoomba',
        address: '41 Carrington Road',
        lat: -27.547136306762695,
        lon: 151.89544677734375,
        postcode: 4350,
        suburb: 'Torrington',
        state: 'QLD',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Townsville',
        address: '696 Ingham Road',
        lat: -19.2620518,
        lon: 146.7490804,
        postcode: 4814,
        suburb: 'Mt Louisa',
        state: 'QLD',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Devonport',
        address: 'Bass highway',
        lat: -41.20167922973633,
        lon: 146.40126037597656,
        postcode: 7307,
        suburb: 'Latrobe',
        state: 'TAS',
        distance: Number.MAX_VALUE,
      },
    ];

    //If lat and Lon are both 0 then just show all depots to pick from.
    if (lat == '0' && lon == '0') {
      return;
    }
    depots.forEach((depot) => {
      // console.log("Checking = "+depot.lat+","+depot.lon+","+lat+","+lon);
      var distance = UtilityHelper.getDistanceFromLatLonInKm(
        depot.lat,
        depot.lon,
        lat,
        lon,
      );
      // console.log("Distance = "+distance);
      depot.distance = distance;
    });
    depots.sort((a, b) => a.distance - b.distance);
    depots.forEach((depot) => {
      // console.log("Checking = "+depot.name+","+depot.distance+"km");
    });

    for (let index = 0; index < 3; index++) {
      let closestDepot = depots[index];
      let suburb = closestDepot.suburb + '';
      let postcode = closestDepot.postcode + '';
      let key = postcode.toUpperCase() + '-' + suburb.toUpperCase();
      //Get the depot that is closest to the site
      // console.log("Checking Depot = Suburb = "+suburb+", Postcode = "+postcode);
      // console.log("KEY TEST = "+key);
      var orderWeight: number = 0;
      var volumeWeight: number = 0;
      // console.log("Before pentarch");
      this.order.shippingPackages['Interface'].cartItems.forEach(
        (cartItem: CartItem) => {
          // console.log("cart item = "+JSON.stringify(cartItem));
          var totalWeight =
            parseFloat(
              cartItem.product.weight ? cartItem.product.weight : '0',
            ) * cartItem.quantity;
          var totalVolumeWeight = this.calculateVolumWeight(cartItem);
          // console.log("cart item weight = "+cartItem.product.weight);
          // console.log("cart item quantity = "+cartItem.quantity);
          //TODO change to boxes not SQ/M
          orderWeight = orderWeight + totalWeight;
          volumeWeight = volumeWeight + totalVolumeWeight;
        },
      );
      console.log('Item Weight interface = ' + orderWeight);
      console.log('Volume Weight interface = ' + volumeWeight);
      orderWeight = Math.max(orderWeight, volumeWeight);
      console.log('Usable Weight interface = ' + orderWeight);

      var postcodesObject: any = {};
      var suburbGroupsObject: any = {};

      this.http
        .get('assets/files/postcodeGroups.csv', { responseType: 'text' })
        .subscribe(
          (data) => {
            let csvToRowArray = data.split('\n');
            // console.log('csv = '+data);
            for (let index = 1; index < csvToRowArray.length - 1; index++) {
              let row = csvToRowArray[index].split(',');
              postcodesObject[
                row[2].toUpperCase() + '-' + row[0].toUpperCase()
              ] = {
                Suburb: row[0],
                Postcode: row[2],
                State: row[1],
                SuburbGroup: row[3].replace(/(\r\n|\n|\r)/gm, ''),
              };
            }
            // console.log(JSON.stringify(postcodesObject));
            // console.log("KEY TEST = "+key);
            var postcodeObject = postcodesObject[key];
            // console.log('get code test = '+JSON.stringify(postcodesObject[key]));
            //Default to EX Sydney
            var dispatchRegion = 'Sydney';
            var suburbGroupFile = 'assets/files/suburbGroups.csv';

            // console.log('Suburb file =  = '+suburbGroupFile);
            this.http.get(suburbGroupFile, { responseType: 'text' }).subscribe(
              async (data) => {
                let csvToRowArray = data.split('\n');
                // console.log('csv = '+data);
                for (let index = 1; index < csvToRowArray.length - 1; index++) {
                  let row = csvToRowArray[index].split(',');
                  suburbGroupsObject[row[0].toUpperCase()] = {
                    Location: row[0],
                    Base: row[1],
                    Minimum: row[2],
                    twoHundredAndFiftyKg: row[3],
                    fiveHundredKg: row[4],
                    oneThousandKg: row[5],
                    threeThousandKg: row[6],
                    twelveThousandKg: row[7],
                    greaterThanTwelveThousandKg: row[8],
                  };
                }
                // console.log(JSON.stringify(suburbGroupsObject));
                if (
                  postcodeObject != null &&
                  postcodeObject.SuburbGroup.toUpperCase() !== 'QUOTE REQUIRED'
                ) {
                  var pricingObject =
                    suburbGroupsObject[
                      postcodeObject.SuburbGroup.toUpperCase()
                    ];
                  var pickupPricingObject =
                    suburbGroupsObject['Sydney'.toUpperCase()];
                  // console.log("Found price = "+JSON.stringify(pricingObject));
                  // console.log("Found weight = "+orderWeight);
                  var finalPrice: number = 0;
                  if (orderWeight <= 250) {
                    finalPrice =
                      orderWeight *
                      parseFloat(
                        pricingObject.twoHundredAndFiftyKg.replace('$', ''),
                      );
                  } else if (orderWeight <= 500) {
                    finalPrice =
                      orderWeight *
                      parseFloat(pricingObject.fiveHundredKg.replace('$', ''));
                  } else if (orderWeight <= 1000) {
                    finalPrice =
                      orderWeight *
                      parseFloat(pricingObject.oneThousandKg.replace('$', ''));
                  } else if (orderWeight <= 3000) {
                    finalPrice =
                      orderWeight *
                      parseFloat(
                        pricingObject.threeThousandKg.replace('$', ''),
                      );
                  } else if (orderWeight <= 12000) {
                    finalPrice =
                      orderWeight *
                      parseFloat(
                        pricingObject.twelveThousandKg.replace('$', ''),
                      );
                  } else {
                    finalPrice =
                      orderWeight *
                      parseFloat(
                        pricingObject.greaterThanTwelveThousandKg.replace(
                          '$',
                          '',
                        ),
                      );
                  }
                  finalPrice =
                    finalPrice +
                    parseFloat(pricingObject.Base.replace('$', ''));
                  finalPrice = Math.max(
                    finalPrice,
                    parseFloat(pricingObject.Minimum.replace('$', '')),
                  );
                  var siteDeliveryPercentage = 0.28;
                  var fuelDeliveryPercentage = 0.1745;
                  await DatabaseHelper.instance
                    .getSettings()
                    .then((response) => {
                      fuelDeliveryPercentage = +response.gmkFuelExcise;
                    })
                    .catch((error) => {
                      console.error(error);
                    });
                  var deliveryExtraPrice = 50;
                  //If a role
                  var palletFee = 0;
                  Object.keys(this.order.shippingPackages).forEach(
                    (key: any) => {
                      this.order.shippingPackages[key].cartItems.forEach(
                        (cartItem: CartItem) => {
                          if (cartItem.product.formatType == 'Roll') {
                            palletFee = 200;
                            return;
                          }
                        },
                      );
                    },
                  );
                  // console.log("Final price for to site shipping t = "+finalPrice);
                  // console.log("CHECKING DEPOT NUMBER = "+index);
                  // console.log('Packages 3 = '+JSON.stringify(this.order.shippingPackages));
                  this.order.shippingPackages[
                    'Interface'
                  ].deliveryOptions.splice(index, 0, {
                    title:
                      'Depot Pickup (' +
                      this.capitalizeFirstLetter(closestDepot.name) +
                      ' Depot)',
                    description:
                      'You can pick your order up from Mainfreights depot at ' +
                      closestDepot.address +
                      ', ' +
                      closestDepot.suburb +
                      ' ' +
                      closestDepot.postcode +
                      ' ' +
                      closestDepot.state +
                      ', ' +
                      closestDepot.distance.toFixed(1) +
                      'kms from the site address.',
                    price:
                      '$' +
                      (
                        finalPrice *
                        (siteDeliveryPercentage + 1) *
                        (fuelDeliveryPercentage + 1)
                      ).toFixed(2),
                    dispatchRegion: dispatchRegion,
                    deliveryRegion: postcodeObject.SuburbGroup,
                    address:
                      closestDepot.address +
                      ', ' +
                      closestDepot.suburb +
                      ' ' +
                      closestDepot.postcode +
                      ' ' +
                      closestDepot.state,
                  });
                  // this.order.shippingPackages['Interface'].deliveryOptions.splice(index, 0, {title:'Depot Pickup ('+this.capitalizeFirstLetter(postcodeObject.SuburbGroup.toLowerCase()) +' Depot)', description:'You can pick your order up from Mainfreights depot at '+closestDepot.address+', '+closestDepot.suburb+' '+closestDepot.postcode+' '+closestDepot.state+", "+closestDepot.distance.toFixed(1)+'kms from the site address.',price:'$'+(finalPrice*(siteDeliveryPercentage+1)*(fuelDeliveryPercentage+1)).toFixed(2), dispatchRegion:dispatchRegion,deliveryRegion:postcodeObject.SuburbGroup, address: closestDepot.address+', '+closestDepot.suburb+' '+closestDepot.postcode+' '+closestDepot.state});
                  // this.order.shippingPackages['Interface'].deliveryOptions.push({title:'Depot Pickup ('+this.capitalizeFirstLetter(postcodeObject.SuburbGroup.toLowerCase()) +' Depot)', description:'You can pick your order up from Mainfreights depot in '+this.capitalizeFirstLetter(postcodeObject.SuburbGroup),price:'$'+(finalPrice*(siteDeliveryPercentage+1)*(fuelDeliveryPercentage+1)).toFixed(2)});
                  // console.log('Packages 4 = '+JSON.stringify(this.order.shippingPackages));
                  this.checkTempShippingPackages();
                }
              },
              (error) => {
                console.error(error);
              },
            );
          },
          (error) => {
            console.error(error);
          },
        );
    }
  }

  getInterfaceCost(postcode: string, suburb: string, state: string) {
    var orderWeight: number = 0;
    var volumeWeight: number = 0;
    // console.log("Before pentarch");
    this.order.shippingPackages['Interface'].cartItems.forEach(
      (cartItem: CartItem) => {
        // console.log("cart item = "+JSON.stringify(cartItem));
        var totalWeight =
          parseFloat(cartItem.product.weight ? cartItem.product.weight : '0') *
          cartItem.quantity;
        var totalVolumeWeight = this.calculateVolumWeight(cartItem);
        // console.log("cart item weight = "+cartItem.product.weight);
        // console.log("cart item quantity = "+cartItem.quantity);
        //TODO change to boxes not SQ/M
        orderWeight = orderWeight + totalWeight;
        volumeWeight = volumeWeight + totalVolumeWeight;
      },
    );
    console.log('Item Weight interface = ' + orderWeight);
    console.log('Volume Weight interface = ' + volumeWeight);
    orderWeight = Math.max(orderWeight, volumeWeight);
    console.log('Usable Weight interface = ' + orderWeight);

    var postcodesObject: any = {};
    var suburbGroupsObject: any = {};

    this.http
      .get('assets/files/postcodeGroups.csv', { responseType: 'text' })
      .subscribe(
        (data) => {
          let csvToRowArray = data.split('\n');
          // console.log('csv = '+data);
          for (let index = 1; index < csvToRowArray.length - 1; index++) {
            let row = csvToRowArray[index].split(',');
            postcodesObject[row[2].toUpperCase() + '-' + row[0].toUpperCase()] =
              {
                Suburb: row[0],
                Postcode: row[2],
                State: row[1],
                SuburbGroup: row[3].replace(/(\r\n|\n|\r)/gm, ''),
              };
          }
          // console.log(JSON.stringify(postcodesObject));
          var key = postcode.toUpperCase() + '-' + suburb.toUpperCase();
          var postcodeObject = postcodesObject[key];
          // console.log('get code = '+JSON.stringify(postcodesObject[key]));
          this.http
            .get('assets/files/suburbGroups.csv', { responseType: 'text' })
            .subscribe(
              async (data) => {
                let csvToRowArray = data.split('\n');
                // console.log('csv = '+data);
                for (let index = 1; index < csvToRowArray.length - 1; index++) {
                  let row = csvToRowArray[index].split(',');
                  suburbGroupsObject[row[0].toUpperCase()] = {
                    Location: row[0],
                    Base: row[1],
                    Minimum: row[2],
                    twoHundredAndFiftyKg: row[3],
                    fiveHundredKg: row[4],
                    oneThousandKg: row[5],
                    threeThousandKg: row[6],
                    twelveThousandKg: row[7],
                    greaterThanTwelveThousandKg: row[8],
                  };
                }
                // console.log(JSON.stringify(suburbGroupsObject));
                if (
                  postcodeObject != null &&
                  postcodeObject.SuburbGroup.toUpperCase() !== 'QUOTE REQUIRED'
                ) {
                  var pricingObject =
                    suburbGroupsObject[
                      postcodeObject.SuburbGroup.toUpperCase()
                    ];
                  var pickupPricingObject =
                    suburbGroupsObject['Sydney'.toUpperCase()];
                  // console.log("Found price = "+JSON.stringify(pricingObject));
                  // console.log("Found weight = "+orderWeight);
                  var finalPrice: number = 0;
                  var pickupFinalPrice: number = 0;
                  if (orderWeight <= 250) {
                    finalPrice =
                      orderWeight *
                      parseFloat(
                        pricingObject.twoHundredAndFiftyKg.replace('$', ''),
                      );
                    if (postcodeObject.State === 'NSW') {
                      pickupFinalPrice =
                        orderWeight *
                        parseFloat(
                          pickupPricingObject.twoHundredAndFiftyKg.replace(
                            '$',
                            '',
                          ),
                        );
                    }
                  } else if (orderWeight <= 500) {
                    finalPrice =
                      orderWeight *
                      parseFloat(pricingObject.fiveHundredKg.replace('$', ''));
                    if (postcodeObject.State === 'NSW') {
                      pickupFinalPrice =
                        orderWeight *
                        parseFloat(
                          pickupPricingObject.fiveHundredKg.replace('$', ''),
                        );
                    }
                  } else if (orderWeight <= 1000) {
                    finalPrice =
                      orderWeight *
                      parseFloat(pricingObject.oneThousandKg.replace('$', ''));
                    if (postcodeObject.State === 'NSW') {
                      pickupFinalPrice =
                        orderWeight *
                        parseFloat(
                          pickupPricingObject.oneThousandKg.replace('$', ''),
                        );
                    }
                  } else if (orderWeight <= 3000) {
                    finalPrice =
                      orderWeight *
                      parseFloat(
                        pricingObject.threeThousandKg.replace('$', ''),
                      );
                    if (postcodeObject.State === 'NSW') {
                      pickupFinalPrice =
                        orderWeight *
                        parseFloat(
                          pickupPricingObject.threeThousandKg.replace('$', ''),
                        );
                    }
                  } else if (orderWeight <= 12000) {
                    finalPrice =
                      orderWeight *
                      parseFloat(
                        pricingObject.twelveThousandKg.replace('$', ''),
                      );
                    if (postcodeObject.State === 'NSW') {
                      pickupFinalPrice =
                        orderWeight *
                        parseFloat(
                          pickupPricingObject.twelveThousandKg.replace('$', ''),
                        );
                    }
                  } else {
                    finalPrice =
                      orderWeight *
                      parseFloat(
                        pricingObject.greaterThanTwelveThousandKg.replace(
                          '$',
                          '',
                        ),
                      );
                    if (postcodeObject.State === 'NSW') {
                      pickupFinalPrice =
                        orderWeight *
                        parseFloat(
                          pickupPricingObject.greaterThanTwelveThousandKg.replace(
                            '$',
                            '',
                          ),
                        );
                    }
                  }
                  finalPrice =
                    finalPrice +
                    parseFloat(pricingObject.Base.replace('$', ''));
                  finalPrice = Math.max(
                    finalPrice,
                    parseFloat(pricingObject.Minimum.replace('$', '')),
                  );
                  var siteDeliveryPercentage = 0.28;
                  var fuelDeliveryPercentage = 0.1745;
                  await DatabaseHelper.instance
                    .getSettings()
                    .then((response) => {
                      fuelDeliveryPercentage = +response.gmkFuelExcise;
                    })
                    .catch((error) => {});
                  var deliveryExtraPrice = 50;
                  //If a role
                  var palletFee = 0;
                  Object.keys(this.order.shippingPackages).forEach(
                    (key: any) => {
                      this.order.shippingPackages[key].cartItems.forEach(
                        (cartItem: CartItem) => {
                          if (cartItem.product.formatType == 'Roll') {
                            palletFee = 200;
                            return;
                          }
                        },
                      );
                    },
                  );

                  if (pickupFinalPrice != 0) {
                    pickupFinalPrice =
                      pickupFinalPrice +
                      parseFloat(pickupPricingObject.Base.replace('$', ''));
                    pickupFinalPrice = Math.max(
                      pickupFinalPrice,
                      parseFloat(pickupPricingObject.Minimum.replace('$', '')),
                    );
                    var siteDeliveryPercentage = 0.28;
                    var fuelDeliveryPercentage = 0.1745;
                    await DatabaseHelper.instance
                      .getSettings()
                      .then((response) => {
                        fuelDeliveryPercentage = +response.gmkFuelExcise;
                      })
                      .catch((error) => {});
                    var deliveryExtraPrice = 50;
                    //If a role
                    var palletFee = 0;
                    Object.keys(this.order.shippingPackages).forEach(
                      (key: any) => {
                        this.order.shippingPackages[key].cartItems.forEach(
                          (cartItem: CartItem) => {
                            if (cartItem.product.formatType == 'Roll') {
                              palletFee = 200;
                              return;
                            }
                          },
                        );
                      },
                    );
                    this.order.shippingPackages[
                      'Interface'
                    ].deliveryOptions.push({
                      title: 'Pick up from supplier (Primary NSW Depot)',
                      description:
                        'You can pick your order up from Mainfreights primary depot in NSW',
                      price:
                        '$' +
                        (
                          finalPrice *
                          (siteDeliveryPercentage + 1) *
                          (fuelDeliveryPercentage + 1)
                        ).toFixed(2),
                      dispatchRegion: 'Sydney',
                      deliveryRegion: 'Sydney',
                      address: 'Mainfreights primary depot in NSW',
                    });
                  }
                  // console.log("Final price for to site shipping = "+finalPrice);
                  // console.log('Packages 3 = '+JSON.stringify(this.order.shippingPackages));
                  // this.order.shippingPackages['Interface'].deliveryOptions.push({title:'Regional Depot Delivery ('+this.capitalizeFirstLetter(postcodeObject.SuburbGroup.toLowerCase()) +' Depot)', description:'You can pick your order up from Mainfreights regional depot in '+this.capitalizeFirstLetter(postcodeObject.SuburbGroup),price:'$'+(finalPrice*(siteDeliveryPercentage+1)*(fuelDeliveryPercentage+1)).toFixed(2)});
                  this.order.shippingPackages['Interface'].deliveryOptions.push(
                    {
                      title: 'Site Delivery',
                      description:
                        'Delivery right to your site, conditions apply',
                      price:
                        '$' +
                        (
                          (finalPrice + deliveryExtraPrice) *
                          (siteDeliveryPercentage + 1) *
                          (fuelDeliveryPercentage + 1)
                        ).toFixed(2),
                      dispatchRegion: 'Sydney',
                      deliveryRegion: postcodeObject.SuburbGroup,
                    },
                  );
                  // console.log('Packages 4 = '+JSON.stringify(this.order.shippingPackages));
                } else {
                  //Quote is required for shipping
                  this.order.shippingPackages['Interface'].deliveryOptions = [];
                  this.order.shippingPackages['Interface'].deliveryOptions.push(
                    {
                      title: 'Site Delivery (Quote Required)',
                      description:
                        'A quote is required for shipping to your location.',
                      price: 'TBC',
                      dispatchRegion: 'Sydney',
                      deliveryRegion: this.order.shippingSuburb,
                    },
                  );
                }
                this.checkTempShippingPackages();
              },
              (error) => {
                console.error(error);
              },
            );
        },
        (error) => {
          console.error(error);
        },
      );
  }

  getGodfreyHirstDepotCost(lat: string, lon: string) {
    //GMK
    //Get all depots
    var depots = [
      {
        name: 'Sydney Depot (Head office)',
        address: '82 Rodeo Road',
        lat: -34.026083,
        lon: 150.762928,
        postcode: 2557,
        suburb: 'Gregory Hills',
        state: 'NSW',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Adelaide Depot',
        address: '122-132 Purling Avenue',
        lat: -34.735481,
        lon: 138.63935,
        postcode: 5111,
        suburb: 'Edinburgh',
        state: 'SA',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Melbourne Depot',
        address: '21 Leakes Rd',
        lat: -37.845126,
        lon: 144.779143,
        postcode: 3026,
        suburb: 'Laverton North',
        state: 'VIC',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Perth Depot',
        address: '22 Glassford Rd',
        lat: -31.970984,
        lon: 115.953948,
        postcode: 6105,
        suburb: 'Kewdale',
        state: 'WA',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Brisbane Depot',
        address: '100 Lahrs Rd',
        lat: -27.766865,
        lon: 153.252289,
        postcode: 4208,
        suburb: 'Ormeau',
        state: 'QLD',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Newcastle Depot',
        address: 'Unit 2A, 19 Nelson Rd',
        lat: -32.940429,
        lon: 151.647148,
        postcode: 2285,
        suburb: 'Cardiff',
        state: 'NSW',
        distance: Number.MAX_VALUE,
      },
    ];

    //If lat and Lon are both 0 then just show all depots to pick from.
    if (lat == '0' && lon == '0') {
      return;
    }
    var closestDepot: any = null;
    depots.forEach((depot) => {
      // console.log("Tarkett Checking = "+depot.lat+","+depot.lon+","+lat+","+lon);
      var distance = UtilityHelper.getDistanceFromLatLonInKm(
        depot.lat,
        depot.lon,
        lat,
        lon,
      );
      // console.log("Tarkett Distance = "+distance);
      depot.distance = distance;
      if (closestDepot == null || distance < closestDepot.distance) {
        closestDepot = depot;
      }
    });
    // console.log("Tarkett Closest depot = "+closestDepot.name);

    var suburb = closestDepot.suburb + '';
    var postcode = closestDepot.postcode + '';
    //Get the depot that is closest to the site

    var smallOrderCost = 0;
    var additionalFreightCharge = 0;
    var timberPackingCost = 0;
    var totalQuantity = 0;
    var totalRollQuantity = 0;
    var packingAndOrderCost = 0;
    this.order.shippingPackages['GH Commercial'].cartItems.forEach(
      (cartItem: any) => {
        if (
          cartItem.product.unitType !== 'LM' &&
          cartItem.product.productCategory !== 'Carpet Rolls'
        ) {
          console.log('CARPET ROLLS NOT IN HERE!');
          totalQuantity = totalQuantity + cartItem.quantity;
        } else if (cartItem.product.productCategory == 'Carpet Rolls') {
          totalRollQuantity = totalRollQuantity + cartItem.quantity;
        }
        //If the users closest depot suburb is WA then increase the additionalFreightCharge by $1.50 p/lm and 0.38 cents p/m2
        if (closestDepot.state == 'WA') {
          if (cartItem.product.unitType == 'LM') {
            //Extra charge for LM products
            additionalFreightCharge =
              additionalFreightCharge + cartItem.quantity * 1.5;
          } else {
            //Extra cost for M2 products
            additionalFreightCharge =
              additionalFreightCharge + cartItem.quantity * 0.38;
          }
        }
        //Check to see if there is any timber in the order and if so add 55 to timberPackingCost
        console.log('Cart Item = ' + JSON.stringify(cartItem));
        if (cartItem.product.productCategory == 'Timber') {
          timberPackingCost = 55;
        }
      },
    );
    //Check to see if the total cartItem quantity is less than 36 and if so add 75 to smallOrderCost\
    if (totalQuantity < 36 && totalQuantity != 0 && totalRollQuantity < 10) {
      smallOrderCost = 75;
    }

    var depotCost = 75;
    packingAndOrderCost = +(
      timberPackingCost +
      smallOrderCost +
      additionalFreightCharge
    ).toFixed(2);
    this.order.shippingPackages['GH Commercial'].deliveryOptions.unshift({
      title:
        'Depot Pickup (' +
        this.capitalizeFirstLetter(suburb.toLowerCase()) +
        ' Depot)',
      description:
        'You can pick your order up from GMKs regional depot at ' +
        closestDepot.address +
        ', ' +
        closestDepot.suburb +
        ' ' +
        closestDepot.postcode +
        ' ' +
        closestDepot.state +
        ', ' +
        closestDepot.distance.toFixed(1) +
        'kms from the site address.',
      price:
        '$' +
        (
          depotCost +
          timberPackingCost +
          smallOrderCost +
          additionalFreightCharge
        ).toFixed(2),
      dispatchRegion: 'Melbourne',
      deliveryRegion: suburb,
      address:
        'GMK: ' +
        closestDepot.address +
        ', ' +
        closestDepot.suburb +
        ' ' +
        closestDepot.postcode +
        ' ' +
        closestDepot.state,
      packingAndOrderCost: packingAndOrderCost,
    });
    this.checkTempShippingPackages();
  }

  getTarkettDepotCost(lat: string, lon: string) {
    //GMK
    //Get all depots
    var depots = [
      {
        name: 'Sydney Depot (Head office)',
        address: '82 Rodeo Road',
        lat: -34.026083,
        lon: 150.762928,
        postcode: 2557,
        suburb: 'Gregory Hills',
        state: 'NSW',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Adelaide Depot',
        address: '122-132 Purling Avenue',
        lat: -34.735481,
        lon: 138.63935,
        postcode: 5111,
        suburb: 'Edinburgh',
        state: 'SA',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Melbourne Depot',
        address: '21 Leakes Rd',
        lat: -37.845126,
        lon: 144.779143,
        postcode: 3026,
        suburb: 'Laverton North',
        state: 'VIC',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Perth Depot',
        address: '22 Glassford Rd',
        lat: -31.970984,
        lon: 115.953948,
        postcode: 6105,
        suburb: 'Kewdale',
        state: 'WA',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Brisbane Depot',
        address: '100 Lahrs Rd',
        lat: -27.766865,
        lon: 153.252289,
        postcode: 4208,
        suburb: 'Ormeau',
        state: 'QLD',
        distance: Number.MAX_VALUE,
      },
      {
        name: 'Newcastle Depot',
        address: 'Unit 2A, 19 Nelson Rd',
        lat: -32.940429,
        lon: 151.647148,
        postcode: 2285,
        suburb: 'Cardiff',
        state: 'NSW',
        distance: Number.MAX_VALUE,
      },
    ];

    //If lat and Lon are both 0 then just show all depots to pick from.
    if (lat == '0' && lon == '0') {
      return;
    }
    var closestDepot: any = null;
    depots.forEach((depot) => {
      // console.log("Tarkett Checking = "+depot.lat+","+depot.lon+","+lat+","+lon);
      var distance = UtilityHelper.getDistanceFromLatLonInKm(
        depot.lat,
        depot.lon,
        lat,
        lon,
      );
      // console.log("Tarkett Distance = "+distance);
      depot.distance = distance;
      if (closestDepot == null || distance < closestDepot.distance) {
        closestDepot = depot;
      }
    });
    // console.log("Tarkett Closest depot = "+closestDepot.name);

    var suburb = closestDepot.suburb + '';
    var postcode = closestDepot.postcode + '';
    //Get the depot that is closest to the site

    var rollWeight: number = 0;
    var palletWeight: number = 0;
    let carpetRolls = 0;
    let vinylRolls = 0;
    let numberOfPallets = 0;

    var rollVolumeWeight: number = 0;
    var palletVolumeWeight: number = 0;

    this.order.shippingPackages['Tarkett'].cartItems.forEach(
      (cartItem: CartItem) => {
        var totalWeight =
          parseFloat(cartItem.product.weight ? cartItem.product.weight : '0') *
          cartItem.quantity;
        var totalRollVolumeWeight = this.calculateVolumWeight(cartItem);
        var totalPalletVolumeWeight = this.calculateVolumWeight(cartItem);
        if (cartItem.product.formatType == 'Roll') {
          rollWeight = rollWeight + totalWeight;
          rollVolumeWeight = rollVolumeWeight + totalRollVolumeWeight;
          if (cartItem.product.productCategory === 'Carpet Rolls') {
            carpetRolls += Math.ceil(
              cartItem.quantity /
                ((+cartItem.product.width * +cartItem.product.length) / 10000),
            );
          } else {
            vinylRolls += Math.ceil(
              cartItem.quantity /
                ((+cartItem.product.width * +cartItem.product.length) / 10000),
            );
          }
        } else {
          palletWeight = palletWeight + totalWeight;
          palletVolumeWeight = palletVolumeWeight + totalPalletVolumeWeight;
          numberOfPallets += Math.ceil(
            cartItem.quantity /
              +cartItem.product.surfacePerBox /
              +cartItem.product.boxPerPallet,
          );
        }
      },
    );
    console.log('Pallet Item Weight Tarkett = ' + palletWeight);
    console.log('Pallet Volume Weight Tarkett = ' + palletVolumeWeight);
    palletWeight = Math.max(palletWeight, palletVolumeWeight);
    console.log('Usable Pallet Weight Tarkett = ' + palletWeight);

    console.log('Roll Item Weight Tarkett = ' + rollWeight);
    console.log('Roll Volume Weight Tarkett = ' + rollVolumeWeight);
    //GMK/Tarkett don't do volume weight for rolls
    // rollWeight = Math.max(rollWeight,rollVolumeWeight);
    console.log('Usable Roll Weight Tarkett = ' + rollWeight);

    // console.log("Carpet Rolls: " + carpetRolls + ", Vinyl Rolls: " + vinylRolls + ", Number of Pallets: " + numberOfPallets);

    var postcodesObject: any = [];
    var suburbGroupsObject: any = {};

    this.http
      .get('assets/files/postcodeGroupsGMK.csv', { responseType: 'text' })
      .subscribe(
        (data) => {
          let csvToRowArray = data.split('\n');
          var postCodeGroup = {
            GroupName: 'QUOTE REQUIRED',
            PostCodeMin: '',
            PostCodeMax: '',
          };
          for (let index = 1; index < csvToRowArray.length - 1; index++) {
            let row = csvToRowArray[index].split(',');
            postcodesObject[index - 1] = {
              GroupName: row[0],
              PostCodeMin: row[1],
              PostCodeMax: row[2].replace(/(\r\n|\n|\r)/gm, ''),
            };
            if (
              Number.parseInt(postcode) >= Number.parseInt(row[1]) &&
              Number.parseInt(postcode) <=
                Number.parseInt(row[2].replace(/(\r\n|\n|\r)/gm, ''))
            ) {
              postCodeGroup = postcodesObject[index - 1];
            }
          }
          var key = postcode.toUpperCase() + '-' + suburb.toUpperCase();
          var postcodeObject: any = postCodeGroup;
          this.http
            .get('assets/files/suburbGroupsGMK.csv', { responseType: 'text' })
            .subscribe(
              async (data) => {
                let csvToRowArray = data.split('\n');
                for (let index = 1; index < csvToRowArray.length - 1; index++) {
                  let row = csvToRowArray[index].split(',');
                  suburbGroupsObject[
                    row[0].toUpperCase() +
                      '-' +
                      row[8].toUpperCase().replace(/(\r\n|\n|\r)/gm, '')
                  ] = {
                    Location: row[0],
                    Base: row[1],
                    Minimum: row[2],
                    oneHundredKg: row[3],
                    fiveHundredKg: row[4],
                    oneThousandKg: row[5],
                    fiveThousandKg: row[6],
                    greaterThanFiveThousandKg: row[7],
                  };
                }
                // console.log(JSON.stringify(suburbGroupsObject));
                // console.log("Getting things! - ");
                if (
                  postcodeObject != null &&
                  postcodeObject.GroupName.toUpperCase() !== 'QUOTE REQUIRED'
                ) {
                  var finalRollPrice: number = 0;
                  // console.log("Roll Weight = "+rollWeight);
                  if (rollWeight > 0) {
                    var pricingObject =
                      suburbGroupsObject[
                        postcodeObject.GroupName.toUpperCase() + '-ROLL'
                      ];
                    // console.log("TARKETT OBJ = "+postcodeObject.GroupName.toUpperCase()+"-ROLL");
                    if (rollWeight <= 100) {
                      finalRollPrice =
                        rollWeight *
                        parseFloat(pricingObject.oneHundredKg.replace('$', ''));
                    } else if (rollWeight <= 500) {
                      finalRollPrice =
                        rollWeight *
                        parseFloat(
                          pricingObject.fiveHundredKg.replace('$', ''),
                        );
                    } else if (rollWeight <= 1000) {
                      finalRollPrice =
                        rollWeight *
                        parseFloat(
                          pricingObject.oneThousandKg.replace('$', ''),
                        );
                    } else if (rollWeight <= 5000) {
                      finalRollPrice =
                        rollWeight *
                        parseFloat(
                          pricingObject.fiveThousandKg.replace('$', ''),
                        );
                    } else {
                      finalRollPrice =
                        rollWeight *
                        parseFloat(
                          pricingObject.greaterThanFiveThousandKg.replace(
                            '$',
                            '',
                          ),
                        );
                    }
                    finalRollPrice =
                      finalRollPrice +
                      parseFloat(pricingObject.Base.replace('$', ''));
                    finalRollPrice = Math.max(
                      finalRollPrice,
                      parseFloat(pricingObject.Minimum.replace('$', '')),
                    );
                    //If a roll then we need to package as a pallet
                    var palletFee = 0;
                    finalRollPrice = finalRollPrice + palletFee;
                  }
                  var finalPalletPrice: number = 0;
                  // console.log("Pallet Weight = "+palletWeight);
                  if (palletWeight > 0) {
                    var pricingObject =
                      suburbGroupsObject[
                        postcodeObject.GroupName.toUpperCase() + '-PALLET'
                      ];
                    if (palletWeight <= 100) {
                      finalPalletPrice =
                        palletWeight *
                        parseFloat(pricingObject.oneHundredKg.replace('$', ''));
                    } else if (palletWeight <= 500) {
                      finalPalletPrice =
                        palletWeight *
                        parseFloat(
                          pricingObject.fiveHundredKg.replace('$', ''),
                        );
                    } else if (palletWeight <= 1000) {
                      finalPalletPrice =
                        palletWeight *
                        parseFloat(
                          pricingObject.oneThousandKg.replace('$', ''),
                        );
                    } else if (palletWeight <= 5000) {
                      // console.log("Getting tarkett price = "+pricingObject.fiveThousandKg.replace('$',''));
                      finalPalletPrice =
                        palletWeight *
                        parseFloat(
                          pricingObject.fiveThousandKg.replace('$', ''),
                        );
                    } else {
                      finalPalletPrice =
                        palletWeight *
                        parseFloat(
                          pricingObject.greaterThanFiveThousandKg.replace(
                            '$',
                            '',
                          ),
                        );
                    }
                    finalPalletPrice =
                      finalPalletPrice +
                      parseFloat(pricingObject.Base.replace('$', ''));
                    finalPalletPrice = Math.max(
                      finalPalletPrice,
                      parseFloat(pricingObject.Minimum.replace('$', '')),
                    );
                  }

                  var siteDeliveryPercentage = 0.28;
                  var fuelDeliveryPercentage = 0.1745;
                  let perRollPrice = 0;
                  let perPalletPrice = 0;
                  await DatabaseHelper.instance
                    .getSettings()
                    .then((response) => {
                      fuelDeliveryPercentage = +response.gmkFuelExcise;
                      perRollPrice += +response.gmkRollCarpet * carpetRolls;
                      perRollPrice += +response.gmkRollVinyl * vinylRolls;
                      perPalletPrice +=
                        +response.gmkPalletStandard * numberOfPallets;
                    })
                    .catch((error) => {});
                  var deliveryExtraPrice = 500;
                  Object.keys(this.order.shippingPackages).forEach(
                    (key: any) => {
                      this.order.shippingPackages[key].cartItems.forEach(
                        (cartItem: CartItem) => {
                          if (cartItem.product.formatType == 'Roll') {
                            palletFee = 200;
                            return;
                          }
                        },
                      );
                    },
                  );
                  var finalPrice: number = 0;
                  // console.log("perRollPrice: " + perRollPrice + ", perPalletPrice: " + perPalletPrice);
                  finalPrice =
                    (finalRollPrice + finalPalletPrice) *
                    (siteDeliveryPercentage + 1) *
                    (fuelDeliveryPercentage + 1);
                  //Removed per pallet pricing
                  // finalPrice += this.tarkettHandlingFee + perRollPrice + perPalletPrice;
                  finalPrice += this.tarkettHandlingFee;
                  this.order.shippingPackages[
                    'Tarkett'
                  ].deliveryOptions.unshift({
                    title:
                      'Depot Pickup (' +
                      this.capitalizeFirstLetter(
                        postcodeObject.GroupName.toLowerCase(),
                      ) +
                      ' Depot)',
                    description:
                      'You can pick your order up from GMKs regional depot at ' +
                      closestDepot.address +
                      ', ' +
                      closestDepot.suburb +
                      ' ' +
                      closestDepot.postcode +
                      ' ' +
                      closestDepot.state +
                      ', ' +
                      closestDepot.distance.toFixed(1) +
                      'kms from the site address.',
                    price: '$' + finalPrice.toFixed(2),
                    dispatchRegion: 'Sydney',
                    deliveryRegion: postcodeObject.LocationName,
                  });
                  this.checkTempShippingPackages();
                }
              },
              (error) => {
                console.error(error);
              },
            );
        },
        (error) => {
          console.error(error);
        },
      );
  }

  getTarkettCost(postcode: string, suburb: string) {
    //GMK

    // const fs = require('fs');
    // let csvToJson = require('convert-csv-to-json');
    // var json = csvToJson.getJsonFromCsv('../../../assets/files/postcodeGroups.csv');
    // var jsonString = JSON.stringify(json);
    // console.log('string = '+jsonString);

    var rollWeight: number = 0;
    var palletWeight: number = 0;
    let carpetRolls = 0;
    let vinylRolls = 0;
    let numberOfPallets = 0;

    var rollVolumeWeight: number = 0;
    var palletVolumeWeight: number = 0;

    this.order.shippingPackages['Tarkett'].cartItems.forEach(
      (cartItem: CartItem) => {
        var totalWeight =
          parseFloat(cartItem.product.weight ? cartItem.product.weight : '0') *
          cartItem.quantity;
        var totalRollVolumeWeight = this.calculateVolumWeight(cartItem);
        var totalPalletVolumeWeight = this.calculateVolumWeight(cartItem);
        if (cartItem.product.formatType == 'Roll') {
          rollWeight = rollWeight + totalWeight;
          rollVolumeWeight = rollVolumeWeight + totalRollVolumeWeight;
          if (cartItem.product.productCategory === 'Carpet Rolls') {
            carpetRolls += Math.ceil(
              cartItem.quantity /
                ((+cartItem.product.width * +cartItem.product.length) / 10000),
            );
          } else {
            vinylRolls += Math.ceil(
              cartItem.quantity /
                ((+cartItem.product.width * +cartItem.product.length) / 10000),
            );
          }
        } else {
          palletWeight = palletWeight + totalWeight;
          palletVolumeWeight = palletVolumeWeight + totalPalletVolumeWeight;
          numberOfPallets += Math.ceil(
            cartItem.quantity /
              +cartItem.product.surfacePerBox /
              +cartItem.product.boxPerPallet,
          );
        }
      },
    );
    console.log('Pallet Item Weight Tarkett = ' + palletWeight);
    console.log('Pallet Volume Weight Tarkett = ' + palletVolumeWeight);
    palletWeight = Math.max(palletWeight, palletVolumeWeight);
    console.log('Usable Pallet Weight Tarkett = ' + palletWeight);

    console.log('Roll Item Weight Tarkett = ' + rollWeight);
    console.log('Roll Volume Weight Tarkett = ' + rollVolumeWeight);
    rollWeight = Math.max(rollWeight, rollVolumeWeight);
    console.log('Usable Roll Weight Tarkett = ' + rollWeight);

    var postcodesObject: any = [];
    var suburbGroupsObject: any = {};

    this.http
      .get('assets/files/postcodeGroupsGMK.csv', { responseType: 'text' })
      .subscribe(
        (data) => {
          let csvToRowArray = data.split('\n');
          // console.log('csv = '+data);
          var postCodeGroup = {
            GroupName: 'QUOTE REQUIRED',
            PostCodeMin: '',
            PostCodeMax: '',
            LocationName: '',
          };
          for (let index = 1; index < csvToRowArray.length - 1; index++) {
            let row = csvToRowArray[index].split(',');
            // console.log("row = "+row);
            postcodesObject[index - 1] = {
              GroupName: row[0],
              PostCodeMin: row[1],
              PostCodeMax: row[2].replace(/(\r\n|\n|\r)/gm, ''),
              LocationName: row[3],
            };
            if (
              Number.parseInt(postcode) >= Number.parseInt(row[1]) &&
              Number.parseInt(postcode) <=
                Number.parseInt(row[2].replace(/(\r\n|\n|\r)/gm, ''))
            ) {
              postCodeGroup = postcodesObject[index - 1];
            }
          }
          //Now we have a postCodeGroup so lets work out the cost
          //{'GroupName':'ADE', 'PostCodeMin':'5000','PostCodeMax':'5100'};
          //LOCATION,BASE,MINIMUM,1-100KG,101-500KG,501-1000KG,1001-5000KG,5000+KG

          // console.log(JSON.stringify(postcodesObject));
          var key = postcode.toUpperCase() + '-' + suburb.toUpperCase();
          var postcodeObject: any = postCodeGroup;
          // console.log('get code = '+JSON.stringify(postcodeObject));
          this.http
            .get('assets/files/suburbGroupsGMK.csv', { responseType: 'text' })
            .subscribe(
              async (data) => {
                let csvToRowArray = data.split('\n');
                // console.log('csv = '+data);
                for (let index = 1; index < csvToRowArray.length - 1; index++) {
                  let row = csvToRowArray[index].split(',');
                  // console.log("Group name 1 = "+row[0].toUpperCase()+"-"+row[8]);
                  suburbGroupsObject[
                    row[0].toUpperCase() +
                      '-' +
                      row[8].toUpperCase().replace(/(\r\n|\n|\r)/gm, '')
                  ] = {
                    Location: row[0],
                    Base: row[1],
                    Minimum: row[2],
                    oneHundredKg: row[3],
                    fiveHundredKg: row[4],
                    oneThousandKg: row[5],
                    fiveThousandKg: row[6],
                    greaterThanFiveThousandKg: row[7],
                  };
                }
                // console.log(JSON.stringify(suburbGroupsObject));
                if (
                  postcodeObject != null &&
                  postcodeObject.GroupName.toUpperCase() !== 'QUOTE REQUIRED'
                ) {
                  var finalRollPrice: number = 0;
                  if (rollWeight > 0) {
                    // console.log("groupName = "+suburbGroupsObject);
                    // console.log("Group name = "+postcodeObject.GroupName.toUpperCase());
                    var pricingObject =
                      suburbGroupsObject[
                        postcodeObject.GroupName.toUpperCase() + '-ROLL'
                      ];
                    // console.log("Found price = "+JSON.stringify(pricingObject));
                    // console.log("Found weight = "+rollWeight);
                    if (rollWeight <= 100) {
                      finalRollPrice =
                        rollWeight *
                        parseFloat(pricingObject.oneHundredKg.replace('$', ''));
                    } else if (rollWeight <= 500) {
                      finalRollPrice =
                        rollWeight *
                        parseFloat(
                          pricingObject.fiveHundredKg.replace('$', ''),
                        );
                    } else if (rollWeight <= 1000) {
                      finalRollPrice =
                        rollWeight *
                        parseFloat(
                          pricingObject.oneThousandKg.replace('$', ''),
                        );
                    } else if (rollWeight <= 5000) {
                      finalRollPrice =
                        rollWeight *
                        parseFloat(
                          pricingObject.fiveThousandKg.replace('$', ''),
                        );
                    } else {
                      finalRollPrice =
                        rollWeight *
                        parseFloat(
                          pricingObject.greaterThanFiveThousandKg.replace(
                            '$',
                            '',
                          ),
                        );
                    }
                    finalRollPrice =
                      finalRollPrice +
                      parseFloat(pricingObject.Base.replace('$', ''));
                    finalRollPrice = Math.max(
                      finalRollPrice,
                      parseFloat(pricingObject.Minimum.replace('$', '')),
                    );
                    //If a roll then we need to package as a pallet
                    var palletFee = 0;
                    finalRollPrice = finalRollPrice + palletFee;
                  }
                  var finalPalletPrice: number = 0;
                  if (palletWeight > 0) {
                    // console.log("Group = " + JSON.stringify(suburbGroupsObject));
                    // console.log("Looking for = "+postcodeObject.GroupName.toUpperCase()+"-PALLET");
                    var pricingObject =
                      suburbGroupsObject[
                        postcodeObject.GroupName.toUpperCase() + '-PALLET'
                      ];
                    // console.log("Found price = "+JSON.stringify(pricingObject));
                    // console.log("Found weight = "+palletWeight);
                    if (palletWeight <= 100) {
                      finalPalletPrice =
                        palletWeight *
                        parseFloat(pricingObject.oneHundredKg.replace('$', ''));
                    } else if (palletWeight <= 500) {
                      finalPalletPrice =
                        palletWeight *
                        parseFloat(
                          pricingObject.fiveHundredKg.replace('$', ''),
                        );
                    } else if (palletWeight <= 1000) {
                      finalPalletPrice =
                        palletWeight *
                        parseFloat(
                          pricingObject.oneThousandKg.replace('$', ''),
                        );
                    } else if (palletWeight <= 5000) {
                      finalPalletPrice =
                        palletWeight *
                        parseFloat(
                          pricingObject.fiveThousandKg.replace('$', ''),
                        );
                    } else {
                      finalPalletPrice =
                        palletWeight *
                        parseFloat(
                          pricingObject.greaterThanFiveThousandKg.replace(
                            '$',
                            '',
                          ),
                        );
                    }
                    finalPalletPrice =
                      finalPalletPrice +
                      parseFloat(pricingObject.Base.replace('$', ''));
                    finalPalletPrice = Math.max(
                      finalPalletPrice,
                      parseFloat(pricingObject.Minimum.replace('$', '')),
                    );
                  }

                  var siteDeliveryPercentage = 0.28;
                  var fuelDeliveryPercentage = 0.1745;
                  let perRollPrice = 0;
                  let perPalletPrice = 0;
                  await DatabaseHelper.instance
                    .getSettings()
                    .then((response) => {
                      fuelDeliveryPercentage = +response.gmkFuelExcise;
                      perRollPrice += +response.gmkRollCarpet * carpetRolls;
                      perRollPrice += +response.gmkRollVinyl * vinylRolls;
                      perPalletPrice +=
                        +response.gmkPalletStandard * numberOfPallets;
                    })
                    .catch((error) => {});
                  var deliveryExtraPrice = 500;
                  Object.keys(this.order.shippingPackages).forEach(
                    (key: any) => {
                      this.order.shippingPackages[key].cartItems.forEach(
                        (cartItem: CartItem) => {
                          if (cartItem.product.formatType == 'Roll') {
                            palletFee = 200;
                            return;
                          }
                        },
                      );
                    },
                  );
                  var finalPrice: number = 0;
                  finalPrice =
                    (finalRollPrice + finalPalletPrice + deliveryExtraPrice) *
                    (siteDeliveryPercentage + 1) *
                    (fuelDeliveryPercentage + 1);
                  //Removed per pallet pricing
                  // finalPrice += + this.tarkettHandlingFee + perRollPrice + perPalletPrice;
                  finalPrice += +this.tarkettHandlingFee;
                  // console.log("Final price for to site shipping = "+finalPrice);
                  //this.order.shippingPackages['Tarkett'].deliveryOptions.push({title:'Regional Depot Delivery ('+this.capitalizeFirstLetter(postcodeObject.GroupName.toLowerCase()) +' Depot)', description:'You can pick your order up from GMKs regional depot in '+this.capitalizeFirstLetter(postcodeObject.GroupName),price:'$'+(finalPrice*(siteDeliveryPercentage+1)*(fuelDeliveryPercentage+1)+this.tarkettHandlingFee).toFixed(2)});
                  this.order.shippingPackages['Tarkett'].deliveryOptions.push({
                    title: 'Site Delivery',
                    description:
                      'Delivery right to your site, conditions apply',
                    price: '$' + finalPrice.toFixed(2),
                    dispatchRegion: 'Sydney',
                    deliveryRegion: postcodeObject.LocationName,
                  });
                } else {
                  //Quote is required for shipping
                  this.order.shippingPackages['Tarkett'].deliveryOptions = [];
                  this.order.shippingPackages['Tarkett'].deliveryOptions.push({
                    title: 'Site Delivery (Quote Required)',
                    description:
                      'A quote is required for shipping to your location.',
                    price: 'TBC',
                    dispatchRegion: 'Sydney',
                    deliveryRegion: postcodeObject.LocationName,
                  });
                }
                this.checkTempShippingPackages();
              },
              (error) => {
                console.error(error);
              },
            );
        },
        (error) => {
          console.error(error);
        },
      );
  }

  dateChanged() {
    //This removes the focus on the date field
    (document.activeElement as HTMLElement).blur();
    // console.log('date changes = ' + cartItem.dispatchDate);
    this.errors.deliveryDate = false;
  }

  capitalizeFirstLetter(string: any) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  keysForObject(object: Object): string[] {
    return Object.keys(object);
  }

  validate() {
    if (!this.startValidation) return false;
    this.errors = {};
    if (!this.order.deliveryDate) {
      this.errors.deliveryDate = true;
    }
    if (!this.order.shippingName) {
      this.errors.shippingName = true;
    }
    if (
      !this.order.shippingPhone ||
      !UtilityHelper.isPhoneValid(this.order.shippingPhone)
    ) {
      this.errors.shippingPhone = true;
    }
    if (!this.order.shippingAddress) {
      this.errors.shippingAddress = true;
    }
    this.keysForObject(this.order.shippingPackages).forEach(
      (packageKey: any) => {
        // console.log('delivery option', this.order.shippingPackages[packageKey].deliveryOption.option)
        if (!this.order.shippingPackages[packageKey].deliveryOption.option) {
          this.errors['deliveryOption-' + packageKey] = true;
        }

        // run validation for the site delivery fields
        if (
          this.order.shippingPackages[packageKey].deliveryOption.option
            .toLowerCase()
            .includes('site delivery')
        ) {
          if (!this.order.shippingPackages[packageKey].siteContact) {
            this.errors['siteContact-' + packageKey] = true;
          }

          if (!this.order.shippingPackages[packageKey].siteContactNumber) {
            this.errors['siteContactNumber-' + packageKey] = true;
          }

          if (
            !this.order.shippingPackages[packageKey].deliverySiteOpeningTime
          ) {
            this.errors['siteOpenTime-' + packageKey] = true;
          }

          if (
            !this.order.shippingPackages[packageKey].deliverySiteClosingTime
          ) {
            this.errors['deliveryHours-' + packageKey] = true;
          }

          if (
            !this.order.shippingPackages[packageKey].deliveryDropOffLocation
          ) {
            this.errors['deliveryDropOffLocation-' + packageKey] = true;
          }
        }
      },
    );
    console.log(JSON.stringify(this.errors));
    return Object.keys(this.errors).length === 0;
  }

  next() {
    this.startValidation = true;
    if (this.validate()) {
      if (this.data) {
        DatabaseHelper.instance.dataCallbacks.dataCallback({
          page: 'payment',
          order: this.order,
        });
      } else {
        this.router.navigateByUrl('payment', { state: { order: this.order } });
        this.user.save();
      }
    }
  }

  selectDeliveryOption(
    option: string,
    price: number,
    supplier: string,
    dispatchRegion: string,
    deliveryRegion: string,
    address: string,
    packingAndOrderCost?: number,
  ) {
    this.order.shippingPackages[supplier].deliveryOption = {
      option: option,
      cost: price + '',
      dispatchRegion: dispatchRegion,
      deliveryRegion: deliveryRegion,
      address: address ? address : '',
      packingAndOrderCost: packingAndOrderCost ? packingAndOrderCost : 0,
    };
    AnalyticsHelper.instance.logEvent('delivery_option_selected', {
      delivery_option: option,
      supplier: supplier,
      price: price,
    });
    this.validate();
  }

  unitTypeToMeasure(unitType: string) {
    return UtilityHelper.unitTypeToMeasure(unitType);
  }

  get user() {
    return AuthHelper.instance.user;
  }

  get isPhone() {
    return UtilityHelper.isPhone();
  }

  get containsLessThan2WeldRodsOnly() {
    let bool = true;
    let weldRods = 0;
    this.order.cart.forEach((item) => {
      if (item.quantity > 0) {
        bool = false;
      } else {
        item.lineItems.forEach((lineItem) => {
          weldRods += lineItem.quantity;
        });
      }
    });
    return bool && weldRods <= 2;
  }

  back() {
    window.history.back();
  }

  get loading() {
    return !AuthHelper.instance.userLoaded;
  }
}
