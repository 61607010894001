import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { User } from 'src/app/classes/User';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';
import { PaymentsHelper } from 'src/app/helpers/PaymentsHelper';
import { UtilityHelper } from 'src/app/helpers/UtilityHelper';

@Component({
  selector: 'app-sign-up-payment',
  templateUrl: './sign-up-payment.component.html',
  styleUrls: ['./sign-up-payment.component.scss']
})
export class SignUpPaymentComponent implements OnInit {

  errors: any = {};
  errorMessage: string = "";
  loading = false;
  startValidation: boolean = false;

  name: string = '';
  cardNumber: string = '';
  expiry: string = '';
  cvc: string = '';

  discountBoxExpanded = false;
  loadingDiscountCode = false;
  discountCode = "";
  discountDescription = "";

  constructor(private router: Router) {

  }

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView("Sign Up Payment");
  }

  validate(): boolean {
    this.errors = {};
    if (!this.startValidation) return false;
    if (!this.name) {
      this.errors.name = true;
    }
    if (!this.cardNumber) {
      this.errors.cardNumber = true;
    }
    if (!this.expiry || this.expiry.length < 5) {
      this.errors.expiry = true;
    }
    if (!this.cvc) {
      this.errors.cvc = true;
    }
    return Object.keys(this.errors).length === 0;
  }

  signUp() {
    this.errorMessage = '';
    this.startValidation = true;
    if (!this.validate()) {
      return;
    }
    this.loading = true;
    PaymentsHelper.instance.createCardToken(this.name, this.cardNumber, this.cvc, this.expiry).then(val => {
      if (val.error) {
        val.error.data.error.fieldErrors.forEach((e: any) => {
          if (e.field === "card.name") {
            this.errors.name = true;
          } else if (e.field === "card.number") {
            this.errors.cardNumber = true;
          } else if (e.field === "card.expMonth") {
            this.errors.expiry = true;
          } else if (e.field === "card.expYear") {
            this.errors.expiry = true;
          } else if (e.field === "card.cvc") {
            this.errors.cvc = true;
          }
        });
        this.loading = false;
      } else {
        this.user.card = val.card;
        PaymentsHelper.instance.createSimplifyCustomer(this.user).then(val3 => {
          this.user.simplifyCustomerId = val3.id;
          PaymentsHelper.instance.updateSimplifyCustomer(this.user, val.id).then(async val4 => {
            this.user.status = 'PENDING';
            await this.user.save();
            this.loading = false
            await DatabaseHelper.instance.sendAccountUnderReviewEmail(this.user);
            this.router.navigateByUrl('account-pending');
          });
        });
      }
    }).catch(err => {
      this.loading = false;
    });
  }

  applyDiscountCode() {
    if (!this.discountCode) {
      this.errors.discountCode = true;
      return;
    }
    this.loadingDiscountCode = true;
    this.discountDescription = "";
    this.user.discountCode = "";
    this.user.discountCodeId = "";
    DatabaseHelper.instance.applyCouponToSubscription(this.discountCode).then(val => {
      if (val.error) {
        this.errors.discountCode = true;
      } else {
        this.errors.discountCode = false;
        this.discountDescription = "Discount applied (" + val.description + ")";
        this.user.discountCode = val.couponCode;
        this.user.discountCodeId = val.id;
      }
    }).catch(() => {
      this.errors.discountCode = true;
    }).finally(() => this.loadingDiscountCode = false);
  }

  get user() {
    return AuthHelper.instance.user;
  }

  back() {
    window.history.back();
  }

}
