
<div class="page-wrapper">

  <header id="masthead">
      <div class="inner boxed center-logo">
        <div class="site-logo"><a href="/"><img src="/assets/images/logo.png" width="165" height="50" alt="Flrhub Logo"/></a></div>
      </div>
  </header><!-- #masthead -->

  <div class="page-content">



      <section id="entry-content" class="entry-content boxed">
          <div class="container">
              <div class="heading">
                  <h1>Delivery Policy</h1>
                  <div class="context hierarchy-list">
                      <p>This Delivery Policy (“Policy”), as amended or replaced from time to time, applies to the delivery of any Goods to be supplied by FLRHUB to the Customer, or any third party on the Customer’s behalf (“Customer”).</p>

<ol class="normal-ol">
  <li>Delivery of Goods to the Customer shall be made at the premises indicated in the invoice issued by FLRHUB for such Goods, or such other order, shipment documentation or other notification issued by FLRHUB.</li>
  <li>FLRHUB will use reasonable endeavours to deliver the Goods to the Customer on or before the delivery date notified to the Customer (the “Delivery Date”). However, any Delivery Date is an estimate only and time for delivery shall not be of the essence.</li>
  <li>Subject to any rights the Customer has under the Australian Consumer Law, FLRHUB will not be liable for:
    <div class="alpha-list">
      <div class="list">any failure to deliver, or delay in delivery of Goods;</div>
      <div class="list">any damage or loss due to unloading or packaging of Goods; and</div>
      <div class="list">any damage to property caused upon entering premises to deliver the Goods.</div>
    </div>
  </li>
  <li>Goods shall be dispatched from the premises of the relevant supplier(s) or manufacturer(s) of such Goods, and not from FLRHUB. Goods are not shipped or delivered on weekends or holidays. If FLRHUB’s relevant supplier(s) or manufacturer(s) (as relevant) is experiencing a high volume of orders, shipments may be delayed.</li>
  <li>Unless FLRHUB and the Customer specifically agree to the contrary, Goods may be delivered by instalments. If Goods are delivered by instalments then: (a) each delivery shall constitute a separate agreement and failure by FLRHUB to deliver one or more of the instalments in accordance with this Policy or the Terms and Conditions of Sale, or any claim by the Customer in respect of any one or more instalments, shall not entitle the Customer to treat the Terms and Conditions of Sale as a whole as repudiated; and (b) FLRHUB is entitled to complete delivery of the Goods by instalment despite any delay or failure by FLRHUB to deliver any instalment.</li>
  <li>The Customer acknowledges that FLRHUB will not be liable for any direct, indirect or consequential loss (all three of which terms include, without limitation, loss of profits, loss of business, depletion of goodwill and like loss), costs, damages, charges or expenses caused directly or indirectly by any failure to deliver the goods on the Delivery Date (even if caused by FLRHUB’s negligence). Late delivery of the Goods will not entitle the Customer to rescind or repudiate any agreement.</li>
  <li>The Customer must ensure there is clear and safe access for delivery and that a person is present at the delivery premises who is authorised to accept delivery.</li>
  <li>The Customer agrees to accept delivery of the Goods at any time during normal business hours and shall provide all necessary assistance and facilities for off-loading at its own cost, using only certified materials, handling equipment and work practices within occupational health and safety guidelines.</li>
  <li>If the Customer, or any person authorised to accept delivery, is not in attendance at the delivery premises, FLRHUB may in its absolute discretion, and without prejudice to all rights and remedies which FLRHUB has under this Policy, the Terms and Conditions of Sale or at law or in equity:
    <div class="alpha-list">
      <div class="list">leave any Goods at the premises if the carrier considers it is safe and appropriate to do so, and FLRHUB shall not be responsible for any claims, damages, costs, or expenses arising or resulting there from including any claim that the Goods were not delivered; or</div>
      <div class="list">return the Goods to a third-party location, and in either case the Goods will be deemed to have been delivered to the Customer.</div>
    </div>
  </li>
  <li>The Customer will, unless FLRHUB and the Customer otherwise agree, bear the cost of delivery of the Goods. Delivery will be calculated based on matters including, without limitation, courier/shipping agent, weight and delivery location.</li>
  <li>The Customer will be liable for all extra costs, charges, losses or expenses incurred by FLRHUB including, in respect of any returned deliveries, and all delivery, handling and storage charges associated with Goods being returned and redelivered. If the Goods are stored at a third-party location, the Customer will be liable to pay storage charges at the rate notified by FLRHUB.</li>
  <li>The Customer will indemnify FLRHUB against any losses, costs or expenses incurred by FLRHUB due to any failure by the Customer to accept the Goods at the time of delivery.</li>
  <li>In this Policy unless the contrary intention appears:
    <div class="alpha-list">
      <div class="list"><strong>“Australian Consumer Law”</strong> means the Australian Consumer Law contained in Schedule 2 of the Competition and Consumer Act 2010 (Cth);</div>
      <div class="list"><strong>“FLRHUB”</strong> means FLRHUB Pty Ltd and any and all of its “Related Bodies Corporate” as such term is defined in the Corporations Act 2001 (Cth);</div>
      <div class="list"><strong>“Goods”</strong> means any goods FLRHUB supplies to the Customer whether on consignment or otherwise under the Terms and Conditions of Sale. They include goods described on any invoice, quotation, work authorisation or any other forms or notification FLRHUB provides the Customer or any order the Customer gives FLRHUB.</div>
      <div class="list"><strong>“Terms and Conditions of Sale”</strong> means FLRHUB’s Terms and Conditions of Sale, as amended or replaced from time to time, which applied to the supply of Goods to the Customer, and in the case of a trade customer, means, where applicable, the terms and conditions of sale incorporated into the Commercial Credit Trade Account the extent of any inconsistency or conflict with the Terms and Conditions of Sale.</div>
      <div class="list">Any capitalised term used in this Policy which is not otherwise defined in this Policy but is defined in the Terms and Conditions of Sale has the same meaning as in the Terms and Conditions of Sale.</div>
    </div>
  </li>
</ol>


                  </div>
              </div>
          </div>
      </section><!-- .entry content -->





      <section id="footer" class="section-footer color-white reveal-blocks">
          <div class="container">
              <h2 class="h1">Any questions?</h2>
              <h3>Please email us at <a href="mailto:team@flrhub.com.au" target="_blank">team@flrhub.com.au</a></h3>
          </div>
          <div class="copyright-lines">
              <div class="inner">
                  <div class="copy">&copy;2001-<span id="current-year">2022</span> All Rights Reserved. FLRHUB®</div>
                  <nav class="links">
                      <ul class="ul-reset">
                          <li><a href="/privacy/">Privacy</a></li>
                          <li><a href="/terms/">Terms</a></li>
                      </ul>
                  </nav>
                  <div class="credit">Built by <a href="https://kwpx.au" target="_blank"><em>kwpx</em></a></div>
              </div>
          </div>
      </section>



  </div><!-- .page-content -->
</div><!-- .page-wrapper -->
