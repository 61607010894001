<div class="page-container">
    <img class="logo hide-phone" src="../../../assets/images/logo.png">
    <div class="content-container">
        <div class="header show-phone">
            <div class="small-button back-button" (click)="back()">Back</div>
            <div class="header-title">Check Out</div>
        </div>
        <div class="content">
            <div class="column grey-bg">
                <div class="small-button back-button" (click)="back()">Back</div>
                <div class="title hide-phone">Check Out</div>
                <div class="steps">
                    <div class="step complete">
                        <div class="circle"><img src="../../../assets/images/check_icon.png"></div>
                        <div class="text">Shipping</div>
                    </div>
                    <div class="step active">
                        <div class="circle">2</div>
                        <div class="text">Payment</div>
                    </div>
                    <div class="step">
                        <div class="circle">3</div>
                        <div class="text">Review</div>
                    </div>
                </div>
                <div class="cart-total-container">
                    <div class="title">Cart Total</div>
                    <div class="total-rows">
                        <div class="total-item">Subtotal:</div>
                        <div class="total-cost">{{order.getSubTotal()}}</div>
                    </div>
                    <div class="total-rows" *ngIf="order.getBulkDiscount()">
                        <div class="total-item">Discount (Bulk order):</div>
                        <div class="total-cost">-{{this.order.getBulkDiscount()}}</div>
                    </div>
                    <div class="total-rows" *ngIf="this.order.discountCodeAmount">
                        <div class="total-item">Discount ({{this.order.discountCode}}):</div>
                        <div class="total-cost">-{{this.order.discountCodeAmount}}</div>
                    </div>
                    <div class="total-rows"
                        *ngIf="order.paymentOption === 'Credit Card Old' || order.paymentOption === 'Bank Transfer Old'">
                        <div class="total-item">Discount* (Payment option)</div>
                        <div class="total-cost">{{'-'+order.getTotalDiscount()}}</div>
                    </div>
                    <div *ngFor="let parcelKey of keysForObject(order.shippingPackages); let supplierIndex = index" class="total-rows">
                        <div class="total-item">Parcel {{supplierIndex+1}} - {{parcelKey}} - {{order.shippingPackages[parcelKey].deliveryOption.option}}:</div>
                        <div class="total-cost shipping-cost">{{order.shippingPackages[parcelKey].deliveryOption.cost}}</div>
                    </div>
                    <div class="total-rows">
                        <div class="total-item">GST:</div>
                        <div class="total-cost">{{order.getGst()}}</div>
                    </div>
                    <div class="total-rows" *ngIf="order.paymentOption === 'Credit Card'">
                      <div class="total-item">1.85% Surcharge:</div>
                      <div class="total-cost">{{ order.getSurchargeAmount() }}</div>
                    </div>
                    <div class="total-rows">
                        <div class="total-item">Total:</div>
                        <div class="total-cost">{{order.getTotalWithGst()}}</div>
                    </div>
                </div>
            </div>
            <div class="column">
                <div class="title hide-phone">Choose your payment option</div>
                <div class="payment-options-container" [ngClass]="{error: errors.paymentOption}">
                    <div class="payment-option" (click)="selectPaymentOption('Credit Card')">
                        <div class="payment-title-container">
                            <div class="check-box" [ngClass]="{selected: order.paymentOption === 'Credit Card'}">
                                <img src="../../../assets/images/check_icon.png">
                            </div>
                            <div class="payment-option-title">Credit Card</div>
                            <div class="spacer"></div>
                            <img class="card-icon" src="../../../assets/images/visa_icon.png">
                            <img class="card-icon" src="../../../assets/images/amex_icon.png">
                            <img class="card-icon" src="../../../assets/images/mastercard_icon.png">
                        </div>
                        <div class="payment-details-container"
                            [ngClass]="{selected: order.paymentOption === 'Credit Card'}">
                            <div class="card-selector" (click)="useNewCard = false">
                                <div class="check-box" [ngClass]="{selected: !useNewCard}">
                                    <img src="../../../assets/images/check_icon.png">
                                </div>
                                <div class="card-text">Saved card - **** **** **** {{user.card.last4}}</div>
                            </div>
                            <div class="card-selector" (click)="useNewCard = true">
                                <div class="check-box" [ngClass]="{selected: useNewCard}">
                                    <img src="../../../assets/images/check_icon.png">
                                </div>
                                <div class="card-text">Use a different card</div>
                            </div>

                            <div style="margin-top: 20px;" *ngIf="useNewCard">
                                <div class="input-field" [ngClass]="{error: errors.name}">
                                    <label for="name">Name On Card</label>
                                    <input id="name" [(ngModel)]="name" (ngModelChange)="validate()"
                                        placeholder="Type here..." type="text" autocomplete="name">
                                </div>
                                <div class="input-field" [ngClass]="{error: errors.cardNumber}">
                                    <label for="cardNumber">Card Number</label>
                                    <input id="cardNumber" [(ngModel)]="cardNumber" (ngModelChange)="validate()"
                                        placeholder="•••• •••• •••• ••••" type="text" maxlength="19" cc-number>
                                </div>
                                <div class="input-field-row">
                                    <div class="input-field" [ngClass]="{error: errors.expiry}">
                                        <label for="expiry">Expiration (MM/YY)</label>
                                        <input id="expiry" [(ngModel)]="expiry" (ngModelChange)="validate()"
                                            placeholder="MM/YY" type="text" maxlength="5" cc-expiry>
                                    </div>
                                    <div class="input-field" [ngClass]="{error: errors.cvc}">
                                        <label for="cvc">CVC</label>
                                        <input id="cvc" [(ngModel)]="cvc" (ngModelChange)="validate()"
                                            placeholder="Type here..." type="text" numbersOnly="true">
                                    </div>
                                </div>
                                <div class="card-selector" (click)="updateCard = !updateCard">
                                    <div class="check-box" [ngClass]="{selected: updateCard}">
                                        <img src="../../../assets/images/check_icon.png">
                                    </div>
                                    <div class="card-text">Update your saved card with this card</div>
                                </div>
                            </div>

                            <div *ngIf="errorMessage" style="margin-bottom: 35px;" class="error"
                                [innerHTML]="errorMessage"></div>
                        </div>
                    </div>
                    <!-- <div class="payment-option" (click)="selectPaymentOption('Leda')">
                        <div class="payment-title-container">
                            <div class="check-box" [ngClass]="{selected: order.paymentOption === 'Leda'}">
                                <img src="../../../assets/images/check_icon.png">
                            </div>
                            <div class="payment-option-title">Leda</div>
                            <div class="spacer"></div>
                            <img class="leda-icon" src="../../../assets/images/leda_icon.png">
                        </div>
                        <div class="payment-details-container" [ngClass]="{selected: order.paymentOption === 'Leda'}">
                            <p>Get up to 3 months free from interest and repayments.</p>
                            <p><a target="_blank" href="https://leda.inc/pay/buying">Learn more here</a></p>
                        </div>
                    </div> -->
                    <div class="payment-option" (click)="selectPaymentOption('Bank Transfer')">
                        <div class="payment-title-container">
                            <div class="check-box" [ngClass]="{selected: order.paymentOption === 'Bank Transfer'}">
                                <img src="../../../assets/images/check_icon.png">
                            </div>
                            <div class="payment-option-title">Bank Transfer</div>
                            <div class="spacer"></div>
                            <img class="bank-transfer-icon" src="../../../assets/images/bank_icon.png">
                        </div>
                        <div class="payment-details-container"
                            [ngClass]="{expanded: order.paymentOption === 'Bank Transfer'}">
                            <p>This option will take 2-3 days longer</p>
                        </div>
                    </div>
                </div>
                <div class="primary-button" [ngClass]="{loading: loading}" (click)="next()">Review Order</div>
            </div>
        </div>
    </div>
</div>