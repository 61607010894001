import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AuthHelper } from 'src/app/helpers/AuthHelper';
import { MatDialog } from '@angular/material/dialog';
import { ProfileComponent } from 'src/app/modals/profile/profile.component';
import { AccountComponent } from 'src/app/modals/account/account.component';
import { UtilityHelper } from 'src/app/helpers/UtilityHelper';
import { DatabaseHelper } from 'src/app/helpers/DatabaseHelper';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @Input() title = '';
  @Input() showBackButton = '';
  @Input() handleBackButton = '';
  @Output() onBack = new EventEmitter<string>();
  showMenu = false;

  constructor(
    private router: Router,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    if (this.handleBackButton) {
      this.showBackButton = this.handleBackButton;
    }
    console.log('user details in header component:');
    console.log(AuthHelper.instance.user);
  }

  closeDialog() {
    DatabaseHelper.instance.dataCallbacks.dataCallback({
      page: 'dismissProducts',
    });
  }

  get user() {
    return AuthHelper.instance.user;
  }

  cart() {
    this.router.navigateByUrl('/cart');
  }

  profile() {
    this.dialog.open(ProfileComponent, {
      disableClose: true,
      height: this.isPhone ? '100vh' : 'calc(100vh - 60px)',
      width: this.isPhone ? '100vw' : 'calc(60vw)',
      maxWidth: '100vw',
      maxHeight: '-webkit-fill-available',
      position: { bottom: '0', left: this.isPhone ? '0' : '20vw' },
      enterAnimationDuration: '0',
      panelClass: ['profile-modal', 'no-padding'],
      id: 'panel-modal',
    });
  }

  settings() {
    this.dialog.open(AccountComponent, {
      disableClose: true,
      height: this.isPhone ? '100vh' : 'calc(100vh - 60px)',
      width: this.isPhone ? '100vw' : 'calc(60vw)',
      maxWidth: '100vw',
      maxHeight: '-webkit-fill-available',
      position: { bottom: '0', left: this.isPhone ? '0' : '20vw' },
      enterAnimationDuration: '0',
      panelClass: ['profile-modal', 'no-padding'],
      id: 'account-modal',
    });
  }

  logout() {
    AuthHelper.instance.logout().then(() => {
      this.router.navigate(['login']);
    });
  }

  back() {
    if (this.handleBackButton) {
      this.onBack.emit('back');
    } else {
      window.history.back();
    }
  }

  getEstimation() {
    this.router.navigateByUrl('/estimation');
  }

  get isPhone() {
    return UtilityHelper.isPhone();
  }

  get isClientView() {
    return DatabaseHelper.instance.isClientView();
  }

  leaveClientMode() {
    DatabaseHelper.instance.leaveClientMode();
  }
}
