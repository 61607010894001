<div class="page-wrapper">
  <header id="masthead">
    <div class="inner boxed center-logo">
      <div class="site-logo">
        <a href="/"
          ><img
            src="/assets/images/logo.png"
            width="165"
            height="50"
            alt="Flrhub Logo"
        /></a>
      </div>
      <!--
            Dont need on terms and privacy aye?
          <nav class="primary">
              <ul class="ul-reset">
                  <li><a href="/#features">Features</a></li>
                  <li><a href="/#pricing">Pricing</a></li>
              </ul>
          </nav>
          <nav class="secondary">
              <ul class="ul-reset">
                  <li><a href="/login/">Log In</a></li>
                  <li><a href="/#pricing" class="primary-button">Get Started</a></li>
              </ul>
          </nav>
          -->
    </div>
  </header>
  <!-- #masthead -->

  <div class="page-content">
    <section id="entry-content" class="entry-content boxed">
      <div class="container">
        <div class="heading">
          <h1>Terms and Conditions of Sale</h1>
        </div>

        <div class="content hierarchy-list">
          <p>
            These Terms and Conditions (“Terms”), as amended or replaced from
            time to time, apply to any Goods supplied or to be supplied by
            FLRHUB to the Customer, or to any third party on the Customer’s
            behalf (“Customer”).
          </p>

          <ol>
            <li>
              CONTRACT
              <ol>
                <li>
                  These Terms constitute the entire agreement between FLRHUB and
                  the Customer. All prior negotiations, representations,
                  understandings, arrangements and agreements (whether oral
                  and/or in writing) are superseded by these Terms.
                </li>
                <li>
                  These Terms will in all circumstances prevail over the
                  Customer’s terms and conditions (if any), unless FLRHUB agrees
                  in writing to be bound by the Customer’s terms and conditions
                  or any of them.
                </li>
                <li>
                  If Goods are supplied by FLRHUB to a Commercial Credit Trade
                  Account Customer, then the terms and conditions of sale
                  incorporated into the Commercial Credit Trade Account shall
                  prevail to the extent of any inconsistency or conflict with
                  these Terms.
                </li>
              </ol>
            </li>
            <li>
              PRICES
              <ol>
                <li>
                  The price for the supply of Goods will be specified in the
                  invoice or account issued by FLRHUB to the Customer, or such
                  other notification provided by FLRHUB at the time an order is
                  placed for Goods.
                </li>
                <li>
                  Unless otherwise stated:
                  <ol class="alpha-list">
                    <li class="list">
                      the price for the Goods and any other amount payable under
                      these Terms shall be exclusive of any tax payable pursuant
                      to A New Tax System (Goods and Services Tax) Act 1999
                      (Cth) (“GST”) except where the price is expressed to be
                      inclusive of GST; and
                    </li>
                    <li class="list">
                      the Customer will be required to pay to FLRHUB an amount
                      equal to the GST (which will be included in the invoice,
                      account or notification issued) in addition to (and at the
                      same time as) the price or any relevant other amount.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              ORDERS & DELIVERY
              <ol>
                <li>
                  Each order by a Customer will constitute an offer only by the
                  Customer to FLRHUB.
                </li>
                <li>
                  The Customer will, unless FLRHUB and the Customer otherwise
                  agree, bear the cost of delivery of the Goods.
                </li>
                <li>
                  Delivery times are estimates only. FLRHUB will make all
                  reasonable efforts to have Goods delivered to the Customer by
                  the date notified to the Customer, but, subject to any rights
                  the Customer has under the Australian Consumer Law, FLRHUB
                  will not be liable for:
                  <ol class="alpha-list">
                    <li class="list">
                      any failure to deliver, or delay in delivery of Goods;
                    </li>
                    <li class="list">
                      any damage or loss due to unloading or packaging of Goods;
                      and
                    </li>
                    <li class="list">
                      any damage to property caused upon entering premises to
                      deliver the Goods.
                    </li>
                  </ol>
                </li>
                <li>
                  The Customer must ensure there is clear and safe access for
                  delivery and that a person is present at the delivery premises
                  who is authorised to accept delivery.
                </li>
                <li>
                  If a person is not present at the delivery premises who is
                  authorised to accept delivery, the Goods will be left at the
                  delivery premises if the carrier considers it is safe and
                  appropriate to do so.
                </li>
                <li>
                  FLRHUB may in its absolute discretion unload Goods at any
                  premises that the Customer nominates for delivery. If the
                  Customer, or any person authorised to accept delivery, is not
                  in attendance at any nominated premises, FLRHUB may leave any
                  Goods at the premises and FLRHUB shall not be responsible for
                  any claims, damages, costs, or expenses arising or resulting
                  there from including any claim that the Goods were not
                  delivered.
                </li>
                <li>
                  Where Goods are returned to warehouse by FLRHUB’s carrier, the
                  Customer may be required to pay for re-delivery at a later
                  date.
                </li>
                <li>
                  Upon delivery or collection and prior to use of any Goods, the
                  Customer will inspect the Goods as appropriate as to the type,
                  quantity, quality, aesthetics, appearance, suitability of
                  purpose and any other characteristic of the Goods.
                </li>
                <li>
                  Delivered Goods may not be returned unless defective or
                  damaged in transit or wrongly supplied. Claims are subject to
                  inspection. If any Goods are damaged, defective, wrongly
                  supplied or not in accordance with these Terms, the Customer
                  may return those Goods in accordance with clause 4.
                </li>
                <li>
                  The Customer will be deemed to have accepted delivery of the
                  Goods in the following events:
                  <ol class="alpha-list">
                    <li class="list">
                      failure by the Customer to return Goods in accordance with
                      clause 4; or
                    </li>
                    <li class="list">
                      use of the Goods in any way by the Customer.
                    </li>
                  </ol>
                </li>
                <li>
                  The Customer will indemnify FLRHUB against any losses, costs
                  or expenses incurred by FLRHUB due to any failure by the
                  Customer to accept the Goods at the time of delivery.
                </li>
                <li>
                  FLRHUB may stop Goods in transit whether or not delivery has
                  been made if the Customer is in default of these Terms at any
                  time.
                </li>
                <li>
                  Upon the occurrence of an Insolvency Event, the Customer
                  irrevocably authorises FLRHUB and any of its lawful agents, at
                  any reasonable time, to enter the Customer’s premises or any
                  premises occupied by the Customer or its agents and re-take
                  any Goods and then to re-sell those Goods and retain the
                  proceeds of the sale without prejudice to FLRHUB’s rights to
                  claim the balance of all moneys due by the Customer. The
                  Customer indemnifies FLRHUB in respect of any such entry. The
                  provisions of this clause survive the termination of any
                  security agreement that arises in respect of these Terms.
                </li>
              </ol>
            </li>
            <li>
              RETURN OF GOODS
              <ol>
                <li>
                  The Customer may reject and return Goods to FLRHUB provided
                  that:
                  <ol class="alpha-list">
                    <li class="list">
                      the Goods are damaged in transit, wrongly supplied,
                      defective or not in accordance with these Terms;
                    </li>
                    <li class="list">
                      the Customer sends FLRHUB photographic evidence of such
                      damaged or defective Goods, or wrongly supplied Goods or
                      Goods that do not comply with these Terms, within
                      forty-eight (48) hours of delivery of those Goods to the
                      Customer;
                    </li>
                    <li class="list">
                      the Goods are returned within fourteen (14) days of the
                      date of the invoice or account issued by FLRHUB to the
                      Customer in respect of those Goods;
                    </li>
                    <li class="list">
                      the Customer notifies FLRHUB of the invoice or account
                      number in respect of the Goods to be returned; and
                    </li>
                    <li class="list">
                      the Goods are returned in the original state or condition
                      in which they were supplied, and remain in original boxes
                      (where applicable) together with all packaging and
                      instruction material.
                    </li>
                  </ol>
                </li>
                <li>
                  All Goods returned by the Customer are subject to assessment
                  by FLRHUB and/or the manufacturer of such Goods, and FLRHUB
                  may, if permitted by the Australian Consumer Law or any other
                  law, refuse to accept the return of the Goods at its sole
                  discretion.
                </li>
                <li>
                  Unless FLRHUB and the Customer otherwise agree in writing, the
                  Customer will bear the costs of delivery when returning any
                  Goods.
                </li>
                <li>
                  Subject to the Customer’s rights under the Australian Consumer
                  Law, the following Goods cannot be returned by the Customer
                  under any circumstances:
                  <ol class="alpha-list">
                    <li class="list">
                      those that were specially made, sourced, ordered or
                      purchased for the Customer;
                    </li>
                    <li class="list">
                      those that were used, installed, laid, damaged or altered
                      in any way by the Customer;
                    </li>
                    <li class="list">
                      those that were sold to the Customer at wholesale or
                      discount prices, or as second grade or quality; or
                    </li>
                    <li class="list">those that have been discontinued.</li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              PAYMENT
              <ol>
                <li>
                  Unless the Customer is a Commercial Credit Trade Account
                  Customer and FLRHUB agrees in writing for the Customer to pay
                  for Goods on the Customer’s Commercial Credit Trade Account,
                  the Customer will pay for the Goods in full at the time an
                  order is placed for the Goods.
                </li>
                <li>FLRHUB may allocate payments in any manner it chooses.</li>
                <li>
                  The Customer must pay for the Goods by any method of payment
                  specified by FLRHUB.
                </li>
                <li>
                  Payment is only received by FLRHUB when the proceeds of
                  payment are credited and cleared to FLRHUB’s bank account.
                </li>
                <li>
                  The Customer may not assert or exercise any right of set-off
                  against monies payable by it to FLRHUB.
                </li>
              </ol>
            </li>
            <li>
              RISK AND TITLE
              <ol>
                <li>Risk in any Goods passes to the Customer on delivery.</li>
                <li>
                  Title in Goods remains with FLRHUB until the Customer has paid
                  FLRHUB for all such Goods in full and in cleared funds. Until
                  title passes to the Customer, the Customer will hold them on
                  trust and as bailee for FLRHUB.
                </li>
              </ol>
            </li>
            <li>
              LIMITATION OF LIABILITY
              <ol>
                <li>
                  If under the Australian Consumer Law or any other law any
                  terms which apply to the sale of Goods under these Terms
                  cannot be legally excluded, restricted or modified then those
                  terms apply only to the extent permitted by law.
                </li>
                <li>
                  All terms, conditions, warranties and consumer guarantees
                  which would otherwise be implied by law or otherwise are
                  excluded except as stated in these Terms.
                </li>
                <li>
                  To the extent permitted by law, FLRHUB’s liability for any
                  breach of these Terms, and any condition or warranty implied
                  by the provisions of the Australian Consumer Law, is limited
                  to and will be completely discharged by any one of the
                  following as determined by FLRHUB in its absolute discretion:
                  <ol class="alpha-list">
                    <li class="list">
                      the replacement of the Goods or the supply of equivalent
                      Goods;
                    </li>
                    <li class="list">the repair of the Goods;</li>
                    <li class="list">
                      the payment of the cost of replacing the Goods or of
                      acquiring equivalent Goods;
                    </li>
                    <li class="list">
                      the payment of the cost of having the Goods repaired; or
                    </li>
                    <li class="list">
                      in the case of services, by supplying the services again.
                    </li>
                  </ol>
                </li>
                <li>
                  The Customer will rely entirely on its own knowledge, skill
                  and judgment in selecting Goods which it orders from FLRHUB
                  from time to time.
                </li>
                <li>
                  Except as expressly provided in this clause and to the extent
                  permitted by law, FLRHUB is not liable to the Customer (and
                  any party claiming through the Customer) for:
                  <ol class="alpha-list">
                    <li class="list">
                      any claim made after laying, installation or use of the
                      Goods;
                    </li>
                    <li class="list">
                      any claim made under, or in connection with, these Terms
                      (whether under contract, in tort, under statute, in equity
                      or otherwise) in respect of any defects whatsoever to the
                      Goods or for any loss or damage to person or property
                      arising from or caused by such defects;
                    </li>
                    <li class="list">
                      any liability for a claim that the Goods are not fit for a
                      particular purpose, except where the Goods are consumer
                      goods or services in which case FLRHUB's liability is
                      limited as under this clause 7; and
                    </li>
                    <li class="list">
                      any indirect, special or consequential loss or damage of
                      any nature whatsoever resulting from or caused in any way
                      by the Goods, where indirect, special or consequential
                      loss or damage includes, without limitation:
                      <ol class="roman-list">
                        <li class="list">
                          any loss of income, profit or business; or
                        </li>
                        <li class="list">
                          any loss of goodwill or reputation.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              SECURITY AGREEMENT & SECURITY INTEREST
              <ol>
                <li>
                  The Customer agrees that these Terms, including any order and
                  invoice covering any Goods ordered by the Customer, constitute
                  a security agreement for the purposes of the PPSA.
                </li>
                <li>
                  The Customer gives FLRHUB a security interest in:
                  <ol class="alpha-list">
                    <li class="list">
                      all Goods FLRHUB supplies whether by way of sale or lease
                      to the Customer that are described in any invoice,
                      delivery or shipping documents of FLRHUB’s and/or order
                      documents that the Customer gives FLRHUB; and
                    </li>
                    <li class="list">
                      all present and future property of the Customer including
                      real property. The security interest includes a charge and
                      a mortgage. The Customer agrees that FLRHUB may lodge a
                      caveat against any of its real property. The security
                      interest given by the Customer is given as beneficial
                      owner. If the Customer is trustee of any trust, the
                      Customer acknowledges that the security interest given by
                      it applies to the assets of the trust and its personal
                      assets. The Customer irrevocably appoints any one of
                      FLRHUB’s personnel as its attorney by way of security for
                      the purposes of this clause to execute and/or lodge any
                      mortgage over any real property and in respect of any
                      enforcement powers.
                    </li>
                  </ol>
                </li>
                <li>
                  If the Customer should agree to new Terms, then this clause 8
                  of these Terms shall continue to apply despite the agreement
                  to the new Terms.
                </li>
                <li>
                  Any security interest that arises in respect of these Terms is
                  a continuing security interest and in respect of any Goods
                  supplied by FLRHUB applies to all Goods and their proceeds. It
                  is not extinguished or in any way diminished even if the Goods
                  or any part of them is processed or commingled with or becomes
                  part of a product or a mass as part of a manufacturing,
                  assembling or commingling process.
                </li>
                <li>
                  Any security interest arising secures the due and punctual
                  payment of all moneys payable by the Customer under these
                  Terms. Any account arising by virtue of any sale of any Goods
                  supplied to the Customer takes effect as a transfer.
                </li>
                <li>
                  FLRHUB may register a financing statement including any
                  financing change statement on the PPSR. The Customer will
                  reimburse FLRHUB for any costs and expenses and other charges
                  incurred by FLRHUB in registering them. The Customer
                  irrevocably waives any rights to receive a copy of any
                  verification statement.
                </li>
                <li>
                  The Customer acknowledges that until it has paid for all Goods
                  it is not the owner of those Goods and, as such, if chapter 4
                  of the PPSA applies to these Terms, then the Customer
                  contracts out of the enforcement provisions in s 115(1) except
                  sections 120 and 121.
                </li>
              </ol>
            </li>
            <li>
              ENFORCEMENT
              <ol>
                <li>
                  If an event of Default occurs or is continuing, FLRHUB may
                  immediately enforce these Terms. Without limitation, this
                  includes:
                  <ol class="alpha-list">
                    <li class="list">
                      retaking possession of any Goods not paid for by the
                      Customer;
                    </li>
                    <li class="list">
                      suspending delivery of any Goods on order and/or refusing
                      to process any unfulfilled order;
                    </li>
                    <li class="list">enforcing any security interest;</li>
                    <li class="list">
                      requiring payment of any proceeds held by the Customer in
                      a separate account or otherwise;
                    </li>
                    <li class="list">
                      appointing a receiver and manager of any of the Customer’s
                      real or personal property. The Customer agrees that any
                      such receiver and manager has the powers conferred by the
                      Corporations Act 2001 (Cth).
                    </li>
                  </ol>
                </li>

                <li>
                  The powers exercisable by FLRHUB are those given by these
                  Terms including by statute, at law or in equity.
                </li>
                <li>
                  In the event that the Customer has not been paid for the sale
                  of FLRHUB’s Goods by any of its own customers or contractors,
                  at FLRHUB’s option, FLRHUB may in addition to the powers in s
                  120 of the PPSA, collect that account on giving, to that
                  customer or contractor (as applicable), notice in writing to
                  that effect. FLRHUB will be entitled to issue proceedings in
                  the Customer’s name against its customer or contractor (as
                  applicable) for recovery of that account for FLRHUB’s benefit.
                  The Customer irrevocably appoints FLRHUB as its attorney for
                  that purpose. For the purposes of this clause, the Customer
                  must keep proper records and accounts in respect of such
                  unpaid sales, including the dates of sale, price, amount and
                  identity of such customer or contractor (as applicable) and
                  FLRHUB’s Goods.
                </li>
                <li>
                  FLRHUB may in its discretion despite s 14(6) of the PPSA apply
                  any moneys received in respect of any PMSI obligations of the
                  Customer on a pro rata basis.
                </li>
                <li>
                  The Customer will pay FLRHUB for its expenses, including
                  mercantile agent’s fees and any fees and commission paid to
                  mercantile agents engaged by FLRHUB in relation to the actual
                  or contemplated enforcement of the supply agreement including
                  legal costs and expenses.
                </li>
              </ol>
            </li>
            <li>
              PRIVACY
              <ol>
                <li>
                  FLRHUB collects personal information which is reasonably
                  necessary to enable it to supply the Goods to the Customer.
                  Our Privacy Policy can be accessed through the home page or at
                  <a href="/privacy" target="_blank">www.flrhub.com.au</a>
                </li>
              </ol>
            </li>
            <li>
              INDEMNITY
              <ol>
                <li>
                  The Customer will indemnify and keep indemnified and hold
                  FLRHUB, its officers, directors, employees, agents, successor
                  and assignees, harmless from and against all liabilities,
                  losses, damages, costs or expenses incurred or suffered by
                  FLRHUB, and from and against all actions, proceedings, claims
                  or demands made against FLRHUB, arising from one or more of
                  the following:
                  <ol class="alpha-list">
                    <li class="list">
                      the Customer’s failure to comply with any applicable laws,
                      rules, standards, regulations or instructions in relation
                      to the Goods or the use of the Goods;
                    </li>
                    <li class="list">
                      any negligence or breach of duty by the Customer and its
                      employees, agents or contractors in relation to the Goods
                      or the use of the Goods; and
                    </li>
                    <li class="list">
                      any breach of these Terms by the Customer.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              FORCE MAJEURE
              <ol>
                <li>
                  FLRHUB is not liable for any delay or the failure to perform
                  any obligation in the Customer’s favour arising as a result of
                  any event beyond FLRHUB’s control.
                </li>
              </ol>
            </li>
            <li>
              CONFIDENTIALITY
              <ol>
                <li>
                  Any security agreement that arises under these Terms is
                  confidential. Despite this, the Customer agrees that FLRHUB
                  may disclose any information concerning it to any interested
                  person under s 275(6) of the PPSA.
                </li>
              </ol>
            </li>
            <li>
              NOTICES AND ELECTRONIC COMMUNICATION
              <ol>
                <li>
                  Any notice required under these Terms may be given by any
                  party, including any director or authorised person of that
                  party. Any notice may be given at that party’s registered
                  address or other address stipulated in any application in
                  connection with these Terms or as notified in writing for the
                  purposes of this clause. Without limitation, this includes any
                  electronic address notified to the other party.
                </li>
                <li>
                  The Customer agrees that:
                  <ol class="alpha-list">
                    <li class="list">
                      if the Customer’s signature or execution is required, the
                      requirement is taken to have been met by an electronic
                      communication; and
                    </li>
                    <li class="list">
                      if FLRHUB is required to produce a document that is in the
                      form of paper, the requirement is taken to have been met
                      by an electronic communication.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              GENERAL
              <ol>
                <li>
                  These Terms are governed by the laws of South Australia. The
                  Customer submits to the non-exclusive jurisdiction of any
                  South Australian court and waives any rights to claim that
                  courts there are an inconvenient forum.
                </li>
                <li>
                  The rights and obligations of the parties will not merge on
                  completion of any transaction under these Terms or upon the
                  execution of any other document in connection with the subject
                  matter of these Terms.
                </li>
                <li>
                  All rights under these Terms are in addition to and do not
                  abrogate, limit or reduce any other rights that FLRHUB may
                  have.
                </li>
                <li>
                  Any provision of these Terms that is invalid, unenforceable or
                  illegal must be read down to the extent necessary to avoid
                  that effect. If that is not possible, that provision must be
                  excluded from these Terms but only to the extent necessary to
                  avoid that effect. All other provisions of the Terms continue
                  to be valid and enforceable.
                </li>
                <li>
                  Each indemnity and payment obligation of the Customer under
                  these Terms is a continuing obligation, separate and
                  independent from all other obligations, and survives
                  termination of these Terms.
                </li>
                <li>
                  It is not necessary for FLRHUB to incur expense or make a
                  payment before enforcing a right of indemnity.
                </li>
                <li>
                  These Terms contain the entire agreement in respect of the
                  supply of Goods or services to the Customer.
                </li>
                <li>
                  FLRHUB may at any time set off any amount FLRHUB owes the
                  Customer against any amount payable by the Customer to FLRHUB.
                </li>
                <li>
                  For the purposes of any payment obligation under these Terms,
                  time is of the essence.
                </li>
                <li>
                  FLRHUB may vary these Terms with reasonable notice given to
                  the Customer.
                </li>
                <li>
                  A right or obligation under these Terms cannot be waived
                  except by a document executed by the party waiving that right
                  or obligation and specifying the waiver.
                </li>
              </ol>
            </li>
            <li>
              DEFINITIONS
              <ol>
                <li>
                  In these Terms unless the contrary intention appears:
                  <div class="alpha-list">
                    <div class="list">
                      “Australian Consumer Law” means the Australian Consumer
                      Law contained in Schedule 2 of the Competition and
                      Consumer Act 2010 (Cth);
                    </div>
                    <div class="list">
                      “FLRHUB” means FLRHUB Pty Ltd and any and all of its
                      “Related Bodies Corporate” as such term is defined in the
                      Corporations Act 2001 (Cth);
                    </div>
                    <div class="list">
                      “Commercial Credit Trade Account Customer” means a
                      Customer who has entered into an agreement called a
                      Commercial Credit Trade Account with FLRHUB;
                    </div>
                    <div class="list">
                      “Default” means:
                      <div class="roman-list">
                        <div class="list">
                          the Customer fails to pay when due any moneys owing to
                          FLRHUB under these Terms;
                        </div>
                        <div class="list">
                          the Customer gives any third party a security interest
                          in accounts as original collateral in respect of the
                          proceeds of any Goods supplied by FLRHUB;
                        </div>
                        <div class="list">
                          an Insolvency Event occurs or is continuing;
                        </div>
                        <div class="list">
                          the Customer breaches any provision of these Terms; or
                        </div>
                        <div class="list">
                          upon any employee of FLRHUB posting by ordinary
                          pre-paid post or emailing a letter advising that all
                          money owing to FLRHUB is, despite any agreement to the
                          contrary, immediately due and owing.
                        </div>
                      </div>
                    </div>
                    <div class="list">
                      “electronic communication” means a communication of
                      information in the form of data, text or images by guided
                      or unguided electromagnetic energy and includes a scanned
                      image or any other form of digital storage.
                    </div>
                    <div class="list">
                      “Goods” means any goods FLRHUB supplies to the Customer
                      whether on consignment or otherwise under these Terms.
                      They include goods described on any invoice, quotation,
                      work authorisation or any other forms or notification
                      FLRHUB provides the Customer or any order the Customer
                      gives FLRHUB.
                    </div>
                    <div class="list">
                      “Insolvency Event” means:
                      <div class="roman-list">
                        <div class="list">
                          an administrator is appointed to the Customer.
                        </div>
                        <div class="list">
                          except for the purpose of a solvent reconstruction or
                          amalgamation previously approved by FLRHUB, an
                          application or an order is made, proceedings are
                          commenced, a resolution is passed or proposed in a
                          notice of meeting, an application to a court or other
                          steps are taken for:
                        </div>
                        <div class="ordered-list">
                          <div class="list">the Customer’s winding up; or</div>
                          <div class="list">
                            the Customer entering into an arrangement,
                            compromise or composition with or assignment for the
                            benefit of creditors or a class of them;
                          </div>
                        </div>
                        <div class="list">
                          the Customer ceases, suspends or threatens to cease or
                          suspend the conduct of all or a material part of its
                          business or disposes of or threaten to dispose of a
                          material part of its assets.
                        </div>
                        <div class="list">
                          the Customer is, or under legislation is presumed or
                          taken to be, insolvent or cease to carry on a
                          business.
                        </div>
                        <div class="list">
                          a receiver, receiver and manager, administrative
                          receiver or similar officer is appointed in respect of
                          the Customer.
                        </div>
                        <div class="list">
                          any distress, attachment, execution or other legal
                          process is levied, enforced or sued out on or against
                          all or any material part of the Customer’s assets and
                          is not discharged or stayed within 14 business days.
                        </div>
                        <div class="list">
                          a security interest becomes enforceable or is
                          enforced.
                        </div>
                      </div>
                    </div>
                    <div class="list">
                      “PPSA” means the Personal Property Securities Act 2009
                      (Cth).
                    </div>
                    <div class="list">
                      The expressions account, financing statement, financing
                      change statement, PPSR, proceeds, purchase money security
                      interest (pmsi), security agreement, security interest,
                      verification statement have the meanings given by the
                      PPSA. Without limitation, security interest bears its
                      natural meaning and applies to all land and licences and
                      personal property that is not personal property covered by
                      the PPSA.
                    </div>
                    <div class="list">
                      The singular includes the plural and the converse.
                    </div>
                    <div class="list">
                      Any reference in these Terms to the Customer also includes
                      its respective successors or permitted assigns. If the
                      Customer constitutes more than one person or entity, these
                      Terms bind each of them jointly and severally.
                    </div>
                  </div>
                </li>
              </ol>
            </li>
          </ol>
        </div>

        <hr class="separator" />
        <div class="heading">
          <h2 class="h1">Subscription Terms</h2>
        </div>
        <div class="content hierarchy-list">
          <p>
            These Subscription Terms (“Terms”), as amended or replaced from time
            to time, apply to the subscription to, and use of, FLRHUB’s Site by
            you (“Customer”, “You” or “Your”) and they govern Your and FLRHUB’s
            respective rights and obligations.
          </p>

          <ol>
            <li>
              CONTRACT
              <ol>
                <li>
                  These Terms constitute the entire agreement between FLRHUB and
                  You in relation to Your subscription to the Site. All prior
                  negotiations, representations, understandings, arrangements
                  and agreements (whether oral and/or in writing) are superseded
                  by these Terms.
                </li>
                <li>
                  You shall be deemed to have accepted these Terms and to have
                  entered into a Contract when You subscribe to the Site.
                </li>
              </ol>
            </li>
            <li>
              SUBSCRIPTION
              <ol>
                <li>
                  Your subscription gives You access to the Site during Your
                  subscription period. If there are different subscription
                  packages for the Site, then Your subscription only gives You
                  access for the package for which You subscribed.
                </li>
                <li>
                  We reserve the right to modify the content, inclusions, type
                  and availability of any features, benefits and services on the
                  Site at any time.
                </li>
                <li>
                  We reserve the right to reject any order for a subscription at
                  any time. Subscription Fees are not refundable except to the
                  extent expressly set out in these Terms.
                </li>
                <li>
                  FLRHUB is not responsible and has no liability for any claim
                  relating to a situation where You have entered incorrect
                  details in Your subscription order. It will be Your
                  responsibility to report any such errors to FLRHUB as soon as
                  You become aware of this and if FLRHUB decides to provide You
                  with any credit as a result, such credit may (at FLRHUB’s
                  option) take the form of an extension to Your subscription
                  expiry date or a reduction in the price of Your next payment
                  of the Subscription Fee.
                </li>
              </ol>
            </li>
            <li>
              SUBSCRIPTION TERM AND PAYMENT
              <ol>
                <li>
                  Your subscription begins as soon as your initial payment of
                  the Subscription Fee is processed. Your subscription will
                  automatically renew each month without notice unless and until
                  You cancel Your subscription.
                </li>
                <li>
                  You authorise FLRHUB to store Your payment method(s) and to
                  automatically charge Your payment method(s) every month until
                  You cancel Your subscription. FLRHUB will automatically charge
                  You the then-current rate for your subscription package, plus
                  GST, every month upon renewal until you cancel.
                </li>
                <li>
                  Payment of the Subscription Fee may be made by Your nominated
                  payment method which can include direct debit. Where You pay
                  by direct debit, it is Your responsibility to make sure You
                  have sufficient clear funds in Your nominated bank account for
                  the direct debit payment to be deducted. It is Your
                  responsibility to check Your bank account statements to make
                  sure that the correct amounts are being debited from Your
                  account and to promptly let FLRHUB know if You believe you are
                  being billed incorrectly.
                </li>
                <li>
                  You are responsible for making sure Your payment details are
                  always valid and up to date – You can do on the Site.
                </li>
                <li>
                  If Your primary payment method fails, You authorise FLRHUB to
                  charge any other payment method in Your account. If You have
                  not provided FLRHUB with a backup payment method(s) and You
                  fail to provide payment or if all payment methods in Your
                  account fail, FLRHUB may suspend or cancel Your subscription
                  and revoke Your access to the Site. You will then need to
                  re-subscribe and the same subscription package will not
                  necessarily be made available to You.
                </li>
                <li>
                  If You pay by credit card, FLRHUB may add a credit card
                  surcharge (currently 1.85% but subject to annual review) which
                  reflects no more than the costs of acceptance to FLRHUB of
                  processing Your payment of the Subscription Fee. There is no
                  surcharge for payments by debit card or direct debit.
                </li>
                <li>
                  Your login details for the Site should not be shared with
                  anyone else. If FLRHUB detects or suspects any misuse of Your
                  login details for any reason, FLRHUB may terminate Your
                  subscription and no refunds will be given.
                </li>
                <li>
                  Using Your subscription may mean You will incur other expenses
                  (eg internet fees, data fees and/or service provider fees) and
                  You will be responsible for these extra expenses.
                </li>
              </ol>
            </li>
            <li>
              PRICING
              <ol>
                <li>
                  The current Subscription Fee, the available subscription
                  packages and duration of the subscription(s) are stated on the
                  Site. FLRHUB charges in Australian dollars and You agree to
                  pay the Subscription Fee at the payment intervals stated at
                  the time You order Your subscription.
                </li>
                <li>
                  FLRHUB may change the Subscription Fee at any time. FLRHUB
                  will give You at least 14 days’ notice if the new Subscription
                  Fee is greater than the price You received at the commencement
                  of Your subscription. The new Subscription Fee will be
                  notified to You by email or via the Site. The new Subscription
                  Fee won’t apply until Your next monthly renewal term after the
                  notice. If You would prefer to cancel Your subscription, you
                  are free to do so before the end of the direct debit/credit
                  card monthly billing cycle.
                </li>
                <li>
                  The Subscription Fee is non-refundable except as expressly set
                  forth in these Terms.
                </li>
                <li>
                  Unless otherwise stated:
                  <ol class="alpha-list">
                    <li class="list">
                      the Subscription Fee and any other amount payable under
                      the Contract shall be exclusive of any tax payable
                      pursuant to A New Tax System (Goods and Estimating
                      Services Tax) Act 1999 (Cth) (“GST”) except where the
                      amount is expressed to be inclusive of GST; and
                    </li>
                    <li class="list">
                      You will be required to pay to FLRHUB an amount equal to
                      the GST in addition to (and at the same time as) the
                      Subscription Fee and any relevant other amount.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              PROMOTIONS
              <ol>
                <li>
                  From time to time, FLRHUB may offer certain customers specific
                  trial or other promotional subscriptions, which are subject to
                  these Terms except as otherwise stated in the promotional or
                  subscription terms and conditions (if any). if any of the
                  promotional subscription terms and conditions conflict with
                  these Terms, then these Terms and conditions will override the
                  promotional terms and conditions.
                </li>
                <li>
                  You will likely be required to provide Your direct
                  debit/credit card payment details when you sign up for a
                  promotion or trial and Your subscription will then renew
                  automatically at the end of the promotional or trial period at
                  the then current Subscription Fee unless You cancel during the
                  relevant trial or promotional period.
                </li>
                <li>
                  FLRHUB reserves the right to offer, withdraw, change, cancel
                  or determine Your eligibility for any promotion or trial in
                  its discretion for the purposes of preventing abuse of the
                  promotion or trial.
                </li>
              </ol>
            </li>
            <li>
              MANAGING YOUR SUBSCRIPTION
              <ol>
                <li>
                  You can manage Your account, change Your personal details or
                  Your payment details and upgrade/downgrade Your subscription
                  level through the Site.
                </li>
                <li>
                  Upgrades are billed from Your next monthly billing cycle but
                  You will get immediate access to the upgraded subscription
                  package. Downgrades will take effect from Your next monthly
                  billing cycle.
                </li>
              </ol>
            </li>
            <li>
              CANCELLING A SUBSCRIPTION
              <ol>
                <li>
                  You may request a cancellation of Your subscription at any
                  time by contacting FLRHUB. Cancellation won’t be effective
                  until we have received all relevant information from You and
                  until the end of Your then current monthly subscription period
                  (note that your cancellation request must be made at least 72
                  hours before your next subscription payment is due).
                </li>
                <li>
                  No pro-rata refunds will be provided if You cancel during a
                  subscription period.
                </li>
              </ol>
            </li>
            <li>
              PERMITTED USE OF THE SITE
              <ol>
                <li>
                  You must follow FLRHUB’s reasonable instructions about how You
                  use the Site. You must not use the Site for any improper or
                  unlawful purpose and You must not allow anyone else to do the
                  same.
                </li>
                <li>
                  You must not circumvent, disable or otherwise interfere with
                  security-related features of the Site or any features which
                  prevent or restrict the use, distribution or copying of any
                  Site content. You must not alter or modify the Site content or
                  the Site in any way.
                </li>
                <li>
                  You are solely responsible and liable for activity that occurs
                  on Your account and You are responsible for maintaining the
                  confidentiality of Your password and Site account details.
                </li>
              </ol>
            </li>
            <li>
              LIMITATION OF LIABILITY
              <ol>
                <li>
                  FLRHUB gives no warranty in relation to the Site and Your
                  subscription, and You expressly agree that use of the Site is
                  at Your own risk.
                </li>
                <li>
                  To the maximum extent permitted by law:
                  <ol class="alpha-list">
                    <li class="list">
                      except as expressly provided to the contrary in the
                      Contract, all representations, warranties, terms and
                      conditions in relation to the Site, Your subscription and
                      these Terms (whether express, implied, statutory or
                      otherwise) are hereby excluded; and
                    </li>
                    <li class="list">
                      without limiting the generality of the preceding clause,
                      FLRHUB is not liable or responsible in any way to You or
                      any other person for any Loss arising from, related to, or
                      in any way connected with your subscription, the Site
                      and/or the benefits offered.
                    </li>
                  </ol>
                </li>
                <li>
                  To the extent permitted by law, the liability of FLRHUB to You
                  for any loss or damage arising out of or in connection with
                  Your subscription to the Site, the supply of Site benefits
                  under these Terms, any breach by FLRHUB of these Terms however
                  arising (whether tort (including negligence), statute, custom,
                  law or on any other basis) or any non-compliance with a
                  statutory guarantee is limited to, at the discretion of
                  FLRHUB:
                  <ol class="alpha-list">
                    <li class="list">
                      the supplying of the Site benefits again; or
                    </li>
                    <li class="list">
                      a refund of the Subscription Fees paid by You during the
                      previous 12-month period.
                    </li>
                  </ol>
                </li>
                <li>
                  Nothing in these Terms is intended to exclude, restrict or
                  modify any non-excludable right or remedy you have under law
                  including the Australian Consumer Law. Any disclaimer,
                  exclusion, or limitation as provided for in these Terms
                  applies to the full extent permitted by law and subject to any
                  non-excludable right or remedy.
                </li>
                <li>
                  You agree to indemnify FLRHUB and holds it harmless against
                  any and all actions, claims, proceedings, costs, Losses, and
                  liabilities (including legal fees) suffered or incurred by
                  FLRHUB arising out of, in connection with or resulting from
                  Your use of the Site, including any subscription to the Site,
                  whether direct or Consequential Loss.
                </li>
                <li>
                  FLRHUB is not liable for any Consequential Loss however
                  caused, suffered or incurred by You, or any party claiming
                  through You, in connection with the Contract.
                </li>
              </ol>
            </li>
            <li>
              INTELLECTUAL PROPERTY
              <ol>
                <li>
                  These Terms do not pass any interest to You in the proprietary
                  information or intellectual property of FLRHUB, and You agree
                  that You will not disclose any such information or
                  intellectual property or use it for purposes outside of these
                  Terms and Your use of the Site.
                </li>
              </ol>
            </li>
            <li>
              INDEMNITY
              <ol>
                <li>
                  You agree to indemnify and keep indemnified and hold FLRHUB
                  and its officers, directors, employees, agents, successor and
                  assignees, harmless from and against all Loss incurred or
                  suffered by FLRHUB, and from and against all actions,
                  proceedings, claims or demands made against FLRHUB, arising
                  from, but no limited to, one or more of the following:
                  <ol class="alpha-list">
                    <li class="list">
                      the Site (including Your use of the Site), Your
                      subscription to the Site and/or the Contract;
                    </li>
                    <li class="list">
                      any breach of the Contract (including these Terms) by You.
                    </li>
                  </ol>
                </li>
                <li>
                  This clause 11 remains in force after the termination or
                  expiry of the Contract.
                </li>
              </ol>
            </li>
            <li>
              TERMINATION
              <ol>
                <li>
                  FLRHUB may terminate Your subscription with 14 days' notice
                  for convenience and will issue a prorated refund of Your paid
                  Subscription Fee based on the time remaining in Your current
                  monthly subscription period.
                </li>
                <li>
                  FLRHUB may terminate Your subscription immediately by notice
                  if FLRHUB consider that: (a) Your use of the Site or Your
                  subscription to the Site breaches these Terms or any
                  applicable law; or (b) You fraudulently use or misuse the Site
                  or Your subscription. In this case FLRHUB will not give any
                  refund. FLRHUB’s failure to insist upon or enforce Your strict
                  compliance with these Terms will not constitute a waiver of
                  any of FLRHUB’s rights. You may terminate your subscription at
                  any time in accordance with these Terms.
                </li>
              </ol>
            </li>
            <li>
              INFORMATION & WARRANTIES
              <ol>
                <li>
                  You are responsible for the completeness and accuracy of the
                  Information that You supply to FLRHUB.
                </li>
                <li>
                  You acknowledge and agree that all Information You provide to
                  FLRHUB is accurate and complete and that FLRHUB will, without
                  independent verification, rely on it for the purpose of
                  providing You with a subscription to the Site. FLRHUB shall
                  not assume any responsibility or have any liability for such
                  Information. FLRHUB takes no responsibility for any Loss
                  arising if the Information is in any way incomplete,
                  inaccurate or contains errors. You take full responsibility
                  for, and shall indemnify FLRHUB in respect of, all Losses that
                  arise (and incurred by either party to the Contract) if the
                  Information is incomplete, inaccurate or contains errors.
                </li>
                <li>
                  To the extent permitted by law, FLRHUB disclaims all
                  responsibility for Your failure to inform FLRHUB of any
                  changes to any Information which impacts, or may impact, upon
                  Your subscription to the Site.
                </li>
              </ol>
            </li>
            <li>
              FORCE MAJEURE
              <p>
                Neither party will be liable to the other party for any delay or
                failure to fulfil obligations under the Contract to the extent
                that such delay or failure arises from unforeseen causes beyond
                the first-mentioned party’s reasonable control.
              </p>
            </li>
            <li>
              NOTICES AND ELECTRONIC COMMUNICATION
              <ol>
                <li>
                  Any notice required under the Contract may be given by any
                  party, including any director or authorised person of that
                  party. Any notice may be given at that party’s registered
                  address or other address stipulated in any notice provided by
                  that party or as notified in writing for the purposes of Your
                  subscription. Without limitation, this includes any electronic
                  address notified to the other party.
                </li>
                <li>
                  You agree that:
                  <ol class="alpha-list">
                    <li class="list">
                      if the Your signature or execution is required, the
                      requirement is taken to have been met by an electronic
                      communication; and
                    </li>
                    <li class="list">
                      if FLRHUB is required to produce a document that is in the
                      form of paper, the requirement is taken to have been met
                      by an electronic communication.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              NO TRANSFER OR ASSIGNMENT OF SUBSCRIPTION OR BENEFITS
              <p>
                You may not transfer or assign Your subscription or the
                associated benefits except as expressly allowed in these Terms.
              </p>
            </li>
            <li>
              GOVERNING LAW
              <p>
                The Contract is governed by the laws of South Australia and each
                party irrevocably and unconditionally submits to the
                non-exclusive jurisdiction of courts exercising jurisdiction
                there.
              </p>
            </li>
            <li>
              VARIATION
              <p>
                FLRHUB may in its reasonable discretion change these Terms, or
                any aspect of Your subscription including any benefits, by
                posting the changes on the Site. Any changes implemented by
                FLRHUB will not affect Your rights to cancel Your subscription
                at any time. Any increase in the applicable Subscription Fee
                will not apply before the end of your then current monthly
                subscription period. If any change to these Terms is found
                invalid, void, or for any reason unenforceable, that change is
                severable and does not affect the validity and enforceability of
                any remaining changes or conditions. Your continued subscription
                and use of the Site after any such change constitutes Your
                acceptance of the change. If You do not agree to any changes,
                You must cancel Your subscription in accordance with these
                Terms.
              </p>
            </li>
            <li>
              SEVERANCE
              <p>
                If any provision of the Contract is invalid, illegal or
                unenforceable, that provision must be severed from and ignored
                in the interpretation of the Contract to the minimum extent
                necessary and to the intent that the remaining provisions of the
                Contract remain in full force and effect.
              </p>
            </li>
            <li>
              FURTHER ASSURANCE
              <p>
                Each party at its own expense must do everything necessary to
                give full effect to the Contract.
              </p>
            </li>
            <li>
              DEFINITIONS
              <ol>
                <li>
                  In these Terms unless the contrary intention appears:
                  <ol class="alpha-list">
                    <li class="list">
                      <strong>“Contract”</strong> means the contract for Your
                      subscription to and use of the Site, constituted by these
                      Terms.
                    </li>
                    <li class="list">
                      <strong>“Consequential Loss”</strong> means consequential
                      loss and:
                      <ol class="roman-list">
                        <li class="list">indirect loss;</li>
                        <li class="list">loss of revenues;</li>
                        <li class="list">loss of reputation;</li>
                        <li class="list">loss of profits;</li>
                        <li class="list">
                          loss of actual or anticipated savings;
                        </li>
                        <li class="list">
                          loss of capital through over-payment or under-sale;
                        </li>
                        <li class="list">
                          lost opportunities, including opportunities to enter
                          into arrangements with third parties; and
                        </li>
                        <li class="list">loss or corruption of data.</li>
                      </ol>
                    </li>
                    <li class="list">
                      <strong>“FLRHUB”</strong> means FLRHUB Pty Ltd and any and
                      all of its “Related Bodies Corporate” as such term is
                      defined in the Corporations Act 2001 (Cth).
                    </li>
                    <li class="list">
                      <strong>“electronic communication”</strong> means a
                      communication of information in the form of data, text or
                      images by guided or unguided electromagnetic energy and
                      includes a scanned image or any other form of digital
                      storage.
                    </li>
                    <li class="list">
                      <strong>“Information”</strong> means all data and
                      information whatsoever provided by You for the purpose of
                      Your subscription to and use of the Site.
                    </li>
                    <li class="list">
                      <strong>“Loss”</strong> means any and all loss, liability,
                      damage, cost, and expense (including legal costs on a
                      solicitor-client basis), whether direct or Consequential
                      Loss, and whether present or future.
                    </li>
                    <li class="list">
                      <strong>“party”</strong> means a party to the Contract,
                      being FLRHUB or You, as the context requires.
                    </li>
                    <li class="list">
                      <strong>“Site”</strong> means the FLRHUB website.
                    </li>
                    <li class="list">
                      <strong>“Subscription Fee”</strong> means the monthly fee
                      payable by You for Your subscription to the Site as
                      notified by FLRHUB on the Site. The Subscription Fee
                      excludes GST.
                    </li>
                    <li class="list">
                      The singular includes the plural and the converse.
                    </li>
                    <li class="list">
                      Any reference in the Contract to the Customer or You also
                      includes its/Your respective successors or permitted
                      assigns. If the Customer constitutes more than one person
                      or entity, the Contract bind each of them jointly and
                      severally.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </section>
    <!-- .entry content -->

    <section id="footer" class="section-footer color-white reveal-blocks">
      <div class="container">
        <h2 class="h1">Any questions?</h2>
        <h3>
          Please email us at
          <a href="mailto:team@flrhub.com.au" target="_blank"
            >team@flrhub.com.au</a
          >
        </h3>
      </div>
      <div class="copyright-lines">
        <div class="inner">
          <div class="copy">
            &copy;2001-<span id="current-year">2022</span> All Rights Reserved.
            FLRHUB®
          </div>
          <nav class="links">
            <ul class="ul-reset">
              <li><a href="/privacy/">Privacy</a></li>
              <li><a href="/terms/">Terms</a></li>
            </ul>
          </nav>
          <div class="credit">
            Built by <a href="https://kwpx.au" target="_blank"><em>kwpx</em></a>
          </div>
        </div>
      </div>
    </section>
  </div>
  <!-- .page-content -->
</div>
<!-- .page-wrapper -->
