import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AnalyticsHelper } from 'src/app/helpers/AnalyticsHelper';

@Component({
  selector: 'app-create-job-type',
  templateUrl: './create-job-type.component.html',
  styleUrls: ['./create-job-type.component.scss'],
})
export class CreateJobTypeComponent implements OnInit {
  needSiteVisit: any;

  constructor(
    public dialog: MatDialog,
    public router: Router,
  ) {}

  ngOnInit(): void {
    AnalyticsHelper.instance.pageView('Create Job Type Modal');
  }

  closeCreateJobDialog() {
    this.dialog.getDialogById('create-job')?.addPanelClass('animate-out');
    setTimeout(() => {
      this.dialog.getDialogById('create-job')?.close();
    }, 500);
  }

  createJobByType(event: any) {
    if (event.needSiteVisit == 'No') {
      setTimeout(() => {
        this.router.navigateByUrl('job-board-post-no-visit');
        this.closeCreateJobDialog();
      }, 500);
    } else
      [
        setTimeout(() => {
          // this.router.navigateByUrl('job-board-post-visit');
          this.router.navigateByUrl(
            'job-board-post-no-visit?siteVisitRequired=true',
            { state: { siteVisitRequired: true } },
          );
          this.closeCreateJobDialog();
        }, 500),
      ];
  }
}
