import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AnalyticsHelper } from './helpers/AnalyticsHelper';
import { AuthHelper } from './helpers/AuthHelper';
import { DatabaseHelper } from './helpers/DatabaseHelper';
import { PaymentsHelper } from './helpers/PaymentsHelper';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'FLRHUB';

  constructor(
    private authHelper: AuthHelper,
    private dbHelper: DatabaseHelper,
    private paymentsHelper: PaymentsHelper,
    private analyticsHelper: AnalyticsHelper,
    private gtmService: GoogleTagManagerService,
  ) {
    this.analyticsHelper.logEvent('App launch', {});
    this.gtmService.addGtmToDom();
  }
}
